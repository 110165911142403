import React from "react";
import { PropertiesInput, WorkflowLandingTabs } from "../../components";
import { WorkflowLandingTab } from "../../models";
import { Grid, Typography, Box, CardContent, Card } from "@mui/material";

const tabs: Array<WorkflowLandingTab> = [
  {
    id: "0",
    label: "Description",
    value: (
      <>
        <Typography paragraph>
          Screenie computes the physicochemical and medicinal chemistry
          properties of molecules. It allows the user to construct a focused
          library from a larger set of compounds by applying filters (e.g.,
          PAINS, toxic and unstable moieties, infeasible ring structures, and
          extended Lipinski filters) based on these calculated properties.
        </Typography>
        <Box
          component="img"
          src="/images/hero/Screene.png"
          alt="Protein Info Visual"
          sx={{
            width: "50%", // Reduces the width to 80% of the parent container
            height: "auto",
            mt: 4, // Adds margin-top for space above the image
            mb: 2, // Adds margin-bottom for space below the image
            mx: "auto", // Centers the image horizontally
            display: "block", // Ensures the Box behaves like a block element
            p: 2, // Adds padding around the image inside the Box
          }}
        />
      </>
    ),
  },

  {
    id: "1",
    label: "References",
    value: (
      <>
        <Typography variant={"body1"} mb={1}>
          1. Lipinski, Christopher A. "Lead-and drug-like compounds: the
          rule-of-five revolution." Drug discovery today: Technologies 1.4
          (2004): 337-341.
        </Typography>
        <Typography variant={"body1"} mb={1}>
          2. Benet, Leslie Z., et al. "BDDCS, the Rule of 5 and drugability."
          Advanced drug delivery reviews 101 (2016): 89-98.
        </Typography>
        <Typography variant={"body1"} mb={1}>
          3. Bento, A. Patrícia, et al. "An open source chemical structure
          curation pipeline using RDKit." Journal of Cheminformatics 12 (2020):
          1-16.
        </Typography>
        <Typography variant={"body1"} mb={1}>
          4. Rings in Clinical Trials and Drugs: Present and Future | Journal of
          Medicinal Chemistry (acs.org), and Rings in Drugs | Journal of
          Medicinal Chemistry (acs.org)
        </Typography>
      </>
    ),
  },
];
function PropertiesHome() {
  return (
    <Grid container spacing={2.5} px={3} mt={2}>
      <Grid item xs={8}>
        <WorkflowLandingTabs tabs={tabs} />
      </Grid>
      <Grid item xs={4}>
        <PropertiesInput inFilterPage={false} />
      </Grid>
    </Grid>
  );
}

export default PropertiesHome;

import React, { useEffect } from "react";

import { useAppSelector } from "../../app/hooks";
import { Link, useNavigate } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";
import { selectTaskStatusProperties } from "./SnakebarSlice";
import { Button } from "@mui/material";

function MyAppAlert() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { properties } = useAppSelector(selectTaskStatusProperties);

  const generateSnackbarKey = () =>
    `snackbar-${Math.random().toString(36).substring(2, 9)}`;

  const navigate = useNavigate();

  const handleSnackbarActionClick = (url: string, key: string) => {
    closeSnackbar(key); // Close the snackbar when action is clicked
    navigate(url);
  };

  useEffect(() => {
    const key = generateSnackbarKey();

    if (properties.status != "watched") {
      if (properties.status === "success") {
        enqueueSnackbar(properties.msg, {
          key: key,
          variant: properties.varient,
          autoHideDuration: properties.autoHideTime,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          action: (
            <Button
              onClick={() => handleSnackbarActionClick(properties.url, key)}
              style={{ color: "white" }}
            >
              Click Here
            </Button>
          ),
        });
      } else {
        enqueueSnackbar(properties.msg, {
          key: key,
          variant: properties.varient,
          autoHideDuration: properties.autoHideTime,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      }
    }
  }, [properties.status]);
  return <React.Fragment></React.Fragment>;
}

export default function GlobalAlert() {
  return (
    <SnackbarProvider maxSnack={10}>
      <MyAppAlert />
    </SnackbarProvider>
  );
}

import { Login, Logout } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userWorkflowValidation } from "../../context/authentication/userWorkflowValidation";

function UserMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {
    logOut,
    user,
    updatePassword,
    accessWorkflows,
    worklowValidation,
    userSubscription,
    razorpaySubInfo,
  } = useUserAuth();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getInitials = (name: string) => {
    const nameParts = name.split(" ");
    const firstNameInitial = nameParts[0].charAt(0);
    let initials = `${firstNameInitial}`;
    if (nameParts.length > 1) {
      const lastNameInitial = nameParts[nameParts.length - 1].charAt(0);
      initials += `${lastNameInitial}`;
    }

    return initials;
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logOut();
    navigate("/");
  };

  const handleLogin = () => {
    setAnchorEl(null);
    navigate("/user/login");
  };
  const handleResetPassword = async () => {
    await updatePassword(user.email);
    alert("Reset Password Mail has been sent");
  };
  //console.log("User:", user);
  //console.log("User Display Name:", user?.displayName);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        {user && (
          <Typography sx={{ fontWeight: 500 }}>{user?.displayName}</Typography>
        )}
        <Tooltip title="User Menu">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 1 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {/* '#6644E8' */}
            {user?.displayName ? (
              <Avatar
                sx={{ bgcolor: deepOrange[700], width: 36, height: 36, p: 1 }}
              >
                {getInitials(user.displayName as string)}
              </Avatar>
            ) : (
              <Avatar sx={{ width: 36, height: 36 }}></Avatar>
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider /> */}
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>*/}
        {!user && (
          <MenuItem onClick={handleLogin}>
            <ListItemIcon>
              <Login fontSize="small" />
            </ListItemIcon>
            Login
          </MenuItem>
        )}
        {/* {user && <MenuItem onClick={handleClose}>{user.displayName}</MenuItem>} */}
        {user && (
          <MenuItem onClick={() => navigate("/user/profile")}>
            <ListItemIcon>Profile</ListItemIcon>
          </MenuItem>
        )}

        {user && (
          <div>
            <MenuItem onClick={handleResetPassword}>
              <ListItemIcon>Reset Password</ListItemIcon>
            </MenuItem>
            {
              accessWorkflows.length!==0 && (
                <MenuItem sx={{ pointerEvents: "none" }}>
              <ListItemIcon>
                Expires In :&nbsp;
                <span style={{ fontWeight: "bold", color: "#582FF1" }}>
                  {" "}
                  {userWorkflowValidation(worklowValidation) <= 0 ||
                  isNaN(userWorkflowValidation(worklowValidation))
                    ? "0"
                    : userWorkflowValidation(worklowValidation).toString()}{" "}
                  Days
                </span>{" "}
              </ListItemIcon>
            </MenuItem>
              )
            }

            {razorpaySubInfo && razorpaySubInfo.status && (
              <MenuItem sx={{ pointerEvents: "none" }}>
                <ListItemIcon>
                  Subscription:&nbsp;
                  <span
                    style={{
                      fontWeight: "bold",
                      color:
                        razorpaySubInfo.status === "active" &&
                        userSubscription.initial_status !== "cancelled"
                          ? "green"
                          : "#d32f2f",
                    }}
                  >
                    {razorpaySubInfo.status === "active" &&
                    userSubscription.initial_status !== "cancelled"
                      ? "Active"
                      : "Cancelled"}
                  </span>{" "}
                </ListItemIcon>
              </MenuItem>
            )}
          </div>
        )}
        {user && (
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
}

export default UserMenu;

export {};

interface Category {
  heading: string;
  data: string[];
}

const ADME_DATA_AND_TOX: Category[] = [
  {
    heading: "Absorption",
    data: ["caco", "lipo", "solubility", "pampa", "hia_hou", "pgp"],
  },
  { heading: "Distribution", data: ["bbb", "vdss", "ppbr", "vdss_log"] },
  {
    heading: "Metabolism",
    data: [
      "CYP1A2_in",
      "CYP2C19_in",
      "CYP2C9_in",
      "CYP2D6_in",
      "CYP3A4_in",
      "CYP2C9_sub",
      "CYP2D6_sub",
      "CYP3A4_sub",
    ],
  },
  {
    heading: "Excretion",
    data: ["hl", "hl_log", "clear_hep", "clear_hep_log"],
  },
  {
    heading: "Toxicity",
    data: [
      "mouse_oral",
      "LOAEL",
      "carci",
      "mutagen",
      "cytotoxicity",
      "hepato",
      "cardio",
      "respiratory",
      "clintox",
      "NR-AR",
      "NR-AR-LBD",
      "NR-AhR",
      "NR-Aromatase",
      "NR-ER",
      "NR-ER-LBD",
      "NR-PPAR-gamma",
      "SR-ARE",
      "SR-ATAD5",
      "SR-HSE",
      "SR-MMP",
      "SR-p53",
    ],
  },
];

// function to make adme and tox properties order based on given pdf.
export const ADME_TOX_ORDER = <T extends { apiName: string }>(
  data: T[],
  heading: string
): T[] => {
  const headingIndex = ADME_DATA_AND_TOX.findIndex(
    (item) => item.heading === heading
  );
  const indexMap = Object.fromEntries(
    ADME_DATA_AND_TOX[headingIndex].data.map((key, index) => [key, index])
  );

  data.forEach((item) => {
    const { apiName } = item;
    if (!(apiName in indexMap)) {
      indexMap[apiName] = Object.keys(indexMap).length;
    }
  });
  return data.sort((a, b) => indexMap[a.apiName] - indexMap[b.apiName]);
};

//function to get color and text for tooltip
export const ADME_TOX_TOOLTIP = (
  rowValue: string,
  rowLowerValue: number | null,
  rowUpperValue: number | null
): string[] => {
  let toolTipColorAndtext = [];
  if (rowLowerValue == null && rowUpperValue == null) {
    toolTipColorAndtext.push("Between Lower and Upper Limit");
    toolTipColorAndtext.push("#68b06a");
  } else if (
    parseFloat(rowValue) <= rowUpperValue &&
    parseFloat(rowValue) >= rowLowerValue
  ) {
    toolTipColorAndtext.push("Between Lower and Upper Limit");
    toolTipColorAndtext.push("#68b06a");
  } else if (rowLowerValue != null && parseFloat(rowValue) < rowLowerValue) {
    toolTipColorAndtext.push("Below Lower Limit");
    toolTipColorAndtext.push("#d95143");
  } else if (rowUpperValue != null && parseFloat(rowValue) > rowUpperValue) {
    toolTipColorAndtext.push("Above Upper Limit");
    toolTipColorAndtext.push("#d95143");
  } else if (
    rowLowerValue == null &&
    rowUpperValue != null &&
    parseFloat(rowValue) > rowUpperValue
  ) {
    toolTipColorAndtext.push("Above Upper Limit");
    toolTipColorAndtext.push("#d95143");
  } else if (
    rowLowerValue != null &&
    rowUpperValue == null &&
    parseFloat(rowValue) < rowLowerValue
  ) {
    toolTipColorAndtext.push("Below Lower Limit");
    toolTipColorAndtext.push("#d95143");
  } else {
    toolTipColorAndtext.push("Between Lower and Upper Limit");
    toolTipColorAndtext.push("#68b06a");
  }

  return toolTipColorAndtext;
};

// endpoints which requires custom values
// handling those custom values in addingCustomValue function
export const ADME_TOX_CUSTOM_ENDPOINTS: string[] = [
  "ppbr",
  "vdss_log",
  "hl_log",
  "mouse_oral",
  "LOAEL",
];

// function to add custom value for endpoints
export const ADD_ADME_TOX_CUSTOM_VALUES = (
  endpointName: string,
  value: string,
  molWt: string
): string => {
  // console.log("endpoints for custom values", endpointName, value,molWt);

  if (endpointName === "ppbr") {
    if (parseFloat(value) < 0) return "0";
    else if (parseFloat(value) > 100) return "100";
  }

  // Ranit's comment on 6/06/2024 : Remove transformations till further decision except PPBR
  else if (endpointName === "vdss_log" || endpointName === "hl_log") {
    // value is : 10^value
    let customValue = Math.pow(10, parseFloat(value)).toString();
    return customValue;
  } else if (endpointName === "LOAEL") {
    let customValue = Math.pow(10, -parseFloat(value)).toString();
    return customValue;
  } else if (endpointName === "mouse_oral") {
    let customValue = Math.pow(10, parseFloat(value));
    return (customValue * parseFloat(molWt) * 1000).toString();
  }

  return value;
};

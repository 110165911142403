import React from "react";
import { SOLAR_DEV } from "../../config";

export default function RGroupsChemlet() {
  return (
    <div className="chemlet rgroups" style={{margin:"1em"}}>
      <iframe
        title="R Groups"
        width="100%"
        height="800"
        src={`${SOLAR_DEV}/rgroup_v2/`}
      ></iframe>
    </div>
  );
}

import { ESP_SERVER_URL } from "../../config";
import httpCommon from "../../net/http-common";


export const addLigand = ({}) => {};

export const  getLigandInfo = async ({ smileData, params}: { smileData: FormData,params: any }) => {
  
 
  return httpCommon.post("/ligand/prepare",smileData, {
    params: {
        'smiles': params.smiles,
        'min_ph': params.min_ph.toString(),
        'max_ph': params.max_ph.toString(),
        'generate_conformers': params.generate_conformers.toString(),
        'generate_tautomers': params.generate_tautomers.toString(),
        'minimization_steps': params.minimization_steps.toString(),
        'num_conformers': params.num_conformers.toString(),
    },
    headers: {
      'accept': 'application/json',
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getSDF = ({ smileData,smiles,column_name }: { smileData: FormData,smiles: String, column_name:string }) => {
  return httpCommon.post("/ligand/validate", smileData, {
    params: {
      'column_name': column_name,
      'smiles': smiles
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
};

import { stringify } from "querystring";
import { useEffect, useState } from "react";
import InteractionType from "./InteractionTypeTable";
import { Typography, Card, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";

export interface SiteInfo {
  site: string;
  data: any;
}

const matrixObject = {
  res_name: "",
  hydrophobic: false,
  hbond: false,
  waterbridge: false,
  saltbridge: false,
  pistacking: false,
  metal: false,
  pication: false,
  halogen: false,
};
const columns: GridColDef[] = [
  {
    field: "res_name",
    headerName: "Residue",
    width: 120,
    renderCell: (params) => (
      <Typography sx={{ pl: 1 }}>{params.value}</Typography>
    ),
  },
  {
    field: "hydrophobic",
    headerName: "Hydrophobic",
    width: 150,
    type: "boolean",
  },
  {
    field: "hbond",
    headerName: "H-Bond",
    width: 150,
    type: "boolean",
  },
  {
    field: "waterbridge",
    headerName: "Water Bridge",
    width: 150,
    type: "boolean",
  },
  {
    field: "saltbridge",
    headerName: "Salt Bridge",
    width: 150,
    type: "boolean",
  },
  {
    field: "pistacking",
    headerName: "Pi-Stacking",
    width: 150,
    type: "boolean",
  },
  {
    field: "metal",
    headerName: "Metal",
    width: 150,
    type: "boolean",
  },
  {
    field: "pication",
    headerName: "Pi-Cation",
    width: 150,
    type: "boolean",
  },
  {
    field: "halogen",
    headerName: "Halogen",
    width: 150,
    type: "boolean",
  },
];

function InteractionTable(props: SiteInfo) {
  const [matrixDATA, setMatrixdata] = useState(null);

  function generateInteractionMatrix(data: any) {
    const MATRIX: any[] = [];
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (value.length > 1) {
        for (let i = 1; i < value.length; i++) {
          const res_name = value[i][0] + value[i][2];
          const index = MATRIX.findIndex((item) => item.res_name === res_name);
          if (index !== -1) {
            MATRIX[index][key] = true;
          } else {
            const newObj = { ...matrixObject, res_name: res_name, [key]: true };
            MATRIX.push(newObj);
          }
        }
      }
    });
    setMatrixdata(MATRIX);
  }
  const getRowId = (row: any) => row.res_name;
  useEffect(() => {
    generateInteractionMatrix(props.data);
  }, []);

  function getInteractionByType(type: string, interaction: any) {
    let data = [];
    if (interaction.length <= 1) {
      return [];
    }
    switch (type) {
      case "hydrophobic":
        // index, residue - RESTYPE, AA - RESCHAIN, Distance - DIST, Ligand Atom - LIGCARBONIDX, Protein Atom - PROTCARBONIDX
        data.push(["Residue", "AA", "Distance", "Ligand Atom", "Protein Atom"]);
        for (let i = 1; i < interaction.length; i++) {
          data.push([
            interaction[i][interaction[0].indexOf("RESNR")] +
              interaction[i][interaction[0].indexOf("RESCHAIN")],
            interaction[i][interaction[0].indexOf("RESTYPE")],
            interaction[i][interaction[0].indexOf("DIST")],
            interaction[i][interaction[0].indexOf("LIGCARBONIDX")],
            interaction[i][interaction[0].indexOf("PROTCARBONIDX")],
          ]);
        }
        // //console.log("data");
        // //console.log(data);
        break;
      case "hbond":
        //index, residue-RESNR + RESCHAIN, AA-RESTYPE, dist H-A-DIST_H-A , dist D-A-DIST_D-A, Donor Angle - DON_ANGLE
        //protein donor - PROTISDON, side chain - SIDECHAIN, donor atom - DONORIDX + DONORTYPE, acceptor atom-ACCEPTORIDX + ACCEPTORTYPE
        data.push([
          "Residue",
          "AA",
          "Dist H-A",
          "Dist D-A",
          "Donor Angle",
          "Protein Donor?",
          "Side Chain?",
          "Donor Atom",
          "Acceptor Atom",
        ]);

        for (let i = 1; i < interaction.length; i++) {
          data.push([
            interaction[i][interaction[0].indexOf("RESNR")] +
              interaction[i][interaction[0].indexOf("RESCHAIN")],
            interaction[i][interaction[0].indexOf("RESTYPE")],
            interaction[i][interaction[0].indexOf("DIST_H-A")],
            interaction[i][interaction[0].indexOf("DIST_D-A")],
            interaction[i][interaction[0].indexOf("DON_ANGLE")],
            interaction[i][interaction[0].indexOf("PROTISDON")] === true
              ? "✓"
              : "x",
            interaction[i][interaction[0].indexOf("SIDECHAIN")] === true
              ? "✓"
              : "x",
            interaction[i][interaction[0].indexOf("DONORIDX")] +
              "(" +
              interaction[i][interaction[0].indexOf("DONORTYPE")] +
              ")",
            interaction[i][interaction[0].indexOf("ACCEPTORIDX")] +
              "(" +
              interaction[i][interaction[0].indexOf("ACCEPTORTYPE")] +
              ")",
          ]);
        }

        break;
      case "waterbridge":
        data.push([
          "Residue",
          "AA",
          "Dist A-W", // DIST_A-W
          "Dist D-W", // DIST_D-W
          "Donor Angle", // DON_ANGLE
          "Water Angle", // WATER_ANGLE
          "Protein Donor?", // PROTISDON
          "Donor Atom", // "DONOR_IDX" + "DONORTYPE"
          "Acceptor Atom",
          "Water Atom",
        ]);

        for (let i = 1; i < interaction.length; i++) {
          data.push([
            interaction[i][interaction[0].indexOf("RESNR")] +
              interaction[i][interaction[0].indexOf("RESCHAIN")],
            interaction[i][interaction[0].indexOf("RESTYPE")],
            interaction[i][interaction[0].indexOf("DIST_A-W")],
            interaction[i][interaction[0].indexOf("DIST_D-W")],
            interaction[i][interaction[0].indexOf("DON_ANGLE")],
            interaction[i][interaction[0].indexOf("WATER_ANGLE")],
            interaction[i][interaction[0].indexOf("PROTISDON")] === true
              ? "✓"
              : "x",
            interaction[i][interaction[0].indexOf("DONOR_IDX")] +
              "(" +
              interaction[i][interaction[0].indexOf("DONORTYPE")] +
              ")",
            interaction[i][interaction[0].indexOf("ACCEPTOR_IDX")] +
              "(" +
              interaction[i][interaction[0].indexOf("ACCEPTORTYPE")] +
              ")",
            interaction[i][interaction[0].indexOf("WATER_IDX")],
          ]);
        }
        break;
      case "saltbridge":
        // //console.log(interaction);
        data.push([
          "Residue",
          "AA",
          "Distance", // DIST
          "Protein Positive", //PROTISPOS
          "Ligand Group", // LIG_GROUP
          "Ligand Atoms", // LIG_IDX_LIST,
        ]);
        for (let i = 1; i < interaction.length; i++) {
          data.push([
            interaction[i][interaction[0].indexOf("RESNR")] +
              interaction[i][interaction[0].indexOf("RESCHAIN")],
            interaction[i][interaction[0].indexOf("RESTYPE")],
            interaction[i][interaction[0].indexOf("DIST")],
            interaction[i][interaction[0].indexOf("PROTISPOS")] === true
              ? "✓"
              : "x",
            interaction[i][interaction[0].indexOf("LIG_GROUP")],
            interaction[i][interaction[0].indexOf("LIG_IDX_LIST")],
          ]);
        }

        break;
      case "pistacking":
        // //console.log(interaction);
        data.push([
          "Residue",
          "AA",
          "Distance", // CENTDIST
          "Angle", //ANGLE
          "Offset", // OFFSET
          "Stacking Type", // TYPE
          "Ligand Atoms", // LIG_IDX_LIST
        ]);

        for (let i = 1; i < interaction.length; i++) {
          data.push([
            interaction[i][interaction[0].indexOf("RESNR")] +
              interaction[i][interaction[0].indexOf("RESCHAIN")],
            interaction[i][interaction[0].indexOf("RESTYPE")],
            interaction[i][interaction[0].indexOf("CENTDIST")],
            interaction[i][interaction[0].indexOf("ANGLE")],
            interaction[i][interaction[0].indexOf("OFFSET")],
            interaction[i][interaction[0].indexOf("TYPE")],
            interaction[i][interaction[0].indexOf("LIG_IDX_LIST")],
          ]);
        }
        break;
      case "metal":
        data.push([
          "Residue",
          "AA",
          "Metal", // METAL_IDX + METAL_TYPE
          "Target", // TARGET_IDX + TARGET_TYPE
          "Distance",
          "Location",
        ]);

        for (let i = 1; i < interaction.length; i++) {
          data.push([
            interaction[i][interaction[0].indexOf("RESNR")] +
              interaction[i][interaction[0].indexOf("RESCHAIN")],
            interaction[i][interaction[0].indexOf("RESTYPE")],
            interaction[i][interaction[0].indexOf("METAL_IDX")] +
              "(" +
              interaction[i][interaction[0].indexOf("METAL_TYPE")] +
              ")",
            interaction[i][interaction[0].indexOf("TARGET_IDX")] +
              "(" +
              interaction[i][interaction[0].indexOf("TARGET_TYPE")] +
              ")",
            interaction[i][interaction[0].indexOf("DIST")],
            interaction[i][interaction[0].indexOf("LOCATION")],
          ]);
        }

        break;
      case "pication":
        data.push([
          "Residue",
          "AA",
          "Distance", // DIST
          "Offset", // OFFSET
          "Protein Charged?", // PROTCHARGED
          "Ligand Group", // LIG_GROUP
          "Ligand Atoms", // LIG_IDX_LIST
        ]);

        for (let i = 1; i < interaction.length; i++) {
          data.push([
            interaction[i][interaction[0].indexOf("RESNR")] +
              interaction[i][interaction[0].indexOf("RESCHAIN")],
            interaction[i][interaction[0].indexOf("RESTYPE")],
            interaction[i][interaction[0].indexOf("DIST")],
            interaction[i][interaction[0].indexOf("OFFSET")],
            interaction[i][interaction[0].indexOf("PROTCHARGED")] === true
              ? "✓"
              : "x",
            interaction[i][interaction[0].indexOf("LIG_GROUP")],
            interaction[i][interaction[0].indexOf("LIG_IDX_LIST")],
          ]);
        }

        break;
      case "halogen":
        data.push([
          "Residue",
          "AA",
          "Distance", // DIST
          "Doner Angle", // OFFSET
          "Acceptor Angle", // PROTCHARGED
          "Donor Atom", // LIG_GROUP
          "Acceptor Atom", // LIG_IDX_LIST
        ]);

        for (let i = 1; i < interaction.length; i++) {
          data.push([
            interaction[i][interaction[0].indexOf("RESNR")] +
              interaction[i][interaction[0].indexOf("RESCHAIN")],
            interaction[i][interaction[0].indexOf("RESTYPE")],
            interaction[i][interaction[0].indexOf("DIST")],
            interaction[i][interaction[0].indexOf("DON_ANGLE")],

            interaction[i][interaction[0].indexOf("ACC_ANGLE")],
            interaction[i][interaction[0].indexOf("DON_IDX")] +
              "(" +
              interaction[i][interaction[0].indexOf("DONORTYPE")] +
              ")",
            interaction[i][interaction[0].indexOf("ACC_IDX")] +
              "(" +
              interaction[i][interaction[0].indexOf("ACCEPTORTYPE")] +
              ")",
          ]);
        }

        break;

      default:
        break;
    }

    return data;
  }

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" sx={{ px: 3 }}>
            {props.site}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div></div>
          {matrixDATA && (
            <Box sx={{ width: "100%" }}>
              <DataGrid
                getRowId={getRowId}
                rows={matrixDATA}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                autoHeight
                autoPageSize
                pageSizeOptions={[10, 20, 50]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
              />
            </Box>
          )}
          {Object.keys(props.data).map((type) => (
            <div key={type}>
              <InteractionType
                site={props.site}
                type={type}
                data={getInteractionByType(type, props.data[type])}
              ></InteractionType>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default InteractionTable;

import { Stage } from "ngl";
import * as NGL from "ngl";
import React, { useEffect, useState } from "react";
import http from "../../net/http-common";

import { ESP_SERVER_URL } from "../../config";

import "../../css/esp.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchESPData, selectPdbInfo } from "../../views/PDBInfo/pdbInfoSlice";
import { CircularProgress, Alert, Snackbar, Button } from "@mui/material";

interface ESPProps {
  pdbId: string;
  jobDir: string;
}

const ESP = ({ pdbId, jobDir }: ESPProps) => {
  const pdbInfo = useAppSelector(selectPdbInfo);
  const dispatch = useAppDispatch();
  const [file, setFile] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);

  const downloadESPFile = () => {
    if (!file) {
      alert("file has not been loaded");
      return;
    }
    const href = window.URL.createObjectURL(new Blob([file]));

    const anchorElement = document.createElement("a");

    anchorElement.href = href;
    anchorElement.download = `${pdbId}_${jobDir}.pdb.pqr`;
    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  };

  useEffect(() => {
    if (!pdbId || !jobDir || pdbInfo?.esp?.data) return;

    const form = new FormData();
    form.append("pdb_file", "");

    dispatch(fetchESPData({ pdbID: pdbId, form, jobDir }))
      .then((res) => {
        if (res.payload.error) {
          setShowError(true);
          setErrorMsg("Error loading ESP data. Please try again later");
        }
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg("Error loading ESP data. Please try again later");
      });
  }, [pdbId, jobDir]);

  useEffect(() => {
    if (!pdbInfo?.esp?.data?.pqr_file) return;

    ////console.log(pdbInfo?.esp?.data?.pqr_file)
    fetch(`${ESP_SERVER_URL}/static/${jobDir}/${pdbInfo?.esp?.data?.pqr_file}`)
      .then((response) => {
        return response.text();
      })
      .then((pdbData) => {
        const pdbBlob = new Blob([pdbData], {
          type: "text/plain; charset=utf-8",
        });
        const pdbFile = new File([pdbBlob], "protein_structure1.pdb.pqr", {
          type: "text/plain; charset=utf-8",
        });
        setFile(pdbFile);
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg("Error visualizing ESP data. Please try again later");
      });
  }, [pdbInfo?.esp?.data?.pqr_file]);

  // async function addRep (comp :void | NGL.Component){
  //   if (!comp) return;

  //   async function innerHandler(comp: NGL.Component) {

  //     return new Promise((resolve) => {
  //       comp.addRepresentation('surface', {
  //         surfaceType: 'av',
  //         radiusType: 'explicit',
  //         colorScheme: 'electrostatic',
  //         scaleFactor: 4.0,
  //         opacity: 0.5,
  //         colorDomain: [-50, 50],
  //         colorScale: 'rwb',
  //       });
  //       resolve(comp);
  //     });
  //   }
  //   await innerHandler(comp);
  //   const time = new Date().getTime();
  //   //console.log("finally loaded", time);
  // }

  useEffect(() => {
    if (!file) return;
    //const time = new Date().getTime();
    ////console.log("file Loading", time);
    let stage = new Stage("esp-viewer");
    stage.loadFile(file, { ext: "pqr" }).then(function (o) {
      if (!o) return;
      o.addRepresentation("surface", {
        surfaceType: "av",
        radiusType: "explicit",
        colorScheme: "electrostatic",
        scaleFactor: 4.0,
        opacity: 0.5,
        colorDomain: [-50, 50],
        colorScale: "rwb",
      });

      setTimeout(() => {
        o.autoView();
      }, 500);
    });

    function handleResize() {
      stage.handleResize();
    }

    window.addEventListener("resize", handleResize);
  }, [file]);

  // useEffect(() => {

  //   async function loadAndAddRepresentation() {
  //     try {
  //       if (!file)
  //         return;
  //       let stage = new Stage('esp-viewer');
  //       let o = await stage.loadFile(file, { ext: 'pqr' });

  //       if (!o) {
  //         throw new Error('Object not loaded.');
  //       }

  //       await o.addRepresentation('surface', {
  //         surfaceType: 'av',
  //         radiusType: 'explicit',
  //         colorScheme: 'electrostatic',
  //         scaleFactor: 4.0,
  //         opacity: 0.5,
  //         colorDomain: [-50, 50],
  //         colorScale: 'rwb',
  //       });

  //     } catch (error) {

  //     }
  //   }
  //   loadAndAddRepresentation();
  // }, [file])

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      {!file ? (
        <CircularProgress />
      ) : (
        <>
          <div
            style={{
              width: "600px",
              textAlign: "right",
            }}
          >
            <Button onClick={downloadESPFile}>Download ESP File</Button>
          </div>
          <div id="esp-viewer"></div>
          <div>
            <i>
              Please wait while the viewer renders. Might take up to few minutes
            </i>
          </div>
          <div>
            <p>
              Reference : Practical High-Quality Electrostatic Potential
              Surfaces for Drug Discovery Using a Graph-Convolutional Deep
              Neural Network{" "}
            </p>
            <p>doi: 10.1021/acs.jmedchem.9b01129</p>
            <p>https://doi.org/10.1021/acs.jmedchem.9b01129</p>
          </div>
        </>
      )}
    </>
  );
};

export default ESP;

import { ESP_SERVER_URL } from "../../config";
import httpCommon from "../../net/http-common";


export const getTargetData = ({
  target
}: {
  target: string
}) => {
  return httpCommon.post(`/models/targetdiff`, "", {
    params: {
      target: target,
      num_max: "10",
    },
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};

      


function MolProperties() {
  return (
    <div className="chemlet mol-props" style={{margin:"10px"}}>
      <iframe
        title="Molecular Properties"
        width="100%"
        height="800"
        src={`http://15.207.149.15:8501/molecular_app/`}
      ></iframe>
    </div>
  );
}

export default MolProperties;

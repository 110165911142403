
function MoleculeClustring() {
    return (
      <div className="chemlet mol-props" style={{margin:"10px"}}>
        <iframe
          title="Molecular Properties"
          width="100%"
          height="800"
          src={`https://solar-dev.knowdisdata.com/clustering/`}
        ></iframe>
      </div>
    );
  }
  
  export default MoleculeClustring;
  
import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormData from "form-data";
import Grid from "@mui/material/Grid";

import {
  Alert,
  Button,
  Card,
  CardContent,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Typography,
  Snackbar,
} from "@mui/material";
import { FileUploader, JobsTable, WorkflowLandingTabs } from "../../components";
import { JOB_SERVER_URL, SERVER_URL } from "../../config";
import Loaders from "../../components/common/Loaders";
import { useUserAuth } from "../../context";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import http from "../../net/http-common";
import { WorkflowLandingTab } from "../../models";
import { useLocation, useNavigate } from "react-router-dom";

const tabs: Array<WorkflowLandingTab> = [
  {
    id: "0",
    label: "Description",
    value: (
      <>
        <Typography>
          The Ranker pipeline performs docking on a set of input ligands and
          filters the ones with a docking score greater than that of a specified
          reference ligand. Physicochemical filtering, followed by ADMET
          analysis, is performed on the ligands passing the docking threshold.
          The Ranker pipeline is integrated with MAIMol, such that the output of
          MAIMol can be passed to Ranker as input.
        </Typography>
        <Box
          component="img"
          src="images/new-descriptive-images/ranker_descriptive.png"
          alt="Protein Info Visual"
          sx={{
            width: "80%",
            height: "auto",
            mt: 2, // Adds margin-top for space above the image
            // mb: 1, // Adds margin-bottom for space below the image
            mx: "auto", // Centers the image horizontally
            display: "block", // Ensures the Box behaves like a block element
            // p: 1, // Adds padding around the image inside the Box
          }}
        />
      </>
    ),
  },
];

function RankerSubmitJobs() {
  const MODEL_NAME = "ranker";

  const [doLoadJobs, setDoLoadJobs] = useState(true);
  const [jobID, setJobID] = useState(-1);
  const [showSubmitMsg, SetShowSubmitMsg] = useState(false);
  const [rows, setRows] = useState<[]>();
  const [PDBFile, setPDBFile] = useState<File>(null);
  const [ligandsFile, setLigandsFile] = useState<File>(null);
  const [residue, setResidue] = useState("");
  const [protChain, setProtChain] = useState("");
  const [ligandColName, setLigandColName] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [fetchingFields, setfetchingFields] = useState(false);
  const [chains, setChains] = useState<string[]>([]);
  const [residues, setResidues] = useState<string[]>([]);

  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);

  const [maiToRankerJob, setMaiToRankerJob] = useState<boolean>(false);
  const [maiToRankerLoading, setMaiToRankerLoading] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useUserAuth();
  const handleFileUpload = async (file: File, name: string) => {
    switch (name) {
      case "pdb":
        setPDBFile(file);
        break;
      case "csv":
        setLigandsFile(file);
        break;
      default:
        //console.log("file type unknown");
        break;
    }
  };

  const handleDownloadSample = (fileType: string) => {
    const link = document.createElement("a");

    if (fileType === "protein") {
      link.download = "ranker_protein_sample.pdb";
      link.href = "/samples/ranker/rank_sample_input.pdb";
    } else if (fileType === "ligand") {
      link.download = "ranker_ligands_sample.csv";
      link.href = "/samples/ranker/rank_ligand_input.csv";
    }

    link.click();
  };

  useEffect(() => {
    if (!PDBFile) return;

    const form = new FormData();
    form.append("uploaded_protein_file", PDBFile);

    setfetchingFields(true);

    http
      .post(`${SERVER_URL}/pdb/chains`, form, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      })
      .then((response: any) => {
        setfetchingFields(false);
        setChains(response.data[0]);
        setResidues(response.data[1]);
        setProtChain(response.data[0][0]);
        setResidue(response.data[1][0]);
      })
      .catch((error) => {
        setfetchingFields(false);
      });
  }, [PDBFile]);

  useEffect(() => {
    if (!doLoadJobs || !user) return;
    http
      .get(`${JOB_SERVER_URL}/userjobs`, {
        params: {
          user_id: user.uid,
          model_name: MODEL_NAME,
          start: "0",
          end: "10",
        },
        headers: {
          accept: "application/json",
        },
      })
      .then((res) => {
        setDoLoadJobs(false);
        setRows(res.data);
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg(errorMessages.jobTableError);
        setDoLoadJobs(false);
        setRows([]);
      });
  }, [doLoadJobs]);

  // handler to submit job with the details coming from maimol model
  const maiToRankerJobHandler = async (state: any) => {
    try {
      setInProgress(true);

      const response = await http.post(
        `${JOB_SERVER_URL}/ranker/submit_from_maimol`,
        {},
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            uid: state.uid,
            maimol_id: state.maimol_id,
            model_name: state.model_name,
          },
        }
      );

      // making sure that the mai to ranker job not submit again for other activities in this page.
      setMaiToRankerJob(true);
      setJobID(response.data.task_id);
      SetShowSubmitMsg(true);
    } catch (error) {
      setShowError(true);
      setErrorMsg(errorMessages.submitJobError);
    }

    setInProgress(false);

    setTimeout(() => {
      setDoLoadJobs(true);
    }, 3000);
  };

  useEffect(() => {
    if (location.state && maiToRankerJob === false) {
      maiToRankerJobHandler(location.state);
      // Clear the state immediately to avoid it triggering again on navigation back
      navigate(location.pathname, { replace: true, state: null });
    }
  }, [location]);

  const handleSubmit = async (e: any) => {
    setInProgress(true);
    const form = new FormData();
    form.append("uid", user.uid);
    form.append("protein_file", PDBFile);
    form.append("ligand_file", ligandsFile);

    try {
      const response = await http.post(
        `${JOB_SERVER_URL}/ranker/submit`,
        form,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
          params: {
            protein_chain: protChain,
            residue: residue,
            smiles_column: ligandColName,
            model_name: MODEL_NAME,
          },
        }
      );

      setInProgress(false);
      setJobID(response.data.task_id);
      SetShowSubmitMsg(true);
      setDoLoadJobs(true);
    } catch (error) {
      setInProgress(false);
      setShowError(true);
      setErrorMsg(errorMessages.submitJobError);
    }
  };

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Box py={2}>
        <Grid container px={3} spacing={2} alignItems="stretch">
          <Grid item sm={12} md={8} sx={{ width: "100%" }} display={"flex"}>
            <WorkflowLandingTabs tabs={tabs} />
          </Grid>
          <Grid item sm={12} md={4} sx={{ width: "100%" }} display={"flex"}>
            <Card sx={{ height: 1, width: "100%" }}>
              <Grid item px={2} py={1.3} sx={{ backgroundColor: "grey.100" }}>
                <i className="ri-login-box-line"></i>
                <Typography
                  display={"inline-block"}
                  variant={"h6"}
                  sx={{ color: "grey.900" }}
                  ml={0.5}
                  fontWeight={"600"}
                >
                  {"Input"}
                </Typography>
              </Grid>
              <CardContent>
                <Typography variant="h6" my={1}>
                  Submit Jobs to Ranker
                </Typography>
                <FormControl fullWidth>
                  <Stack direction="column" spacing={1.5}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        sx={{
                          mt: 1,
                          color: "var(--shade-2900, #29283B)",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        Protein file (.pdb)
                      </Typography>
                      <Button
                        variant="text"
                        onClick={() => handleDownloadSample("protein")}
                      >
                        {"Download Sample"}
                      </Button>
                    </Box>
                    <FileUploader
                      accept={".pdb"}
                      handleFileUpload={(files) =>
                        handleFileUpload(files[0], "pdb")
                      }
                      deleteHandlerDisable={() => {
                        return false;
                      }}
                    />
                  </Stack>
                  <Stack direction="column" spacing={1.5}>
                    <FormControl>
                      <Typography
                        sx={{
                          mt: 1,
                          color: "var(--shade-2900, #29283B)",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        Residue Name
                      </Typography>
                      {fetchingFields ? <LinearProgress /> : ""}
                      <Select
                        disabled={residues?.length === 0}
                        value={residue}
                        label="Residue"
                        onChange={(e) => setResidue(e.target.value)}
                      >
                        {residues?.map((v, i) => {
                          return (
                            <MenuItem key={i} value={v}>
                              {v}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1.5}>
                    <FormControl>
                      <Typography
                        sx={{
                          mt: 1,
                          color: "var(--shade-2900, #29283B)",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        Protein Chain
                      </Typography>
                      {fetchingFields ? <LinearProgress /> : ""}
                      <Select
                        disabled={chains?.length === 0}
                        value={protChain}
                        label="Protein Chain"
                        onChange={(e) => setProtChain(e.target.value)}
                      >
                        {chains?.map((v, i) => {
                          return (
                            <MenuItem key={i} value={v}>
                              {v}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack
                    direction="column"
                    spacing={1.5}
                    mt={1}
                    p={2}
                    sx={{ border: "1px solid #d7d6d6", borderRadius: "0.2rem" }}
                  >
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        sx={{
                          mt: 1,
                          color: "var(--shade-2900, #29283B)",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        Ligands file (.csv)
                      </Typography>
                      <Button
                        variant="text"
                        onClick={() => handleDownloadSample("ligand")}
                      >
                        {"Download Sample"}
                      </Button>
                    </Box>
                    <FileUploader
                      accept={".csv"}
                      handleFileUpload={(files) =>
                        handleFileUpload(files[0], "csv")
                      }
                      deleteHandlerDisable={() => {
                        setLigandsFile(null);
                        return false;
                      }}
                      headerSelector={true}
                      handleSelectedHeader={setLigandColName}
                    />
                  </Stack>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!PDBFile || !ligandsFile}
                    sx={{ mt: 2 }}
                  >
                    Submit
                  </Button>
                </FormControl>
                <IndefiniteLoader state={inProgress} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid sx={{ width: "50%", mx: "auto" }}>
          {showSubmitMsg && (
            <Alert
              onClose={() => {
                SetShowSubmitMsg(false);
              }}
              sx={{ mt: 2 }}
              variant="filled"
              severity="success"
            >{`Job submitted with id ${jobID}`}</Alert>
          )}
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className="jobs-container"
          mt={2}
          px={3}
        >
          <Card sx={{ width: 1 }}>
            <CardContent>
              {doLoadJobs && <Loaders type={"table"} />}
              {!doLoadJobs && (
                <JobsTable
                  jobs={rows}
                  disableVisualize={false}
                  setDoLoadJobs={setDoLoadJobs}
                  nextUrl="visualize"
                  disableDownload={true}
                ></JobsTable>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </>
  );
}

export default RankerSubmitJobs;

function Hopper() {
  return (
    <div className="chemlet mol-props" style={{ margin: "10px" }}>
      <iframe
        title="Molecular Properties"
        width="100%"
        height="800"
        src={`https://gamma.knowdisdata.com/hopper/`}
      ></iframe>
    </div>
  );
}

export default Hopper;

import { Box, Typography } from "@mui/material";
import React from "react";

function ConditionalDisplayBox({ msg }: { msg: string }) {
  return (
    <div>
      <Box>
        <Typography sx={{ textAlign: "center" }} variant="h5" component={"div"}>
          {msg}
        </Typography>
      </Box>
    </div>
  );
}

export default ConditionalDisplayBox;

import React from "react";
import { SOLAR_DEV } from "../../config";

function PLIP() {
  return (
    <div className="chemlet plip">
      <iframe
        title="PLIP"
        width="100%"
        height="800"
        src={`${SOLAR_DEV}/PLIP/`}
      ></iframe>
    </div>
  );
}

export default PLIP;

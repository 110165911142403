import axios from "axios";
import { SERVER_URL } from "../config";
import { auth } from "../init/firebase";

const axiosInstance = axios.create({
  baseURL: SERVER_URL,
  headers: {
    "Content-type": "application/json",
    accept: "application/json",
    "Access-Control-Allow-Origin": "http://localhost:3000",
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  const user = await auth.currentUser;
  if (user) {
    const token = await user.getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;

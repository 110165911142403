import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormData from "form-data";

import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";

import {
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { JOB_SERVER_URL } from "../../../config";

function TargetDiffJobs() {
  const params = useParams();

  const [jobStatus, setJobStatus] = useState("NA");
  const [jobReady, setJobReady] = useState(false);
  const [targetData, setTargetData] = useState<any>();
  const [selectedRow, setSelectedRow] = useState(-1);
  const [inProgress, setInProgress] = useState(true);

  const [isTableVisible, setIsTableVisible] = useState(false);
  const jobID = params.jobID;

  useEffect(() => {
    axios
      .get(`${JOB_SERVER_URL}/status`, {
        params: {
          task_id: jobID,
        },
        headers: {
          accept: "application/json",
        },
      })
      .then((response) => {
        //console.log(response.data);
        const job_status = response.data.task_status;
        setJobStatus(job_status);
        if (job_status === "SUCCESS") {
          setJobReady(true);
        }
      });
  }, [jobID]);

  const handleSubmit = async (e: any) => {
    //console.log("submit clicked");

    await axios
      .get(`${JOB_SERVER_URL}/targetdiff/results`, {
        params: {
          task_id: jobID,
        },
        headers: {
          accept: "application/json",
        },
      })
      .then((response) => {
        //console.log(response);
        setTargetData(response.data);
        setInProgress(false);
        setIsTableVisible(true);
      });
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <Grid
        container
        direction="column"
        spacing={2}
        alignItems="center"
        justifyContent="center"
        className="form-container"
      >
        <Grid container item xs={8} md={6}>
          <Typography variant="h4" component="h2">
            Job ID {jobID}
          </Typography>{" "}
        </Grid>

        <Grid container item xs={8} md={12}>
          Job Status : {jobStatus}
        </Grid>
        {jobReady && (
          <Grid container item xs={8} md={12}>
            <Button variant="contained" onClick={handleSubmit}>
              Visualize Results
            </Button>
          </Grid>
        )}
      </Grid>

      <>
        {isTableVisible && (
          <>
            <Grid container spacing={2} className="viewer-container">
              <Grid item xs={8} md={8}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="left">Image</TableCell>
                        <TableCell align="left">SMILES</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(targetData).map((v, i) => {
                        return (
                          <>
                            <TableRow
                              key={i}
                              onClick={() => setSelectedRow(i)}
                              selected={i === selectedRow}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {targetData[v].id}
                              </TableCell>

                              <TableCell align="left">
                                <img
                                  src={`data:image/svg+xml;base64,${btoa(
                                    targetData[v].svg
                                  )}`}
                                  alt="2D structure"
                                  width="100"
                                  height="100"
                                ></img>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {targetData[v].smile}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </Box>
  );
}

export default TargetDiffJobs;

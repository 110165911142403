import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Fragment } from "react";
import { Button, Typography, Card, Modal } from "@mui/material";
import Viewer from "../../components/common/MolViewer";

import NorthEastIcon from "@mui/icons-material/NorthEast";
import ExploreIcon from "@mui/icons-material/Explore";
import { setSingleSimleForBridge } from "../AdmeTox/AdmeSlice";

import PropertiesTable from "../../components/Properties/PropertiesTable";

import { useEffect, useState, useRef } from "react";

import IndefiniteLoader from "../../components/common/IndefiniteLoader";

import { useDispatch, useSelector } from "react-redux";
import { selectPropertiesV2 } from "./propertiesSliceV2";
import { useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";

const PropertiesEvaluations: React.FC = () => {
  const [finalSmile, setFinalSmile] = useState(null);

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);

  const singleSmileForBridge = useAppSelector(
    (state) => state.admeProperty.properties.singleSmileForBridge
  );

  const screenieSingleSmilePropertyv3 = useAppSelector(
    (state: any) => state.propertiesV3.properties
  );

  const dispatch = useDispatch();

  interface Item {
    title: string;
    value: any;
    color: string;
    info: string;
  }

  interface Category {
    heading: string;
    data: Item[];
  }

  const SCREENIE_DATA: Category[] = [
    { heading: "Physicochemical Properties", data: [] },
    { heading: "MedChem Filters", data: [] },
    { heading: "Molecule AI Proprietary Filters", data: [] },
    { heading: "Toxic Filters", data: [] },
  ];

  const [screenie, setScreenie] = useState(SCREENIE_DATA);

  const [open, setOpen] = useState(false);
  const [molViewerId, setMolViewerId] = useState("molviewer");
  const [openImg, setOpenImg] = useState(false);
  // Other code...
  const handleOpenimg = () => {
    setOpenImg(true);
  };

  const handleCloseimg = () => {
    setOpenImg(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setMolViewerId("molviewer2"); // Set the id to "molviewer2" when opening the modal
  };

  const handleClose = () => {
    setOpen(false);
    setMolViewerId("molviewer"); // Reset the id to "molviewer" when closing the modal
  };

  const modelMap: Record<string, string> = {
    MolWt: "Physicochemical Properties",
    MolLogP: "Physicochemical Properties",
    NumRotatableBonds: "Physicochemical Properties",
    HBondDonors: "Physicochemical Properties",
    HBondAcceptors: "Physicochemical Properties",
    TPSA: "Physicochemical Properties",
    HeavyAtoms: "Physicochemical Properties",
    NumAromaticRings: "Physicochemical Properties",
    // SASA: "Physicochemical Properties",
    QED: "Physicochemical Properties",
    SA: "Physicochemical Properties",
    UndesiredRings: "Physicochemical Properties",
    Ghose: "MedChem Filters",
    Veber: "MedChem Filters",
    Egan: "MedChem Filters",
    Muegge: "MedChem Filters",
    Oprea: "MedChem Filters",
    REOS: "MedChem Filters",
    Mozziconacci: "MedChem Filters",
    Palm: "MedChem Filters",
    "Pfizer3/75": "MedChem Filters",
    Lipinski: "MedChem Filters",
    Zinc: "MedChem Filters",
    NolargeRings: "MedChem Filters",
  };

  const MaimodelMap: Record<string, string> = {
    // PAINS: "Molecule AI Proprietary Filters",
    "MAI Rings": "Molecule AI Proprietary Filters",
    "Unwanted Substructures": "Molecule AI Proprietary Filters",
    "MAI Lipinski+": "Molecule AI Proprietary Filters",
  };

  const ToxicModelMap: Record<string, string> = {
    BRENK: "Toxic Filters",
    NIH: "Toxic Filters",
    ZINC: "Toxic Filters",
    PAINS_A: "Toxic Filters",
    PAINS_B: "Toxic Filters",
    PAINS_C: "Toxic Filters",
  };

  const infoModelMap: Record<string, string> = {
    MolLogP: "Logarithm of the n-octanol/water partition coefficient at pH 7.4",
    MolWt: "Molecular weight (Daltons)",
    NumRotatableBonds: "Number of rotatable bonds",
    HBondDonors: "Number of hydrogen bond donors",
    HBondAcceptors: "Number of hydrogen bond acceptors",
    TPSA: "Total Polar Surface Area (Angstrom²)",
    HeavyAtoms: "Number of non-hydrogen atoms",
    NumAromaticRings: "Number of aromatic rings",
    QED: "Quantitative Estimate of Drug-likeness, a score based on a combination of multiple physicochemical properties",
    SA: "The synthetic accessibility score as a metric for the ease of synthesis",
  };

  interface MolInfo {
    SMILE: string;
  }

  const MOL_INFO: MolInfo = {
    SMILE: finalSmile,
  };
  const navigate = useNavigate();

  let viewer = useRef<Viewer>();

  const initViewer = async () => {
    viewer.current = new Viewer();
    try {
      // console.log("moleculeId", molViewerId);
      await viewer.current.init(molViewerId, {
        layoutShowControls: false,
        viewportShowExpand: false,
        collapseLeftPanel: true,
        layoutShowSequence: false,
      });
    } catch (err) {
      console.log("error", err);
    }
    // console.log(viewer.current);
  };

  const updateViewer = async (
    data: string,
    format:
      | "mmcif"
      | "cifCore"
      | "pdb"
      | "pdbqt"
      | "gro"
      | "xyz"
      | "mol"
      | "sdf"
      | "mol2"
  ) => {
    initViewer().then(() => {
      const _ref = viewer?.current?.loadStructureFromData(data, format, {
        dataLabel: "",
      });
    });
  };

  useEffect(() => {
    if (!screenieSingleSmilePropertyv3.data) {
      navigate("/virtual-screening/properties-v3");
    } else {
      setTimeout(() => {
        updateViewer(
          screenieSingleSmilePropertyv3.data["physico_chemical_properties"]
            .mol2,
          "mol2"
        );
        setImage(
          screenieSingleSmilePropertyv3.data["physico_chemical_properties"].svg
        );
      }, 100);
    }

    const setADMEAndToxValues = (DATA: any) => {
      const updatedScreenieData = [...SCREENIE_DATA];

      setImage(DATA["svg"]);

      for (const key in DATA) {
        if (modelMap[key]) {
          const heading = modelMap[key];
          const value = DATA[key];
          const newItem = {
            title: key,
            value: value,
            color: "",
            info: infoModelMap[key],
            failReason: "",
          };

          const categoryIndex = updatedScreenieData.findIndex(
            (category) => category.heading === heading
          );

          if (categoryIndex !== -1) {
            updatedScreenieData[categoryIndex].data.push(newItem);
          }
        }
      }

      setScreenie(updatedScreenieData);
      setFinalSmile(DATA.smiles);
    };

    const MedicalFiltersDataValues = (DATA: any) => {
      const updatedScreenieData = [...SCREENIE_DATA];

      for (const key in DATA) {
        if (modelMap[key]) {
          const heading = modelMap[key];
          let evalDATA: any = {};
          // can be a string data when read from rows
          if (typeof DATA[key] === "string") {
            // replace True and False with true and false. Else eval will fail
            evalDATA = DATA[key]
              .replace(/\bFalse\b/g, "false")
              .replace(/\bTrue\b/g, "true");
            evalDATA = eval(evalDATA);
          } else {
            evalDATA = DATA[key];
          }

          const value = evalDATA[0];
          const rule = evalDATA[2];

          const newItem = {
            title: key,
            value: value,
            color: "",
            info: rule,
            failReason: evalDATA[0] === false ? evalDATA[1][0] : "",
          };

          const categoryIndex = updatedScreenieData.findIndex(
            (category) => category.heading === heading
          );
          if (categoryIndex !== -1) {
            updatedScreenieData[categoryIndex].data.push(newItem);
          }
        }
      }
      setScreenie(updatedScreenieData);
    };

    const MAIFiltersDataValues = (DATA: any) => {
      const updatedScreenieData = [...SCREENIE_DATA];

      for (const key in DATA) {
        if (MaimodelMap[key]) {
          const heading = MaimodelMap[key];
          const value = DATA[key];

          const newItem = {
            title: key,
            value: value,
            color: "",
            info: "",
          };

          const categoryIndex = updatedScreenieData.findIndex(
            (category) => category.heading === heading
          );
          if (categoryIndex !== -1) {
            updatedScreenieData[categoryIndex].data.push(newItem);
            // //console.log("Item pushed to category:", heading);
          }
        }
      }
      setScreenie(updatedScreenieData);
    };

    const ToxicFiltersValues = (DATA: any) => {
      const updatedScreenieData = [...SCREENIE_DATA];
      for (const key in DATA) {
        if (ToxicModelMap[key]) {
          const heading = ToxicModelMap[key];
          const value = DATA[key];
          const newItem = {
            title: key,
            value: value,
            color: "",
            info: "",
          };
          const categoryIndex = updatedScreenieData.findIndex(
            (category) => category.heading === heading
          );
          if (categoryIndex !== -1) {
            updatedScreenieData[categoryIndex].data.push(newItem);
            // //console.log("Item pushed to category:", heading);
          }
        }
      }
      setScreenie(updatedScreenieData);
    };

    setADMEAndToxValues(
      screenieSingleSmilePropertyv3.data["physico_chemical_properties"]
    );
    MedicalFiltersDataValues(
      screenieSingleSmilePropertyv3.data["medchem_filters"]
    );
    MAIFiltersDataValues(screenieSingleSmilePropertyv3.data["mai_filters"]);
    ToxicFiltersValues(screenieSingleSmilePropertyv3.data["toxic_filters"]);

    if (singleSmileForBridge) {
      dispatch(setSingleSimleForBridge(null));
    }
  }, [open, molViewerId]);

  const handleButtonClick = () => {
    navigate("/adme", {
      state: {
        smilename: finalSmile,
      },
    });
  };

  return (
    <>
      {finalSmile && (
        <Box width="100%" py={2} pr={2} pl={2} sx={{ overflowX: "hidden" }}>
          <Box
            width="100%"
            mb={2}
            // mt={2}
            pr={1}
            pl={1}
            py={1}
            sx={{
              backgroundColor: "#cce5ff",
              borderRadius: 2,
              border: "1.5px solid #b8daff",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Box
                  width="100%"
                  sx={{ display: "flex", alignItems: "center", height: "100%" }}
                >
                  <Typography
                    sx={{
                      color: "#004085",
                      fontWeight: 700,
                      fontSize: 18,
                      whiteSpace: "normal",
                    }}
                  >
                    {MOL_INFO.SMILE}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  width="100%"
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ExploreIcon sx={{ fontSize: 12 }} />}
                    sx={{ fontSize: 14, paddingTop: 1, paddingBottom: 1 }}
                    onClick={handleButtonClick}
                  >
                    Get ADMET Report
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Card>
                <Grid height={"100%"}>
                  <Grid
                    container
                    direction="column"
                    spacing={0}
                    height={"100%"}
                  >
                    <Grid item xs={12} md={12} sx={{ m: 0, pl: 1, pr: 1 }}>
                      <div
                        id="molviewer"
                        style={{
                          height: "450px",
                          width: "100%",
                          position: "relative",
                        }}
                      ></div>
                    </Grid>
                    <Grid sx={{ display: "flex", alignSelf: "flex-end" }}>
                      <Fragment>
                        <Button onClick={handleOpen}>
                          View <NorthEastIcon />{" "}
                        </Button>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="molviewer2"
                          // aria-describedby="modal-modal-description"
                        >
                          <Box
                            sx={{
                              position: "absolute" as "absolute",
                              top: "50%",
                              left: "50%",
                              right: "40%",
                              transform: "translate(-50%, -50%)",
                              width: 1400,
                              bgcolor: "background.paper",
                              border: "2px solid #000",
                              boxShadow: 24,
                              p: 4,
                              height: 1000,
                            }}
                          >
                            <div
                              id="molviewer2"
                              style={{
                                height: "910px",
                                width: "100%",
                                position: "relative",
                              }}
                            ></div>
                          </Box>
                        </Modal>
                      </Fragment>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid item md={6} xs={12}>
              <Card>
                <Grid height={"100%"}>
                  <Grid
                    container
                    direction="column"
                    spacing={0}
                    height={"100%"}
                  >
                    <Grid item xs={12} md={12} sx={{ m: 0 }}>
                      {image && (
                        <div
                          style={{
                            width: "100%",
                            height: 450,
                            backgroundColor: "white",
                            display: "flex",
                            justifyContent: "center",
                            overflow: "hidden",
                            alignItems: "center",
                          }}
                        >
                          <img
                            alt="smile string"
                            src={`data:image/svg+xml;base64,${btoa(image)}`}
                            style={{ width: "90%", height: "100%" }}
                          ></img>
                        </div>
                      )}
                    </Grid>
                    <Grid sx={{ display: "flex", alignSelf: "flex-end" }}>
                      <Fragment>
                        <Button onClick={handleOpenimg}>
                          View <NorthEastIcon />{" "}
                        </Button>
                        <Modal
                          open={openImg}
                          onClose={handleCloseimg}
                          aria-labelledby="image"
                          // aria-describedby="modal-modal-description"
                        >
                          <Box
                            sx={{
                              position: "absolute" as "absolute",
                              top: "50%",
                              left: "50%",
                              right: "40%",
                              transform: "translate(-50%, -50%)",
                              width: 1400,
                              bgcolor: "background.paper",
                              border: "2px solid #000",
                              boxShadow: 24,
                              p: 4,
                              height: 950,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                id="image"
                                src={`data:image/svg+xml;base64,${btoa(image)}`}
                                alt="smile "
                                style={{
                                  width: "60%",
                                }}
                              />
                            </div>
                          </Box>
                        </Modal>
                      </Fragment>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={12}>
              <PropertiesTable screenie_data={screenie} />
            </Grid>
          </Grid>
        </Box>
      )}
      <IndefiniteLoader state={loading} />
    </>
  );
};

export default PropertiesEvaluations;

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCkmQKazoVK_fdNC4iI_KMLRP9LCUKEgb0",
  authDomain: "moleculegen-auth.firebaseapp.com",
  projectId: "moleculegen-auth",
  storageBucket: "moleculegen-auth.appspot.com",
  messagingSenderId: "1091580691749",
  appId: "1:1091580691749:web:a08045dab8ac53385586cc",
  measurementId: "G-SGRV4VQD5S",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;

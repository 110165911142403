import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import { Typography, Popover, Box } from "@mui/material";

function JobArgs({ data }: { data: string }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const inputData = JSON.parse(data);
  // //console.log(inputData);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // //console.log(data);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button aria-describedby={id} variant="text" onClick={handleClick}>
        Show
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2 }}>
          {Object.entries(inputData).map(([key, value]) => {
            // //console.log(key, value);
            return (
              <Typography key={key}>
                <strong>{key}:</strong> {value ? JSON.stringify(value) : "N/A"}
              </Typography>
            );
          })}
        </Box>
      </Popover>
    </>
  );
}

export default JobArgs;

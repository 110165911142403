import React, { useRef, useState, useEffect } from "react";
import { GridColDef, GridRowParams, GridToolbar } from "@mui/x-data-grid-pro";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { TabPanel } from "../../components/common/TabPanel";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  Typography,
} from "@mui/material";

import {
  DataGrid,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";

// custom toolbar with download cavity button
const CustomToolbar = (props: any) => {
  const { onClickDownload } = props;

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Box>
        <Button onClick={onClickDownload}>Download Cavity</Button>
      </Box>
    </GridToolbarContainer>
  );
};

function PromiscuityVisualize() {
  const [tabValue, setTabValue] = useState("1");
  const [tableVisible, settableVisible] = useState(false);
  const [rows, setRows] = useState<[]>();
  const [selectedRowsForDownload, setSelectedRowsForDownload] = useState<
    string[]
  >([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const promiscuityResults = useSelector(
    (state: any) => state.promiscuity.properties
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleRowClick = (
    params: GridRowParams, // GridRowParams
    event: any
  ) => {
    if (!params) return;
    navigate("/lab/promiscuity/viewer", {
      state: {
        id: params.row.id,
      },
    });
  };

  // Custom tooltip text and colors for cell value
  const handleNullValueOfColumn = (
    params: GridRenderCellParams<any, number>
  ) => {
    if (!params.value) {
      return <div>...</div>;
    }

    if (typeof params.value == "number")
      return <p>{params.value.toFixed(3)}</p>;
    else return <p>{params.value}</p>;
  };

  // rows selection for download
  const handleSelectionChange = (selectedRows: any) => {
    const selectedRowsData = rows
      .filter((row: any) => selectedRows.includes(row.id))
      .map((row: any) => {
        return row.cavity_url;
      });

    setSelectedRowsForDownload(selectedRowsData);
  };

  // download visualize table rows cavity files
  const downloadFile = (fileUrl: string) => {
    return new Promise((resolve, reject) => {
      // Resolve the promise after a short delay to ensure the download has started
      setTimeout(() => {
        const anchor = document.createElement("a");
        anchor.href = fileUrl;
        anchor.download = "";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        resolve(0);
      }, 2500);
    });
  };

  // handler for download button
  const handleDownloadClick = async () => {
    if (selectedRowsForDownload.length === 0) {
      alert("Please Select Atleast a row");
      return;
    }

    for (let i = 0; i < selectedRowsForDownload.length; i++) {
      try {
        const fileUrl = selectedRowsForDownload[i];
        await downloadFile(fileUrl);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  };

  //columns for data grid table
  //   {
  // 	"0": {
  // 		"Cavity": 1,
  // 		"Similarity_score": 0.711722,
  // 		"Docking_score": -6.327
  // 	}
  // }
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", headerAlign: "center", align: "center" },
    {
      field: "Uniprotid",
      headerName: "Uniprot ID",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: handleNullValueOfColumn,
    },
    {
      field: "InputPDBid",
      headerName: "Input PDB",
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "PDBid",
      headerName: "PDB ID",
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Chain_id",
      headerName: "Chain",
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Cavity",
      headerName: "Cavity",
      editable: false,
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "Similarity_score",
      headerName: "Similarity Score",
      editable: false,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "Docking_score",
      headerName: "Docking Score",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: handleNullValueOfColumn,
    },
  ];

  useEffect(() => {
    if (
      typeof promiscuityResults.promiscuityData != "undefined" &&
      promiscuityResults.promiscuityData.results
    ) {
      const modifiedRows = promiscuityResults.promiscuityData.results.map(
        (propertyObject: any) => {
          return {
            ...propertyObject,
            id: propertyObject.id + 1,
          };
        }
      );

      modifiedRows.sort((a: any, b: any) => a.id - b.id);
      setRows(modifiedRows);
      settableVisible(true);
    } else if (
      (typeof promiscuityResults.promiscuityData == "undefined" ||
        typeof promiscuityResults.promiscuityData.results == "undefined") &&
      !promiscuityResults.loading
    ) {
      navigate("/promiscuity");
    }
  }, [promiscuityResults]);

  return (
    <>
      <Grid container spacing={2} p={2} height={"95%"}>
        <Grid item container px={0} pt={3} height={"100%"}>
          <Grid item width={"100%"} sx={{ overflowY: "auto" }} height={"100%"}>
            {tableVisible ? (
              <Card sx={{ position: "relative" }}>
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      value={tabValue}
                      onChange={handleTabChange}
                      aria-label="filter tabs"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab label="Predictions" value="1" />
                    </TabList>
                  </Box>
                  {/* Predictions */}
                  <TabPanel value="1">
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <DataGrid
                        sx={{
                          "& .MuiDataGrid-columnHeaderTitle": {
                            whiteSpace: "normal",
                            lineHeight: "normal",
                          },
                          "& .MuiDataGrid-columnHeader": {
                            // Forced to use important since overriding inline styles
                            height: "unset !important",
                          },
                          "& .MuiDataGrid-columnHeaders": {
                            // Forced to use important since overriding inline styles
                            maxHeight: "175px !important",
                            textAlign: "center",
                          },
                        }}
                        rows={rows}
                        columns={columns}
                        onRowClick={handleRowClick}
                        rowHeight={100}
                        pagination
                        initialState={{
                          pagination: {
                            paginationModel: { pageSize: 5 },
                          },
                          // pinnedColumns: { left: ["id", "smiles"] },
                        }}
                        checkboxSelection
                        onRowSelectionModelChange={handleSelectionChange}
                        pageSizeOptions={[5, 10, 20]}
                        autoHeight
                        // slots={{ toolbar: GridToolbar }}
                        slots={{
                          toolbar: (props) => (
                            <CustomToolbar
                              onClickDownload={handleDownloadClick}
                              {...props}
                            />
                          ),
                        }}
                      />
                    </Box>
                  </TabPanel>
                </TabContext>
              </Card>
            ) : (
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "50%",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ textAlign: "center" }}
                    variant="h5"
                    component={"div"}
                  >
                    No Data Available
                  </Typography>
                  <p style={{ textAlign: "center" }}></p>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
        <IndefiniteLoader state={promiscuityResults.loading} />
      </Grid>
    </>
  );
}

export default PromiscuityVisualize;

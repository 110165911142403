import { createContext, ReactNode, useContext, useReducer } from "react";
import { appReducer } from "./app.reducers";

type AppContextType = {
  currentPageTitle: string;
};

type AppDispatchContextType = {
  dispatch: Function;
};

const AppContext = createContext<AppContextType | null>(null);
const AppDispatchContext = createContext(null);

type AppProviderType = {
  children: ReactNode | Array<ReactNode>;
};

const AppProvider = ({ children }: AppProviderType) => {
  const [appState, dispatch] = useReducer(appReducer, initialAppState);

  return (
    <AppContext.Provider value={appState}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
};

const useApp = (): AppContextType => {
  const appContext = useContext<AppContextType>(AppContext);

  if (!appContext) {
    throw new Error("use appContext inside AppContext Provider");
  }

  return appContext;
};

const useAppDispatch = () => {
  const appDispatchContext = useContext(AppDispatchContext);

  if (!appDispatchContext) {
    throw new Error(
      "use appDispatchContext inside DispatchAppContext Provider"
    );
  }

  return appDispatchContext;
};

const initialAppState = {
  currentPageTitle: "Molecule Gen",
};

export { AppProvider, useAppDispatch, useApp };

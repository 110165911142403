import { Stage } from "ngl";
import { useEffect } from "react";

import "../../css/interface-residue.css";

const NGLTestViewer = (data: any) => {
  useEffect(() => {
    let stage = new Stage("interface-viewer", { backgroundColor: "white" });
    stage
      .loadFile(
        "http://localhost:9098/static/pdb-info/00D393844A/5l21_chothia.pdb",
        { ext: "pdb" }
      )
      .then(function (o) {
        if (!o) return;
        const antibody_chain = ":B";
        const antigen_chain = ":A";

        const ab_residues = "1-100:B";
        const ag_residues = "900-950:A";

        o.addRepresentation("cartoon", {
          sele: antibody_chain,
          name: "ab_chain",
          color: "blue",
          opacity: 0.1,
        });

        o.addRepresentation("cartoon", {
          sele: antigen_chain,
          name: "next",
          color: "aqua",
          opacity: 0.1,
        });

        o.addRepresentation("cartoon", {
          sele: ag_residues,
          name: "flatSyntaxRes",
          color: "yellow",
        });

        o.addRepresentation("cartoon", {
          sele: ab_residues,
          name: "flatSyntaxRes",
          color: "red",
        });

        o.autoView();
      });
    stage.autoView();
    function handleResize() {
      // //console.log("resized to: ", window.innerWidth, "x", window.innerHeight);
      stage.handleResize();
    }
    window.addEventListener("resize", handleResize);
  });

  return <div id="interface-viewer"></div>;
};

export default NGLTestViewer;

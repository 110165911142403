import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { type RootState } from "../../app/store";
import { JOB_SERVER_URL } from "../../config";
import { setTaskStatus } from "../../components/layout/SnakebarSlice";
import {
  getSingleSmileData,
  getBioisostericProperties,
} from "./BioisostericApi";

interface stateType {
  properties: {
    generated_data: any;
    properties_data : any;
    replacement_data : any;
    loading: boolean;
  };
}

const initialState: stateType = {
  properties: {
    generated_data: null, // set null to check if data is recieved from api
    properties_data : [],
    replacement_data : [],
    loading: false,
  },
};

export const fetchBioisostericProperties = createAsyncThunk(
  "bioisoProperties/fetchBioisostericProperties",
  async (task_id: string) => {
    // return await getBioisostericProperties({task_id});
    return testData;
  }
);

export const fetchSingleSmileData = createAsyncThunk(
  "bioisoProperties/fetchSingleSmileData",
  async (
    {
      smileName,
      properties,
      moleculeCount,
      leavingGroup,
      leavingIdx,
    }: {
      smileName: string;
      properties: string[];
      moleculeCount: Number;
      leavingGroup: string;
      leavingIdx: Number;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(
        setTaskStatus({
          status: "pending",
          varient: "info",
          msg: "Bioisosteric Replacement models running...",
          url: "",
          autoHideTime: 5000,
        })
      );

      const response = await getSingleSmileData({
        smileName,
        properties,
        moleculeCount,
        leavingGroup,
        leavingIdx,
      });

      dispatch(
        setTaskStatus({
          status: "success",
          varient: "success",
          msg: "Bioisosteric Replacement request finished successfully.",
          url: "/leader/bioisosteric/visualize",
          autoHideTime: 300000,
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        setTaskStatus({
          status: "error",
          varient: "error",
          msg: `${error?.response?.data?.detail || "Your request failed"}`,
          url: "",
          autoHideTime: 8000,
        })
      );
      return rejectWithValue(
        error.response?.data?.detail || "Failed to fetch data."
      );
    }
  }
);

const bioisoSlice = createSlice({
  name: "leaderReinventProperties",
  initialState,
  reducers: {
    setProperties(state, action) {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchBioisostericProperties.fulfilled,
        (state: stateType, action: any) => {
          state.properties.generated_data = action.payload;
          state.properties.loading = false;
        }
      )
      .addCase(fetchBioisostericProperties.rejected, (state, action: any) => {
        state.properties.loading = false;
      })
      .addCase(fetchBioisostericProperties.pending, (state) => {
        state.properties.loading = true;
      })
      .addCase(
        fetchSingleSmileData.fulfilled,
        (state: stateType, action: any) => {
          state.properties.generated_data = action.payload.generated_data;
          state.properties.properties_data = action.payload.properties_data;
          state.properties.replacement_data = action.payload.replacement_data;
          state.properties.loading = false;
        }
      )
      .addCase(fetchSingleSmileData.rejected, (state, action: any) => {
        state.properties.loading = false;
      })
      .addCase(fetchSingleSmileData.pending, (state) => {
        state.properties.loading = true;
      });
  },
});

export const { setProperties } = bioisoSlice.actions;
export const bioisoProperties = (state: RootState) => state.bioiso;
export default bioisoSlice.reducer;

const testData = [
  {
    id: 1,
    "INPUT-MOL-SMI": "CNC1(CCCCC1=O)C2=CC=CC=C2Cl",
    "GEN-MOL-SMI": "CNC1(c2ccc(OC)cc2)CCCCC1=O",
    "LEAVING-MOL-SMI": "c1ccccc1Cl",
    insertingFragSmi: "c1ccc(OC)cc1",
    logP: 2.25,
    mw: 233.14,
    qed: 0.87,
    sa: 2.92,
    pains: "PASS",
    maiLipinski: "PASS",
    unwantedSubstructures: "PASS",
    maiRings: "PASS",
  },
  {
    id: 2,
    inputMolSmi: "CNC1(CCCCC1=O)C2=CC=CC=C2Cl",
    genMolSmi: "CNC1(c2ccc(OO)cc2)CCCCC1=O",
    leavingFragSmi: "c1ccccc1Cl",
    insertingFragSmi: "c1ccc(OO)cc1",
    logP: 2.1,
    mw: 235.12,
    qed: 0.62,
    sa: 3.39,
    pains: "PASS",
    maiLipinski: "PASS",
    unwantedSubstructures: "FAIL",
    maiRings: "PASS",
  },
  {
    id: 3,
    inputMolSmi: "CNC1(CCCCC1=O)C2=CC=CC=C2Cl",
    genMolSmi: "CNC1(c2ccc([N+](=O)[O-])cc2)CCCCC1=O",
    leavingFragSmi: "c1ccccc1Cl",
    insertingFragSmi: "c1ccc([N+](=O)[O-])cc1",
    logP: 2.15,
    mw: 248.12,
    qed: 0.66,
    sa: 3.09,
    pains: "PASS",
    maiLipinski: "PASS",
    unwantedSubstructures: "FAIL",
    maiRings: "PASS",
  },
];

import { Stage } from "ngl";
import { useEffect, useState } from "react";
import NGL from "ngl";
import "../../css/hole.css";
import { SERVER_URL } from "../../config";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { fetchHoleData, selectPdbInfo } from "../../views/PDBInfo/pdbInfoSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CircularProgress, Button, Alert, Snackbar } from "@mui/material";
import { func } from "prop-types";

interface HoleAnalysisProps {
  pdbId: string;
  jobDir: string;
}

function HoleAnalysis({ pdbId, jobDir }: HoleAnalysisProps) {
  const [baseURL, setBaseURL] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [SPHFile, setSPHFile] = useState(null);
  const [PDBFile, setPDBFile] = useState(null);
  const pdbInfo = useAppSelector(selectPdbInfo);
  const [exportMsg, setExportMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);

  // console.log("pdbinfo",pdbInfo);
  // console.log("pdbinfo",pdbInfo.hole.data);
  // console.log("pdbinfo",pdbInfo.hole.data.hole_output);


  

  const dispatch = useAppDispatch();

  //console.log(pdbInfo,"pdbinfo");
  let stage: Stage = null;

  useEffect(() => {
    if (!pdbId || !jobDir) return;
    const form = new FormData();
    form.append("pdb_file", "");
    dispatch(fetchHoleData({ pdbID: pdbId, form, jobDir }))
      .then((res) => {
        if (res.payload.error) {
          setShowError(true);
          setErrorMsg("Error loading tunnel information");
        }
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg("Error loading tunnel information");
      });
  }, [jobDir, pdbId]);

  const renderCharts = async () => {
    // //console.log("render chart run")
    setLoading(true);
    setImageLoader(true);
    setBaseURL(SERVER_URL + `/static/${pdbInfo?.hole?.data.job_static_dir}`);

    fetch(
      `${SERVER_URL}/static/pdb-info/${jobDir}/${pdbInfo?.hole?.data.hole_output.sph}`
    )
      .then((response) => {
        return response.text();
      })
      .then((pdbData) => {
        const pdbBlob = new Blob([pdbData], {
          type: "text/plain; charset=utf-8",
        });
        const pdbFile = new File([pdbBlob], "protein_structure1.sph", {
          type: "text/plain; charset=utf-8",
        });
        setSPHFile(pdbFile);
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg("Error visualizing tunnel information");
      });

    fetch(`${SERVER_URL}/static/pdb-info/${jobDir}/${pdbId}.pdb`)
      .then((response) => {
        return response.text();
      })
      .then((pdbData) => {
        const pdbBlob = new Blob([pdbData], { type: "chemical/x-pdb" });
        const pdbFile = new File([pdbBlob], "protein_structure2.pdb", {
          type: "chemical/x-pdb",
        });
        setPDBFile(pdbFile);
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg("Error visualizing tunnel information");
      });
  };

  useEffect(() => {
    if (pdbInfo?.hole?.data) {
      renderCharts();
    }
  }, [pdbInfo.hole.data]);

  useEffect(() => {
    if (SPHFile && PDBFile) {
      setLoading(false);
    }
  }, [SPHFile, PDBFile]);

  useEffect(() => {
    if (loading) return;
    stage = new Stage("hole-viewer");
    //console.log("stage loaded");
    stage
      .loadFile(SPHFile, {
        defaultRepresentation: true,
        ext: "pdb",
      })
      .then(function (comp) {
        setImageLoader(false);
        if (!comp) return;
      })
      .catch((e) => {
        setImageLoader(false);
        //console.log("Error", e);
      });

    stage
      .loadFile(PDBFile, {
        defaultRepresentation: true,
        ext: "pdb",
      })
      .then(function (comp) {
        if (!comp) return;
      })
      .catch((e) => {
        //console.log("Error", e);
      });
    const div = document.getElementById("hole-viewer");
    div.style.width = "600px";
    div.style.width = "400px";
    setTimeout(() => {
      stage.handleResize();
      stage.autoView();
    }, 500);
  }, [loading]);

  const handleDownload = () => {
    if (pdbInfo?.hole?.data?.hole_output?.vmd_file) {
      setExportMsg("File download will begin shortly");
      fetch(
        `${SERVER_URL}/static/pdb-info/${jobDir}/${pdbInfo?.hole?.data.hole_output.vmd_file}`
      )
        .then((response) => {
          //console.log(response)
          return response.blob();
        })
        .then((fileData) => {
          const url = window.URL.createObjectURL(fileData);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${pdbId}.vmd`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          //console.log(fileData)
          setExportMsg("File downloaded successfully");
        })
        .catch((error) => {
          setExportMsg("Error downloading file");
        });
    } else {
      setExportMsg("Error downloading file");
    }
  };
  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Typography variant="h5" my={1}>
        Plots
      </Typography>
      <Grid container spacing={2} direction="row">
        {pdbInfo.hole.loading || pdbInfo.hole.data===null || !pdbInfo.hole.data.hole_output ? (
          <CircularProgress sx={{ my: 2, ml: 2 }} />
        ) : (
          <>
            {" "}
            <Grid item>
              <div>
                <Typography variant="h6" gutterBottom>
                  2D Plot
                </Typography>
                <img
                  className="plot-image"
                  src={`${baseURL}/${pdbInfo?.hole?.data?.hole_output.plot_2D}`}
                  alt="2D plot"
                />
              </div>
            </Grid>
            <Grid item>
              <div>
                <Typography variant="h6" gutterBottom>
                  3D Plot
                </Typography>

                <img
                  className="plot-image"
                  src={`${baseURL}/${pdbInfo?.hole?.data?.hole_output.plot_3D}`}
                  alt="3D plot"
                />
              </div>
            </Grid>{" "}
          </>
        )}
      </Grid>

      <Grid container mt={3}>
        <Grid item>
          <Typography variant="h5" gutterBottom>
            Tunnel Viewer{" "}
            <Button variant="text" onClick={handleDownload}>
              {"Download VMD File"}
            </Button>
          </Typography>
          {exportMsg && (
            <Alert
              onClose={() => {
                setExportMsg(null);
              }}
              sx={{ my: 2, px: 5 }}
              severity={
                exportMsg === "Error downloading file" ? "error" : "success"
              }
            >
              {exportMsg}
            </Alert>
          )}
          <>{loading ? <CircularProgress /> : <div id="hole-viewer"></div>}</>
        </Grid>
      </Grid>
    </>
  );
}

export default HoleAnalysis;

import { createContext, useContext, useEffect, useState } from "react";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  UserCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";
import HomeLoader from "../../components/common/HomeLoader";
import { auth } from "../../init/firebase";

import http from "../../net/http-common";
import { SERVER_URL } from "../../config";

interface User {
  uid: string;
  email: string;
  displayName?: string;
}

interface Subscription {
  uid: string;
  subscription_id: string;
  plan_id: string;
  initial_status: string;
  purchased_on: string;
  expiry_on: string;
  workflows: string[];
}

interface razorpaySubInfoType {
  sub_id: string;
  start_at: number;
  end_at: number;
  status: string;
  current_start:number;
  current_end:number;
}

interface UserAuthContextValue {
  user: User;
  logIn: (email: string, password: string) => Promise<UserCredential>;
  signUp: (
    name: string,
    email: string,
    password: string
  ) => Promise<UserCredential>;
  updatePassword: (email: string) => Promise<void>;
  logOut: () => Promise<void>;
  googleSignIn: () => Promise<UserCredential>;
  emailverified: boolean;
  accessWorkflows: string[];
  worklowValidation: string;
  getUserIdToken: () => Promise<string>;
  userSubscription: Subscription;
  razorpaySubInfo: razorpaySubInfoType;
}

const userAuthContext = createContext<UserAuthContextValue | null>(null);

export const freeWorkflows: string[] = ["Dashboard"];

export function UserAuthContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = useState<User>(null);
  const [emailverified, setEmailverified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);
  const [accessWorkflows, setaccessWorkflows] = useState<string[]>([]);
  const [worklowValidation, setWorklowValidation] = useState<string>("");
  const [userSubscription, setUserSubscription] = useState<Subscription | null>(
    null
  );
  const [razorpaySubInfo, setRazorpaySubInfo] =
    useState<razorpaySubInfoType | null>(null);

  function logIn(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(auth, email, password);
  }

  async function getUserIdToken(): Promise<string> {
    return await auth.currentUser.getIdToken();
  }

  // function signUp(email: string, password: string): Promise<UserCredential> {
  //   const userCreds =  createUserWithEmailAndPassword(auth, email, password);
  //   //console.log(userCreds)
  //   return userCreds
  // }

  async function signUp(
    name: string,
    email: string,
    password: string
  ): Promise<UserCredential> {
    // Create a new user with email and password
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    // Access the user object from the userCredential
    const newUser = userCredential.user;

    // Set the display name with the provided name
    await updateProfile(newUser, { displayName: name });

    // Send email verification
    await sendEmailVerification(newUser);

    // Sign out the user (optional)
    await signOut(auth);

    // Return the user credential
    return userCredential;
  }

  async function updatePassword(email: string): Promise<void> {
    await sendPasswordResetEmail(auth, email);
    //console.log("Password Reset email sent")
  }

  function logOut(): Promise<void> {
    return signOut(auth);
  }

  function googleSignIn(): Promise<UserCredential> {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  function handleLoaderEnd() {
    //console.log("triggered");
    setLoading(false);
  }

  useEffect(() => {
    // setTimeout(()=>{
    //   setLoading(false);
    // }, 2500);

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      // console.log("Auth current user", currentUser);
      if (currentUser) {
        if (currentUser.emailVerified === false) {
          setUser(null);
          // setEmailverified(false)
          alert("Please verify your email and Log in");
          signOut(auth);
        } else if (currentUser.emailVerified === true) {
          setUser(currentUser);
          setEmailverified(true);
          // try {

          // http://gamma.knowdisdata.com:9098/user/workflows?uid=${currentUser.uid}
          // http://localhost:8000/user/workflows
          // ${SERVER_URL}/user/workflows
          http
            .get(`${SERVER_URL}/user/workflows`, {
              params: {
                uid: currentUser.uid,
              },
            })
            .then((response) => {
              if (
                response.data.workflows &&
                response.data.workflows.length !== 0
              ) {
                setaccessWorkflows(response.data.workflows);
                setWorklowValidation(response.data.valid);
              }
            })
            .then(async () => {
              // console.log("get subs details");

              const userDBSub = await http.get(
                `${SERVER_URL}/payment/user-subscription-details`,
                {
                  params: {
                    uid: currentUser.uid,
                  },
                }
              );

              if (userDBSub.data.response) {
                // console.log("sub info", userDBSub.data.response);

                const { subscription_id } = userDBSub.data.response;

                setUserSubscription(userDBSub.data.response);
                return subscription_id;
              }
            })
            .then(async (subscription_id) => {
               

              const newWorkflows: string[] = [];
              const workflowQuery = newWorkflows
                .map((workflow) => `workflows=${encodeURIComponent(workflow)}`)
                .join("&");
              const subInfo = await http.get(
                `${SERVER_URL}/payment/get-razorpay-subscription/${subscription_id}?${workflowQuery}`,
                {
                  params: {
                    plan_id: "",
                    uid: currentUser.uid,
                    afterpayment: false,
                    // workflows:newWorkflows.map((n, index) => `storeIds[${index}]=${n}`).join('&')
                  },
                  paramsSerializer: {
                    indexes: true, // use brackets with indexes
                  },
                }
              );

              if (subInfo.data.response) {
                // console.log("get subs details from razorypay",subInfo.data.response);

                const { start_at, end_at, id, status,current_start,current_end } = subInfo.data.response;

                setRazorpaySubInfo({ sub_id: id, start_at, end_at, status,current_start,current_end });
              }
            })
            .catch((error) => {
              if (
                error.response &&
                (error.response.status === 401 || error.response.status === 501)
              ) {
                console.log("Unauthorized");
              } else if (error.message === "Network Error") {
                console.log("Network Error or Unauthorized");
              }
            });
        }
      } else {
        setUser(null);
      }
      // setUser(currentUser)
      //console.log("loading",userLoading)
      setUserLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const value: UserAuthContextValue = {
    user,
    logIn,
    signUp,
    updatePassword,
    logOut,
    googleSignIn,
    emailverified,
    accessWorkflows,
    worklowValidation,
    getUserIdToken,
    userSubscription,
    razorpaySubInfo,
  };

  return loading || userLoading ? (
    <HomeLoader handleLoaderEnd={handleLoaderEnd} />
  ) : (
    <userAuthContext.Provider value={value}>
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  //Custom Hook
  const context = useContext(userAuthContext);
  //console.log("context",context)
  if (!context) {
    throw new Error(
      "useUserAuth must be used within a UserAuthContextProvider"
    );
  }
  return context;
}

import React, { useState } from "react";
import { LigandInput, WorkflowLandingTabs } from "../../components";
import { WorkflowLandingTab } from "../../models";
import { Grid, Typography, Box, CardContent, Card } from "@mui/material";

const tabs: Array<WorkflowLandingTab> = [
  {
    id: "0",
    label: "Description",
    value: (
      <>
        <Typography paragraph>
          Ligand prep is an automated, intelligent ligand library preparation
          tool. Working with ligands provided as SMILES strings, it desalts,
          protonates (at a defined pH; default 7.4), and optionally generates
          tautomers and conformers for each ligand.
        </Typography>
        <Box
          component="img"
          src="/images/hero/Ligand_prep_dashcard.png"
          alt="Protein Info Visual"
          sx={{
            width: "85%",
            height: "auto",
            mt: 4,
            mb: 2,
            mx: "auto",
            display: "block",
            p: 2,
          }}
        />
      </>
    ),
  },
  {
    id: "1",
    label: "References",
    value: (
      <>
         <Typography variant={"body1"} mb={1}>
              1. Bento, A. Patrícia, et al. "An open source chemical structure
              curation pipeline using RDKit." Journal of Cheminformatics 12
              (2020): 1-16.
            </Typography>
            <Typography variant={"body1"} mb={4}>
              2. O'Boyle, Noel M., et al. "Open Babel: An open chemical
              toolbox." Journal of cheminformatics 3.1 (2011): 1-14.
            </Typography>
      </>
    ),
  },
];
function LigandHome() {
  const [setshowLigand, setSetshowLigand] = useState(false);
  return (
    <Grid container spacing={2.5} px={3} mt={1}>
      <Grid item xs={8}>
        <WorkflowLandingTabs tabs={tabs} />
       
      </Grid>
      <Grid item xs={4}>
        <LigandInput />
      </Grid>
    </Grid>
  );
}

export default LigandHome;

import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";

// filter function for grids
const applyFilters = (data: any, filters: any) => {
  const applyFilter = (item: any, filter: any) => {
    const fieldValue = item[filter.field];
    const filterValue = filter.value;
    if (filterValue === undefined) {
      // console.warn(`Filter value is undefined for field: ${filter.field} with operator: ${filter.operator}`);
      return true;
    }

    switch (filter.operator) {
      case "between":
        return (
          Number(fieldValue) >= Number(filter.value[0]) &&
          Number(fieldValue) <= Number(filter.value[1])
        );
      case "=":
        return Number(fieldValue) == Number(filter.value);
      case "!=":
        return Number(fieldValue) != Number(filter.value);
      case ">":
        return Number(fieldValue) > Number(filter.value);
      case ">=":
        return Number(fieldValue) >= Number(filter.value);
      case "<":
        return Number(fieldValue) < Number(filter.value);
      case "<=":
        return Number(fieldValue) <= Number(filter.value);
      case "contains":
        return String(fieldValue)
          .toLowerCase()
          .includes(String(filter.value).toLowerCase());
      case "equals":
        return (
          String(fieldValue).toLowerCase() ===
          String(filter.value).toLowerCase()
        );
      case "startsWith":
        return String(fieldValue)
          .toLowerCase()
          .startsWith(String(filter.value).toLowerCase());
      case "endsWith":
        return String(fieldValue)
          .toLowerCase()
          .endsWith(String(filter.value).toLowerCase());
      case "isEmpty":
        return (
          fieldValue === "" || fieldValue === null || fieldValue === undefined
        );
      case "isNotEmpty":
        return (
          fieldValue !== "" && fieldValue !== null && fieldValue !== undefined
        );
      case "isAnyOf":
        return filterValue
          .map((val: string) => String(val).toLowerCase())
          .includes(String(fieldValue).toLowerCase());
      default:
        return false;
    }
  };

  return data.filter((item: any) => {
    if (filters && filters.logicOperator && filters.logicOperator === "and") {
      return filters.items.every((filter: any) => applyFilter(item, filter));
    } else if (
      filters &&
      filters.logicOperator &&
      filters.logicOperator === "or"
    ) {
      return filters.items.some((filter: any) => applyFilter(item, filter));
    }
    return false;
  });
};

function ScreenieGridView({
  allRows,
  filters,
  table,
  tabValue,
  model,
  isTopLevelEnable,
}: {
  allRows: any;
  filters?: any;
  table?: any;
  tabValue: string;
  model: string;
  isTopLevelEnable: boolean;
}) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [moleculeInfo, setMoleculeInfo] = useState<any>(null);
  const [gridFilters, setGridFilters] = useState<any>(null);
  const [paginatedData, setPaginationData] = useState<any>(null);

  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  const handleClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
 

    const result = applyFilters(allRows, filters[Number(tabValue) - 1]);

    setPaginationData(
      result.slice((page - 1) * itemsPerPage, page * itemsPerPage)
    );

    setGridFilters(result);
    setPage(1);
  }, [filters]);

  // pagination data will change once page number change
  useEffect(() => {
    setPaginationData(
      gridFilters
        ? gridFilters.slice((page - 1) * itemsPerPage, page * itemsPerPage)
        : null
    );
  }, [page]);

  // function to change page number
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <>
      {tabValue === "4" && isTopLevelEnable ? (
        <></>
      ) : (
        <Box>
          <Grid container spacing={1} px={2} pt={2}>
            {paginatedData &&
              paginatedData.map((item: any, index: number) => (
                <Grid item key={index} xs={12} md={3} lg={3} xl={2.4}>
                  <Card
                    variant="outlined"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: 2,
                      flex: "1 0 0",
                      borderRadius: "10px",
                      border: "1px solid var(--shade-200, #E7EBF9)",
                      height: "100%",
                      "&:hover": {
                        boxShadow: 7,
                      },
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setDialogOpen(true);
                      setMoleculeInfo(item);
                    }}
                  >
                    <CardContent
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        p: 1,
                        pb: 0,
                        height: "100%",
                      }}
                    >
                      <Box m={1}>
                        <Typography>
                          ID: {item.ID ? item.ID : item.id}
                        </Typography>
                      </Box>
                      <Box sx={{ width: 1 }}>
                        <Box
                          component={"img"}
                          src={`data:image/svg+xml;base64,${btoa(item.svg || item.image)}`}
                          className="dashIcon" //
                          sx={{
                            display: "block",
                            m: "auto",
                            width: 1,
                            // border: "1px solid #EAEAEC",
                            // borderRadius: "4px",
                            transition: "transform 0.8s ease-in-out",
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.transform = "scale(120%)";
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.transform = "scale(100%)";
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          mt={1}
                          variant={"h6"}
                          fontWeight={600}
                          textAlign={"center"}
                          sx={{
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                            whiteSpace: "normal",
                          }}
                        >
                          {item.name ? item.name : ""}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
          <Box display="flex" justifyContent="center" mt={3} mb={1}>
            <Pagination
              count={Math.ceil(
                (gridFilters ? gridFilters.length : 0) / itemsPerPage
              )}
              page={page}
              onChange={handleChange}
              color="primary"
              size="large"
              variant="text"
            />
          </Box>
        </Box>
      )}

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" textAlign={"center"}>
          <Typography variant="h5">Molecule Information</Typography>
        </DialogTitle>

        {moleculeInfo && (
          <DialogContent
            sx={{
              textAlign: "center",
              p: 8,
            }}
          >
            <Box sx={{ width: 1 }}>
              <Box
                component={"img"}
                src={`data:image/svg+xml;base64,${btoa(moleculeInfo.svg || moleculeInfo.image)}`}
                className="dashIcon" //
                sx={{
                  display: "block",
                  m: "auto",
                  width: 300,
                  height: 300,
                  transition: "transform 0.8s ease-in-out",
                }}
              />
            </Box>

            {moleculeInfo.name && (
              <Typography
                variant="h5"
                sx={{
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {moleculeInfo.name}
              </Typography>
            )}
            <Typography
              variant="h5"
              sx={{
                wordBreak: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {moleculeInfo.smiles || moleculeInfo.SMILES}
            </Typography>

            {model === "screenie" && (
              <>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>QED </span>:{" "}
                  {parseFloat(moleculeInfo.QED).toFixed(3)}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>SA </span>:{" "}
                  {parseFloat(moleculeInfo.SA).toFixed(3)}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>MolLogP </span>:{" "}
                  {parseFloat(moleculeInfo.MolLogP).toFixed(3)}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>MolWt </span>:{" "}
                  {parseFloat(moleculeInfo.MolWt).toFixed(3)}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>TPSA </span>:{" "}
                  {parseFloat(moleculeInfo.TPSA).toFixed(3)}
                </Typography>
              </>
            )}
            {model === "leader_reinvent" && (
              <>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>Gen-QED </span>:{" "}
                  {moleculeInfo['Gen-QED']}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>Gen-SA-Score </span>:{" "}
                  {moleculeInfo['Gen-SA-Score']}
                </Typography>
              </>
            )}
            {model === "bioiso" && (
              <>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>QED </span>:{" "}
                  {parseFloat(moleculeInfo.QED).toFixed(3)}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>SA </span>:{" "}
                  {parseFloat(moleculeInfo.SA).toFixed(3)}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>LogP </span>:{" "}
                  {parseFloat(moleculeInfo.LOGP).toFixed(3)}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>MolWt </span>:{" "}
                  {parseFloat(moleculeInfo.MW).toFixed(3)}
                </Typography>
              </>
            )}
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}

export default memo(ScreenieGridView);

import React from "react";
import { SOLAR_DEV } from "../../config";

function MolSimilarity() {
  return (
    <div className="chemlet mol-similarity">
      <iframe
        title="Molecular Similarity"
        width="100%"
        height="800"
        src={`${SOLAR_DEV}/molecular_similarity`}
      ></iframe>
    </div>
  );
}

export default MolSimilarity;

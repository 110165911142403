import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
 
import {VariantType } from "notistack";


interface stateType {
   properties:{
    status:"pending"|"success"|"watched"|"error";
    varient:VariantType;
    msg:string;
    url?:string;
    autoHideTime:number
   }
}

const initialState: stateType = {
    properties:{
        status:"watched",
        varient:"info",
        msg:"",
        url:"",
        autoHideTime:0
    }
};

 
const taskStatusSlice = createSlice({
  name: "taskstatus",
  initialState,
  reducers: {
    setTaskStatus(state, action) {
      
      state.properties = action.payload;
    //   console.log('status data',state.properties);
      
    },
     
  },
  
});

export const { setTaskStatus} =
taskStatusSlice.actions;
  export const selectTaskStatusProperties = (state: RootState) => state.taskStatusProperty;
export default taskStatusSlice.reducer;

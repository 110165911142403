import { Box, Button, Grid, Typography } from "@mui/material";
import Viewer from "../../components/common/DockViewer";
import React, { useEffect, useState } from "react";
import { Color } from "molstar/lib/mol-util/color";
import { useLocation } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";

function DockingResults() {
  const state = useLocation();

  const [PDB, setPDB] = useState<any>("");
  const [SDF, setSDF] = useState<any>("");

  useEffect(() => {
    if (state.state.result) {
      var pdbqt1 = state.state.result[0]["pdb"];
      var pdbqt2 = state.state.result[0]["output"];

      setPDB(pdbqt1);
      setSDF(pdbqt2);

      Viewer.create("molviewer", [Color(0x33dd22), Color(0x1133ee)], true).then(
        (viewer) => {
          viewer.loadDataAndMerge([
            { data: pdbqt1, format: "pdbqt" },
            { data: pdbqt2, format: "sdf" },
          ]);
        }
      );
    }
  }, [state]);

  const downloadFile = (fileType: string, name: string) => {
    // Create a new Blob object with the content, and specify its type
    let blob: any = "";

    if (fileType === "pdbqt") blob = new Blob([PDB], { type: "text/plain" });
    else blob = new Blob([SDF], { type: "text/plain" });

    // Create a temporary URL for the blob
    const url = URL.createObjectURL(blob);

    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = `${name}.${fileType}`; // File name with .pdb extension
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the temporary link element and revoking the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Box
        width="79%"
        sx={{
          margin: "auto",
          marginBottom: 1,
          textAlign: "right",
        }}
      >
        <Button
          variant="contained"
          color="success"
          size="small"
          sx={{ m: 0.5 }}
          startIcon={<DownloadIcon fontSize="small" />}
          onClick={() => downloadFile("pdbqt", "protein")}
        >
          Prepared Protein
        </Button>
        <Button
          variant="contained"
          color="info"
          size="small"
          sx={{ m: 0.5 }}
          startIcon={<DownloadIcon fontSize="small" />}
          onClick={() => downloadFile("sdf", "interactions")}
        >
          Docking Outcome
        </Button>
      </Box>
      <Box
        width="79%"
        p={1}
        sx={{
          margin: "auto",
          backgroundColor: "#cce5ff",
          borderRadius: 1,
          border: "1.5px solid #b8daff",
          marginBottom: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              width="100%"
              sx={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <Typography
                className="wrap-word"
                sx={{
                  color: "#004085",
                  fontWeight: 700,
                  fontSize: 18,
                }}
              >
                {state.state.result[0]["SMILES"]}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              width="100%"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                color: "rgb(61, 32, 168);",
                fontWeight: "bold",
              }}
            >
              Docking Score : {state.state.result[0]["score"]}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          m: 0,
          pl: 1,
          pr: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          id="molviewer"
          style={{
            height: "500px",
            width: "80%",
            position: "relative",
            display: "flex", // Add display flex
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        ></div>
      </Grid>
    </>
  );
}

export default DockingResults;

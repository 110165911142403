import { ESP_SERVER_URL } from "../../config";
import httpCommon from "../../net/http-common";

export const getRamachandranPlot = ({
  jobDir,
  pdbID,
}: {
  jobDir: string;
  pdbID: string;
}) => {
  return httpCommon.post("/pdb/ramachandran", "", {
    params: {
      job_dir: jobDir,
      pdb_id: pdbID,
    },
  });
};

export const getErratData = ({
  form,
  pdbID,
  jobDir,
}: {
  form: FormData;
  jobDir: string;
  pdbID: string;
}) =>
  httpCommon.post(`/pdb/errat`, form, {
    params: {
      pdb_id: pdbID,
      job_dir: jobDir,
    },
  });

export const getSSData = ({
  form,
  pdbID,
  jobDir,
}: {
  form: FormData;
  jobDir: string;
  pdbID: string;
}) =>
  httpCommon.post("/pdb/secondary-struct", form, {
    params: {
      pdb_id: pdbID,
      job_dir: jobDir,
    },
  });

export const getHoleData = ({
  form,
  pdbID,
  jobDir,
}: {
  form: FormData;
  jobDir: string;
  pdbID: string;
}) =>
  httpCommon.post("/pdb/hole", form, {
    params: {
      pdb_id: pdbID,
      job_dir: jobDir,
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getESPData = ({
  form,
  pdbID,
  jobDir,
}: {
  form: FormData;
  jobDir: string;
  pdbID: string;
}) =>
  httpCommon.post(`${ESP_SERVER_URL}/pdb/esp`, form, {
    params: {
      pdb_id: pdbID,
      job_id: jobDir,
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Grid,
  LinearProgress,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import ConditionalDisplayBox from "../EmptyComponent/ConditionalDisplayBox";
import CompareIcon from "@mui/icons-material/Compare";
import GetAppIcon from "@mui/icons-material/GetApp";

import {
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
  GridRowParams,
  getGridNumericOperators,
} from "@mui/x-data-grid";
import InputNumberInterval from "../Properties/InputNumberInterval";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { CustomToolbar } from "../../views/Properties/PropertiesVisualizeV3";
import Viewer from "../../components/common/DockViewer";
import { Color } from "molstar/lib/mol-util/color";

import http from "../../net/http-common";

import CompareMolecules from "../../views/Chemlets/CompareMolecules";
import { LIFT_MOLBENCH, SOLAR_DEV } from "../../config";


export interface Item {
  title: string;
  value: any;
  color: string;
  info: string;
}

export interface Category {
  heading: string;
  data: Item[];
}

function MolEditorResultV2({
  molEditorResult,
  setMolEditorResult,
}: {
  molEditorResult: any[];
  setMolEditorResult: React.Dispatch<React.SetStateAction<any[]>>;
}) {
  let table = useGridApiRef();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openComapreDialog, setOpenComapreDialog] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [alertMsg, setAlertMsg] = useState<string>("");
  const [compareLoader, setCompareLoader] = useState<boolean>(false);

  const [sdfFile, setSDFFile] = useState<any>(null);
  const [pdbFile, setpdbFile] = useState<any>(null);
  const [allSelectedRows, setAllSelectedRows] = useState<number[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any>([]);
  const [dataToCompare, setDataToCompare] = useState<any>([]);
  const [isExportBtn, setIsExportBtn] = useState<boolean>(false);

  // console.log("selected rows", allSelectedRows);
  // console.log("sected rows data", selectedRowsData);

  const viewerRef = useRef<any>(null); // Reference to the viewer instance

  const handleRowClick = (
    params: GridRowParams, // GridRowParams
    event: any
  ) => {
    if (!params) return;
    setSDFFile(params.row.SDF);
    setpdbFile(params.row.pdbFileContent);
    setOpenDialog(true);
  };

  useEffect(() => {
    if (openDialog && pdbFile && sdfFile) {
      const pdbData = new Blob([pdbFile], { type: "application/octet-stream" });
      const sdfData = new Blob([sdfFile], { type: "application/octet-stream" });

      const pdbUrl = URL.createObjectURL(pdbData);
      // const pdbUrl = "/samples/autodock/ace2.pdbqt";

      const sdfUrl = URL.createObjectURL(sdfData);

      const initViewer = () => {
        // if (!viewerRef.current) {

        Viewer.create("molviewer", [Color(0x33dd22), Color(0x1133ee)], true)
          .then((viewer) => {
            viewerRef.current = viewer; // Save the viewer instance
            viewer.loadStructuresFromUrlsAndMerge([
              { url: pdbUrl, format: "pdb" },
              { url: sdfUrl, format: "sdf" },
            ]);
          })
          .catch((error) => {
            console.error("Error creating viewer:", error);
          });
        // } else {
        //   console.log('else');

        //   viewerRef?.current?.loadStructuresFromUrlsAndMerge([
        //     { url: pdbUrl, format: "pdb" },
        //     { url: sdfUrl, format: "sdf" }
        //   ]);
        // }
      };

      setTimeout(initViewer, 1000); // Slight delay to ensure the dialog is rendered

      return () => {
        URL.revokeObjectURL(pdbUrl);
        URL.revokeObjectURL(sdfUrl);
      };
    }
  }, [openDialog, pdbFile, sdfFile]);

  // between operator config
  const BetweenOperator: GridFilterOperator[] = [
    {
      label: "Between",
      value: "between",
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }
        if (filterItem.value[0] == null || filterItem.value[1] == null) {
          return null;
        }

        return ({ value }) => {
          return (
            value !== null &&
            filterItem.value[0] <= value &&
            value <= filterItem.value[1]
          );
        };
      },
      InputComponent: InputNumberInterval,
    },
    ...getGridNumericOperators(),
  ];

  /*
  const deleteRow=(params:any)=>{
    console.log('param',params);
    
    const allRows:any[]=molEditorResult.filter((item)=>item.smiles!==params.row.smiles);

    const allSelectedTempRows = allSelectedRows.filter((row: any) =>
      row!=params.id
    ).map(row=>row-1);

    allSelectedTempRows.sort((a, b) => a - b);

    console.log('new select',allSelectedTempRows);
    

    setAllSelectedRows(allSelectedTempRows);

    setMolEditorResult(allRows);
  }

  */

  // physico chemical properties columns
  const screeniePropertiesColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "Sr.No.",
      editable: false,
      width: 50,
    },
    {
      field: "smiles",
      headerName: "SMILES",
      editable: false,
      headerAlign: "center",
      width: 200,
    },
    {
      field: "docking_score",
      headerName: "Docking Score",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: BetweenOperator,
    },
    {
      field: "QED",
      headerName: "QED",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: BetweenOperator,
    },
    {
      field: "SA",
      headerName: "SA",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: BetweenOperator,
    },
    {
      field: "MolWt",
      headerName: "MolWt",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: BetweenOperator,
    },

    {
      field: "MolLogP",
      headerName: "MolLogP",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: BetweenOperator,
    },
    // {
    //   field: "delete",
    //   headerName: "Remove",
    //   // width: 200,
    //   editable: false,
    //   // sortable: false,
    //   renderCell: (params: any) => {
    //     return (
    //       <Box>
    //         <IconButton
    //           aria-label="delete"
    //           onClick={(event) => {
    //             event.stopPropagation();
    //            deleteRow(params);
    //           }}
    //         >
    //           <DeleteIcon color="error" />
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    // }
  ];

  // rows selection for download
  const handleSelectionChange = (selectedRows: any) => {
    setAllSelectedRows(selectedRows);
    const selectedRowsTempData = molEditorResult.filter((row: any) =>
      selectedRows.includes(row.id)
    );

    if (!isExportBtn && selectedRowsTempData.length === 1) {
      // condition to enable export btn first
      setIsExportBtn(true);
    }

    setSelectedRowsData(selectedRowsTempData);
  };

  // console.log("moleedit result", molEditorResult);

  const singleRowInteractions = async () => {
    setCompareLoader(true);

    // Convert SDF string to a file
    const sdfBlob1 = new Blob([selectedRowsData[0].SDF], {
      type: "application/vnd.kinar",
    });
    const sdfFile1 = new File([sdfBlob1], "structure1.sdf", {
      type: "application/vnd.kinar",
    });

    // Convert PDB string to a file
    const pdbBlob1 = new Blob([selectedRowsData[0].pdbFileContent], {
      type: "application/x-aportisdoc",
    });
    const pdbFile1 = new File([pdbBlob1], "structure1.pdb", {
      type: "application/x-aportisdoc",
    });

    const form1 = new FormData();

    form1.append("sdf_file", sdfFile1);
    form1.append("protein_pdbqt", pdbFile1);

    try {
      const response1 = await http.post(
        `${LIFT_MOLBENCH}/prolif_api/get_interactions/`,
        form1,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        }
      );

      // const [response1, response2] = await Promise.all([request1, request2]);

      setDataToCompare([
        {
          ...response1.data,
          smiles: selectedRowsData[0].smiles,
          srNo: allSelectedRows[0],
        },
      ]);

      setCompareLoader(false);
      setOpenComapreDialog(true);
    } catch (error) {
      setCompareLoader(false);
      setOpenAlert(true);
      setAlertSeverity("error");
      setAlertMsg("error while comparing molecules");
    }
  };

  const compareRows = async () => {
    if (allSelectedRows.length > 2) {
      setOpenAlert(true);
      setAlertSeverity("info");
      setAlertMsg("Please Select Only 2 Rows for Comparison.");
      return;
    }

    if (allSelectedRows.length === 1) {
      singleRowInteractions();
      return;
    }

    // console.log('allreos',allSelectedRows);

    setCompareLoader(true);

    // Convert SDF string to a file
    const sdfBlob1 = new Blob([selectedRowsData[0].SDF], {
      type: "application/vnd.kinar",
    });
    const sdfFile1 = new File([sdfBlob1], "structure1.sdf", {
      type: "application/vnd.kinar",
    });

    const sdfBlob2 = new Blob([selectedRowsData[1].SDF], {
      type: "application/vnd.kinar",
    });
    const sdfFile2 = new File([sdfBlob2], "structure2.sdf", {
      type: "application/vnd.kinar",
    });

    // Convert PDB string to a file
    const pdbBlob1 = new Blob([selectedRowsData[0].pdbFileContent], {
      type: "application/x-aportisdoc",
    });
    const pdbFile1 = new File([pdbBlob1], "structure1.pdb", {
      type: "application/x-aportisdoc",
    });

    const pdbBlob2 = new Blob([selectedRowsData[1].pdbFileContent], {
      type: "application/x-aportisdoc",
    });
    const pdbFile2 = new File([pdbBlob2], "structure2.pdb", {
      type: "application/x-aportisdoc",
    });

    const form1 = new FormData();
    const form2 = new FormData();

    form1.append("sdf_file", sdfFile1);
    form1.append("protein_pdbqt", pdbFile1);

    form2.append("sdf_file", sdfFile2);
    form2.append("protein_pdbqt", pdbFile2);

    try {
      const response1 = await http.post(
        `${LIFT_MOLBENCH}/prolif_api/get_interactions/`,
        form1,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        }
      );

      const response2 = await http.post(
        `${LIFT_MOLBENCH}/prolif_api/get_interactions/`,
        form2,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        }
      );

      // const [response1, response2] = await Promise.all([request1, request2]);

      setDataToCompare([
        {
          ...response1.data,
          smiles: selectedRowsData[0].smiles,
          srNo: allSelectedRows[0],
        },
        {
          ...response2.data,
          smiles: selectedRowsData[1].smiles,
          srNo: allSelectedRows[1],
        },
      ]);

      setCompareLoader(false);
      setOpenComapreDialog(true);
    } catch (error) {
      setCompareLoader(false);
      setOpenAlert(true);
      setAlertSeverity("error");
      setAlertMsg("error while comparing molecules");
    }
  };

  const exportTableResult = () => {
    setCompareLoader(true);
    const csvOptions = {
      fileName: "compared_molecules_from_molgen.csv",
      delimiter: ",",
    };
    const exportData = table.current.getDataAsCsv(csvOptions);
    const blob = new Blob([exportData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", csvOptions.fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
      setCompareLoader(false);
    }, 2000);
  };

  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={5000}
        onClose={() => setOpenAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenAlert(false)}
          severity={alertSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          background: "#fff",
        }}
      >
        <Grid
          item
          container
          px={1}
          pt={1}
          sx={{
            //  overflowY: "auto",

            height: "450px",
          }}
        >
          <Grid item width={"100%"}>
            {molEditorResult.length > 0 ? (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  mb={1}
                  alignItems={"center"}
                  position={"sticky"}
                  zIndex={1000}
                  sx={{
                    background: "#fff",
                  }}
                >
                  <Typography textAlign={"center"} sx={{ color: "#582FF1" }}>
                    Click on the rows to see the visualization
                  </Typography>
                  <Stack spacing={1} direction={"row"}>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={selectedRowsData.length < 1}
                      onClick={compareRows}
                      startIcon={<CompareIcon />}
                      sx={{
                        px: 2,
                        fontWeight: "bold",
                      }}
                    >
                      {allSelectedRows.length == 1 ? "View" : "Compare"}
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      color="success"
                      disabled={allSelectedRows.length === 0}
                      onClick={exportTableResult}
                      startIcon={<GetAppIcon />}
                      sx={{
                        px: 3,
                        fontWeight: "bold",
                      }}
                    >
                      Export
                    </Button>
                  </Stack>
                </Box>
                {compareLoader && (
                  <Box>
                    <LinearProgress color="success" />
                  </Box>
                )}
                <Box
                // Add margin top to provide space for the fixed header
                >
                  <Card sx={{ width: "100%", height: "380px" }}>
                    <DataGridPro
                      apiRef={table}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                        },
                        "& .MuiDataGrid-columnHeader": {
                          // Forced to use important since overriding inline styles
                          height: "unset !important",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          // Forced to use important since overriding inline styles
                          maxHeight: "175px !important",
                          textAlign: "center",
                        },
                      }}
                      rows={molEditorResult}
                      columns={screeniePropertiesColumns}
                      onRowClick={handleRowClick}
                      rowHeight={60}
                      pagination
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10, 20, 30]}
                      checkboxSelection
                      onRowSelectionModelChange={handleSelectionChange}
                      disableRowSelectionOnClick
                      rowSelectionModel={allSelectedRows}
                      slots={{
                        toolbar: (props) => (
                          <CustomToolbar props={props} isExport={false} />
                        ),
                      }}
                      // autoHeight
                    />
                  </Card>
                </Box>
              </>
            ) : (
              <Box position={"relative"} top={"50%"}>
                <ConditionalDisplayBox msg="Draw and Get the Result" />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .css-1q3t5pl-MuiPaper-root-MuiDialog-paper": {
            maxWidth: "1100px !important",
          },
          "& .css-esw9ho": {
            maxWidth: "1100px !important",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "1100px",
            height: "900px",
          }}
        >
          <div
            id="molviewer"
            style={{
              height: "100%",
              width: "100%",
              position: "relative",
            }}
            ref={viewerRef}
          ></div>
        </DialogContent>
      </Dialog>
      <CompareMolecules
        openComapreDialog={openComapreDialog}
        setOpenComapreDialog={setOpenComapreDialog}
        dataToCompare={dataToCompare}
      />
    </>
  );
}

export default memo(MolEditorResultV2);

import React from "react";
import { SOLAR_DEV } from "../../config";

function AutoFragDiff() {
  return (
    <div className="chemlet deep-dta">
      <iframe
        title="DeepDTA"
        width="100%"
        height="800"
        src={`${SOLAR_DEV}/Autofragdiff/`}
      ></iframe>
    </div>
  );
}

export default AutoFragDiff;

import React, { useEffect, useId, useState } from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormData from "form-data";
import Grid from "@mui/material/Grid";

import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../../context";
import JobsTable from "../../common/JobsTable";
import { JOB_SERVER_URL } from "../../../config";

function TargetDiffJob() {
  const MODEL_NAME = "targetdiff";

  const [inProgress, setInProgress] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const [jobstatusVisible, setJobstatusVisible] = useState(false);
  const [doLoadJobs, setDoLoadJobs] = useState(true);
  const [jobID, setJobID] = useState(-1);
  const [protFile, setProtFile] = useState<File>();
  const [ligandFile, setLigandFile] = useState<File>();
  const [pocketFile, setPocketFile] = useState<File>();
  const [numSamples, setNumSamples] = useState(10);

  const [rows, setRows] = useState<[]>();

  const navigate = useNavigate();
  const { user } = useUserAuth();

  const handleSubmit = async (e: any) => {
    //console.log("submit clicked");
    setInProgress(true);
    const data = new FormData();
    // do validation
    //console.log("prot", protFile);
    //console.log("ligand", ligandFile);
    //console.log("pocket", pocketFile);

    data.append("uid", user.uid);

    if (numSamples > 1000) {
      setInProgress(false);
      alert("maximum number of samples allowed is 1000");
      return;
    }
    data.append("num_samples", numSamples);

    if (!pocketFile) {
      if (!protFile || !ligandFile) {
        alert(
          "You need to submit either a protein pocket file or both protein and ligand file"
        );
        setInProgress(false);
      }
    }

    if (!pocketFile) {
      data.append("protein_file", protFile);
      data.append("ligand_file", ligandFile);
    } else {
      data.append("protein_pocket_file", pocketFile);
    }

    await axios
      .post(`${JOB_SERVER_URL}/targetdiff/submit`, data, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      })
      .then((response: any) => {
        if (response.status != 200) {
          setInProgress(false);
          alert(response.data.response);
        }
        //console.log(response.data);
        setFormVisible(false);
        setJobID(response.data.task_id);
        setJobstatusVisible(true);
        setInProgress(false);
      });
  };
  const handleFileUpload = async (file: File, name: string) => {
    switch (name) {
      case "protein":
        setProtFile(file);
        break;
      case "ligand":
        setLigandFile(file);
        break;
      case "pocket":
        setPocketFile(file);
        break;
      default:
        //console.log("file type unknown");
        break;
    }
  };

  const handleDownloadSample = () => {
    const link = document.createElement("a");

    // 👇️ set to relative path
    link.href = "/samples/diffmol/diffmol_sample_input.zip";

    link.click();
  };

  useEffect(() => {
    if (!doLoadJobs) return;

    const response = axios
      .get(`${JOB_SERVER_URL}/userjobs`, {
        params: {
          user_id: user.uid,
          model_name: MODEL_NAME,
          start: "0",
          end: "10",
        },
        headers: {
          accept: "application/json",
        },
      })
      .then((res) => {
        //console.log(res.data);
        setDoLoadJobs(false);
        setRows(res.data);
        //console.log("jobs", res.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doLoadJobs]);

  return (
    <Box sx={{ minWidth: 120 }}>
      <Grid container className="form-container" direction="column">
        <Grid item xs={6} md={6}>
          <Typography variant="h4" component="h2">
            Submit Jobs to Diff Mol
          </Typography>{" "}
        </Grid>

        {formVisible && (
          <Grid item xs={6} md={6}>
            <FormControl fullWidth>
              <Stack direction="row" spacing={2}>
                <Typography variant="button" display="block">
                  Protein File
                </Typography>
                <input
                  type="file"
                  accept="chemical/x-pdb"
                  onChange={(e) =>
                    handleFileUpload(e.target.files[0], "protein")
                  }
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography variant="button" display="block">
                  Ligand File
                </Typography>
                <input
                  type="file"
                  accept="chemical/x-mdl-sdfile"
                  onChange={(e) =>
                    handleFileUpload(e.target.files[0], "ligand")
                  }
                />
                <Button variant="outlined" onClick={handleDownloadSample}>
                  Download Sample
                </Button>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography variant="button" display="block">
                  Protein Pocket File
                </Typography>
                <input
                  type="file"
                  accept="chemical/x-pdb"
                  onChange={(e) =>
                    handleFileUpload(e.target.files[0], "pocket")
                  }
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography variant="button" display="block">
                  Number of samples
                </Typography>
                <input
                  type="number"
                  onChange={(e) => setNumSamples(parseInt(e.target.value))}
                />
              </Stack>
            </FormControl>
            <Grid container spacing={2} xs={8} md={6}>
              <Grid item>
                <Button variant="contained" onClick={handleSubmit}>
                  SUBMIT
                </Button>
                {inProgress && <CircularProgress />}

                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Go Back
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className="jobs-container"
      >
        <Grid item xs={8} md={6} sx={{ margin: "20px" }}>
          {jobstatusVisible && (
            <Typography>
              Job <a href={`/models/targetdiff/job/${jobID}`}>{jobID}</a>{" "}
              submitted
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className="jobs-container"
      >
        {!doLoadJobs && <JobsTable jobs={rows}></JobsTable>}
      </Grid>
    </Box>
  );
}

export default TargetDiffJob;

import React, { useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { StateObjectRef } from "molstar/lib/mol-state";

import http from "../../../net/http-common";
import Grid from "@mui/material/Grid";
import Viewer from "../../common/MolViewer";
import { Button, LinearProgress } from "@mui/material";

function TargetDiff() {
  const [target, setTarget] = useState("7te0");
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [inProgress, setInProgress] = useState(true);
  const [targetData, setTargetData] = useState<any>();
  const [selectedRow, setSelectedRow] = useState(-1);
  const [currentRef, setCurrentRef] = useState<StateObjectRef>(null);

  const navigate = useNavigate();

  let viewer = useRef<Viewer>();

  const handleChange = (event: SelectChangeEvent) => {
    setInProgress(true);
    setIsTableVisible(false);
    setTarget(event.target.value);
  };

  const submitNewJob = () => {
    //console.log("clicked");
    navigate("/models/targetdiff/submit");
  };

  useEffect(() => {
    http
      .post(`/models/targetdiff`, "", {
        params: {
          target: target,
          num_max: "10",
        },
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        //console.log(response);
        setTargetData(response.data);
        setInProgress(false);
        setIsTableVisible(true);
      });
  }, [target]);

  const initViewer = async () => {
    viewer.current = new Viewer();
    await viewer.current.init("limo-molviewer", {
      layoutShowControls: false,
      viewportShowExpand: false,
      collapseLeftPanel: true,
      layoutShowSequence: false,
    });
  };

  const updateViewer = async (
    data: string,
    format:
      | "mmcif"
      | "cifCore"
      | "pdb"
      | "pdbqt"
      | "gro"
      | "xyz"
      | "mol"
      | "sdf"
      | "mol2"
  ) => {
    initViewer().then(() => {
      const _ref = viewer?.current?.loadStructureFromData(
        data,
        format,
        { dataLabel: "" },
        currentRef
      );
    });
  };

  useEffect(() => {
    if (selectedRow === -1) return;
    updateViewer(targetData[selectedRow].mol, "mol2");
  }, [selectedRow, targetData]);

  return (
    <Box sx={{ minWidth: 120 }}>
      <Grid container spacing={2} className="viewer-container">
        <Grid item xs={4} md={4}>
          <FormControl fullWidth>
            <InputLabel id="target-select-label">Target</InputLabel>
            <Select
              labelId="target-select-label"
              id="target-simple-select"
              value={target}
              label="Target"
              onChange={handleChange}
            >
              <MenuItem value={"7te0"}>
                7TE0 - SARS-COV-2 main protease in complex with inhibitor
                pf-07321332
              </MenuItem>
              <MenuItem value={"6x8f"}>
                6X8F - crystal structure of tyk2 with compound 11{" "}
              </MenuItem>
              <MenuItem value={"5y0z_At1"}>
                5Y0Z_At1 - human sirt2 in complex with a specific inhibitor,
                npd11033
              </MenuItem>
              <MenuItem value={"4zzi_1ns"}>
                4ZZI_1ns - sirt1/activator/inhibitor complex
              </MenuItem>
              <MenuItem value={"4jsr_t1"}>
                4JSR_t1 - bace crystal structure with tricyclic aminothiazine
                inhibitor{" "}
              </MenuItem>
              <MenuItem value={"2rg5_t1"}>
                2RG5_t1 - phenylalanine pyrrolotriazine p38 alpha map kinase
                inhibitor compound 11b
              </MenuItem>
              <MenuItem value={"2p16_t1"}>
                2P16_t1 - factor xa in complex with the inhibitor apixaban
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} md={4}>
          <Button
            variant="contained"
            onClick={() => {
              submitNewJob();
            }}
          >
            Submit New Jobs
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="viewer-container">
        <Grid item xs={8} md={8}>
          {inProgress && <LinearProgress></LinearProgress>}
        </Grid>
      </Grid>
      <>
        {isTableVisible && (
          <>
            <Grid container spacing={2} className="viewer-container">
              <Grid item xs={8} md={8}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="left">Image</TableCell>
                        <TableCell align="left">SMILES</TableCell>
                        <TableCell align="left">Binding Affinity</TableCell>
                        <TableCell align="left">QED</TableCell>
                        <TableCell align="left">SA</TableCell>
                        <TableCell align="left">LogP</TableCell>
                        <TableCell align="left">Lipinksi</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(targetData).map((v, i) => {
                        return (
                          <>
                            <TableRow
                              key={i}
                              onClick={() => setSelectedRow(i)}
                              selected={i === selectedRow}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {targetData[v].id}
                              </TableCell>

                              <TableCell align="left">
                                <img
                                  src={`data:image/svg+xml;base64,${btoa(
                                    targetData[v].svg
                                  )}`}
                                  alt="2D structure"
                                  width="100"
                                  height="100"
                                ></img>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {targetData[v].smile}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {targetData[v].binding_affinity}
                              </TableCell>
                              <TableCell align="left">
                                {targetData[v].qed}
                              </TableCell>
                              <TableCell align="left">
                                {targetData[v].sa}
                              </TableCell>
                              <TableCell align="left">
                                {targetData[v].logp}
                              </TableCell>
                              <TableCell align="left">
                                {targetData[v].lipinksi}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={4} md={4}>
                <div
                  id="limo-molviewer"
                  style={{
                    height: "600px",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  Select any molecule to visualize
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </Box>
  );
}

export default TargetDiff;

import { useState } from "react";
import InteractionHeader from "./InteractionHeader";
import InteractionTable from "./InteractionTable";
import {
  Box,
  Typography,
  Card,
  Grid,
  Button,
  TextField,
  Alert,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import IndefiniteLoader from "../common/IndefiniteLoader";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import { SERVER_URL } from "../../config";

interface InteractionData {
  hydrophobic: (string | number | number[])[][];
  hbond: (string | number | boolean | number[])[][];
  waterbridge: (string | number | boolean | number[])[][];
  saltbridge: string[][];
  pistacking: string[][];
  pication: string[][];
  halogen: (string | number | number[])[][];
  metal: string[][];
}
const LigandInteractionTable = (data: any) => {
  const info: any = {
    // "pdb_id": "1vsn",
    // "interactions": {
    //   "NFT:A:283": {
    //     "hydrophobic": [
    //       [
    //         "RESNR",
    //         "RESTYPE",
    //         "RESCHAIN",
    //         "RESNR_LIG",
    //         "RESTYPE_LIG",
    //         "RESCHAIN_LIG",
    //         "DIST",
    //         "LIGCARBONIDX",
    //         "PROTCARBONIDX",
    //         "LIGCOO",
    //         "PROTCOO"
    //       ],
    //       [
    //         67,
    //         "TYR",
    //         "A",
    //         283,
    //         "NFT",
    //         "A",
    //         "3.84",
    //         1635,
    //         481,
    //         [
    //           3.244,
    //           26.542,
    //           6.729
    //         ],
    //         [
    //           0.294,
    //           28.886,
    //           7.491
    //         ]
    //       ],
    //       [
    //         133,
    //         "ALA",
    //         "A",
    //         283,
    //         "NFT",
    //         "A",
    //         "3.97",
    //         1635,
    //         994,
    //         [
    //           3.244,
    //           26.542,
    //           6.729
    //         ],
    //         [
    //           6.575,
    //           27.872,
    //           5.025
    //         ]
    //       ],
    //       [
    //         61,
    //         "ASP",
    //         "A",
    //         283,
    //         "NFT",
    //         "A",
    //         "3.67",
    //         1638,
    //         448,
    //         [
    //           -7.395,
    //           24.225,
    //           6.614
    //         ],
    //         [
    //           -6.9,
    //           21.561,
    //           9.09
    //         ]
    //       ],
    //       [
    //         67,
    //         "TYR",
    //         "A",
    //         283,
    //         "NFT",
    //         "A",
    //         "3.73",
    //         1621,
    //         482,
    //         [
    //           -2.692,
    //           25.499,
    //           5.958
    //         ],
    //         [
    //           -2.449,
    //           29.118,
    //           6.843
    //         ]
    //       ]
    //     ],
    //     "hbond": [
    //       [
    //         "RESNR",
    //         "RESTYPE",
    //         "RESCHAIN",
    //         "RESNR_LIG",
    //         "RESTYPE_LIG",
    //         "RESCHAIN_LIG",
    //         "SIDECHAIN",
    //         "DIST_H-A",
    //         "DIST_D-A",
    //         "DON_ANGLE",
    //         "PROTISDON",
    //         "DONORIDX",
    //         "DONORTYPE",
    //         "ACCEPTORIDX",
    //         "ACCEPTORTYPE",
    //         "LIGCOO",
    //         "PROTCOO"
    //       ],
    //       [
    //         66,
    //         "GLY",
    //         "A",
    //         283,
    //         "NFT",
    //         "A",
    //         false,
    //         "2.05",
    //         "2.95",
    //         "150.59",
    //         true,
    //         470,
    //         "Nam",
    //         1637,
    //         "O2",
    //         [
    //           0.422,
    //           22.065,
    //           7.006
    //         ],
    //         [
    //           -1.81,
    //           23.106,
    //           8.621
    //         ]
    //       ],
    //       [
    //         61,
    //         "ASP",
    //         "A",
    //         283,
    //         "NFT",
    //         "A",
    //         true,
    //         "2.26",
    //         "2.99",
    //         "134.61",
    //         true,
    //         451,
    //         "O3",
    //         1644,
    //         "N3",
    //         [
    //           -9.805,
    //           23.545,
    //           10.596
    //         ],
    //         [
    //           -9.236,
    //           20.949,
    //           9.223
    //         ]
    //       ],
    //       [
    //         19,
    //         "GLN",
    //         "A",
    //         283,
    //         "NFT",
    //         "A",
    //         true,
    //         "2.16",
    //         "3.11",
    //         "160.05",
    //         true,
    //         153,
    //         "Nam",
    //         1648,
    //         "N2",
    //         [
    //           2.82,
    //           18.145,
    //           6.806
    //         ],
    //         [
    //           3.976,
    //           15.409,
    //           7.712
    //         ]
    //       ],
    //       [
    //         25,
    //         "CYS",
    //         "A",
    //         283,
    //         "NFT",
    //         "A",
    //         false,
    //         "2.21",
    //         "3.09",
    //         "146.90",
    //         true,
    //         183,
    //         "Nam",
    //         1648,
    //         "N2",
    //         [
    //           2.82,
    //           18.145,
    //           6.806
    //         ],
    //         [
    //           3.306,
    //           18.62,
    //           9.817
    //         ]
    //       ],
    //       [
    //         61,
    //         "ASP",
    //         "A",
    //         283,
    //         "NFT",
    //         "A",
    //         true,
    //         "1.97",
    //         "2.99",
    //         "175.50",
    //         false,
    //         1644,
    //         "N3",
    //         451,
    //         "O3",
    //         [
    //           -9.805,
    //           23.545,
    //           10.596
    //         ],
    //         [
    //           -9.236,
    //           20.949,
    //           9.223
    //         ]
    //       ],
    //       [
    //         66,
    //         "GLY",
    //         "A",
    //         283,
    //         "NFT",
    //         "A",
    //         false,
    //         "2.33",
    //         "3.18",
    //         "140.42",
    //         false,
    //         1630,
    //         "N3",
    //         473,
    //         "O2",
    //         [
    //           0.027,
    //           24.446,
    //           5.449
    //         ],
    //         [
    //           0.194,
    //           25.01,
    //           8.576
    //         ]
    //       ],
    //       [
    //         158,
    //         "ASN",
    //         "A",
    //         283,
    //         "NFT",
    //         "A",
    //         false,
    //         "1.95",
    //         "2.92",
    //         "167.45",
    //         false,
    //         1628,
    //         "Nam",
    //         1199,
    //         "O2",
    //         [
    //           1.645,
    //           21.274,
    //           5.306
    //         ],
    //         [
    //           3.137,
    //           21.57,
    //           2.817
    //         ]
    //       ]
    //     ],
    //     "waterbridge": [
    //       [
    //         "RESNR",
    //         "RESTYPE",
    //         "RESCHAIN",
    //         "RESNR_LIG",
    //         "RESTYPE_LIG",
    //         "RESCHAIN_LIG",
    //         "DIST_A-W",
    //         "DIST_D-W",
    //         "DON_ANGLE",
    //         "WATER_ANGLE",
    //         "PROTISDON",
    //         "DONOR_IDX",
    //         "DONORTYPE",
    //         "ACCEPTOR_IDX",
    //         "ACCEPTORTYPE",
    //         "WATER_IDX",
    //         "LIGCOO",
    //         "PROTCOO",
    //         "WATERCOO"
    //       ],
    //       [
    //         159,
    //         "HIS",
    //         "A",
    //         283,
    //         "NFT",
    //         "A",
    //         "3.67",
    //         "3.13",
    //         "126.73",
    //         "116.36",
    //         true,
    //         1210,
    //         "Nar",
    //         1648,
    //         "N2",
    //         1757,
    //         [
    //           2.82,
    //           18.145,
    //           6.806
    //         ],
    //         [
    //           6.401,
    //           19.307,
    //           4.971
    //         ],
    //         [
    //           3.86,
    //           18.563,
    //           3.309
    //         ]
    //       ],
    //       [
    //         159,
    //         "HIS",
    //         "A",
    //         283,
    //         "NFT",
    //         "A",
    //         "4.03",
    //         "3.13",
    //         "126.73",
    //         "102.30",
    //         true,
    //         1210,
    //         "Nar",
    //         1628,
    //         "Nam",
    //         1757,
    //         [
    //           1.645,
    //           21.274,
    //           5.306
    //         ],
    //         [
    //           6.401,
    //           19.307,
    //           4.971
    //         ],
    //         [
    //           3.86,
    //           18.563,
    //           3.309
    //         ]
    //       ]
    //     ],
    //     "saltbridge": [
    //       [
    //         "RESNR",
    //         "RESTYPE",
    //         "RESCHAIN",
    //         "PROT_IDX_LIST",
    //         "RESNR_LIG",
    //         "RESTYPE_LIG",
    //         "RESCHAIN_LIG",
    //         "DIST",
    //         "PROTISPOS",
    //         "LIG_GROUP",
    //         "LIG_IDX_LIST",
    //         "LIGCOO",
    //         "PROTCOO"
    //       ]
    //     ],
    //     "pistacking": [
    //       [
    //         "RESNR",
    //         "RESTYPE",
    //         "RESCHAIN",
    //         "RESNR_LIG",
    //         "RESTYPE_LIG",
    //         "RESCHAIN_LIG",
    //         "PROT_IDX_LIST",
    //         "CENTDIST",
    //         "ANGLE",
    //         "OFFSET",
    //         "TYPE",
    //         "LIG_IDX_LIST",
    //         "LIGCOO",
    //         "PROTCOO"
    //       ]
    //     ],
    //     "pication": [
    //       [
    //         "RESNR",
    //         "RESTYPE",
    //         "RESCHAIN",
    //         "PROT_IDX_LIST",
    //         "RESNR_LIG",
    //         "RESTYPE_LIG",
    //         "RESCHAIN_LIG",
    //         "DIST",
    //         "OFFSET",
    //         "PROTCHARGED",
    //         "LIG_GROUP",
    //         "LIG_IDX_LIST",
    //         "LIGCOO",
    //         "PROTCOO"
    //       ]
    //     ],
    //     "halogen": [
    //       [
    //         "RESNR",
    //         "RESTYPE",
    //         "RESCHAIN",
    //         "RESNR_LIG",
    //         "RESTYPE_LIG",
    //         "RESCHAIN_LIG",
    //         "SIDECHAIN",
    //         "DIST",
    //         "DON_ANGLE",
    //         "ACC_ANGLE",
    //         "DON_IDX",
    //         "DONORTYPE",
    //         "ACC_IDX",
    //         "ACCEPTORTYPE",
    //         "LIGCOO",
    //         "PROTCOO"
    //       ],
    //       [
    //         67,
    //         "TYR",
    //         "A",
    //         283,
    //         "NFT",
    //         "A",
    //         true,
    //         "3.37",
    //         "156.70",
    //         "100.53",
    //         1626,
    //         "F",
    //         485,
    //         "O3",
    //         [
    //           -1.862,
    //           29.303,
    //           4.507
    //         ],
    //         [
    //           -1.005,
    //           26.276,
    //           3.287
    //         ]
    //       ],
    //       [
    //         157,
    //         "LEU",
    //         "A",
    //         283,
    //         "NFT",
    //         "A",
    //         false,
    //         "3.24",
    //         "141.32",
    //         "129.13",
    //         1627,
    //         "F",
    //         1191,
    //         "O2",
    //         [
    //           2.348,
    //           25.905,
    //           0.55
    //         ],
    //         [
    //           0.124,
    //           24.593,
    //           2.5
    //         ]
    //       ]
    //     ],
    //     "metal": [
    //       [
    //         "RESNR",
    //         "RESTYPE",
    //         "RESCHAIN",
    //         "RESNR_LIG",
    //         "RESTYPE_LIG",
    //         "RESCHAIN_LIG",
    //         "METAL_IDX",
    //         "METAL_TYPE",
    //         "TARGET_IDX",
    //         "TARGET_TYPE",
    //         "COORDINATION",
    //         "DIST",
    //         "LOCATION",
    //         "RMS",
    //         "GEOMETRY",
    //         "COMPLEXNUM",
    //         "METALCOO",
    //         "TARGETCOO"
    //       ]
    //     ]
    //   }
    // }
  };
  const [pdbID, setPDBId] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const isValid = (data: any) => {
    if (!data) return false;
    var i = 1;
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (value.length > 1) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSubmit = async () => {
    if (pdbID.length < 4 || pdbID.length > 4) {
      setShowError(true);
      setErrorMsg("Please enter valid PDB");
      return;
    }
    setLoading(true);
    const response = await axios
      .post(
        `${SERVER_URL}/pdb/lig`,

        {
          pdb_id: pdbID,
          assembly: "1",
          isAddDisulphideBonds: true,
          isAddMissingResidue: true,
          minimizationSteps: 100,
          pHValue: 7,
        },
        {
          params: {
            pdb_id: pdbID,
          },
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setResult(response.data);
      })
      .catch((error) => {
        setShowError(true);
        if (error.response) {
          if (error.response.status >= 500) {
            setErrorMsg(errorMessages.serverError);
          } else if (error.response.status >= 400) {
            setErrorMsg(errorMessages.clientError);
          } else setErrorMsg(errorMessages.genericError);
        } else if (error.request) {
          setErrorMsg(errorMessages.connectionError);
        } else {
          setErrorMsg(errorMessages.requestError);
        }
      });
    setLoading(false);
  };

  // if (Object.keys(info).length !== 0)
  {
    // get the data ready
    return (
      <div>
        <Snackbar
          open={showError}
          autoHideDuration={9000}
          sx={{ width: "50%" }}
          onClose={() => {
            setShowError(false);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => {
              setShowError(false);
            }}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {errorMsg}
          </Alert>
        </Snackbar>
        <Card sx={{ p: 2 }}>
          <Typography component="label" htmlFor="pdb-id" variant="h6">
            Enter a PDB ID
          </Typography>
          <Grid container pt={1}>
            <Grid item xs={10}>
              <TextField
                placeholder="PDB ID"
                fullWidth
                id="pdb-id"
                name="pdb-id"
                value={pdbID}
                onChange={(e) => setPDBId(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                onClick={handleSubmit}
                variant={"contained"}
                sx={{ p: 2 }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Card>
        {result && (
          <>
            <Box sx={{ p: 2 }}>
              <InteractionHeader data={result}></InteractionHeader>
            </Box>
            <div>
              <Typography variant="h5" sx={{ px: 2 }}>
                Interaction Info:{" "}
              </Typography>
              {Object.keys(result.interactions).map(
                (site) =>
                  isValid(result.interactions[site]) && (
                    <div key={site}>
                      <Box sx={{ p: 2 }}>
                        <InteractionTable
                          site={site}
                          data={result.interactions[site]}
                        ></InteractionTable>
                      </Box>
                    </div>
                  )
              )}
            </div>
            <div></div>
          </>
        )}
        <IndefiniteLoader state={loading} />
      </div>
    );
  }
};

export default LigandInteractionTable;

import React from 'react'
import { WorkflowLandingTabs } from '../../components'
import { WorkflowLandingTab } from '../../models';
import { Grid, Typography, Box, CardContent, Card,Button} from '@mui/material';
import MolViewerInput from '../../components/DrViz/MolViewerInput';

const tabs: Array<WorkflowLandingTab> = [
  {
    id: "0",
    label: "Description",
    value: (
      <>
        <Typography paragraph>
        Dr Viz is a web-based, high-end 3D graphics visualization tool that lets users play with molecular dynamics trajectories, visualize hundreds of superimposed protein structures at once, and render cell-level models with tens of millions of atoms at a time. 

        </Typography>
        <Box
                  component="img"
                  src="/images/hero/DrVizDetails.png"
                  alt="Mol viewer home"
                  sx={{
                    width: '50%', // Reduces the width to 80% of the parent container
                    height: 'auto',
                    mt: 4, // Adds margin-top for space above the image
                    mb: 2, // Adds margin-bottom for space below the image
                    mx: 'auto', // Centers the image horizontally
                    display: 'block', // Ensures the Box behaves like a block element
                    p: 2, // Adds padding around the image inside the Box
                  }}
          />
        
      </>
    
    )
  },

];
function MolViewerHome() {


  return (
    <Grid container spacing={2.5} px={3} mt={2}>
      <Grid item xs={8}>
        <WorkflowLandingTabs tabs={tabs} />
      </Grid>
      <Grid item xs={4}>
        <Card sx={{py:2,height:1}}>
          <CardContent sx={{height:1}}>
            <MolViewerInput/>
          </CardContent>
        </Card>
      </Grid>

    </Grid>
    
  )
}

export default MolViewerHome

import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Alert,
  Snackbar,
  Grid,
  Box,
  Stack,
  FormControl,
  LinearProgress,
  Select,
  MenuItem,
} from "@mui/material";
import IndefiniteLoader from "../common/IndefiniteLoader";
import { useEffect, useState } from "react";
import http from "../../net/http-common";
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import FileUploader from "../common/FileUploader";
import { SERVER_URL } from "../../config";

function CopilotInput() {
  const [smiles, setSmiles] = useState<string>("");
  const [changesNeeded, setChangesNeeded] = useState<string>("");
  const [PDBFile, setPDBFile] = useState<File>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [fetchingFields, setfetchingFields] = useState(false);
  const [chains, setChains] = useState<string[]>([]);
  const [residues, setResidues] = useState<string[]>([]);
  const [residue, setResidue] = useState("");
  const [protChain, setProtChain] = useState("");

  const navigate = useNavigate();

  const handleFileUpload = async (file: File, name: string) => {
    switch (name) {
      case "pdb":
        setPDBFile(file);
        break;
      default:
        //console.log("file type unknown");
        break;
    }
  };

  const submitModification = async () => {
    try {
      setLoading(true);
      const body = {
        changes_needed: changesNeeded,
        modification_history: "",
        smiles: smiles,
        summary: "",
        is_first_modification: true,
      };
      const response = await http.post(
        `https://services.moleculegen.com/copilot_service/copilot/get-modifications`,
        body,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        }
      );

      let proteinDetails = {
        pdbFile: PDBFile,
        protChain: protChain,
        residue: residue,
        firstModification: changesNeeded,
      };
      navigate("modifications", {
        state: { output: response.data, fileInfo: proteinDetails },
      });
    } catch (error) {
      setShowError(true);
      setErrorMsg(errorMessages.serverError);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!PDBFile) return;

    const form = new FormData();
    form.append("uploaded_protein_file", PDBFile);

    setfetchingFields(true);

    http
      .post(`${SERVER_URL}/pdb/chains`, form, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      })
      .then((response: any) => {
        setfetchingFields(false);
        //  console.log(response);
        setChains(response.data[0]);
        setResidues(response.data[1]);
        setProtChain(response.data[0][0]);
        setResidue(response.data[1][0]);
      })
      .catch((error) => {
        setfetchingFields(false);
      });
  }, [PDBFile]);

  const handleDownloadSample = (fileType: string) => {
    const link = document.createElement("a");

    if (fileType == "protein") {
      link.download = "copilot_sample.pdb";
      link.href = "/samples/autodock/2p16.pdb";
    } else if (fileType == "ligand") {
      link.download = "lift_ligand_sample.sdf";
      link.href = "/samples/lift/4ffw_ligand.sdf";
    }

    link.click();
  };

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Card sx={{ width: "100%" }}>
        <Grid item px={2} py={1.3} sx={{ backgroundColor: "grey.100" }}>
          <i className="ri-login-box-line"></i>
          <Typography
            display={"inline-block"}
            variant={"h6"}
            sx={{ color: "grey.900" }}
            ml={0.5}
            fontWeight={"600"}
          >
            {"Input"}
          </Typography>
        </Grid>
        <CardContent>
          <Stack direction="column" spacing={1.5}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography
                sx={{
                  mt: 1,
                  color: "var(--shade-2900, #29283B)",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                Protein File (.pdb)
              </Typography>
              <Button
                variant="text"
                onClick={() => handleDownloadSample("protein")}
              >
                {"Download Sample"}
              </Button>
            </Box>
            <FileUploader
              accept={".pdb"}
              handleFileUpload={(files) => handleFileUpload(files[0], "pdb")}
              deleteHandlerDisable={() => {
                return false;
              }}
            />
          </Stack>
          <Stack direction="column" spacing={1.5}>
            <FormControl>
              <Typography
                sx={{
                  mt: 1,
                  color: "var(--shade-2900, #29283B)",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                Residue Name
              </Typography>
              {fetchingFields ? <LinearProgress /> : ""}
              <Select
                disabled={residues?.length === 0}
                value={residue}
                label="Residue"
                onChange={(e) => setResidue(e.target.value)}
              >
                {residues?.map((v, i) => {
                  return (
                    <MenuItem key={i} value={v}>
                      {v}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="column" spacing={1.5}>
            <FormControl>
              <Typography
                sx={{
                  mt: 1,
                  color: "var(--shade-2900, #29283B)",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                Protein Chain
              </Typography>
              {fetchingFields ? <LinearProgress /> : ""}
              <Select
                disabled={chains?.length === 0}
                value={protChain}
                label="Protein Chain"
                onChange={(e) => setProtChain(e.target.value)}
              >
                {chains?.map((v, i) => {
                  return (
                    <MenuItem key={i} value={v}>
                      {v}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
          <Box mt={2}>
            <Typography component="label" htmlFor="smiles-string">
              Enter a SMILES String
            </Typography>
            <TextField
              placeholder="SMILES string"
              fullWidth
              id="smiles-string"
              name="smiles-string"
              value={smiles}
              onChange={(e) => {
                setSmiles(e.target.value);
              }}
            />
          </Box>
          <Box mt={2}>
            <Typography component="label" htmlFor="smiles-string">
              Enter the Desired Modifications
            </Typography>
            <TextField
              placeholder="Desired Modifications"
              fullWidth
              id="changes-string"
              name="changes-string"
              value={changesNeeded}
              multiline
              maxRows={10} // Optional: set a max number of rows if desired
              onChange={(e) => {
                setChangesNeeded(e.target.value);
              }}
            />
          </Box>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              mt: 2,
              "&.Mui-disabled": {
                background: "#BDADF9",
                color: "#fff",
              },
            }}
            disabled={
              !smiles || !changesNeeded || !PDBFile || !residue || !protChain
            }
            onClick={submitModification}
          >
            Submit
          </Button>
        </CardContent>

        <IndefiniteLoader state={loading} />
      </Card>
    </>
  );
}

export default CopilotInput;

import React, { useEffect, useId, useState } from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormData from "form-data";
import Grid from "@mui/material/Grid";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context";
import JobsTable from "../../components/common/JobsTable";
import { FileUploader, ModelCardHeading } from "../../components";
import DownloadIcon from "@mui/icons-material/Download";
import Loaders from "../../components/common/Loaders";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import BetaModal from "../../components/common/BetaModal";
import { JOB_SERVER_URL } from "../../config";
import { RUNTIME } from "../../config";

function JobSubmit() {
  const MODEL_NAME = "diffdock";

  const [inProgress, setInProgress] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const [jobstatusVisible, setJobstatusVisible] = useState(false);
  const [doLoadJobs, setDoLoadJobs] = useState(true);
  const [jobID, setJobID] = useState(-1);
  const [protFile, setProtFile] = useState<File>();
  const [ligandFile, setLigandFile] = useState<File>();
  const [numSamples, setNumSamples] = useState(10);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);

  const [betaOpen, setBetaOpen] = useState(false);

  const [rows, setRows] = useState<[]>();

  const navigate = useNavigate();
  const { user } = useUserAuth();

  const runInProd = RUNTIME === "PROD";

  const handleSubmit = async (e: any) => {
    // console.log("submit clicked");
    setInProgress(true);
    const data = new FormData();
    // do validation
    //console.log("prot", protFile);
    //console.log("ligand", ligandFile);

    data.append("uid", user.uid);

    if (numSamples > 100) {
      setInProgress(false);
      alert("maximum number of samples allowed is 100");
      return;
    }

    data.append("num_samples", numSamples);

    if (!protFile || !ligandFile) {
      alert(
        "You need to submit either a protein pocket file or both protein and ligand file"
      );
      setInProgress(false);
      return;
    }

    data.append("protein_file", protFile);
    data.append("ligand_file", ligandFile);

    await axios
      .post(`${JOB_SERVER_URL}/diffdock/submit`, data, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      })
      .then((response: any) => {
        setInProgress(false);
        ////console.log(response.data);
        setFormVisible(false);
        setJobID(response.data.task_id);
        setJobstatusVisible(true);
        setDoLoadJobs(true);
      })
      .catch((error) => {
        setInProgress(false);
        setShowError(true);
        setErrorMsg(errorMessages.submitJobError);
      });
  };

  const fileUploaders = [
    {
      accept: ".pdb,chemical/x-pdb",
      label: "Protein File",
      onUpload(files: FileList) {
        setProtFile(files[0]);
      },
    },
    {
      accept: ".sdf,chemical/x-mdl-sdfile",
      label: "Ligand File",
      onUpload(files: FileList) {
        setLigandFile(files[0]);
      },
    },
  ];

  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.download = "diffdock_sample_input.zip";
    link.href = "/samples/diffdock/diffdock_sample_input.zip";
    link.click();
  };

  useEffect(() => {
    if (!doLoadJobs) return;

    const response = axios
      .get(`${JOB_SERVER_URL}/userjobs`, {
        params: {
          user_id: user.uid,
          model_name: MODEL_NAME,
          start: "0",
          end: "10",
        },
        headers: {
          accept: "application/json",
        },
      })
      .then((res) => {
        ////console.log(res);
        setDoLoadJobs(false);
        setRows(res.data);
        //console.log("jobs", res.data);
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg(errorMessages.jobTableError);
        setDoLoadJobs(false);
        setRows([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doLoadJobs]);

  const onBetaClick = () => {
    //    console.log("clicked beta access");
    setBetaOpen(true);
  };

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Box sx={{ minWidth: 120, px: 3, py: 2 }}>
        <Grid container spacing={2}>
          {/* Description */}

          <Grid item xs={12} md={8} sx={{ pt: 2, width: "100%" }} pt={2.5}>
            <Card>
              <CardContent sx={{ pt: 2 }}>
                <Typography variant={"h5"} fontWeight={"bold"} mb={3}>
                  Description
                </Typography>
                <Typography mb={3}>
                  DiffDock is a deep learning-based protocol designed to predict
                  a given ligand's most likely binding mode. DiffDock
                  automatically identifies the most druggable binding sites on
                  proteins and precisely docks the ligand in the anticipated
                  binding site with the least amount of ligand preparation.
                </Typography>
                <Box
                  component="img"
                  src="/images/hero/diffdock_image.png"
                  alt="Protein Info Visual"
                  sx={{
                    width: "50%", // Increases the width to 100% of the parent containerimages
                    height: "auto",
                    mt: 4,
                    mb: 2,
                    mx: "auto",
                    display: "block",
                    p: 2,
                  }}
                />
                <Typography mb={3}>
                  It provides a range of ranks for distinct ligand binding
                  conformations. Diffdock rank1 is the top-ranked ligand pose
                  with the highest confidence score based on the
                  interatomic-distance matrix and degrees of freedom. DiffDock
                  accepts proteins and ligands in PDB and SDF formats,
                  respectively.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4} pt={2}>
            <Card>
              <ModelCardHeading
                button={{
                  label: (
                    <>
                      <DownloadIcon />
                      {" Sample"}
                    </>
                  ),
                  onClick: handleDownloadSample,
                }}
                heading={"Submit Job for Ligand Docking"}
              />
              <CardContent>
                {runInProd && (
                  <Grid container direction="column" className="beta-anchor">
                    <Grid item xs={12} sx={{ mt: 5 }}>
                      <Typography>
                        This is a Premium feature. Try with{" "}
                        <Button variant="contained" onClick={onBetaClick}>
                          BETA ACCESS
                        </Button>
                        <BetaModal
                          open={betaOpen}
                          openHandler={setBetaOpen}
                        ></BetaModal>
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                <FormControl
                  sx={{ width: "100%" }}
                  className={RUNTIME === "PROD" ? "blurry-bg" : ""}
                >
                  {fileUploaders.map(({ label, onUpload, accept }) => (
                    <Stack spacing={0.5} direction="column">
                      <Typography variant={"h6"}>{label}</Typography>
                      <FileUploader
                        accept={accept}
                        handleFileUpload={onUpload}
                      />
                    </Stack>
                  ))}

                  <Stack spacing={1} mt={2} direction="column">
                    <Typography variant={"h6"} fontWeight={"bold"}>
                      Number of samples
                    </Typography>
                    <TextField
                      type={"number"}
                      onChange={(e) => setNumSamples(parseInt(e.target.value))}
                    />
                  </Stack>
                  <Stack spacing={1} mt={2} direction="column">
                    <Button variant="contained" onClick={handleSubmit}>
                      Submit
                    </Button>
                    {/* {jobstatusVisible && (
                    <Typography>Job {jobID} submitted</Typography>
                  )} */}
                  </Stack>
                  <Grid container direction={"row"} mt={2}>
                    <Grid item ml={"auto"} mt={"auto"}>
                      {inProgress && <CircularProgress />}
                    </Grid>
                    <Grid sx={{ mx: "auto" }}>
                      {jobstatusVisible && (
                        <Typography color="green" variant="body2">
                          {`Job submitted with id ${jobID}`}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid sx={{ width: "50%", mx: "auto" }}>
          {jobstatusVisible && (
            <Alert
              onClose={() => {
                setJobstatusVisible(false);
              }}
              sx={{ mt: 2 }}
              variant="filled"
              severity="success"
            >{`Job submitted with id ${jobID}`}</Alert>
          )}
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className="jobs-container"
          pt={2}
        >
          <Card sx={{ width: 1 }}>
            <CardContent>
              {doLoadJobs && <Loaders type={"table"} />}
              {!doLoadJobs && <JobsTable jobs={rows}></JobsTable>}
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </>
  );
}

export default JobSubmit;

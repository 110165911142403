import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { LigandInfo } from "../../models";
import { getLigandInfo, getSDF } from "./ligandPrepApi";
import { LigandPrepState } from "../../models/Ligand";

const initialState: LigandPrepState = {
  status: "idle",
  SDF: {
    data: null,
    loading: false,
  },
  ligandInput: {},
  ligandResponse: {
    data: null,
    loading: false,
  },
};

export const fetchLigand = createAsyncThunk(
  "ligandPrep/fetchLigand",
  async ({ smileData,params }: { smileData: FormData ,params: any}) => {
    const response = await getLigandInfo({ smileData ,params});
    return response.data;
  }
);

export const fetchSDF = createAsyncThunk(
  "ligandPrep/fetchSDF",
  async ({ smileData,smiles,column_name }: { smileData: FormData,smiles: String,column_name: string}) => {
    try{
    const response = await getSDF({ smileData,smiles,column_name});
    return {response};  
  }
    catch(error){
      return {error}
    }
  }
);

export const ligandPrepSlice = createSlice({
  name: "ligandPrep",

  initialState,
  reducers: {
    setLigandPrep(state:any, action:any) {
      //console.log(action);
      state.ligandInput = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    // Fetch Ligand Preparation
    builder
      .addCase(fetchLigand.fulfilled, (state:any, action:any) => {
        state.ligandResponse.data = action.payload.ligands;
        state.ligandResponse.loading = false;
      })
      .addCase(fetchLigand.rejected, (state:any, action:any) => {
        state.ligandResponse.loading = false;
      })
      .addCase(fetchLigand.pending, (state:any) => {
        state.ligandResponse.loading = true;
      })

      //  Fetch SDF data.
      .addCase(fetchSDF.fulfilled, (state:any, action:any) => {
        state.SDF.data = action.payload.sdf;
        state.SDF.loading = false;
      })
      .addCase(fetchSDF.rejected, (state:any, action:any) => {
        state.SDF.loading = false;
      })
      .addCase(fetchSDF.pending, (state:any) => {
        state.SDF.loading = true;
      });
  },
});

export const { setLigandPrep } = ligandPrepSlice.actions;
export const selectLigandPrep = (state: RootState) => state.ligandPrep;
export default ligandPrepSlice.reducer;

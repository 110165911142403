enum AppActionKind {
  CHANGE_PAGE_TITLE = 'CHANGE_PAGE_TITLE'
}

interface AppAction {
  type: AppActionKind;
  payload: string;
}


const appReducer = (appState: any, action: AppAction) => {
  
  switch(action.type) {
    case AppActionKind.CHANGE_PAGE_TITLE: {
      return {
        ...appState,
        currentPageTitle: action.payload
      }
    }
  }
}


export {
  appReducer,
  AppActionKind
}

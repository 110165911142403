import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Papa from "papaparse";

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import http from "../../net/http-common";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { LIFT_SERVER_URL } from "../../config";

function MoleculeCopilotResult() {
  const [expandedAccordion, setExpandedAccordion] = useState(""); // To keep track of expanded Accordion
  const [modifications, setModifications] = useState<any>([]);
  const [PDBFile, setPDBFile] = useState<File>(null);
  const [residue, setResidue] = useState("");
  const [protChain, setProtChain] = useState("");
  const [firstModification, setFirstModification] = useState<string>("");

  const [firstSubmittedSmileInfo, setFirstSubmittedSmileInfo] =
    useState<any>(null);

  const [furtherModification, setFurtherModification] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [firstInputSmileID, setFirstInputSmileID] = useState<string>("MOL_0");

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [foundSourceMol, setFoundSourceMol] = useState<any>(null);

  const location = useLocation();

  const data = location.state;

  // console.log("data", data);

  const navigate = useNavigate();

  const furtherModificationHandler = async (item: any, sourceIndex: number) => {
    try {
      setLoading(true);
      const body = {
        changes_needed: item.furtherChanges,
        modification_history: "",
        smiles: item.smiles,
        summary: "",
        is_first_modification: false,
      };
      const response = await http.post(
        `https://services.moleculegen.com/copilot_service/copilot/get-modifications`,
        body,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        }
      );

      if (response.data.status_code === 200) {
        const result = response.data.result.map(
          (molecule: any, index: number) => {
            return {
              ...molecule,
              accName: `${item.accName}_${
                index + 1 + item.generatedModifiedItems
              }`,
              furtherChanges: "",
              sourceMolecule: item,
              generatedModifiedItems: 0,
              dockLoad: false,
              docking_score: null,
              interactionLoad: false,
              interactions: null,
            };
          }
        );

        const newModifications = [...modifications];
        newModifications[sourceIndex] = {
          ...newModifications[sourceIndex],
          generatedModifiedItems: item.generatedModifiedItems + result.length, // Update the specific item's furtherChanges
        };

        setModifications([...newModifications, ...result]);
        setFurtherModification("");
      } else {
        throw new Error("Failed to get successful status from server");
      }
    } catch (error) {
      setShowError(true);
      setErrorMsg(errorMessages.serverError);
    }

    setLoading(false);
  };

  const sourceMoleculeHandler = (sourceMolID: string) => {
    // navigate(`/leader/copilot/modifications#${sourceMol.accName}`)
    if (sourceMolID !== firstInputSmileID) setExpandedAccordion(sourceMolID); // Expand the accordion

    // Scroll to the accordion
    const accordionElement = document.getElementById(sourceMolID);
    if (accordionElement) {
      accordionElement.scrollIntoView({ behavior: "smooth" });
    }

    // setFoundSourceMol(sourceMol);
    // setOpenDialog(true);
  };

  // get docking score of selected smiles
  const getDockingScore = async (dockingSmiles: string, indexValue: number) => {
    let dockScore: any = null;
    // Update the specific accordion's loading state
    setModifications((prevModifications: any) => {
      const newModifications = [...prevModifications];
      newModifications[indexValue] = {
        ...newModifications[indexValue],
        dockLoad: true, // Set loading state for the selected accordion
      };
      return newModifications;
    });

    try {
      // updating loading of the accordion that selected

      const form = new FormData();
      form.append("pdb_file", PDBFile);
      form.append("residue_name", residue);
      form.append("protein_chain", protChain);
      form.append("ligand_smiles", dockingSmiles);

      const requestUrl = `https://app.moleculeai.com/unidock_api/get_docking_scores`;
      const response = await http.post(requestUrl, form, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      });

      dockScore = response.data.docking_score;
    } catch (error) {
      setShowError(true);
      setErrorMsg(errorMessages.serverError);
    }

    // agin updating loading of the accordion that selected
    // also modifying docking score if we get it from api call
    // Update the accordion's state with the docking score and turn off loading
    setModifications((prevModifications: any) => {
      const newModifications = [...prevModifications];
      newModifications[indexValue] = {
        ...newModifications[indexValue],
        dockLoad: false, // Turn off loading state
        docking_score: dockScore, // Set the docking score
      };
      return newModifications;
    });
  };

  // get first smiles docking score
  const getFirstSmilesDockingScore = async (dockingSmiles: string) => {
    let dockScore: any = null;

    setFirstSubmittedSmileInfo((prevModifications: any) => {
      let newModifications = { ...prevModifications };
      newModifications = {
        ...newModifications,
        dockLoad: true, // Turn off loading state
      };
      return newModifications;
    });

    try {
      // updating loading of the accordion that selected

      const form = new FormData();
      form.append("pdb_file", PDBFile);
      form.append("residue_name", residue);
      form.append("protein_chain", protChain);
      form.append("ligand_smiles", dockingSmiles);

      const requestUrl = `https://app.moleculeai.com/unidock_api/get_docking_scores`;
      const response = await http.post(requestUrl, form, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      });

      dockScore = response.data.docking_score;
    } catch (error) {
      setShowError(true);
      setErrorMsg(errorMessages.serverError);
    }

    // agin updating loading of the accordion that selected
    // also modifying docking score if we get it from api call

    setFirstSubmittedSmileInfo((prevModifications: any) => {
      let newModifications = { ...prevModifications };
      newModifications = {
        ...newModifications,
        dockLoad: false, // Turn off loading state
        docking_score: dockScore,
      };
      return newModifications;
    });
  };

  const getInteractions = async (
    interactionsSmiles: string,
    indexValue: number
  ) => {
    let allInteractions: string[] = [];

    setModifications((prevModifications: any) => {
      const newModifications = [...prevModifications];
      newModifications[indexValue] = {
        ...newModifications[indexValue],
        interactionLoad: true, // Set loading state for the selected accordion
      };
      return newModifications;
    });

    try {
      // updating loading of the accordion that selected
      const form = new FormData();
      form.append("pdb_file", PDBFile);

      let response = await http.post(
        `${LIFT_SERVER_URL}/LIFT_api/get_interaction_ligand_smiles`,
        form,
        {
          params: {
            residue_name: residue,
            protein_chain: protChain,
            ligand_smiles: interactionsSmiles,
          },
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        }
      );

      for (let key in response.data) {
        let interaction = response.data[key];

        const subInteractions = Object.entries(interaction)
          .filter(([key]) => !key.includes("image"))
          .map(([property, data]: [any, any], index: any) => {
            const concatProp = data.map((dataProp: any) => {
              return `${dataProp.restype}_${dataProp.resnr}_${dataProp.reschain}_${property}`;
            });

            allInteractions = [...allInteractions, ...concatProp];

            allInteractions.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

            return concatProp;
          });
      }
    } catch (error) {
      setShowError(true);
      setErrorMsg(errorMessages.serverError);
    }

    // agin updating loading of the accordion that selected
    // also modifying docking score if we get it from api call

    setModifications((prevModifications: any) => {
      const newModifications = [...prevModifications];
      newModifications[indexValue] = {
        ...newModifications[indexValue],
        interactionLoad: false, // Update the specific item's furtherChanges
        interactions: allInteractions,
      };
      return newModifications;
    });
  };

  // get first smiles interactions
  const getFirstSmileInteractions = async (interactionsSmiles: string) => {
    let allInteractions: string[] = [];
    let firstSmileInfo = firstSubmittedSmileInfo;
    firstSmileInfo = {
      ...firstSmileInfo,
      interactionLoad: true, // Update the specific item's furtherChanges
    };

    setFirstSubmittedSmileInfo(firstSmileInfo);

    try {
      // updating loading of the accordion that selected

      const form = new FormData();
      form.append("pdb_file", PDBFile);

      let response = await http.post(
        `${LIFT_SERVER_URL}/LIFT_api/get_interaction_ligand_smiles`,
        form,
        {
          params: {
            residue_name: residue,
            protein_chain: protChain,
            ligand_smiles: interactionsSmiles,
          },
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        }
      );

      for (let key in response.data) {
        let interaction = response.data[key];

        const subInteractions = Object.entries(interaction)
          .filter(([key]) => !key.includes("image"))
          .map(([property, data]: [any, any], index: any) => {
            const concatProp = data.map((dataProp: any) => {
              return `${dataProp.restype}_${dataProp.resnr}_${dataProp.reschain}_${property}`;
            });

            allInteractions = [...allInteractions, ...concatProp];
            allInteractions.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

            return concatProp;
          });
      }
    } catch (error) {
      setShowError(true);
      setErrorMsg(errorMessages.serverError);
    }

    // agin updating loading of the accordion that selected
    // also modifying docking score if we get it from api call

    firstSmileInfo = firstSubmittedSmileInfo;
    firstSmileInfo = {
      ...firstSmileInfo,
      interactionLoad: false, // Update the specific item's furtherChanges
      interactions: allInteractions,
    };

    setFirstSubmittedSmileInfo(firstSmileInfo);
  };

  // console.log("modifications", modifications);

  // download result as csv
  const downloadResult = () => {
    // Convert JSON to CSV using PapaParse

    const csvData = modifications.map((item: any) => {
      return {
        ID: item.accName,
        Smiles: item.smiles,
        QED: item.QED,
        SA: item.SA,
        Docking_Score: item.docking_score,
        Description: item.description,
        Input_Smiles: item.input_smiles,
      };
    });

    const csv = Papa.unparse(csvData);

    // Create a Blob from the CSV
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "molecule_copilot_result.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (!data) {
      navigate("/leader/copilot");
    } else {
      const result = data.output.result.map((item: any, index: number) => {
        if (index === 0) {
          setExpandedAccordion(`Mol_${index + 1}`);
        }
        return {
          ...item,
          accName: `Mol_${index + 1}`,
          furtherChanges: "",
          generatedModifiedItems: 0,
          dockLoad: false,
          docking_score: null,
          interactionLoad: false,
          interactions: null,
        };
      });
      setFirstSubmittedSmileInfo({
        ...data.output.smile_info,
        dockLoad: false,
        docking_score: null,
        interactionLoad: false,
        interactions: null,
      });
      setModifications(result);
      setPDBFile(data.fileInfo.pdbFile);
      setResidue(data.fileInfo.residue);
      setProtChain(data.fileInfo.protChain);
      setFirstModification(data.fileInfo.firstModification);
    }
    return () => {};
  }, []);

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      {firstSubmittedSmileInfo && (
        <Box px={2} pt={1} id="MOL_0">
          <Card
            sx={{
              p: 2,
            }}
          >
            <Box m={1}>
              <Typography variant="h5" mb={1}>
                YOUR INPUT
              </Typography>
            </Box>
            <Box display={"flex"} gap={"20px"}>
              <Box>
                <Box
                  component="img"
                  src={`data:image/svg+xml;base64,${btoa(
                    firstSubmittedSmileInfo.svg
                  )}`}
                  alt="Protein Info Visual"
                  sx={{
                    width: "250px", // Reduces the width to 80% of the parent container
                    height: "250px",
                    // Adds margin-top for space above the image
                    // Adds margin-bottom for space below the image
                    // Centers the image horizontally
                    display: "block", // Ensures the Box behaves like a block element
                    // Adds padding around the image inside the Box
                  }}
                />
              </Box>
              <Box>
                <Box display={"flex"} gap={"10px"}>
                  <Box mt={5}>
                    <Typography fontSize={"1.1rem"} className="wrap-word">
                      <span style={{ fontWeight: "bold" }}>SMILES:</span>{" "}
                      {firstSubmittedSmileInfo.smiles}
                    </Typography>

                    <Typography fontSize={"1.1rem"}>
                      <span style={{ fontWeight: "bold" }}>QED:</span>{" "}
                      {firstSubmittedSmileInfo.QED}
                    </Typography>
                    <Typography fontSize={"1.1rem"}>
                      <span style={{ fontWeight: "bold" }}>SA:</span>{" "}
                      {firstSubmittedSmileInfo.SA}
                    </Typography>
                    <Typography fontSize={"1.1rem"}>
                      <span style={{ fontWeight: "bold" }}>DOCKING SCORE:</span>{" "}
                      {firstSubmittedSmileInfo.docking_score ? (
                        firstSubmittedSmileInfo.docking_score
                      ) : (
                        <Button
                          size="large"
                          sx={{ fontSize: "1.1rem" }}
                          disabled={
                            firstSubmittedSmileInfo.dockLoad ||
                            firstSubmittedSmileInfo.interactionLoad
                          }
                          onClick={() =>
                            getFirstSmilesDockingScore(
                              firstSubmittedSmileInfo.smiles
                            )
                          }
                        >
                          Check
                        </Button>
                      )}
                    </Typography>

                    {firstSubmittedSmileInfo.dockLoad && (
                      <LinearProgress sx={{ mb: 0.5 }} />
                    )}
                  </Box>
                  <Box mt={5}>
                    <Typography fontSize={"1.1rem"}>
                      <span style={{ fontWeight: "bold" }}>PDB File:</span>{" "}
                      {PDBFile.name}
                    </Typography>
                    <Typography fontSize={"1.1rem"}>
                      <span style={{ fontWeight: "bold" }}>Residue:</span>{" "}
                      {residue}
                    </Typography>
                    <Typography fontSize={"1.1rem"}>
                      <span style={{ fontWeight: "bold" }}>Protein Chain:</span>{" "}
                      {protChain}
                    </Typography>
                    <Typography fontSize={"1.1rem"}>
                      <span style={{ fontWeight: "bold" }}>
                        Modification Input:
                      </span>{" "}
                      {firstModification}
                    </Typography>
                  </Box>
                </Box>

                {firstSubmittedSmileInfo.interactions ? (
                  <>
                    {firstSubmittedSmileInfo.interactions.length > 0 ? (
                      <>
                        <Typography fontSize={"1.1rem"} fontWeight={"bold"}>
                          INTERACTIONS:{" "}
                        </Typography>

                        <Paper elevation={1} sx={{ p: 1 }}>
                          {firstSubmittedSmileInfo.interactions.map(
                            (interactionItem: string) => (
                              <Chip
                                sx={{ my: 0.5, mr: 0.5 }}
                                label={interactionItem}
                              />
                            )
                          )}
                        </Paper>
                      </>
                    ) : (
                      <>
                        <Typography color="error">
                          No Interactions Found
                        </Typography>
                      </>
                    )}
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    disabled={
                      firstSubmittedSmileInfo.dockLoad ||
                      firstSubmittedSmileInfo.interactionLoad
                    }
                    onClick={() =>
                      getFirstSmileInteractions(firstSubmittedSmileInfo.smiles)
                    }
                  >
                    View Interactions
                  </Button>
                )}
                {firstSubmittedSmileInfo.interactionLoad && (
                  <LinearProgress sx={{ mt: 0.5 }} />
                )}
              </Box>
            </Box>
          </Card>
        </Box>
      )}

      <Box sx={{ fontSize: "1.2rem" }}>
        <Grid container spacing={2} p={2} height={"95%"}>
          <Grid item container px={0} pt={3} height={"100%"}>
            <Grid
              item
              width={"100%"}
              sx={{ overflowY: "auto" }}
              height={"100%"}
            >
              <Card sx={{ position: "relative", p: 2 }}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="h5" mb={1}>
                    MODIFICATIONS
                  </Typography>
                  <Button
                    startIcon={<DownloadIcon sx={{ fontSize: 12 }} />}
                    variant="contained"
                    size="small"
                    color="success"
                    sx={{
                      fontSize: 12,
                      paddingTop: 1,
                      paddingBottom: 1,
                      mb:1
                    }}
                    onClick={downloadResult}
                    
                  >
                    Download Modifications
                  </Button>
                </Box>
                {modifications.map((item: any, index: number) => (
                  <Accordion
                    key={index}
                    defaultExpanded={index === 0}
                    sx={{
                      mb: 2,
                    }}
                    id={item.accName}
                    expanded={expandedAccordion === item.accName} // Control expansion
                    onChange={() => setExpandedAccordion(item.accName)} // Toggle the accordion
                  >
                    <AccordionSummary
                      expandIcon={<GridExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        color: "rgb(88, 47, 241)",
                        fontWeight: "bold",
                      }}
                    >
                      {`${item.accName} (QED: ${item.QED}, SA: ${item.SA})`}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display={"flex"} gap={"20px"} alignItems={"center"}>
                        <Box>
                          <Box
                            component="img"
                            src={`data:image/svg+xml;base64,${btoa(item.svg)}`}
                            alt="Protein Info Visual"
                            sx={{
                              width: "400px", // Reduces the width to 80% of the parent container
                              height: "400px",
                              // Adds margin-top for space above the image
                              // Adds margin-bottom for space below the image
                              // Centers the image horizontally
                              display: "block", // Ensures the Box behaves like a block element
                              // Adds padding around the image inside the Box
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography fontSize={"1.1rem"}>
                            <span style={{ fontWeight: "bold" }}>SMILES:</span>{" "}
                            {item.smiles}
                          </Typography>
                          <Typography fontSize={"1.1rem"}>
                            <span style={{ fontWeight: "bold" }}>
                              DESCRIPTION:
                            </span>{" "}
                            {item.description}
                          </Typography>
                          <Typography fontSize={"1.1rem"}>
                            <span style={{ fontWeight: "bold" }}>QED:</span>{" "}
                            {item.QED}
                          </Typography>
                          <Typography fontSize={"1.1rem"}>
                            <span style={{ fontWeight: "bold" }}>SA:</span>{" "}
                            {item.SA}
                          </Typography>
                          <Typography fontSize={"1.1rem"}>
                            <span style={{ fontWeight: "bold" }}>
                              DOCKING SCORE:
                            </span>{" "}
                            {item.docking_score ? (
                              item.docking_score
                            ) : (
                              <Button
                                size="large"
                                sx={{ fontSize: "1.1rem" }}
                                disabled={item.dockLoad || item.interactionLoad}
                                onClick={() =>
                                  getDockingScore(item.smiles, index)
                                }
                              >
                                Check
                              </Button>
                            )}
                          </Typography>
                          {item.dockLoad && <LinearProgress sx={{ mb: 0.5 }} />}

                          {item.interactions ? (
                            <>
                              {item.interactions.length > 0 ? (
                                <>
                                  <Typography
                                    fontSize={"1.1rem"}
                                    fontWeight={"bold"}
                                  >
                                    INTERACTIONS:{" "}
                                  </Typography>
                                  <Paper elevation={1} sx={{ p: 1 }}>
                                    {item.interactions.map(
                                      (interactionItem: string) => (
                                        <Chip
                                          sx={{ my: 0.5, mr: 0.5 }}
                                          label={interactionItem}
                                        />
                                      )
                                    )}
                                  </Paper>
                                </>
                              ) : (
                                <>
                                  <Typography color="error">
                                    No Interactions Found
                                  </Typography>
                                </>
                              )}
                            </>
                          ) : (
                            <Button
                              variant="outlined"
                              disabled={item.dockLoad || item.interactionLoad}
                              onClick={() =>
                                getInteractions(item.smiles, index)
                              }
                            >
                              View Interactions
                            </Button>
                          )}
                          {item.interactionLoad && (
                            <LinearProgress sx={{ mt: 0.5 }} />
                          )}

                          <Typography fontSize={"1.1rem"}>
                            <span style={{ fontWeight: "bold" }}>
                              INPUT SMILES:
                            </span>{" "}
                            {item.input_smiles}{" "}
                            <Button
                              size="large"
                              sx={{ fontSize: "1.1rem" }}
                              onClick={() =>
                                sourceMoleculeHandler(
                                  item.sourceMolecule
                                    ? item.sourceMolecule.accName
                                    : firstInputSmileID
                                )
                              }
                            >
                              View Details
                            </Button>
                          </Typography>

                          <Box mt={2}>
                            <TextField
                              size="small"
                              placeholder="Enter Further Modifications"
                              fullWidth
                              id="changes-string"
                              name="changes-string"
                              value={item.furtherChanges}
                              multiline
                              maxRows={10} // Optional: set a max number of rows if desired
                              onChange={(e) => {
                                const newModifications = [...modifications];
                                newModifications[index] = {
                                  ...newModifications[index],
                                  furtherChanges: e.target.value, // Update the specific item's furtherChanges
                                };
                                setModifications(newModifications); // Update the state
                              }}
                            />
                          </Box>
                          <Button
                            variant="outlined"
                            size="medium"
                            sx={{
                              mt: 2,
                              "&.Mui-disabled": {
                                background: "#BDADF9",
                                color: "#fff",
                              },
                            }}
                            onClick={() =>
                              furtherModificationHandler(item, index)
                            }
                          >
                            Apply Further Modifications
                          </Button>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" textAlign={"center"}>
          <Typography variant="h5">Source Molecule Information</Typography>
        </DialogTitle>

        {foundSourceMol && (
          <DialogContent
            sx={{
              textAlign: "center",
              p: 8,
            }}
          >
            <Box sx={{ width: 1 }}>
              <Box
                component={"img"}
                src={`data:image/svg+xml;base64,${btoa(foundSourceMol.svg)}`}
                className="dashIcon" //
                sx={{
                  display: "block",
                  m: "auto",
                  width: 400,
                  height: 400,
                  transition: "transform 0.8s ease-in-out",
                }}
              />
            </Box>
            <Box
              sx={{
                textAlign: "left",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                }}
                mb={1}
              >
                {foundSourceMol.smiles}
              </Typography>
              <Typography fontSize={"1.1rem"}>
                <span style={{ fontWeight: "bold" }}>QED:</span>{" "}
                {foundSourceMol.QED}
              </Typography>
              <Typography fontSize={"1.1rem"}>
                <span style={{ fontWeight: "bold" }}>SA:</span>{" "}
                {foundSourceMol.SA}
              </Typography>
              <Typography fontSize={"1.1rem"}>
                <span style={{ fontWeight: "bold" }}>INPUT SMILES:</span>{" "}
                {foundSourceMol.input_smiles}
              </Typography>
              <Typography fontSize={"1.1rem"}>
                <span style={{ fontWeight: "bold" }}>DESCRIPTION:</span>{" "}
                {foundSourceMol.description}
              </Typography>
              <Typography fontSize={"1.1rem"}>
                <span style={{ fontWeight: "bold" }}>REFERENCE:</span>{" "}
                {foundSourceMol.accName}
              </Typography>
            </Box>
          </DialogContent>
        )}
      </Dialog>
      <IndefiniteLoader state={loading} />
    </>
  );
}

export default MoleculeCopilotResult;

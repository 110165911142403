import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { getPropertiesV3 } from "./propertiesApi";
import { PropertiesState } from "../../models";

const initialState: PropertiesState = {
  status: "idle",
  properties: {
    data: null,
    loading: false,
    singleSmileData: null,
  },
};

export const fetchPropertiesV3 = createAsyncThunk(
  "properties/fetchPropertiesV3",
  async ({ smiles }: { smiles: string }) => {
    try {
      const response = await getPropertiesV3({ smiles });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const propertiesSliceV3 = createSlice({
  name: "properties",
  initialState,
  reducers: {
    setProperties(state, action) {},
    setSinglesmileData(state, action) {
      state.properties.data = action.payload;
    },
    setSinglesmileinData(state, action) {
      state.properties.singleSmileData = {};
      state.properties.singleSmileData.smiles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchPropertiesV3.fulfilled,
        (state: PropertiesState, action) => {
          state.properties.data = action.payload;
          state.properties.loading = false;
        }
      )
      .addCase(fetchPropertiesV3.rejected, (state, action) => {
        state.properties.loading = false;
      })
      .addCase(fetchPropertiesV3.pending, (state) => {
        state.properties.loading = true;
      });
  },
});

export const { setProperties, setSinglesmileData, setSinglesmileinData } =
  propertiesSliceV3.actions;
export const selectPropertiesV3 = (state: RootState) => state.propertiesV3;
export default propertiesSliceV3.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { getProperties } from "./propertiesApi";
import { PropertiesState } from "../../models";

const initialState: PropertiesState = {
  status: "idle",
  properties: {
    data: null,
    loading: false,
    singleSmileData: null
  },
};

export const fetchProperties = createAsyncThunk(
  "properties/fetchProperties",
  async ({
    smiles,
    smilesFile,
    from_model,
    model_name,
    job_id,
  }: {
    smiles: string;
    smilesFile: string;
    from_model: boolean;
    model_name: string;
    job_id: string;
  }) => {
    const response = await getProperties({
      smiles,
      smilesFile,
      from_model,
      model_name,
      job_id,
    });
    return response.data;
  }
);

export const propertiesSlice = createSlice({
  name: "properties",

  initialState,
  reducers: {
    setProperties(state, action) {
      //console.log(action);
    },
  },
  extraReducers: (builder) => {
    // Fetch Properties
    builder
      .addCase(fetchProperties.fulfilled, (state: PropertiesState, action) => {
        state.properties.data = action.payload.properties;
        state.properties.loading = false;
      })
      .addCase(fetchProperties.rejected, (state, action) => {
        state.properties.loading = false;
      })
      .addCase(fetchProperties.pending, (state) => {
        state.properties.loading = true;
      });
  },
});

export const { setProperties } = propertiesSlice.actions;
export const selectProperties = (state: RootState) => state.properties;
export default propertiesSlice.reducer;

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  NativeSelect,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";

import { CustomizeToxicFilters } from "../../components/Properties/screenieTypes";
import http from "../../net/http-common";
import { TOXGROUP_URL } from "../../config";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import IndefiniteLoader from "../common/IndefiniteLoader";
import {
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import InitialData from "./initialData";

const InputComponent = ({
  item,
  applyValue,
}: {
  item: any;
  applyValue: any;
}) => {
  const dataHandler = new InitialData();
  const subrulesoptions = dataHandler.getAllSubRules();
  let options = [...new Set(subrulesoptions)];
  options = options.sort((a, b) =>
    a.toLowerCase().localeCompare(b.toLowerCase())
  );
  const [selectedValues, setSelectedValues] = useState(item.value || []);

  const handleFilterChange = (event: any, newValue: any) => {
    setSelectedValues(newValue);
    applyValue({ ...item, value: newValue });
  };

  return (
    <Box width={700}>
      <Autocomplete
        sx={{ mt: 2 }}
        multiple
        options={options} // Use the updated prop
        value={selectedValues}
        freeSolo
        onChange={handleFilterChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Select Interactions"
          />
        )}
      />
    </Box>
  );
};

function BrenkFilter({
  openCustomizeToxicFilterDialog,
  setOpenCustomizeToxicFilterDialog,
  customizeToxicFilters,
  allSmilesAnd2dForToxicFilters,
  setToxicCol,
  setToxicRow,
  setIsTopLevelEnable,
  isTopLevelEnable,
  toxicFilter,
}: {
  openCustomizeToxicFilterDialog: boolean;
  setOpenCustomizeToxicFilterDialog: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  customizeToxicFilters: CustomizeToxicFilters[];
  allSmilesAnd2dForToxicFilters: any[];
  setToxicCol: React.Dispatch<React.SetStateAction<any[]>>;
  setToxicRow: React.Dispatch<React.SetStateAction<any[]>>;
  setIsTopLevelEnable: React.Dispatch<React.SetStateAction<boolean>>;
  isTopLevelEnable: boolean;
  toxicFilter: any;
}) {
  //  prepared input for brenk filter api
  const [preparedInputs, setPreparedInputs] = useState<any>({
    input_json: {
      BRENK: {
        action: "SELECT_FEW",
        group: [],
      },
      NIH: {
        action: "SELECT_FEW",
        group: [],
      },
      ZINC: {
        action: "SELECT_FEW",
        group: [],
      },
      PAINS_A: {
        action: "SELECT_FEW",
        group: [],
      },
      PAINS_B: {
        action: "SELECT_FEW",
        group: [],
      },
      PAINS_C: {
        action: "SELECT_FEW",
        group: [],
      },
    },
    list_of_smiles: [],
    top_level: false,
  });

  const [showError, setShowError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  // State for custom filter operator

  const [loading, setLoading] = useState<boolean>(false);

  // showing interactions as a chips
  const handleInteractionsData = (
    params: GridRenderCellParams<any, string[]>,
    filters: any
  ) => {
    if (!params.value) {
      return <>...</>;
    }

    // console.log("params", params);
    // console.log("filters", filters);

    const appliedSubrules = toxicFilter.items.filter(
      (item: any) => item.field === "subrules"
    );

    // console.log('appliedsubrules',appliedSubrules);

    let allFilteredSubrules = appliedSubrules.map((item: any) => {
      if (Array.isArray(item.value)) {
        return item.value;
      }
    });

    allFilteredSubrules = allFilteredSubrules.flat();

    return (
      <Box display={"flex"} flexWrap={"wrap"}>
        {params.value.map((pa) => (
          <>
            {allFilteredSubrules.includes(pa) ? (
              <>
                <Chip
                  label={pa}
                  sx={{
                    m: 0.5,
                    color: "green",
                    border: "1px solid green",
                    background: "#dcffdc",
                    fontWeight: "bold",
                  }}
                />
              </>
            ) : (
              <>
                <Chip label={pa} sx={{ m: 0.5 }} />
              </>
            )}
          </>
        ))}
      </Box>
    );
  };

  // Custom filter operator for array data using Autocomplete
  const customArrayFilterOperator: GridFilterOperator = {
    label: "contains",
    value: "contains",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      // If no value or if the value is not an array, no filter is applied
      if (
        !filterItem.value ||
        !Array.isArray(filterItem.value) ||
        filterItem.value.length === 0
      ) {
        return null; // Don't apply filter if no value selected
      }

      return ({ value }) => {
        // 'value' refers to the array in the current cell data (concatInteractions array)
        if (!value || !Array.isArray(value)) {
          return false;
        }

        // Check if all the selected values from Autocomplete are in the cell's array
        return filterItem.value.every((selected: any) =>
          value.includes(selected)
        );
      };
    },
    InputComponent: (props) => <InputComponent {...props} />,
  };

  // Mai filter properties columns
  let customizeTosicFilterColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "smiles",
      headerName: "SMILES",
      editable: false,
      headerAlign: "center",
      width: 200,
    },
    {
      field: "svg",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      disableExport: true,
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{
            height: "80px",
            width: "80%",
            transition: "transform 0.3s ease-in-out",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(150%)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "scale(100%)";
          }}
        />
      ),
    },

    {
      field: "subrules",
      headerName: "FAILED SUBRULES",
      editable: false,
      headerAlign:"center",
      align:"center",
      renderCell: (params: GridRenderCellParams<any, string[]>) => {
        return handleInteractionsData(params, toxicFilter);
      },
      width: 1000,
      filterOperators: customArrayFilterOperator
        ? [customArrayFilterOperator]
        : [], // Apply updated filter operator
    },
  ];

  // updating groups of inputs
  const handleGroupChange = (event: any, value: string, filterType: string) => {
    if (!value) return;

    setPreparedInputs((prevInputs: any) => ({
      ...prevInputs,
      input_json: {
        ...prevInputs.input_json,
        [filterType]: {
          ...prevInputs.input_json[filterType],
          group: [...prevInputs.input_json[filterType].group, value], // Update the group array with the selected values
        },
      },
    }));
  };

  // updating  group type
  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    filterType: string
  ) => {
    const value = event.target.value;

    setPreparedInputs((prevInputs: any) => ({
      ...prevInputs,
      input_json: {
        ...prevInputs.input_json,
        [filterType]: {
          ...prevInputs.input_json[filterType],
          action: value, // Update the action for the corresponding filter type
          group:
            value === "SELECT_ALL" || value === "IGNORE_ALL"
              ? []
              : prevInputs.input_json[filterType].group,
        },
      },
    }));
  };

  // delete subrules from chips section
  const deleteChips = (filterType: string, subrule: string) => {
    setPreparedInputs((prevInputs: any) => ({
      ...prevInputs,
      input_json: {
        ...prevInputs.input_json,
        [filterType]: {
          ...prevInputs.input_json[filterType],
          group: prevInputs.input_json[filterType].group.filter(
            (item: string) => item !== subrule
          ), // Remove the specific subrule from the group
        },
      },
    }));
  };

  // console.log('skd',allSmilesAnd2dForToxicFilters);

  // submit handler
  const submitInputFilters = async () => {
    setLoading(true);
    const inputFilters = preparedInputs;
    const allSmiles = allSmilesAnd2dForToxicFilters.map((item) => item.smiles);
    inputFilters.list_of_smiles = allSmiles;

    try {
      const response = await http.post(
        `${TOXGROUP_URL}/filter/`,
        inputFilters,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      // console.log("response", response);

      let flagToGetOtherKeys: boolean = true;
      let allKeys: any = [];
      let removedKeys = ["id", "smiles", "svg"];
      const finalCustomizeFilterOutput: any[] = response.data.map(
        (res: any, index: number) => {
          let brenkArr = Object.keys(res["BRENK"]).filter(
            (key) => res["BRENK"][key] === 0
          );

          let nihArr = Object.keys(res["NIH"]).filter(
            (key) => res["NIH"][key] === 0
          );

          let painsaArr = Object.keys(res["PAINS_A"]).filter(
            (key) => res["PAINS_A"][key] === 0
          );

          let painsbArr = Object.keys(res["PAINS_B"]).filter(
            (key) => res["PAINS_B"][key] === 0
          );

          let painscArr = Object.keys(res["PAINS_C"]).filter(
            (key) => res["PAINS_C"][key] === 0
          );

          let zincArr = Object.keys(res["ZINC"]).filter(
            (key) => res["ZINC"][key] === 0
          );

          let allSubrules = [
            ...brenkArr,
            ...nihArr,
            ...painsaArr,
            ...painsbArr,
            ...painscArr,
            ...zincArr,
          ];

          const smileobjectwithsvg = allSmilesAnd2dForToxicFilters.filter(
            (item) => item.smiles === res.smiles
          );

          // console.log("allsubrules", allSubrules);

          let finalObject = {
            id: index,
            smiles: res.smiles,
            svg: smileobjectwithsvg[0].svg,
            subrules: allSubrules.sort((a, b) =>
              a.toLowerCase().localeCompare(b.toLowerCase())
            ),
          };

          if (flagToGetOtherKeys) {
            allKeys = Object.keys(finalObject).filter(
              (item) => !removedKeys.includes(item)
            );
            flagToGetOtherKeys = false;
          }

          // console.log('finalobj',finalObject);
          return finalObject;
        }
      );

      // const dynamicColumns = allKeys.map((field: any) => ({
      //   field,
      //   headerName: field.toUpperCase(),
      //   headerAlign: "center",
      //   align: "center",
      //   width: 150,
      //   type: "string",
      //   renderCell: (params: any) => {
      //     return (
      //       <Box
      //         sx={{
      //           color:params.value===1?"green":"red"
      //         }}
      //       >
      //         {params.value===1?"PASS":"FAIL"}
      //       </Box>
      //     )
      //   },
      //   filterOperators: [customPassFailFilterOperator],
      // }));

      setIsTopLevelEnable(true);
      setToxicCol(customizeTosicFilterColumns);
      setToxicRow(finalCustomizeFilterOutput);

      setPreparedInputs((prevInputs: any) => ({
        ...prevInputs,
        input_json: {
          ...prevInputs.input_json,
          BRENK: {
            action: "SELECT_FEW",
            group: [],
          },
          NIH: {
            action: "SELECT_FEW",
            group: [],
          },
          ZINC: {
            action: "SELECT_FEW",
            group: [],
          },
          PAINS_A: {
            action: "SELECT_FEW",
            group: [],
          },
          PAINS_B: {
            action: "SELECT_FEW",
            group: [],
          },
          PAINS_C: {
            action: "SELECT_FEW",
            group: [],
          },
        },
      }));
      setOpenCustomizeToxicFilterDialog(false);
    } catch (error) {
      setErrorMsg(errorMessages.serverError);
      setShowError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (isTopLevelEnable) setToxicCol(customizeTosicFilterColumns);
  }, [toxicFilter]);

  // console.log('preapreddata',preparedInputs);

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Box>
        <Dialog
          open={openCustomizeToxicFilterDialog}
          onClose={() => {
            setPreparedInputs((prevInputs: any) => ({
              ...prevInputs,
              input_json: {
                ...prevInputs.input_json,
                BRENK: {
                  action: "SELECT_FEW",
                  group: [],
                },
                NIH: {
                  action: "SELECT_FEW",
                  group: [],
                },
                ZINC: {
                  action: "SELECT_FEW",
                  group: [],
                },
                PAINS_A: {
                  action: "SELECT_FEW",
                  group: [],
                },
                PAINS_B: {
                  action: "SELECT_FEW",
                  group: [],
                },
                PAINS_C: {
                  action: "SELECT_FEW",
                  group: [],
                },
              },
            }));
            setOpenCustomizeToxicFilterDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="brenk-dialog"
          sx={{
            "& .css-1q3t5pl-MuiPaper-root-MuiDialog-paper": {
              maxWidth: "1100px",
            },
            "& .css-esw9ho": {
              maxWidth: "1100px !important",
            },
          }}
        >
          <DialogTitle id="alert-dialog-title" textAlign={"center"}>
            <Typography variant="h5">CUSTOMIZE TOXIC GROUPS</Typography>
          </DialogTitle>

          <DialogContent>
            {customizeToxicFilters.map((customFilter) => (
              <Box
                key={customFilter.type}
                sx={{
                  display: "flex",
                  gap: "10em",
                  // alignItems: "center",
                  mb: 5,
                  mt: 1,
                }}
              >
                <Typography variant="h6" width={"30%"}>
                  {customFilter.type}
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    width: "50%",
                  }}
                >
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    {customFilter.type}
                  </InputLabel>
                  <NativeSelect
                    defaultValue={"SELECT_FEW"}
                    inputProps={{
                      name: "BRENK",
                      id: "uncontrolled-native",
                    }}
                    onChange={(event) =>
                      handleSelectChange(event, customFilter.type)
                    } // Pass event and filter type
                  >
                    <option value={"SELECT_ALL"}>SELECT ALL</option>
                    <option value={"SELECT_FEW"}>SELECT FEW</option>
                    <option value={"IGNORE_ALL"}>IGNORE ALL</option>
                    <option value={"IGNORE_FEW"}>IGNORE FEW</option>
                  </NativeSelect>
                </FormControl>

                <Box width={1000}>
                  <Autocomplete
                    options={customFilter.subrules.sort((a, b) =>
                      a.toLowerCase().localeCompare(b.toLowerCase())
                    )}
                    disabled={
                      preparedInputs.input_json[customFilter.type].action ===
                        "SELECT_ALL" ||
                      preparedInputs.input_json[customFilter.type].action ===
                        "IGNORE_ALL"
                    }
                    onChange={(event, value) =>
                      handleGroupChange(event, value, customFilter.type)
                    } // Update group on selection
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={customFilter.type + " Subrules"}
                      />
                    )}
                  />
                  {preparedInputs.input_json[customFilter.type].group.map(
                    (subrule: string) => (
                      <Chip
                        size="medium"
                        label={subrule}
                        onDelete={() => deleteChips(customFilter.type, subrule)}
                        sx={{ m: 1 }}
                      />
                    )
                  )}
                </Box>
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Button
              size="large"
              onClick={() => setOpenCustomizeToxicFilterDialog(false)}
            >
              <Typography variant="h6">Cancel</Typography>
            </Button>
            <Button size="large" onClick={submitInputFilters}>
              <Typography variant="h6">Submit</Typography>
            </Button>
          </DialogActions>
          <IndefiniteLoader state={loading} />
        </Dialog>
      </Box>
    </>
  );
}

export default memo(BrenkFilter);

import { DataGrid, GridColDef } from "@mui/x-data-grid";
//import { GridColDef } from '@mui/x-data-grid';

export interface ClusterRow {
  id?: string;
  Method?: string;
  Resolution?: string;
  Chains?: string;
  Positions?: string;
}

export interface ClusterInfo {
  data: ClusterRow[];
  pdb_id: string;
}

export default function PDBClusterTable(props: ClusterInfo) {
  //console.log(props);

  if (props.data === undefined) {
    return (
      <p>
        No cluster information found for <b>{props.pdb_id}</b>
      </p>
    );
  }

  ///
  const columns: GridColDef[] = [
    { field: "id", headerName: "PDB ID", flex: 1, width: 100 },
    { field: "Method", headerName: "Method", flex: 1, width: 100 },
    { field: "Resolution", headerName: "Resolution", flex: 1, width: 100 },
    { field: "Chains", headerName: "Chains", flex: 1.2, width: 100 },
    { field: "Positions", headerName: "Positions", flex: 1, width: 100 },
  ];

  const rows = Object.values(props.data).map((v) => ({
    id: v.id,
    Method: v.Method,
    Resolution: v.Resolution,
    Chains: v.Chains,
    Positions: v.Positions,
  }));
  /////
  return (
    <div style={{ minHeight: 650, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        pageSizeOptions={[10, 25, 50]}
        autoHeight
      />
    </div>
  );
}

import React from "react";
import { SOLAR_DEV } from "../../config";

function Fragments() {
  return (
    <div className="chemlet podda">
      <iframe
        title="Fragment-based ligand design"
        width="100%"
        height="800"
        src={`${SOLAR_DEV}/podda/`}
      ></iframe>
    </div>
  );
}

export default Fragments;

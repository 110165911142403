import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { JOB_SERVER_URL } from "../../config";

import http from "../../net/http-common";

interface stateType {
  loading: boolean;
  data: any | null;
  conserved_signatures: string[];
  allInteractions: string[];
}

const initialState: stateType = {
  loading: false,
  data: null,
  conserved_signatures: [],
  allInteractions: [],
};

export const fetchLiftFilterData = createAsyncThunk(
  "liftFilters/fetchLiftFilterData",
  async (task_id: string) => {
    return http
      .get(`${JOB_SERVER_URL}/lift/results?task_id=${task_id}&as_file=false`)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  }
);

const liftFiltersSlice = createSlice({
  name: "liftFilters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch Properties
    builder
      .addCase(
        fetchLiftFilterData.fulfilled,
        (state: stateType, action: any) => {
          //   console.log(action.payload);

          //   console.log(JSON.parse(action.payload.results[0]));
          //   //   console.log(JSON.parse(action.payload.results[1]));

          let duplicateInteractions: string[] = [];

          const result = action.payload.results.map(
            (res: any, index: number) => {
              const ligand = JSON.parse(res);
              duplicateInteractions = [
                ...duplicateInteractions,
                ...ligand.concatenated_interactions,
              ];
              return {
                id: index,
                smiles:ligand.smiles,
                img:ligand.img,
                name: ligand.Name,
                concatInteractions: [...new Set(ligand.concatenated_interactions)],
              };
            }
          );

          state.data = result;
          state.conserved_signatures = action.payload.conserved_signatures;
          state.allInteractions = [...new Set(duplicateInteractions)];

          state.loading = false;
        }
      )
      .addCase(fetchLiftFilterData.rejected, (state, action: any) => {
        state.loading = false;
      })
      .addCase(fetchLiftFilterData.pending, (state) => {
        state.loading = true;
      });
  },
});

// export const { setProperties } = liftFiltersSlice.actions;

export default liftFiltersSlice.reducer;

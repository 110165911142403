import { CustomizeToxicFilters } from "./screenieTypes";

class InitialData {
  private customizeToxicInitialData: CustomizeToxicFilters[] = [
    {
      type: "BRENK",
      action: ["SELECT_ALL", "IGNORE_ALL", "SELECT_FEW", "IGNORE_FEW"],
      subrules: [],
    },
    {
      type: "NIH",
      action: ["SELECT_ALL", "IGNORE_ALL", "SELECT_FEW", "IGNORE_FEW"],
      subrules: [],
    },
    {
      type: "PAINS_A",
      action: ["SELECT_ALL", "IGNORE_ALL", "SELECT_FEW", "IGNORE_FEW"],
      subrules: [],
    },
    {
      type: "PAINS_B",
      action: ["SELECT_ALL", "IGNORE_ALL", "SELECT_FEW", "IGNORE_FEW"],
      subrules: [],
    },
    {
      type: "PAINS_C",
      action: ["SELECT_ALL", "IGNORE_ALL", "SELECT_FEW", "IGNORE_FEW"],
      subrules: [],
    },
    {
      type: "ZINC",
      action: ["SELECT_ALL", "IGNORE_ALL", "SELECT_FEW", "IGNORE_FEW"],
      subrules: [],
    },
  ];

  private AllSubrules: string[] = [
    ">_2_ester_groups",
    "2-halo_pyridine",
    "acid_halide",
    "acyclic_C=C-O",
    "acyl_cyanide",
    "acyl_hydrazine",
    "aldehyde",
    "Aliphatic_long_chain",
    "alkyl_halide",
    "amidotetrazole",
    "aniline",
    "azepane",
    "Azido_group",
    "Azo_group",
    "azocane",
    "benzidine",
    "beta-keto/anhydride",
    "biotin_analogue",
    "Carbo_cation/anion",
    "catechol",
    "charged_oxygen_or_sulfur_atoms",
    "chinone_1",
    "chinone_2",
    "conjugated_nitrile_group",
    "crown_ether",
    "cumarine",
    "cyanamide",
    "cyanate_/aminonitrile_/thiocyanate",
    "cyanohydrins",
    "cycloheptane_1",
    "cycloheptane_2",
    "cyclooctane_1",
    "cyclooctane_2",
    "diaminobenzene_1",
    "diaminobenzene_2",
    "diaminobenzene_3",
    "diazo_group",
    "diketo_group",
    "disulphide",
    "enamine",
    "ester_of_HOBT",
    "four_member_lactones",
    "halogenated_ring_1",
    "halogenated_ring_2",
    "heavy_metal",
    "het-C-het_not_in_ring",
    "hydantoin",
    "hydrazine",
    "hydroquinone",
    "hydroxamic_acid",
    "imine_1",
    "imine_2",
    "iodine",
    "isocyanate",
    "isolated_alkene",
    "ketene",
    "methylidene-1,3-dithiole",
    "Michael_acceptor_1",
    "Michael_acceptor_2",
    "Michael_acceptor_3",
    "Michael_acceptor_4",
    "Michael_acceptor_5",
    "N_oxide",
    "N-acyl-2-amino-5-mercapto-1,3,4-_thiadiazole",
    "N-C-halo",
    "N-halo",
    "N-hydroxyl_pyridine",
    "nitro_group",
    "N-nitroso",
    "oxime_1",
    "oxime_2",
    "Oxygen-nitrogen_single_bond",
    "Perfluorinated_chain",
    "peroxide",
    "phenol_ester",
    "phenyl_carbonate",
    "phosphor",
    "phthalimide",
    "Polycyclic_aromatic_hydrocarbon_1",
    "Polycyclic_aromatic_hydrocarbon_2",
    "Polycyclic_aromatic_hydrocarbon_3",
    "polyene",
    "quaternary_nitrogen_1",
    "quaternary_nitrogen_2",
    "quaternary_nitrogen_3",
    "saponine_derivative",
    "silicon_halogen",
    "stilbene",
    "sulfinic_acid",
    "Sulfonic_acid_1",
    "Sulfonic_acid_2",
    "sulfonyl_cyanide",
    "sulfur_oxygen_single_bond",
    "sulphate",
    "sulphur_nitrogen_single_bond",
    "Thiobenzothiazole_1",
    "thiobenzothiazole_2",
    "Thiocarbonyl_group",
    "thioester",
    "thiol_1",
    "thiol_2",
    "Three-membered_heterocycle",
    "triflate",
    "triphenyl_methyl-silyl",
    "triple_bond",
    "2halo_pyrazine_3EWG",
    "2halo_pyrazine_5EWG",
    "2halo_pyridazine_3EWG",
    "2halo_pyridazine_5EWG",
    "2halo_pyridine_3EWG",
    "2halo_pyridine_5EWG",
    "2halo_pyrimidine_5EWG",
    "3halo_pyridazine_2EWG",
    "3halo_pyridazine_4EWG",
    "4_pyridone_3_5_EWG",
    "4halo_pyridine_3EWG",
    "4halo_pyrimidine_2_6EWG",
    "4halo_pyrimidine_5EWG",
    "CH2_S#O_3_ring",
    "HOBT_ester",
    "NO_phosphonate",
    "acrylate",
    "activated_4mem_ring",
    "activated_S#O_3_ring",
    "activated_acetylene",
    "activated_diazo",
    "activated_vinyl_ester",
    "activated_vinyl_sulfonate",
    "acyclic_imide",
    "acyl_123_triazole",
    "acyl_134_triazole",
    "acyl_activated_NO",
    "acyl_cyanide",
    "acyl_imidazole",
    "acyl_pyrazole",
    "aldehyde",
    "alpha_dicarbonyl",
    "alpha_halo_EWG",
    "alpha_halo_amine",
    "alpha_halo_carbonyl",
    "alpha_halo_heteroatom",
    "alpha_halo_heteroatom_tert",
    "anhydride",
    "aryl_phosphonate",
    "aryl_thiocarbonyl",
    "azide",
    "aziridine_diazirine",
    "azo_amino",
    "azo_aryl",
    "azo_filter1",
    "azo_filter2",
    "azo_filter3",
    "azo_filter4",
    "bad_boron",
    "bad_cations",
    "benzidine_like",
    "beta_lactone",
    "betalactam",
    "betalactam_EWG",
    "bis_activated_aryl_ester",
    "bis_keto_olefin",
    "boron_warhead",
    "branched_polycyclic_aromatic",
    "carbodiimide_iso#thio#cyanate",
    "carbonyl_halide",
    "contains_metal",
    "crown_ether",
    "cyano_phosphonate",
    "cyanohydrin",
    "diamino_sulfide",
    "diazo_carbonyl",
    "diazonium",
    "dicarbonyl_sulfonamide",
    "disulfide_acyclic",
    "disulfonyliminoquinone",
    "double_trouble_warhead",
    "flavanoid",
    "four_nitriles",
    "gte_10_carbon_sb_chain",
    "gte_2_N_quats",
    "gte_2_free_phos",
    "gte_2_sulfonic_acid",
    "gte_3_COOH",
    "gte_3_iodine",
    "gte_4_basic_N",
    "gte_4_nitro",
    "gte_5_phenolic_OH",
    "gte_7_aliphatic_OH",
    "gte_7_total_hal",
    "gte_8_CF2_or_CH2",
    "halo_5heterocycle_bis_EWG",
    "halo_acrylate",
    "halo_imino",
    "halo_olefin_bis_EWG",
    "halo_phenolic_carbonyl",
    "halo_phenolic_sulfonyl",
    "halogen_heteroatom",
    "hetero_silyl",
    "hydrazine",
    "hydrazothiourea",
    "hydroxamate_warhead",
    "hyperval_sulfur",
    "isonitrile",
    "keto_def_heterocycle",
    "linear_polycyclic_aromatic_I",
    "linear_polycyclic_aromatic_II",
    "maleimide_etc",
    "meldrums_acid_deriv",
    "monofluoroacetate",
    "nitrone",
    "nitrosamine",
    "non_ring_CH2O_acetal",
    "non_ring_acetal",
    "non_ring_ketal",
    "ortho_hydroiminoquinone",
    "ortho_hydroquinone",
    "ortho_nitrophenyl_carbonyl",
    "ortho_quinone",
    "oxaziridine",
    "oxime",
    "oxonium",
    "para_hydroiminoquinone",
    "para_hydroquinone",
    "para_nitrophenyl_ester",
    "para_quinone",
    "paraquat_like",
    "pentafluorophenylester",
    "perchloro_cp",
    "perhalo_dicarbonyl_phenyl",
    "perhalo_phenyl",
    "peroxide",
    "phenolate_bis_EWG",
    "phos_serine_warhead",
    "phos_threonine_warhead",
    "phos_tyrosine_warhead",
    "phosphite",
    "phosphonium",
    "phosphorane",
    "phosphorous_nitrogen_bond",
    "phosphorus_phosphorus_bond",
    "phosphorus_sulfur_bond",
    "polyene",
    "polyhalo_phenol_a",
    "polyhalo_phenol_b",
    "polyhalo_phenol_c",
    "polyhalo_phenol_d",
    "polyhalo_phenol_e",
    "polysulfide",
    "porphyrin",
    "primary_halide_sulfate",
    "quat_N_N",
    "quat_N_acyl",
    "quinone_methide",
    "rhodanine",
    "secondary_halide_sulfate",
    "sulf_D2_nitrogen",
    "sulf_D2_oxygen_D2",
    "sulf_D3_nitrogen",
    "sulfite_sulfate_ester",
    "sulfonium",
    "sulfonyl_anhydride",
    "sulfonyl_halide",
    "sulfonyl_heteroatom",
    "sulphonyl_cyanide",
    "tertiary_halide_sulfate",
    "thio_hydroxamate",
    "thio_xanthate",
    "thiocarbonate",
    "thioester",
    "thiol_warhead",
    "thiopyrylium",
    "thiosulfoxide",
    "triamide",
    "triaryl_phosphine_oxide",
    "trichloromethyl_ketone",
    "triflate",
    "trifluoroacetate_ester",
    "trifluoroacetate_thioester",
    "trifluoromethyl_ketone",
    "trihalovinyl_heteroatom",
    "trinitro_aromatic",
    "trinitromethane_derivative",
    "tris_activated_aryl_ester",
    "trisub_bis_act_olefin",
    "vinyl_carbonyl_EWG",
    "Non-Hydrogen_atoms",
    "carbons",
    "N,O,S",
    "Sulfonyl_halides",
    "Acid_halides",
    "Alkyl_halides",
    "Phosphenes",
    "Heptanes",
    "Perchlorates",
    "Fluorines",
    "Cl,Br,I",
    "Carbazides",
    "Acid_anhydrides",
    "Peroxides",
    "Iso(thio)cyanates",
    "Thiocyanates",
    "Phosphoranes",
    "P/S_halides",
    "Cyanohydrines",
    "Carbazides",
    "Sulfate_esters",
    "Sulfonates",
    "Pentafluorophenyl_esters",
    "Paranitrophenyl_esters",
    "HOBt_esters",
    "Triflates",
    "Lawesson's_reagents",
    "Phosphoramides",
    "Aromatic_azides",
    "Quaternary_C,Cl,I,P,S",
    "Beta_carbonyl_quaternary_N",
    "Acylhydrazides",
    "Chloramidines",
    "Isonitriles",
    "Triacyloximes",
    "Acyl_cyanides",
    "Sulfonyl_cyanides",
    "Cyanophosphonates",
    "Azocyanamides",
    "Azoalkanals",
    "(Thio)epoxides,aziridines",
    "Benzylic_quaternary_N",
    "Thioesters",
    "Diand_Triphosphates",
    "Aminooxy(oxo)",
    "nitros",
    "Imines",
    "Acrylonitriles",
    "Propenals",
    "Quaternary_N",
    "ene_six_het_A(483)",
    "hzone_phenol_A(479)",
    "anil_di_alk_A(478)",
    "indol_3yl_alk(461)",
    "quinone_A(370)",
    "azo_A(324)",
    "imine_one_A(321)",
    "mannich_A(296)",
    "anil_di_alk_B(251)",
    "anil_di_alk_C(246)",
    "ene_rhod_A(235)",
    "hzone_phenol_B(215)",
    "ene_five_het_A(201)",
    "anil_di_alk_D(198)",
    "imine_one_isatin(189)",
    "anil_di_alk_E(186)",
    "thiaz_ene_A(128)",
    "pyrrole_A(118)",
    "catechol_A(92)",
    "ene_five_het_B(90)",
    "imine_one_fives(89)",
    "ene_five_het_C(85)",
    "hzone_pipzn(79)",
    "keto_keto_beta_A(68)",
    "hzone_pyrrol(64)",
    "ene_one_ene_A(57)",
    "cyano_ene_amine_A(56)",
    "ene_five_one_A(55)",
    "cyano_pyridone_A(54)",
    "anil_alk_ene(51)",
    "amino_acridine_A(46)",
    "ene_five_het_D(46)",
    "thiophene_amino_Aa(45)",
    "ene_five_het_E(44)",
    "sulfonamide_A(43)",
    "thio_ketone(43)",
    "sulfonamide_B(41)",
    "anil_no_alk(40)",
    "thiophene_amino_Ab(40)",
    "het_pyridiniums_A(39)",
    "anthranil_one_A(38)",
    "cyano_imine_A(37)",
    "diazox_sulfon_A(36)",
    "hzone_anil_di_alk(35)",
    "rhod_sat_A(33)",
    "hzone_enamin(30)",
    "pyrrole_B(29)",
    "thiophene_hydroxy(28)",
    "cyano_pyridone_B(27)",
    "imine_one_sixes(27)",
    "dyes5A(27)",
    "naphth_amino_A(25)",
    "naphth_amino_B(25)",
    "ene_one_ester(24)",
    "thio_dibenzo(23)",
    "cyano_cyano_A(23)",
    "hzone_acyl_naphthol(22)",
    "het_65_A(21)",
    "imidazole_A(19)",
    "ene_cyano_A(19)",
    "anthranil_acid_A(19)",
    "dyes3A(19)",
    "dhp_bis_amino_CN(19)",
    "het_6_tetrazine(18)",
    "ene_one_hal(17)",
    "cyano_imine_B(17)",
    "thiaz_ene_B(17)",
    "ene_rhod_B(16)",
    "thio_carbonate_A(15)",
    "anil_di_alk_furan_A(15)",
    "ene_five_het_F(15)",
    "anil_di_alk_F(14)",
    "hzone_anil(14)",
    "het_5_pyrazole_OH(14)",
    "het_thio_666_A(13)",
    "styrene_A(13)",
    "ene_rhod_C(13)",
    "dhp_amino_CN_A(13)",
    "cyano_imine_C(12)",
    "thio_urea_A(12)",
    "thiophene_amino_B(12)",
    "keto_keto_beta_B(12)",
    "keto_phenone_A(11)",
    "cyano_pyridone_C(11)",
    "thiaz_ene_C(11)",
    "hzone_thiophene_A(11)",
    "ene_quin_methide(10)",
    "het_thio_676_A(10)",
    "ene_five_het_G(10)",
    "acyl_het_A(9)",
    "anil_di_alk_G(9)",
    "dhp_keto_A(9)",
    "thio_urea_B(9)",
    "anil_alk_bim(9)",
    "imine_imine_A(9)",
    "thio_urea_C(9)",
    "imine_one_fives_B(9)",
    "dhp_amino_CN_B(9)",
    "anil_OC_no_alk_A(8)",
    "het_thio_66_one(8)",
    "styrene_B(8)",
    "het_thio_5_A(8)",
    "anil_di_alk_ene_A(8)",
    "ene_rhod_D(8)",
    "ene_rhod_E(8)",
    "anil_OH_alk_A(8)",
    "pyrrole_C(8)",
    "thio_urea_D(8)",
    "thiaz_ene_D(8)",
    "ene_rhod_F(8)",
    "thiaz_ene_E(8)",
    "het_65_B(7)",
    "keto_keto_beta_C(7)",
    "het_66_A(7)",
    "thio_urea_E(7)",
    "thiophene_amino_C(7)",
    "hzone_phenone(7)",
    "ene_rhod_G(7)",
    "ene_cyano_B(7)",
    "dhp_amino_CN_C(7)",
    "het_5_A(7)",
    "ene_five_het_H(6)",
    "thio_amide_A(6)",
    "ene_cyano_C(6)",
    "hzone_furan_A(6)",
    "anil_di_alk_H(6)",
    "het_65_C(6)",
    "thio_urea_F(6)",
    "ene_five_het_I(6)",
    "keto_keto_gamma(5)",
    "quinone_B(5)",
    "het_6_pyridone_OH(5)",
    "hzone_naphth_A(5)",
    "thio_ester_A(5)",
    "ene_misc_A(5)",
    "cyano_pyridone_D(5)",
    "het_65_Db(5)",
    "het_666_A(5)",
    "diazox_sulfon_B(5)",
    "anil_NH_alk_A(5)",
    "sulfonamide_C(5)",
    "het_thio_N_55(5)",
    "keto_keto_beta_D(5)",
    "ene_rhod_H(5)",
    "imine_ene_A(5)",
    "het_thio_656a(5)",
    "pyrrole_D(5)",
    "pyrrole_E(5)",
    "thio_urea_G(5)",
    "anisol_A(5)",
    "pyrrole_F(5)",
    "dhp_amino_CN_D(5)",
    "thiazole_amine_A(4)",
    "het_6_imidate_A(4)",
    "anil_OC_no_alk_B(4)",
    "styrene_C(4)",
    "azulene(4)",
    "furan_acid_A(4)",
    "cyano_pyridone_E(4)",
    "anil_alk_thio(4)",
    "anil_di_alk_I(4)",
    "het_thio_6_furan(4)",
    "anil_di_alk_ene_B(4)",
    "imine_one_B(4)",
    "anil_OC_alk_A(4)",
    "ene_five_het_J(4)",
    "pyrrole_G(4)",
    "ene_five_het_K(4)",
    "cyano_ene_amine_B(4)",
    "thio_ester_B(4)",
    "ene_five_het_L(4)",
    "hzone_thiophene_B(4)",
    "dhp_amino_CN_E(4)",
    "het_5_B(4)",
    "imine_imine_B(3)",
    "thiazole_amine_B(3)",
    "imine_ene_one_A(3)",
    "diazox_A(3)",
    "ene_one_A(3)",
    "anil_OC_no_alk_C(3)",
    "thiazol_SC_A(3)",
    "het_666_B(3)",
    "furan_A(3)",
    "colchicine_A(3)",
    "thiophene_C(3)",
    "anil_OC_alk_B(3)",
    "het_thio_66_A(3)",
    "rhod_sat_B(3)",
    "ene_rhod_I(3)",
    "keto_thiophene(3)",
    "imine_imine_C(3)",
    "het_65_pyridone_A(3)",
    "thiazole_amine_C(3)",
    "het_thio_pyr_A(3)",
    "melamine_A(3)",
    "anil_NH_alk_B(3)",
    "rhod_sat_C(3)",
    "thiophene_amino_D(3)",
    "anil_OC_alk_C(3)",
    "het_thio_65_A(3)",
    "het_thio_656b(3)",
    "thiazole_amine_D(3)",
    "thio_urea_H(3)",
    "cyano_pyridone_F(3)",
    "rhod_sat_D(3)",
    "ene_rhod_J(3)",
    "imine_phenol_A(3)",
    "thio_carbonate_B(3)",
    "het_thio_N_5A(3)",
    "het_thio_N_65A(3)",
    "anil_di_alk_J(3)",
    "pyrrole_H(3)",
    "ene_cyano_D(3)",
    "cyano_cyano_B(3)",
    "ene_five_het_M(3)",
    "cyano_ene_amine_C(3)",
    "thio_urea_I(3)",
    "dhp_amino_CN_F(3)",
    "anthranil_acid_B(3)",
    "diazox_B(3)",
    "thio_aldehyd_A(3)",
    "thio_amide_B(2)",
    "imidazole_B(2)",
    "thiazole_amine_E(2)",
    "thiazole_amine_F(2)",
    "thio_ester_C(2)",
    "ene_one_B(2)",
    "quinone_C(2)",
    "keto_naphthol_A(2)",
    "thio_amide_C(2)",
    "phthalimide_misc(2)",
    "sulfonamide_D(2)",
    "anil_NH_alk_C(2)",
    "het_65_E(2)",
    "hzide_naphth(2)",
    "anisol_B(2)",
    "thio_carbam_ene(2)",
    "thio_amide_D(2)",
    "het_65_Da(2)",
    "thiophene_D(2)",
    "het_thio_6_ene(2)",
    "cyano_keto_A(2)",
    "anthranil_acid_C(2)",
    "naphth_amino_C(2)",
    "naphth_amino_D(2)",
    "thiazole_amine_G(2)",
    "het_66_B(2)",
    "coumarin_A(2)",
    "anthranil_acid_D(2)",
    "het_66_C(2)",
    "thiophene_amino_E(2)",
    "het_6666_A(2)",
    "sulfonamide_E(2)",
    "anil_di_alk_K(2)",
    "het_5_C(2)",
    "ene_six_het_B(2)",
    "steroid_A(2)",
    "het_565_A(2)",
    "thio_imine_ium(2)",
    "anthranil_acid_E(2)",
    "hzone_furan_B(2)",
    "thiophene_E(2)",
    "ene_misc_B(2)",
    "het_thio_5_B(2)",
    "thiophene_amino_F(2)",
    "anil_OC_alk_D(2)",
    "tert_butyl_A(2)",
    "thio_urea_J(2)",
    "het_thio_65_B(2)",
    "coumarin_B(2)",
    "thio_urea_K(2)",
    "thiophene_amino_G(2)",
    "anil_NH_alk_D(2)",
    "het_thio_5_C(2)",
    "thio_keto_het(2)",
    "het_thio_N_5B(2)",
    "quinone_D(2)",
    "anil_di_alk_furan_B(2)",
    "ene_six_het_C(2)",
    "het_55_A(2)",
    "het_thio_65_C(2)",
    "hydroquin_A(2)",
    "anthranil_acid_F(2)",
    "pyrrole_I(2)",
    "thiophene_amino_H(2)",
    "imine_one_fives_C(2)",
    "keto_phenone_zone_A(2)",
    "dyes7A(2)",
    "het_pyridiniums_B(2)",
    "het_5_D(2)",
    "thiazole_amine_H(1)",
    "thiazole_amine_I(1)",
    "het_thio_N_5C(1)",
    "sulfonamide_F(1)",
    "thiazole_amine_J(1)",
    "het_65_F(1)",
    "keto_keto_beta_E(1)",
    "ene_five_one_B(1)",
    "keto_keto_beta_zone(1)",
    "thio_urea_L(1)",
    "het_thio_urea_ene(1)",
    "cyano_amino_het_A(1)",
    "tetrazole_hzide(1)",
    "imine_naphthol_A(1)",
    "misc_anisole_A(1)",
    "het_thio_665(1)",
    "anil_di_alk_L(1)",
    "colchicine_B(1)",
    "misc_aminoacid_A(1)",
    "imidazole_amino_A(1)",
    "phenol_sulfite_A(1)",
    "het_66_D(1)",
    "misc_anisole_B(1)",
    "tetrazole_A(1)",
    "het_65_G(1)",
    "misc_trityl_A(1)",
    "misc_pyridine_OC(1)",
    "het_6_hydropyridone(1)",
    "misc_stilbene(1)",
    "misc_imidazole(1)",
    "anil_NH_no_alk_A(1)",
    "het_6_imidate_B(1)",
    "anil_alk_B(1)",
    "styrene_anil_A(1)",
    "misc_aminal_acid(1)",
    "anil_no_alk_D(1)",
    "anil_alk_C(1)",
    "misc_anisole_C(1)",
    "het_465_misc(1)",
    "anthranil_acid_G(1)",
    "anil_di_alk_M(1)",
    "anthranil_acid_H(1)",
    "thio_urea_M(1)",
    "thiazole_amine_K(1)",
    "het_thio_5_imine_A(1)",
    "thio_amide_E(1)",
    "het_thio_676_B(1)",
    "sulfonamide_G(1)",
    "thio_thiomorph_Z(1)",
    "naphth_ene_one_A(1)",
    "naphth_ene_one_B(1)",
    "amino_acridine_A(1)",
    "keto_phenone_B(1)",
    "hzone_acid_A(1)",
    "sulfonamide_H(1)",
    "het_565_indole(1)",
    "pyrrole_J(1)",
    "pyrazole_amino_B(1)",
    "pyrrole_K(1)",
    "anthranil_acid_I(1)",
    "thio_amide_F(1)",
    "ene_one_C(1)",
    "het_65_H(1)",
    "cyano_imine_D(1)",
    "cyano_misc_A(1)",
    "ene_misc_C(1)",
    "het_66_E(1)",
    "keto_keto_beta_F(1)",
    "misc_naphthimidazole(1)",
    "naphth_ene_one_C(1)",
    "keto_phenone_C(1)",
    "coumarin_C(1)",
    "thio_est_cyano_A(1)",
    "het_65_imidazole(1)",
    "anthranil_acid_J(1)",
    "colchicine_het(1)",
    "ene_misc_D(1)",
    "indole_3yl_alk_B(1)",
    "anil_OH_no_alk_A(1)",
    "thiazole_amine_L(1)",
    "pyrazole_amino_A(1)",
    "het_thio_N_5D(1)",
    "anil_alk_indane(1)",
    "anil_di_alk_N(1)",
    "het_666_C(1)",
    "ene_one_D(1)",
    "anil_di_alk_indol(1)",
    "anil_no_alk_indol_A(1)",
    "dhp_amino_CN_G(1)",
    "anil_di_alk_dhp(1)",
    "anthranil_amide_A(1)",
    "hzone_anthran_Z(1)",
    "ene_one_amide_A(1)",
    "het_76_A(1)",
    "thio_urea_N(1)",
    "anil_di_alk_coum(1)",
    "ene_one_amide_B(1)",
    "het_thio_656c(1)",
    "het_5_ene(1)",
    "thio_imide_A(1)",
    "dhp_amidine_A(1)",
    "thio_urea_O(1)",
    "anil_di_alk_O(1)",
    "thio_urea_P(1)",
    "het_pyraz_misc(1)",
    "diazox_C(1)",
    "diazox_D(1)",
    "misc_cyclopropane(1)",
    "imine_ene_one_B(1)",
    "coumarin_D(1)",
    "misc_furan_A(1)",
    "rhod_sat_E(1)",
    "rhod_sat_imine_A(1)",
    "rhod_sat_F(1)",
    "het_thio_5_imine_B(1)",
    "het_thio_5_imine_C(1)",
    "ene_five_het_N(1)",
    "thio_carbam_A(1)",
    "misc_anilide_A(1)",
    "misc_anilide_B(1)",
    "mannich_B(1)",
    "mannich_catechol_A(1)",
    "anil_alk_D(1)",
    "het_65_I(1)",
    "misc_urea_A(1)",
    "imidazole_C(1)",
    "styrene_imidazole_A(1)",
    "thiazole_amine_M(1)",
    "misc_pyrrole_thiaz(1)",
    "pyrrole_L(1)",
    "het_thio_65_D(1)",
    "ene_misc_E(1)",
    "thio_cyano_A(1)",
    "cyano_amino_het_B(1)",
    "cyano_pyridone_G(1)",
    "het_65_J(1)",
    "ene_one_yne_A(1)",
    "anil_OH_no_alk_B(1)",
    "hzone_acyl_misc_A(1)",
    "thiophene_F(1)",
    "anil_OC_alk_E(1)",
    "anil_OC_alk_F(1)",
    "het_65_K(1)",
    "het_65_L(1)",
    "coumarin_E(1)",
    "coumarin_F(1)",
    "coumarin_G(1)",
    "coumarin_H(1)",
    "het_thio_67_A(1)",
    "sulfonamide_I(1)",
    "het_65_mannich(1)",
    "anil_alk_A(1)",
    "het_5_inium(1)",
    "anil_di_alk_P(1)",
    "thio_urea_Q(1)",
    "thio_pyridine_A(1)",
    "melamine_B(1)",
    "misc_phthal_thio_N(1)",
    "hzone_acyl_misc_B(1)",
    "tert_butyl_B(1)",
    "diazox_E(1)",
    "anil_NH_no_alk_B(1)",
    "anil_no_alk_A(1)",
    "anil_no_alk_B(1)",
    "thio_ene_amine_A(1)",
    "het_55_B(1)",
    "cyanamide_A(1)",
    "ene_one_one_A(1)",
    "ene_six_het_D(1)",
    "ene_cyano_E(1)",
    "ene_cyano_F(1)",
    "hzone_furan_C(1)",
    "anil_no_alk_C(1)",
    "hzone_acid_D(1)",
    "hzone_furan_E(1)",
    "het_6_pyridone_NH2(1)",
    "imine_one_fives_D(1)",
    "pyrrole_M(1)",
    "pyrrole_N(1)",
    "pyrrole_O(1)",
    "ene_cyano_G(1)",
    "sulfonamide_J(1)",
    "misc_pyrrole_benz(1)",
    "thio_urea_R(1)",
    "ene_one_one_B(1)",
    "dhp_amino_CN_H(1)",
    "het_66_anisole(1)",
    "thiazole_amine_N(1)",
    "het_pyridiniums_C(1)",
    "het_5_E(1)",
  ];

  // Method to get all types or data
  public getCustomizedData(): CustomizeToxicFilters[] {
    return this.customizeToxicInitialData;
  }

  public getAllSubRules(): string[] {
    return this.AllSubrules;
  }
}

// Export the class to use in TSX files
export default InitialData;

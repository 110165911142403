import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import GoogleIcon from "@mui/icons-material/Google";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import React, { FormEvent, useEffect, useState } from "react";

import GoogleButton from "react-google-button";
import { useUserAuth } from "../../context";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import { FirebaseError } from "firebase/app";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { flatten } from "ngl";
function Login() {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const { logIn, googleSignIn, user, updatePassword, emailverified } =
    useUserAuth();
  const navigate = useNavigate();

  const handleLoginSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { email, password } = values;
    e.preventDefault();
    //console.log(email, password);

    try {
      const userCred = await logIn(email, password);
      if (userCred.user) {
        navigate("/");
      }
      navigate("/");
    } catch (err: FirebaseError | any) {
      //console.log(err);
      toast.error("Invalid email/password", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const handleGoogleSignIn = async (e: any) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate(-1); // naviagte to previous page
    } catch (error) {
      toast.error("Error in signing in, Please Check again", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const handleLoginChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    // console.log(values)
  };

  const handleUpdatePassword = async (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    const { email } = values;
    try {
      await updatePassword(email);
      //console.log("Login onlcik");
      toast.info("Please check your email and update your password", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    } catch (err) {
      //console.log(err);
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (
    <div>
      <form>
        <Box
          display="flex"
          flexDirection="column"
          maxWidth={500}
          alignItems="center"
          justifyContent="center"
          margin={"auto"}
          marginTop={8}
          padding={2}
          borderRadius={5}
          boxShadow={"5px 5px 10px #ccc"}
          sx={{
            ":hover": {
              boxShadow: "10px 10px 20px #ccc",
            },
          }}
        >
          <Typography
            color={"#4c00b4"}
            variant="h2"
            padding={3}
            textAlign={"center"}
            fontWeight={800}
          >
            Login
          </Typography>

          <TextField
            variant="outlined"
            type="email"
            margin="normal"
            // name="email"
            style={{ width: 300 }}
            label="Email"
            name="email"
            onChange={(e) => handleLoginChange(e)}
          />
          <TextField
            variant="outlined"
            type="password"
            label="Password"
            style={{ width: 300 }}
            margin="normal"
            name="password"
            onChange={(e) => handleLoginChange(e)}
          />
          <Box
            display={"flex"}
            alignSelf={"flex-start"}
            marginLeft={11}
            color={"#989292"}
            fontSize={10}
          >
            <Typography
              onClick={(e) => handleUpdatePassword(e)}
              fontSize={15}
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
            >
              Forgot Password?
            </Typography>
          </Box>

          <Button
            sx={{ marginTop: 3, marginBottom: 2, borderRadius: 3 }}
            variant="contained"
            color="warning"
            onClick={(e) => handleLoginSubmit(e)}
          >
            Login
          </Button>
          <Typography fontWeight={500} marginBottom={2}>
            or
          </Typography>
          <Box>
            <GoogleButton
              className="g-btn"
              type="dark"
              onClick={handleGoogleSignIn}
            />
          </Box>

          <Typography
            display={"flex"}
            alignSelf={"flex-end"}
            marginTop={5}
            sx={{
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            <Link to="/user/signup">Don't have an Account? Click here!</Link>
          </Typography>
        </Box>
      </form>
      <ToastContainer />
    </div>
  );
}

export default Login;

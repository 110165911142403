import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, Typography, Card, Modal } from "@mui/material";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { Fragment } from "react";
import { useState, useEffect, useRef } from 'react'
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Viewer from "../../components/common/MolViewer";
import PropertiesTable from "../../components/Properties/PropertiesTable";



function RankerEvaluations() {
  const singleSmileData = useSelector((state: any) => state.rankerProperty.properties.singleSmileData);
  const [finalSmile, setFinalSmile] = useState(null);
  const [image, setImage] = useState(null);
  
  interface Item {
    title: string;
    value: any;
    color: string;
    info: string;
  }

  interface Category {
    heading: string;
    data: Item[];
  }
  
  const RANKER_DATA: Category[] = [
    { heading: "Physicochemical Properties", data: [] },
    { heading: "Interactions", data: [] }
  ];
  
  const [ranker, setRanker] = useState(RANKER_DATA);
  const [open, setOpen] = useState(false);
  const [molViewerId, setMolViewerId] = useState("molviewer");
  const [openImg, setOpenImg] = useState(false);


  const handleOpenimg = () => {
    setOpenImg(true);
  };

  const handleCloseimg = () => {
    setOpenImg(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setMolViewerId("molviewer2"); // Set the id to "molviewer2" when opening the modal
  };

  const handleClose = () => {
    setOpen(false);
    setMolViewerId("molviewer"); // Reset the id to "molviewer" when closing the modal
  };

  const modelMap: Record<string, string> = {
    MolWt: "Physicochemical Properties",
    MolLogP: "Physicochemical Properties",
    NumRotatableBonds: "Physicochemical Properties",
    HBondDonors: "Physicochemical Properties",
    HBondAcceptors: "Physicochemical Properties",
    TPSA: "Physicochemical Properties",
    HeavyAtoms: "Physicochemical Properties",
    NumAromaticRings: "Physicochemical Properties",
    SASA: "Physicochemical Properties",
    QED: "Physicochemical Properties",
    SA: "Physicochemical Properties",
    UndesiredRings: "Physicochemical Properties",
  };

  const infoModelMap: Record<string, string> = {
    MolLogP: "Logarithm of the n-octanol/water partition coefficient at pH 7.4",
    MolWt: "Molecular weight (Daltons)",
    NumRotatableBonds: "Number of rotatable bonds",
    HBondDonors: "Number of hydrogen bond donors",
    HBondAcceptors: "Number of hydrogen bond acceptors",
    TPSA: "Total Polar Surface Area (Angstrom²)",
    HeavyAtoms: "Number of non-hydrogen atoms",
    NumAromaticRings: "Number of aromatic rings",
    QED: "Quantitative Estimate of Drug-likeness, a score based on a combination of multiple physicochemical properties",
    SA: "The synthetic accessibility score as a metric for the ease of synthesis"
  };

  interface MolInfo {
    SMILE: string;
  }

  const MOL_INFO: MolInfo = {
    SMILE: finalSmile,
  };

  const navigate = useNavigate();

  let viewer = useRef<Viewer>();

  const initViewer = async () => {
    viewer.current = new Viewer();
    try {
      await viewer.current.init(molViewerId, {
        layoutShowControls: false,
        viewportShowExpand: false,
        collapseLeftPanel: true,
        layoutShowSequence: false,
      });
    } catch (err) {
      console.log("error", err);
    }
  };

  const updateViewer = async (
    data: string,
    format:
      | "mmcif"
      | "cifCore"
      | "pdb"
      | "pdbqt"
      | "gro"
      | "xyz"
      | "mol"
      | "sdf"
      | "mol2"
  ) => {
    initViewer().then(() => {
      const _ref = viewer?.current?.loadStructureFromData(data, format, {
        dataLabel: "",
      });
    });
  };

  useEffect(() => {
    if (!singleSmileData) {
      navigate("/genie/ranker/visualize");
    } else {
      setTimeout(() => {
        updateViewer(
          singleSmileData.mol2,
          "mol2"
        );
        setImage(
          singleSmileData.svg
        );
      }, 100);
    }


    const setProperties = (DATA: any) => {
      const updatedRankerData = [...RANKER_DATA];
      setImage(DATA["svg"]);

      for (const key in DATA) {
        if (modelMap[key]) {
          const heading = modelMap[key];
          const value = DATA[key];
          const newItem = {
            title: key,
            value: value,
            color: "",
            info: infoModelMap[key],
            failReason: "",
          };

          const categoryIndex = updatedRankerData.findIndex(
            (category) => category.heading === heading
          );

          if (categoryIndex !== -1) {
            updatedRankerData[categoryIndex].data.push(newItem);
          }
        }
      }

      const interactions = DATA.concatenated_interactions || [];
      const interactionItems = interactions.map((interaction: any) => {
        const parts = interaction.split('_');
        return {
          residueName: parts[0],
          residueNumber: parts[1],
          residueChain: parts[2],
          interactionType: parts[3],
          info: '',
        };
      });

      const interactionCategoryIndex = updatedRankerData.findIndex(
        (category) => category.heading === "Interactions"
      );

      if (interactionCategoryIndex !== -1) {
        updatedRankerData[interactionCategoryIndex].data.push(...interactionItems);
      } else {
        updatedRankerData.push({
          heading: "Interactions",
          data: interactionItems,
        });
      }

      setRanker(updatedRankerData);
      setFinalSmile(DATA.SMILES);
    };
    setProperties(singleSmileData);
  }, [open, molViewerId])


  return (
    <>
      {finalSmile && (
        <Box width="100%" py={2} pr={2} pl={2} sx={{ overflowX: "hidden" }}>
          <Box
            width="100%"
            mb={2}
            pr={1}
            pl={1}
            py={1}
            sx={{
              backgroundColor: "#cce5ff",
              borderRadius: 2,
              border: "1.5px solid #b8daff",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Box
                  width="100%"
                  sx={{ display: "flex", alignItems: "center", height: "100%" }}
                >
                  <Typography
                    sx={{
                      color: "#004085",
                      fontWeight: 700,
                      fontSize: 18,
                      whiteSpace: "normal",
                    }}
                  >
                    {MOL_INFO.SMILE}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  width="100%"
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Card>
                <Grid height={"100%"}>
                  <Grid
                    container
                    direction="column"
                    spacing={0}
                    height={"100%"}
                  >
                    <Grid item xs={12} md={12} sx={{ m: 0, pl: 1, pr: 1 }}>
                      <div
                        id="molviewer"
                        style={{
                          height: "450px",
                          width: "100%",
                          position: "relative",
                        }}
                      ></div>
                    </Grid>
                    <Grid sx={{ display: "flex", alignSelf: "flex-end" }}>
                      <Fragment>
                        <Button onClick={handleOpen}>
                          View <NorthEastIcon />{" "}
                        </Button>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="molviewer2"
                        // aria-describedby="modal-modal-description"
                        >
                          <Box
                            sx={{
                              position: "absolute" as "absolute",
                              top: "50%",
                              left: "50%",
                              right: "40%",
                              transform: "translate(-50%, -50%)",
                              width: '80%', 
                              height: '80%',
                              bgcolor: "background.paper",
                              border: "2px solid #000",
                              boxShadow: 24,
                              p: 1,
                              overflow: 'scroll'
                            }}
                          >
                            <div
                              id="molviewer2"
                              style={{
                                height: "100%",
                                width: "100%",
                                position: "relative",
                              }}
                            ></div>
                          </Box>
                        </Modal>
                      </Fragment>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid item md={6} xs={12}>
              <Card>
                <Grid height={"100%"}>
                  <Grid
                    container
                    direction="column"
                    spacing={0}
                    height={"100%"}
                  >
                    <Grid item xs={12} md={12} sx={{ m: 0 }}>
                      {image && (
                        <div
                          style={{
                            width: "100%",
                            height: 450,
                            backgroundColor: "white",
                            display: "flex",
                            justifyContent: "center",
                            overflow: "hidden",
                            alignItems: "center",
                          }}
                        >
                          <img
                            alt="smile string"
                            src={`data:image/svg+xml;base64,${btoa(image)}`}
                            style={{ width: "80%", height: "80%" }}
                          ></img>
                        </div>
                      )}
                    </Grid>
                    <Grid sx={{ display: "flex", alignSelf: "flex-end" }}>
                      <Fragment>
                        <Button onClick={handleOpenimg}>
                          View <NorthEastIcon />{" "}
                        </Button>
                        <Modal
                          open={openImg}
                          onClose={handleCloseimg}
                          aria-labelledby="image"
                        // aria-describedby="modal-modal-description"
                        >
                          <Box
                            sx={{
                              position: "absolute" as "absolute",
                              top: "50%",
                              left: "50%",
                              right: "40%",
                              transform: "translate(-50%, -50%)",
                              width: '80%',
                              overflow :'scroll',
                              bgcolor: "background.paper",
                              border: "2px solid #000",
                              boxShadow: 24,
                              p: 4,
                              height: '80%',
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                id="image"
                                src={`data:image/svg+xml;base64,${btoa(image)}`}
                                alt="smile"
                                style={{ width: "40%", height: "40%" }}
                              />
                            </div>
                          </Box>
                        </Modal>
                      </Fragment>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={12}>
              <PropertiesTable screenie_data={ranker} />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default RankerEvaluations
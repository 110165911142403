import { Box, Typography } from '@mui/material'
import React from 'react'

interface ProteinInfoCardProps {
  title: string;
  children: string | JSX.Element;
}
function ProteinInfoCard({ title, children }: ProteinInfoCardProps) {
  return (
    <Box
      sx={{
        p: 4,
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
        flex: '1 0 0',
        borderRadius: '30px',
        border: '1px solid var(--gray-2, #E9ECEF)',
        background: 'var(--gray-0, #F8F9FA)',
      }}
    >
      <Typography variant={'h5'} fontSize={'1.75rem'} fontWeight={600}>
        {title}
      </Typography>
      <Box>{children}</Box>
    </Box>
  )
}

export default ProteinInfoCard

import React, { useEffect, useId, useRef, useState } from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormData from "form-data";
import Grid from "@mui/material/Grid";

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";

import { FileUploader, Viewer } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { JOB_SERVER_URL } from "../../config";
function DockingHome() {
  const [protFile, setProtFile] = useState<string>();
  const [ligandFile, setLigandFile] = useState<string>();
  const [inProgress, setInProgress] = useState(false);

  const state = useLocation();
  const navigate = useNavigate();

  interface payload {
    model_name: string;
    job_id: string;
  }

  let viewer = useRef<Viewer>();
  const initViewer = async () => {
    viewer.current = new Viewer();
    await viewer.current.init("viewer", {
      layoutShowControls: false,
      viewportShowExpand: false,
      collapseLeftPanel: true,
      layoutShowSequence: true,
    });
  };

  const updateViewer = async (
    data: string,
    format:
      | "mmcif"
      | "cifCore"
      | "pdb"
      | "pdbqt"
      | "gro"
      | "xyz"
      | "mol"
      | "sdf"
      | "mol2"
  ) => {
    await viewer.current?.loadStructureFromData(data, format);
  };

  useEffect(() => {
    initViewer();
  });

  const handleSubmit = async (e: any) => {
    //console.log("submit clicked");
    setInProgress(true);
    setInProgress(false);
    updateViewer(protFile, "pdb");
    updateViewer(ligandFile, "sdf");
  };

  const loadFromModel = async (params: payload) => {
    axios
      .get(`${JOB_SERVER_URL}/diffdock/results`, {
        params: {
          task_id: params.job_id,
        },
        headers: {
          accept: "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //console.log(response.data);
          updateViewer(response.data?.protein, "pdb");
          updateViewer(response.data?.ligand, "sdf");
        } else {
          alert("unable to load results" + response.statusText);
        }
      });
  };

  if (state.state?.from_model) {
    // submit to ADME
    const model_name: string = state.state.model_name;
    const job_id: string = state.state.job_id;
    loadFromModel({ model_name, job_id });
  }

  return (
    <Box px={3} py={2}>
      {/* <Card>
        <CardContent>
          <FormControl fullWidth>
            <Grid container spacing={2} width={"100%"}>
              <Grid item xs={6}>
                <Stack spacing={1}>
                  <Typography variant={"h6"} fontWeight={"bold"}>
                    Target File
                  </Typography>
                  <FileUploader
                    accept="chemical/x-pdb"
                    handleFileUpload={async (files) => {
                      setProtFile(await files[0].text());
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={1}>
                  <Typography variant={"h6"} fontWeight={"bold"}>
                    Ligand File
                  </Typography>
                  <FileUploader
                    accept="chemical/x-mdl-sdfile"
                    handleFileUpload={async (files) => {
                      setLigandFile(await files[0].text());
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </FormControl>
          <Box display={"flex"} mt={1}>
            <Button variant="contained" sx={{ ml: 0.5 }} onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </CardContent>
      </Card> */}
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <div id="viewer"></div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default DockingHome;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUserAuth } from "../../context";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FirebaseError } from "firebase/app";
import email from "../../Assets/email.png";
import name from "../../Assets/person.png";
import password from "../../Assets/password.png";
import GoogleButton from "react-google-button";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { Link } from "react-router-dom";
function Signup() {
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    confirmpassword: "",
  });
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const [error, setError] = useState("");
  const { signUp, googleSignIn, user } = useUserAuth();
  let navigate = useNavigate();

  const isEmailValid = (email: string): boolean => {
    // A basic email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // console.log("emailRegex",emailRegex);
    return emailRegex.test(email);
  };

  const handleGoogleSignIn = async (e: any) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate(-1); // naviagte to previous page
    } catch (error) {
      toast.error("Error in signing in", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { name, email, password, confirmpassword } = values;
    e.preventDefault();

    // Add email validation logic
    if (!isEmailValid(email)) {
      toast.error("Please enter a valid email address.", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
      return;
    }

    // Add password validation logic
    // console.log("values",values);
    if (password.length < 6) {
      toast.error("Password needs to be more than 6 characters", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });

      return;
    }

    if (password !== confirmpassword) {
      toast.error("Password and Confirm Password need to match", {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });

      return;
    }

    try {
      await signUp(name, email, password);

      // //console.log(email,password)
      // //console.log("Submited")
      navigate("/");
    } catch (err: FirebaseError | any) {
      toast.error(err.message, {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (
    <div>
      <form>
        <Box
          display="flex"
          flexDirection="column"
          maxWidth={550}
          alignItems="center"
          justifyContent="center"
          margin={"auto"}
          marginTop={2}
          padding={2}
          borderRadius={5}
          boxShadow={"5px 5px 12px #ccc"}
          sx={{
            ":hover": {
              boxShadow: "10px 10px 20px #ccc",
            },
          }}
        >
          <Typography
            color={"#4c00b4"}
            variant="h2"
            padding={3}
            textAlign={"center"}
            fontWeight={800}
          >
            Sign Up
          </Typography>
          <TextField
            variant="outlined"
            type="text"
            name="name"
            // placeholder="Name"
            margin="normal"
            style={{ width: 300 }}
            // name="name"
            label="Name"
            value={values.name}
            onChange={(e) => handleChange(e)}
          />
          <TextField
            variant="outlined"
            type="email"
            name="email"
            // placeholder="Email"
            margin="normal"
            style={{ width: 300 }}
            // name="email"
            label="Email Address"
            value={values.email}
            onChange={(e) => handleChange(e)}
          />
          <TextField
            variant="outlined"
            type="password"
            name="password"
            // placeholder="Password"
            margin="normal"
            style={{ width: 300 }}
            // name="password"
            label="Create Password"
            value={values.password}
            onChange={(e) => handleChange(e)}
          />
          <TextField
            variant="outlined"
            type="password"
            name="confirmpassword"
            // placeholder="Confirm Password"
            margin="normal"
            style={{ width: 300 }}
            // name="confirmpassword"
            label="Confirm Password"
            value={values.confirmpassword}
            onChange={(e) => handleChange(e)}
          />
          <Box
            display={"flex"}
            alignSelf={"flext-start"}
            fontSize={15}
            sx={{
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            <Link to="/user/login">Already have an Account?</Link>
          </Box>

          <Button
            sx={{ marginTop: 3, marginBottom: 1, borderRadius: 3 }}
            variant="contained"
            color="warning"
            onClick={(e) => handleSubmit(e)}
          >
            Sign Up
          </Button>
          <Typography fontWeight={500} marginBottom={1}>
            or
          </Typography>
          <Box>
            <GoogleButton
              className="g-btn"
              type="dark"
              onClick={handleGoogleSignIn}
            />
          </Box>
        </Box>
      </form>
      <ToastContainer />
    </div>
  );
}

export default Signup;

import { SOLAR_DEV } from "../../config";

function MoleculeCopilotHome() {
  return (
    <div className="chemlet mol-props" style={{margin:"1em"}}>
      <iframe
        title="Molecular CoPilot"
        width="100%"
        height="800"
        src={`https://delta.knowdis.ai/leadopt/`}
      ></iframe>
    </div>
  );
}

export default MoleculeCopilotHome;

import { Box, Button, Typography } from "@mui/material";

import React from "react";
import { Link, useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography
        variant="h1"
        sx={{
          fontSize: "8rem",
          background:
            "-webkit-linear-gradient(93deg, #6540EE 7.27%, #8062EE 77.03%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        404
      </Typography>
      <Box>
        <h3>Oops!!</h3>
        <p>That page doesn't exist or is unavailable</p>
      </Box>
      <Link to="/">
        <Button variant="contained" size="small">
          Back To Home
        </Button>
      </Link>
    </Box>
  );
}

export default PageNotFound;

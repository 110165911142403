import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { PropertiesState } from "../../models";
import axios from "axios";
import { error } from "console";
import { JOB_SERVER_URL } from "../../config";
import http from '../../net/http-common'
interface stateType {
  status: string;
  properties: {
    autoDockdata: any;
    loading: boolean;
    ligand_url: string;
    SMILES:string,
    Docking_Score:number,
    task_id: string
  };
}

const initialState: stateType = {
  status: "idle",
  properties: {
    autoDockdata: [],
    loading: false,
    ligand_url: "",
    SMILES:"",
    Docking_Score:0,
    task_id: ""
  },
};

// get autodock properties
export const fetchAutoDockProperties = createAsyncThunk(
  "docking/fetchAutoDockProperties",
  async ({task_id,model_name}:{task_id:string,model_name?:string}) => {
   
    // getting result for unidock if model_name is exist
    let url='';
    if(model_name){
       url=`${JOB_SERVER_URL}/dock/results?task_id=${task_id}&model_name=${model_name}&as_file=false`;

    }
    else{
     url=`${JOB_SERVER_URL}/dock/results?task_id=${task_id}&as_file=false`;
    }
    return http
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  }
);

 

// export const fetchPreparedLigands = createAsyncThunk(
//   "docking/fetchPreparedLigands",
//   async (task_id: string) => {
//     return axios
//       .get(`${JOB_SERVER_URL}/dock/get_prepared_ligands?task_id=${task_id}`)
//       .then((response) => response.data)
//       .catch((error) => {
//         console.log(error);
//       });
//   }
// );

const dockingSlice = createSlice({
  name: "docking",
  initialState,
  reducers: {
    setProperties(state, action) {
      //console.log(action);
    },
    setLigandUrl(state, action) {
      state.properties.ligand_url = action.payload[0];
      state.properties.SMILES = action.payload[1];
      state.properties.Docking_Score = action.payload[2];
    },
    setTaskId(state, action) {
      state.properties.task_id = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Fetch Properties
    builder
      .addCase(
        fetchAutoDockProperties.fulfilled,
        (state: stateType, action: any) => {
          state.properties.autoDockdata = action.payload;
          state.properties.loading = false;
          // Set the task ID here when fetchAutoDockProperties is fulfilled
          state.properties.task_id = action.meta.arg;
        }
      )
      .addCase(fetchAutoDockProperties.rejected, (state, action: any) => {
        state.properties.loading = false;
      })
      .addCase(fetchAutoDockProperties.pending, (state) => {
        state.properties.loading = true;
      })

       
  },
});


export const { setProperties, setLigandUrl } = dockingSlice.actions;
export default dockingSlice.reducer;

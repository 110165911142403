export const  convertUnixTimestampToDate=(unixTimestamp:number) =>{
    // Create a new JavaScript Date object based on the Unix timestamp (in milliseconds)
    const date = new Date(Number(unixTimestamp) * 1000);
    
    // Extract the day, month, and year from the Date object
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    
    // Return the date in dd/mm/yyyy format
    return `${day}/${month}/${year}`;
  }

export const convertIndianRupeeFormat = (amount:number)=>{

    const formatter = new Intl.NumberFormat('en-IN', {
        currency: 'INR',
        minimumFractionDigits: 0
      });
      const formattedNumber = formatter.format(amount);
      
      return formattedNumber;

}
import { convertUnixTimestampToDate } from "../../views/ProfilePage/ProfilePageHelper";

/* Provide the number of remaining days by 
calculating the difference between current date and expiration date
*/
export const userWorkflowValidation = (targetDateString: string): number => {
  if (!targetDateString) {
    return 0;
  }

  var currentDate = new Date();

  var targetDate = new Date(targetDateString); // Format: YYYY-MM-DD

  // Convert both dates to UTC to remove the time component
  var currentDateUTC = Date.UTC(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  var targetDateUTC = Date.UTC(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    targetDate.getDate()
  );

  // Calculate the difference in milliseconds between the target date and current date
  var differenceInMilliseconds = targetDateUTC - currentDateUTC;

  // Convert the difference from milliseconds to days
  var differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  return differenceInDays;
};

// check if workflow restricted or not
export const isWorkflowRestricted = (
  worklowValidation: string,
  workflowName: string,
  freeWorkflows: string[],
  accessWorkflows: string[]
): boolean => {
  if (
    (userWorkflowValidation(worklowValidation) <= 0 &&
      !freeWorkflows.includes(workflowName)) ||
    (!freeWorkflows.includes(workflowName) &&
      !accessWorkflows.includes(workflowName))
  ) {
    return true;
  }
  return false;
};

// compare two dates in dd/mm/yyyy format
// Gives true if current date is greater than next billing date
function isRestrictedWithCurrentCycle(givenDateNum: number) {
  const givenDateString = convertUnixTimestampToDate(givenDateNum);

  const [givenDay, givenMonth, givenYear] = givenDateString
    .split("/")
    .map(Number);
  const givenDate = new Date(givenYear, givenMonth - 1, givenDay); // Month is 0-indexed in JS Date

  // Get current date
  const currentDate = new Date();
  const currentDateString = `${String(currentDate.getDate()).padStart(
    2,
    "0"
  )}/${String(currentDate.getMonth() + 1).padStart(
    2,
    "0"
  )}/${currentDate.getFullYear()}`;

  // Convert current date to a comparable date object
  const [currentDay, currentMonth, currentYear] = currentDateString
    .split("/")
    .map(Number);
  const currentDateObj = new Date(currentYear, currentMonth - 1, currentDay);


  // Compare current date string with given date string
  return currentDateObj > givenDate;
}

export const isNotSubscribed = (
  workflowName: string,
  freeWorkflows: string[],
  userSubscription: any,
  razorpaySubInfo: any
) => {
  // console.log('usersub',userSubscription);

  if (!userSubscription || !razorpaySubInfo) return true;


  // if user cancelled his subscription and current date is greater than next billing date then user can't access any workflow
  if (
    userSubscription.initial_status === "cancelled" &&
    isRestrictedWithCurrentCycle(razorpaySubInfo.current_end)
  ) {

    return true;
  } else if (
    razorpaySubInfo.status !== "active" ||
    (!freeWorkflows.includes(workflowName) &&
      !userSubscription.workflows.includes(workflowName))
  ) {
    return true;
  }

  return false;
};

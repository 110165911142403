export const SERVER_URL: string = "https://app.moleculeai.com/api";
export const ESP_SERVER_URL: string = "https://app.moleculeai.com/esp";
export const JOB_SERVER_URL: string = "https://app.moleculeai.com/jobs";
export const ADMET_REST_URL: string = "https://app.moleculeai.com/admet";
export const MEDFILTERS_URL: string = "https://app.moleculeai.com/medfilters";
export const SOLAR_DEV: string = "https://solar-dev.knowdisdata.com";
export const LIFT_SERVER_URL: string = "https://services.moleculegen.com";
export const LIFT_MOLBENCH: string = "https://services.moleculegen.com";
// export const OtherEndPoint:string="https://gamma.knowdisdata.com"
export const REINVENT_SERVER_URL: string =
  "https://app.moleculeai.com/reinvent";
export const TOXGROUP_URL: string = "https://app.moleculeai.com/toxgroups";

// export const SERVER_URL: string = "http://localhost:9098";
// export const JOB_SERVER_URL: string = "http://gamma.knowdisdata.com:7081";
// export const ESP_SERVER_URL: string = "http://gamma.knowdisdata.com:9088";
// export const SERVER: string = window.location.hostname;
// export const API_PORT: string = "9098";
// export const SERVER_URL: string = `${SERVER}:${API_PORT}`;

const ENV: string = process.env.REACT_APP_ENV as string;

let RUNTIME: string = "LOCAL";
if (ENV === "PROD") RUNTIME = "PROD";
export { RUNTIME };

import { Box, Divider, Grid, Typography, Link } from "@mui/material";
import React from "react";

const PDBInfoTable = (data: any) => {
  ////console.log(data);
  const NameDD = ({ children }: { children: JSX.Element | string }) => (
    <Typography variant={"subtitle2"} color={"grey.600"}>
      {children}
    </Typography>
  );

  const NameDT = ({ children }: { children: JSX.Element | string }) => (
    <Typography variant={"h6"} fontSize={"1.125rem"}>
      {children}
    </Typography>
  );

  return (
    <div>
      <Grid container direction={"column"} rowSpacing={3} p={3}>
        <Grid item>
          <Typography
            variant={"h5"}
            textTransform={"capitalize"}
            fontWeight={600}
          >
            {data.data.name}
          </Typography>
        </Grid>
        <Grid item container rowSpacing={3.5}>
          <Grid item xs={6}>
            <NameDD>Chains:</NameDD>
            <NameDT>{data.data.chains.length}</NameDT>
          </Grid>
          <Grid item xs={6}>
            <NameDD>Structure Method:</NameDD>
            <NameDT>{data.data.structure_method}</NameDT>
          </Grid>
          <Grid item xs={6}>
            <NameDD>Resolution:</NameDD>
            <NameDT>{data.data.resolution}</NameDT>
          </Grid>
          <Grid item xs={6}>
            <NameDD>PH:</NameDD>
            <NameDT>{data.data.ph}</NameDT>
          </Grid>
          <Grid item xs={6}>
            <NameDD>Total Water:</NameDD>
            <NameDT>{data.data.water}</NameDT>
          </Grid>
          {/* <Grid item>
            <Typography variant={'subtitle2'} color={'grey.600'}>
              Chains:
            </Typography>
            <Typography variant={'h6'}>{data.data.chains.length}</Typography>
          </Grid> */}
        </Grid>
        <Grid item>
          <Divider></Divider>
        </Grid>
        <Grid item>
          <Typography
            textTransform={"uppercase"}
            color={"grey.500"}
            fontWeight={"700"}
            fontSize={"smaller"}
            mb={2}
          >
            Missing Residues
          </Typography>
          <Box
            component={"ul"}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              padding: 0,
              listStyle: "none",
            }}
          >
            {data.data.grouped_missing_residues?.map((res: any) => (
              <Box
                component={"li"}
                sx={{
                  display: "flex",
                  padding: "7px 12px",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "rgba(38, 70, 162, 0.10)",
                  margin: "0px 12px 16px 0",
                  borderRadius: "8px",
                  border: "1px solid #D7DBE8",
                }}
                key={res.chain + res.start_seq + res.end_seq}
              >
                <Typography color={"primary"} fontSize={"small"}>
                  Chain {res.chain} : {res.start_seq}-{res.end_seq}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item>
          <Divider></Divider>
        </Grid>
        <Grid item>
          <Typography
            textTransform={"uppercase"}
            color={"grey.500"}
            fontWeight={"700"}
            fontSize={"smaller"}
            mb={2}
          >
            More Information
          </Typography>
          <Grid item>
            <Typography variant="body1">
              RCSB :{" "}
              <Link
                href={`https://www.rcsb.org/structure/${data.data.idcode}`}
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
                underline="hover"
              >
                Click for more info
              </Link>
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1">
              PDBE :{" "}
              <Link
                href={`https://www.ebi.ac.uk/pdbe/entry/pdb/${data.data.idcode}`}
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
                underline="hover"
              >
                Click for more info
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow></TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{data.data.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Chains</TableCell>
              <TableCell>
                <p>{data.data.chains.length}</p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Structure Method</TableCell>
              <TableCell>{data.data.structure_method}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Resolution</TableCell>
              <TableCell>{data.data.resolution}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>pH</TableCell>
              <TableCell>{data.data.ph}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Water</TableCell>
              <TableCell>{data.data.water}</TableCell>
            </TableRow>
            {data.data.has_missing_residues && (
              <TableRow>
                <TableCell
                  className="mis-res-header"
                  style={{ verticalAlign: "top" }}
                >
                  Missing Residues
                </TableCell>

                <TableCell>
                  <ul> Residue Name, Chain, SSSEQ</ul>
                  {data.data.missing_residues.map((res: any) => (
                    <ul key={res.ssseq + res.res_name + res.chain}>
                      {res.res_name}, {res.chain}, {res.ssseq}
                    </ul>
                  ))}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer> */}
    </div>
  );
};

export default PDBInfoTable;

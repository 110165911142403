import { useEffect, useState } from "react";
import {
  JobsTable,
  PropertiesInput,
  WorkflowLandingTabs,
} from "../../components";
import { WorkflowLandingTab } from "../../models";
import {
  Grid,
  Typography,
  Box,
  CardContent,
  Card,
  Snackbar,
  Alert,
} from "@mui/material";
import Loaders from "../../components/common/Loaders";
import http from "../../net/http-common";
import { JOB_SERVER_URL } from "../../config";
import { useUserAuth } from "../../context";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import { useAppSelector } from "../../app/hooks";

const tabs: Array<WorkflowLandingTab> = [
  {
    id: "0",
    label: "Description",
    value: (
      <>
        <Typography paragraph>
        Screenie computes the physicochemical and medicinal chemistry
          properties of molecules. It allows the user to construct a focused
          library from a larger set of compounds by applying filters (e.g.,
          PAINS, toxic and unstable moieties, infeasible ring structures, and
          extended Lipinski filters) based on these calculated properties.
        </Typography>
        <Box
          component="img"
          src="/images/hero/Screene.png"
          alt="Protein Info Visual"
          sx={{
            width: "50%", // Reduces the width to 80% of the parent container
            height: "auto",
            mt: 4, // Adds margin-top for space above the image
            mb: 2, // Adds margin-bottom for space below the image
            mx: "auto", // Centers the image horizontally
            display: "block", // Ensures the Box behaves like a block element
            p: 2, // Adds padding around the image inside the Box
          }}
        />
      </>
    ),
  },

  {
    id: "1",
    label: "References",
    value: (
      <>
        <Typography variant={"body1"} mb={1}>
          1. Lipinski, Christopher A. "Lead-and drug-like compounds: the
          rule-of-five revolution." Drug discovery today: Technologies 1.4
          (2004): 337-341.
        </Typography>
        <Typography variant={"body1"} mb={1}>
          2. Benet, Leslie Z., et al. "BDDCS, the Rule of 5 and drugability."
          Advanced drug delivery reviews 101 (2016): 89-98.
        </Typography>
        <Typography variant={"body1"} mb={1}>
          3. Bento, A. Patrícia, et al. "An open source chemical structure
          curation pipeline using RDKit." Journal of Cheminformatics 12 (2020):
          1-16.
        </Typography>
        <Typography variant={"body1"} mb={1}>
          4. Rings in Clinical Trials and Drugs: Present and Future | Journal of
          Medicinal Chemistry (acs.org), and Rings in Drugs | Journal of
          Medicinal Chemistry (acs.org)
        </Typography>
      </>
    ),
  },
];
function PropertiesHome() {
  const MODEL_NAME = "screenie";
  const [doLoadJobs, setDoLoadJobs] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [rows, setRows] = useState<[]>([]);
  const [showSubmitMsg,setShowSubmitMsgFromHome]=useState<boolean>(false);
  const [jobID, setJobID] = useState<any>(-1);

  const screeniePropertyV3 = useAppSelector(
    (state: any) => state.screeniePropertyV3.properties
  );

  const { user } = useUserAuth();

  useEffect(() => {
    if (!doLoadJobs) return;

    setDoLoadJobs(true);

    http
      .get(`${JOB_SERVER_URL}/userjobs`, {
        params: {
          user_id: user.uid,
          model_name: MODEL_NAME,
          start: "0",
          end: "10",
        },
        headers: {
          accept: "application/json",
        },
      })
      .then((res) => {
        setDoLoadJobs(false);
        setRows(res.data);
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg(errorMessages.jobTableError);
        setDoLoadJobs(false);
        setRows([]);
      });
  }, [doLoadJobs]);

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Grid px={3} container spacing={2.5} mt={0}>
        <Grid
          item
          sm={12}
          md={8}
         
          sx={{ width: "100%" }}
          display={"flex"}
        >
          <WorkflowLandingTabs tabs={tabs} />
        </Grid>
        <Grid
          item
          sm={12}
          md={4}
          
          sx={{ width: "100%" }}
          display={"flex"}
        >
          <PropertiesInput inFilterPage={false} setDoLoadJobs={setDoLoadJobs} setShowSubmitMsgFromHome={setShowSubmitMsgFromHome} setJobID={setJobID} />
        </Grid>
      </Grid>
      <Grid sx={{ width: "50%", mx: "auto" }}>
          {showSubmitMsg && (
            <Alert
              onClose={() => {
                setShowSubmitMsgFromHome(false);
              }}
              sx={{ mt: 2 }}
              variant="filled"
              severity="success"
            >{`Job submitted with id ${jobID}`}</Alert>
          )}
        </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className="jobs-container"
        mt={2}
        mb={2}
        px={3}
      >
        <Card sx={{ width: 1 }}>
          <CardContent>
            {doLoadJobs && <Loaders type={"table"} />}
            {!doLoadJobs && (
              <JobsTable
                jobs={rows}
                setDoLoadJobs={setDoLoadJobs}
                nextUrl="visualize"
                setErrorMsg={setErrorMsg}
                setShowError={setShowError}
              ></JobsTable>
            )}
          </CardContent>
        </Card>
      </Grid>
      
    </>
  );
}

export default PropertiesHome;

import { WorkflowLandingTabs } from "../../components";
import { WorkflowLandingTab } from "../../models";
import { Grid, Typography, Box } from "@mui/material";
import CopilotInput from "../../components/MoleculeCopilot/CopilotInput";

const tabs: Array<WorkflowLandingTab> = [
  {
    id: "0",
    label: "Description",
    value: (
      <>
        <Typography paragraph>
          Copilot allows users to optimize molecules using an intuitive,
          interactive interface powered by natural language feedback. Users can
          input a molecule in the form of a SMILES (Simplified Molecular Input
          Line Entry System) string, along with a desired modification described
          in plain text. Copilot then processes this information to generate
          optimized molecules that meet the specified criteria.
        </Typography>
        <Box
          component="img"
          src="images/new-descriptive-images/copilot_descriptive.png"
          alt="Protein Info Visual"
          sx={{
            width: "80%", // Reduces the width to 80% of the parent container
            height: "auto",
            mx: "auto", // Centers the image horizontally
            display: "block", // Ensures the Box behaves like a block element
            pb: 0,
          }}
        />
      </>
    ),
  },
];

function MoleculeCopilot() {
  return (
    <>
      <Grid container py={1} px={3} spacing={2}>
        <Grid
          item
          xs={12}
          md={8}
          sx={{ pt: 2, width: "100%" }}
          pl={3}
          display={"flex"}
        >
          <WorkflowLandingTabs tabs={tabs} />
        </Grid>

        <Grid item xs={12} md={4} sx={{ width: "100%" }} display={"flex"}>
          <CopilotInput />
        </Grid>
      </Grid>
    </>
  );
}

export default MoleculeCopilot;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { ErratData, ESP, Hole, RamachandranData, SSData } from "../../models";
import {
  getErratData,
  getESPData,
  getHoleData,
  getRamachandranPlot,
  getSSData,
} from "./pdbInfoAPI";

// Define a type for the slice state
export interface PdbInfoState {
  status: "idle" | "loading" | "failed";
  pdbData: any;
  ramachandran: {
    data: RamachandranData;
    loading: boolean;
  };
  errat: {
    data: ErratData;
    loading: boolean;
  };
  ss: {
    data: SSData;
    loading: boolean;
  };
  hole: {
    data: Hole;
    loading: boolean;
  };
  esp: {
    data: ESP;
    loading: boolean;
  };
}

const initialState: PdbInfoState = {
  status: "idle",
  pdbData: null,
  ramachandran: {
    data: null,
    loading: false,
  },
  errat: {
    data: null,
    loading: false,
  },
  ss: {
    data: null,
    loading: false,
  },
  hole: {
    data: null,
    loading: false,
  },
  esp: {
    data: null,
    loading: false,
  },
};

export const fetchRamachandran = createAsyncThunk(
  "pdbInfo/fetchRamachandran",
  async ({ pdbID, jobDir }: { pdbID: string; jobDir: string }) => {
    try{
    const response = await getRamachandranPlot({ jobDir, pdbID });
    return response.data;}
    catch(error)
    {
      return {error};
    }
  }
);

export const fetchErrat = createAsyncThunk(
  "pdbInfo/fetchErrat",
  async ({
    pdbID,
    jobDir,
    form,
  }: {
    form: any;
    jobDir: string;
    pdbID: string;
  }) => {
    try{
    const response = await getErratData({ jobDir, pdbID, form });
    return response.data;}
    catch(error)
    {
      return {error}
    }
  }
);

export const fetchSSData = createAsyncThunk(
  "pdbInfo/fetchSSData",
  async ({
    pdbID,
    jobDir,
    form,
  }: {
    form: any;
    jobDir: string;
    pdbID: string;
  }) => {
    try{
    const response = await getSSData({ jobDir, pdbID, form });
    return response.data;}
    catch(error)
    {
      return {error};
    }
  }
);

export const fetchHoleData = createAsyncThunk(
  "pdbInfo/fetchHoleData",
  async ({
    pdbID,
    jobDir,
    form,
  }: {
    form: any;
    jobDir: string;
    pdbID: string;
  }) => {
    try{
    const response = await getHoleData({ jobDir, pdbID, form });
    return response.data;
    }
    catch(error)
    {
      return {error};
    }
  }
);

export const fetchESPData = createAsyncThunk(
  "pdbInfo/fetchESPData",
  async ({
    pdbID,
    jobDir,
    form,
  }: {
    form: any;
    jobDir: string;
    pdbID: string;
  }) => {
    try{
    const response = await getESPData({ jobDir, pdbID, form });
    return response.data;}
    catch(error)
    {
      return {error};
    }
  }
);

export const pdbInfoSlice = createSlice({
  name: "pdbInfo",

  initialState,
  reducers: {
    setPdbInfo(state, action) {
      //console.log(action);
      state.pdbData = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Ramachandran Process
    builder
      .addCase(fetchRamachandran.fulfilled, (state, action) => {
        state.ramachandran.data = action.payload;
        state.ramachandran.loading = false;
      })
      .addCase(fetchRamachandran.rejected, (state, action) => {
        state.ramachandran.loading = false;
      })
      .addCase(fetchRamachandran.pending, (state) => {
        state.ramachandran.loading = true;
      })
      // Errat
      .addCase(fetchErrat.fulfilled, (state, action) => {
        state.errat.data = action.payload;
        state.errat.loading = false;
      })
      .addCase(fetchErrat.rejected, (state, action) => {
        state.errat.loading = false;
      })
      .addCase(fetchErrat.pending, (state) => {
        state.errat.loading = true;
      })
      // SS
      .addCase(fetchSSData.fulfilled, (state, action) => {
        state.ss.data = action.payload;
        state.ss.loading = false;
      })
      .addCase(fetchSSData.rejected, (state, action) => {
        state.ss.loading = false;
      })
      .addCase(fetchSSData.pending, (state) => {
        state.ss.loading = true;
      })

      // Hole Analysis (Tunnel Analysis)
      .addCase(fetchHoleData.fulfilled, (state, action) => {
        state.hole.data = action.payload;
        state.hole.loading = false;
      })
      .addCase(fetchHoleData.rejected, (state, action) => {
        state.hole.loading = false;
      })
      .addCase(fetchHoleData.pending, (state) => {
        state.hole.loading = true;
      })

      //ESP Miscellaneous

      .addCase(fetchESPData.fulfilled, (state, action) => {
        state.esp.data = action.payload;
        state.esp.loading = false;
      })
      .addCase(fetchESPData.rejected, (state, action) => {
        state.esp.loading = false;
      })
      .addCase(fetchESPData.pending, (state) => {
        state.esp.loading = true;
      });
  },
});

export const { setPdbInfo } = pdbInfoSlice.actions;
export const selectPdbInfo = (state: RootState) => state.pdbInfo;
export default pdbInfoSlice.reducer;

import React, { useEffect } from "react";
import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { SERVER_URL } from "../../config";
import FormData from "form-data";
import "../../css/errat.css";
import { ErratProps, TabPanelProps } from "../../models";
import { fetchErrat, selectPdbInfo } from "../../views/PDBInfo/pdbInfoSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CircularProgress, Alert, Snackbar } from "@mui/material";

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function Errat({ jobDir, pdbId }: ErratProps) {
  const [tabValue, setTabValue] = React.useState(0);
  const [baseURL, setBaseURL] = React.useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);

  const pdbInfo = useAppSelector(selectPdbInfo);
  const dispatch = useAppDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    //console.log('errat pdbID', pdbId)
    const form = new FormData();
    form.append("pdb_file", "");

    if (!pdbId || !jobDir) return;

    if (pdbInfo?.errat?.data) return;

    dispatch(fetchErrat({ pdbID: pdbId, jobDir, form }))
      .then((res) => {
        if (res.payload.error) {
          setShowError(true);
          setErrorMsg("Error loading Errat. Please try again later");
        }
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg("Error loading Errat. Please try again later");
      });
  }, [pdbId, jobDir]);

  useEffect(() => {
    if (pdbInfo?.errat?.data?.job_static_dir)
      setBaseURL(
        SERVER_URL + `/static/${pdbInfo?.errat?.data?.job_static_dir}`
      );
  }, [pdbInfo?.errat?.data?.job_static_dir]);

  return pdbInfo?.errat?.loading ? (
    <CircularProgress></CircularProgress>
  ) : (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabValue} onChange={handleChange}>
            {pdbInfo?.errat?.data?.images.map((value, index) => {
              const chain = `Chain ${value[0]}`;
              return <Tab label={chain} key={index} />;
            })}
          </Tabs>
        </Box>
        <>
          {pdbInfo?.errat?.data?.images.map((v, i) => {
            return (
              <TabPanel key={i} value={tabValue} index={i} url={""}>
                <Box
                  component={"img"}
                  sx={{ width: "100%" }}
                  src={baseURL + "/" + v[1]}
                  alt="value"
                ></Box>
              </TabPanel>
            );
          })}
        </>
        <Box>
          <p>
            <a href={baseURL + "/errat.pdf"} target="_blank" rel="noreferrer">
              Errat Report [PDF]
            </a>
          </p>
        </Box>
      </Box>
    </>
  );
}

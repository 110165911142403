import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { type RootState } from "../../app/store";
import { JOB_SERVER_URL } from "../../config";
import http from "../../net/http-common";
import { setTaskStatus } from "../../components/layout/SnakebarSlice";
import { getSingleSmileData } from "./leaderApis";

interface stateType {
  properties: {
    data: any | null;
    loading: boolean;
    isSingleInput: boolean;
  };
}

const initialState: stateType = {
  properties: {
    data: null,
    loading: false,
    isSingleInput: false,
  },
};

export const fetchLeaderReinventProperties = createAsyncThunk(
  "leaderReinventProperties/fetchLeaderReinventProperties",
  async (task_id: string) => {
    return http
      .get(
        `${JOB_SERVER_URL}/reinvent/results?task_id=${task_id}&as_file=false`
      )
      .then((response) => response.data)
      .catch((error) => {
        // console.log(error);
      });
  }
);

export const fetchSingleSmileData = createAsyncThunk(
  "leaderReinventProperties/fetchSingleSmileData",
  async (
    { smileName }: { smileName: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(
        setTaskStatus({
          status: "pending",
          varient: "info",
          msg: "Leader models running..",
          url: "",
          autoHideTime: 5000,
        })
      );
      const response = await getSingleSmileData({ smileName });

      dispatch(
        setTaskStatus({
          status: "success",
          varient: "success",
          msg: "Leader request finished successfully.",
          url: "/leader/leader/visualize",
          autoHideTime: 30000,
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        setTaskStatus({
          status: "error",
          varient: "error",
          msg: `${error?.response?.data?.detail || "Your request failed"}`,
          url: "",
          autoHideTime: 8000,
        })
      );
      return rejectWithValue(
        error.response?.data?.detail || "Failed to fetch data."
      );
    }
  }
);

const leaderReinventPropertiesSlice = createSlice({
  name: "leaderReinventProperties",
  initialState,
  reducers: {
    setProperties(state, action) {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchLeaderReinventProperties.fulfilled,
        (state: stateType, action: any) => {
          state.properties.data = action.payload.results;
          state.properties.loading = false;
          state.properties.isSingleInput = false;
        }
      )
      .addCase(fetchLeaderReinventProperties.rejected, (state, action: any) => {
        state.properties.loading = false;
      })
      .addCase(fetchLeaderReinventProperties.pending, (state) => {
        state.properties.loading = true;
      })
      .addCase(
        fetchSingleSmileData.fulfilled,
        (state: stateType, action: any) => {
          state.properties.data = action.payload;
          state.properties.loading = false;
          state.properties.isSingleInput = true;
        }
      )
      .addCase(fetchSingleSmileData.rejected, (state, action: any) => {
        state.properties.loading = false;
      })
      .addCase(fetchSingleSmileData.pending, (state) => {
        state.properties.loading = true;
      });
  },
});

export const { setProperties } = leaderReinventPropertiesSlice.actions;
export const leaderReinventProperties = (state: RootState) =>
  state.leaderReinventProperty;
export default leaderReinventPropertiesSlice.reducer;

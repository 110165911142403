import React, { useRef, useState, useEffect } from "react";
import {
  DataGridPro,
  getGridNumericOperators,
  GridColDef,
  GridFilterItem,
  GridFilterModel,
  GridFilterOperator,
  GridRowParams,
  GridToolbar,
  GridLogicOperator,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { TabPanel } from "../../components/common/TabPanel";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import type { Theme } from "@mui/material/styles";
import http from "../../net/http-common";
import Viewer from "../../components/common/MolViewer";
import PropertyTable from "../../components/Properties/PropertyTable";
import InputNumberInterval from "../../components/Properties/InputNumberInterval";
import { GridFilterPanelProps } from "@mui/x-data-grid/components/panel/filterPanel/GridFilterPanel";
import theme from "../../theme/theme";
import { PropertiesInput } from "../../components";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectProperties } from "./propertiesSlice";

function Properties() {
  const [smiles, setSmiles] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [ro8TableVisible, setRo8TableVisible] = useState(false);
  const [painsTableVisible, setPainsTableVisible] = useState(false);
  const [smartsTbaleVisible, setSmartsTbaleVisible] = useState(false);
  const [smilesFile, setSmilesFile] = useState("");
  const [currentRow, setCurrentRow] = useState<GridRowParams>();
  const [rowSelected, setRowSelected] = useState(false);
  const [rows, setRows] = useState<[]>();
  const [painsMatchedRows, setPainsMatchedRows] = useState<[]>();
  const [painsUnMatchedRows, setPainsUnMatchedRows] = useState<[]>();
  const [smartsMatchedRows, setSmartsMatchedRows] = useState<[]>();
  const [smartsUnMatchedRows, setSmartsUnMatchedRows] = useState<[]>();
  const [tabValue, setTabValue] = useState("1");

  const { properties } = useAppSelector(selectProperties);
  const dispatch = useAppDispatch();

  const R08FilterPanel: GridFilterPanelProps = {
    // Force usage of "And" operator
    logicOperators: [GridLogicOperator.And, GridLogicOperator.Or],
    // Display columns by ascending alphabetical order

    filterFormProps: {
      // Customize inputs by passing props
      logicOperatorInputProps: {
        variant: "outlined",
        size: "small",
      },
      columnInputProps: {
        variant: "outlined",
        size: "small",
        sx: { mt: "auto" },
      },
      operatorInputProps: {
        variant: "outlined",
        size: "small",
        sx: { mt: "auto" },
      },
      valueInputProps: {
        InputComponentProps: {
          variant: "outlined",
          size: "small",
        },
      },
      deleteIconProps: {
        sx: {
          "& .MuiSvgIcon-root": { color: "#d32f2f" },
        },
      },
    },
    sx: {
      // Customize inputs using css selectors
      "& .MuiDataGrid-filterForm": { p: 2 },
      "& .MuiDataGrid-filterForm::nth-of-type(even)": {
        backgroundColor: (theme: Theme) =>
          theme.palette.mode === "dark" ? "#444" : "#f5f5f5",
      },
      "& .MuiDataGrid-filterFormLogicOperatorInput": { mr: 2 },
      "& .MuiDataGrid-filterFormColumnInput": { mr: 2, width: 150 },
      "& .MuiDataGrid-filterFormOperatorInput": { mr: 2 },
      "& .MuiDataGrid-filterFormValueInput": { width: 200 },
    },
  };

  const Ro8FilterModel: GridFilterModel = {
    items: [
      //n_Ar >=1 & n_Ar <=3, rotBond <=10, TPSA <=140, QED >=0.5]
      {
        id: 1,
        field: "MolWt",
        operator: "<=",
        value: "400",
      },
      {
        id: 2,
        field: "HBondAcceptors",
        operator: "<=",
        value: "10",
      },
      {
        id: 3,
        field: "HBondDonors",
        operator: "<=",
        value: "5",
      },
      {
        id: 4,
        field: "MolLogP",
        operator: "<=",
        value: "3.5",
      },
      {
        id: 5,
        field: "NumRotatableBonds",
        operator: "<=",
        value: "10",
      },
      {
        id: 6,
        field: "TPSA",
        operator: "<=",
        value: "140",
      },
      {
        id: 7,
        field: "QED",
        operator: ">=",
        value: "0.5",
      },
    ],
  };

  const BetweenOperator: GridFilterOperator[] = [
    {
      label: "Between",
      value: "between",
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }
        if (filterItem.value[0] == null || filterItem.value[1] == null) {
          return null;
        }

        return ({ value }) => {
          return (
            value !== null &&
            filterItem.value[0] <= value &&
            value <= filterItem.value[1]
          );
        };
      },
      InputComponent: InputNumberInterval,
    },
    ...getGridNumericOperators(),
  ];

  let viewer = useRef<Viewer>();

  let table = useGridApiRef();

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "Smiles",
      headerName: "SMILES",

      editable: false,
    },

    {
      field: "svg",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,

      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{ height: "200px", width: "100%" }}
        />
      ),
    },

    {
      field: "MolWt",
      headerName: "MolWt",
      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "MolLogP",
      headerName: "MolLogP",
      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "NumRotatableBonds",
      headerName: "NumRotatableBonds",
      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "HBondDonors",
      headerName: "HBondDonors",
      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "HBondAcceptors",
      headerName: "HBondAcceptors",
      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "TPSA",
      headerName: "TPSA",

      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "HeavyAtoms",
      headerName: "HeavyAtoms",
      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "NumAromaticRings",
      headerName: "NumAromaticRings",
      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "SASA",
      headerName: "SASA",

      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "QED",
      headerName: "QED",
      type: "number",
      filterOperators: BetweenOperator,
    },
  ];

  const matchedColumns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "SMILES",
      headerName: "SMILES",

      editable: false,
    },
    {
      field: "substructure_name",
      headerName: "Substructure Name",

      editable: false,
    },
  ];

  const initViewer = async () => {
    viewer.current = new Viewer();
    await viewer.current.init("molviewer", {
      layoutShowControls: false,
      viewportShowExpand: false,
      collapseLeftPanel: true,
      layoutShowSequence: false,
    });
  };

  const updateViewer = async (
    data: string,
    format:
      | "mmcif"
      | "cifCore"
      | "pdb"
      | "pdbqt"
      | "gro"
      | "xyz"
      | "mol"
      | "sdf"
      | "mol2"
  ) => {
    initViewer().then(() => {
      const _ref = viewer?.current?.loadStructureFromData(data, format, {
        dataLabel: "",
      });
    });
  };

  const handleRowClick = (
    params: GridRowParams, // GridRowParams
    event: any
  ) => {
    if (!params) return;
    updateViewer(params.row.mol2, "mol2");
    setCurrentRow(params.row);
    setRowSelected(true);
  };

  useEffect(() => {
    setRo8TableVisible(true);
  }, []);

  const applyFilters = async (e: any) => {
    table.current.setFilterModel(Ro8FilterModel);
  };

  const extractRows = (data: any) => {
    setSmartsMatchedRows(data.matched_smarts);
    setPainsMatchedRows(data.matched_pains);
    setSmartsUnMatchedRows(data.unmatched_smarts);

    setPainsUnMatchedRows(data.unmatched_pains);
  };

  useEffect(() => {
    if (!properties.data) {
      return;
    }
    applyPainsFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties.data]);

  const applyPainsFilters = async () => {
    const smilesFromRows: string[] = rows.map((r) => r["Smiles"]);
    //console.log(smilesFromRows);
    await http
      .post("/properties/filter-pains", smilesFromRows, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      })
      .then((response: any) => {
        //console.log(response);
        // process and populate pains rows
        extractRows(response.data);
        setPainsTableVisible(true);
        setSmartsTbaleVisible(true);
      });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    //console.log("tab change", newValue);
    setTabValue(newValue);
  };

  return (
    <>
      <Grid container px={6} py={3} spacing={3}>
        <Grid item container xs={6} direction={"column"}>
          <Grid item mb={3} width={"100%"}>
            {/* Smiles Input */}
            <PropertiesInput inFilterPage={true} />
          </Grid>

          <Grid item width={"100%"}>
            <Card sx={{ position: "relative" }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="filter tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Properties" value="1" />
                    <Tab label="Pains Filter" value="2" />
                    <Tab label="Smarts Filter" value="3" />
                  </TabList>
                </Box>
                {/* Properties */}
                <TabPanel value="1">
                  {ro8TableVisible && (
                    <Box sx={{ width: "100%" }}>
                      <Button size="small" onClick={applyFilters}>
                        Apply Ro8 Filters
                      </Button>

                      <DataGridPro
                        apiRef={table}
                        rows={properties.data}
                        columns={columns}
                        rowHeight={150}
                        onRowClick={handleRowClick}
                        disableMultipleColumnsFiltering={false}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        slots={{ toolbar: GridToolbar }}
                      />
                    </Box>
                  )}
                </TabPanel>
                {/* Pains Filter */}
                <TabPanel value="2">
                  {painsTableVisible && (
                    <Box>
                      <DataGridPro
                        rows={painsUnMatchedRows}
                        columns={matchedColumns}
                        rowHeight={150}
                        onRowClick={handleRowClick}
                        disableMultipleColumnsFiltering={false}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        slots={{ toolbar: GridToolbar }}
                      />
                    </Box>
                  )}
                </TabPanel>
                {/* Smarts Filter */}
                <TabPanel value="3">
                  {smartsTbaleVisible && (
                    <Box>
                      <DataGridPro
                        rows={smartsUnMatchedRows}
                        columns={matchedColumns}
                        rowHeight={150}
                        onRowClick={handleRowClick}
                        disableMultipleColumnsFiltering={false}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        slots={{ toolbar: GridToolbar }}
                      />
                    </Box>
                  )}
                </TabPanel>
              </TabContext>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Card>
            {ro8TableVisible && (
              <Grid>
                <Grid container direction="column" spacing={0}>
                  <Grid item xs={12} md={12} sx={{ m: 0 }}>
                    <div
                      id="molviewer"
                      style={{
                        height: "500px",
                        width: "100%",
                        position: "relative",
                      }}
                    ></div>
                  </Grid>
                  <Grid item xs={12} md={12} sx={{ m: 0 }}>
                    {rowSelected && (
                      <PropertyTable data={currentRow}></PropertyTable>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Properties;

import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    DataGridPro,
    GridRenderCellParams,
    GridColDef,
    useGridApiRef,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
} from "@mui/x-data-grid-pro";
import {
    Box,
    Button,
    Card,
    Grid,
    Tab,
    ToggleButtonGroup,
    Typography,
    CardMedia,
    Tooltip,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { TabPanel } from "../../components/common/TabPanel";
import { tableStateType } from "../AdmeTox/ADMEPropertyFilter";
import WindowIcon from "@mui/icons-material/Window";
import ScreenieGridView from "../../components/Properties/ScreenieGridView";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import Histogram from "../../components/bioisosteric/propertiesHistogram";
import MolInfoDialog from "../../components/bioisosteric/MoleculeInfoDialog";

export const CustomToolbar = ({
    props,
    isExport = true,
}: {
    props: any;
    isExport?: boolean;
}) => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            {isExport && (
                <GridToolbarExport
                    printOptions={{
                        disableToolbarButton: true,
                    }}
                />
            )}
        </GridToolbarContainer>
    );
};

interface MoleculeInfo {
    svg: string;
    smiles: string;
}

const BioisoVisualize = () => {
    const [tabValue, setTabValue] = useState("1");
    const [alignment, setAlignment] = useState("grid");
    const scrollToRef = useRef(null);
    const [rows, setRows] = useState<any[]>([]);
    const [replacementRows, setReplacementRows] = useState<any[]>([]);
    const [moleculeFilter, setMoleculeFilter] = useState<any>(null);
    const [densityAndRows, setDensityAndRows] = useState<tableStateType>({
        density: "standard",
        pageSize: 5,
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [moleculeInfo, setMoleculeInfo] = useState<MoleculeInfo>({
        svg : "",
        smiles : ""
    });

    const navigate = useNavigate();

    const handleScroll = () => {
        if (scrollToRef.current) {
            scrollToRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleTableStateChange = (
        newState: any,
        setFilterState: React.Dispatch<any>
    ) => {
        setFilterState(newState.filter.filterModel);
    };


    const handleInsertedGroups = (
        params: GridRenderCellParams<any, any[]>
    ) => {
        return (
            <Box display={"flex"} flexWrap={"wrap"} >
                {params.value.map((pa, index) => (
                    <Tooltip title={`${params.row.inserting_groups[index]}`} key={index}>
                        <Card
                            style={{
                                margin: "8px",
                                width: "200px",
                                overflow: "hidden",
                                boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                                transition: "transform 0.3s ease-in-out",
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.transform = "scale(1.05)";
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.transform = "scale(1)";
                            }}
                        >
                            <CardMedia
                                component="img"
                                image={`data:image/svg+xml;base64,${btoa(pa)}`}
                                alt="2D svg representation"
                                style={{
                                    height: "auto",
                                    maxHeight: "200px",
                                    width: "100%",
                                    objectFit: "contain",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setDialogOpen(true);
                                    setMoleculeInfo({
                                        smiles: params.row.inserting_groups[index],
                                        svg: pa
                                    });
                                }}
                            />
                        </Card>
                    </Tooltip>
                ))}
            </Box>
        );
    };
    const bioisoProperties = useSelector(
        (state: any) => state.bioiso.properties
    );

    const plotNameMapping: any = {
        "qedStats": "QED PLOT",
        "saStats": "SA PLOT",
        "logPStats": "LogP Plot",
        "MwtStats": "Molecular Weight Plot"
    }
    let table = useGridApiRef();

    const renderTrueFalse = (params: GridRenderCellParams<any, any>) => (
        <Box sx={{ textAlign: 'center' }}>
            <Typography
                variant="body2"
                sx={{ color: params.value === "PASS" ? 'green' : 'red' }}
            >
                {params.value}
            </Typography>
        </Box>
    );

    const cols: GridColDef[] = [
        {
            field: "id",
            headerName: "ID",
            type: "number",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "INPUT-MOL-SMI",
            headerName: "INPUT-MOL-SMILES",
            editable: false,
            headerAlign: "center",
            width: 200,
        },
        {
            field: "image",
            headerName: "2D Representation",
            description: "This column has images and is not sortable.",
            sortable: false,
            filterable: false,
            disableExport: true,
            width: 140,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => (
                <img
                    src={`data:image/svg+xml;base64,${btoa(params.value)}`}
                    alt="2D svg representation"
                    style={{
                        height: "200px",
                        width: "80%",
                        transition: "transform 0.3s ease-in-out",
                    }}
                    onMouseOver={(e) => {
                        e.currentTarget.style.transform = "scale(150%)";
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.transform = "scale(100%)";
                    }}
                />
            ),
        },
        {
            field: "GEN-MOL-SMI",
            headerName: "GEN-MOL-SMILES",
            editable: false,
            headerAlign: "center",
            width: 200,
        },
        {
            field: "LEAVING-FRAG-SMI",
            headerName: "LEAVING-FRAG-SMILES",
            editable: false,
            headerAlign: "center",
            align: "center",
            width: 200,
        },
        {
            field: "INSERTING-FRAG-SMI",
            headerName: "INSERTING-FRAG-SMILES",
            editable: false,
            headerAlign: "center",
            width: 200,
            align: "center",
        },
        {
            field: "LOGP",
            headerName: "LOGP",
            width: 100,
            type: "number",
            align: "center",
            headerAlign: "center",
        },
        {
            field: "MW",
            headerName: "MW",
            width: 100,
            type: "number",
            align: "center",
            headerAlign: "center",
        },
        {
            field: "QED",
            headerName: "QED",
            width: 100,
            type: "number",
            align: "center",
            headerAlign: "center",
        },
        {
            field: "SA",
            headerName: "SA",
            width: 100,
            type: "number",
            align: "center",
            headerAlign: "center",
        },
        {
            field: "PAINS",
            headerName: "PAINS",
            width: 100,
            type: "string",
            align: "center",
            headerAlign: "center",
            renderCell: renderTrueFalse,
        },
        {
            field: "Unwanted Substructures",
            headerName: "Unwanted Substructures",
            width: 200,
            type: "string",
            align: "center",
            headerAlign: "center",
            renderCell: renderTrueFalse,
        },
        {
            field: "MAI Rings",
            headerName: "MAI Rings",
            width: 150,
            type: "string",
            align: "center",
            headerAlign: "center",
            renderCell: renderTrueFalse,
        },

    ];

    const replacementCols: GridColDef[] = [
        {
            field: "id",
            headerName: "ID",
            type: "number",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "leaving_group_image",
            headerName: "Removed Group",
            description: "This column has images and is not sortable.",
            sortable: false,
            filterable: false,
            disableExport: true,
            width: 240,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <Tooltip title={`${params.row.leaving_group}`} arrow>
                        <Card
                            sx={{
                                maxWidth: "350px", 
                                margin: "8px",
                                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                                transition: "transform 0.3s ease-in-out",
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                        >
                            <CardMedia
                                component="img"
                                src={`data:image/svg+xml;base64,${btoa(params.value)}`}
                                alt="2D svg representation"
                                sx={{
                                    height: "200px",
                                    objectFit: "contain", 
                                    cursor : "pointer"
                                }}
                                onClick={()=> {
                                    setDialogOpen(true);
                                    setMoleculeInfo({
                                        smiles : params.row.leaving_group,
                                        svg : params.value
                                    });
                                }}
                            />
                        </Card>
                    </Tooltip>
                )
            },
        },
        {
            field: "inserting_group_images",
            headerName: "Inserted Groups",
            description: "This column has images and is not sortable.",
            sortable: false,
            filterable: false,
            disableExport: true,
            width: 1140,
            headerAlign: "center",
            align: "center",
            renderCell: handleInsertedGroups,
        },
    ];


    const fieldsToRound = [
        "LOGP",
        "MW",
        "QED",
        "SA",
    ];

    useEffect(() => {
        if (Array.isArray(bioisoProperties?.generated_data)) {
            const modifiedRows = bioisoProperties.generated_data.map((row: any, index: number) => {
                let newRow = { ...row, id: index + 1 };
                fieldsToRound.forEach((field) => {
                    if (newRow[field]) {
                        newRow[field] = newRow[field].toFixed(3);
                    }
                });
                return newRow;
            });
            setRows(modifiedRows);
            setReplacementRows(bioisoProperties.replacement_data);
        } else if (bioisoProperties.generated_data == null && !bioisoProperties.loading) {
            navigate("/leader/bioisosteric");
        }
    }, [bioisoProperties]);

    return (
        <>
            <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                aria-label="Platform"
                sx={{
                    margin: "-1rem 1rem 0rem 3rem",
                    justifyContent: "right",
                    display: tabValue === "1" ? "flex" : "none"
                }}
            >
                <Button
                    sx={{
                        fontSize: 12,
                        paddingTop: 1,
                        paddingBottom: 1,
                        mx: 1,
                        backgroundColor: "#582FF1;",
                        fontWeight: "bold",
                    }}
                    onClick={handleScroll}
                    variant="contained"
                    startIcon={<WindowIcon sx={{ fontSize: 12 }} />}
                    size="large"
                >
                    Grid View
                </Button>
            </ToggleButtonGroup>
            <Grid container spacing={2} p={2}>
                <Grid item container px={1} pt={3} height={"100%"}>
                    <Grid item width={"100%"} sx={{ overflowY: "auto" }} height={"100%"}>
                        <Card sx={{ position: "relative" }}>
                            <TabContext value={tabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList
                                        value={tabValue}
                                        onChange={(event: React.SyntheticEvent, newValue: string) => {
                                            setTabValue(newValue);
                                        }}
                                        aria-label="filter tabs"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Tab label="Molecules" value="1" />
                                        <Tab label="Stats" value="2" />
                                        <Tab label="Replacement" value="3" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Box sx={{ width: "100%", height: "700px" }}>
                                        <DataGridPro
                                            apiRef={table}
                                            sx={{
                                                "& .MuiDataGrid-columnHeaderTitle": {
                                                    whiteSpace: "normal",
                                                    lineHeight: "normal",
                                                    fontWeight: "550",
                                                    fontSize: "0.9rem",
                                                },
                                                "& .MuiDataGrid-columnHeader": {
                                                    // Forced to use important since overriding inline styles
                                                    height: "unset !important",
                                                },
                                                "& .MuiDataGrid-columnHeaders": {
                                                    // Forced to use important since overriding inline styles
                                                    maxHeight: "175px !important",
                                                    textAlign: "center",
                                                    fontWeight: "800 !important",
                                                }
                                            }}
                                            rows={rows}
                                            columns={cols}
                                            disableRowSelectionOnClick
                                            onStateChange={(newState) =>
                                                handleTableStateChange(newState, setMoleculeFilter)
                                            }
                                            rowHeight={100}
                                            pagination
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 5
                                                    },
                                                },
                                            }}
                                            pageSizeOptions={[5, 10, 20]}
                                            slots={{
                                                toolbar: (props) => <CustomToolbar {...props} />,
                                            }}
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Box sx={{ width: "100%", height: "100%", p: 4 }}>
                                        <Typography variant="h3" align="center" sx={{ mb: 4 }}>
                                            Molecular Properties Analysis
                                        </Typography>

                                        <Grid container >
                                            {bioisoProperties.properties_data.map((it: any, idx: number) => (
                                                <Grid item xs={6} key={idx}>
                                                    <Typography variant="h5" marginLeft={9} marginBottom={0} >
                                                        {plotNameMapping[it.property]}
                                                        <Typography variant="body1" color="textSecondary" align="left" sx={{ mb: 2 }}>
                                                            Reference Value: {it.reference}
                                                        </Typography>
                                                    </Typography>
                                                    <Box
                                                        component="img"
                                                        src={`data:image/svg+xml;base64,${btoa(it.plot)}`}
                                                        sx={{ maxWidth: "100%", height: "auto", mx: "auto" }}
                                                        alt="Molecular Property Plot"
                                                    />
                                                </Grid>
                                            )
                                            )}
                                        </Grid>
                                    </Box>
                                </TabPanel>
                                <TabPanel value="3">
                                    <Box sx={{
                                        width: "100%",
                                        maxHeigth: "900",
                                        height: "60%",
                                        p: 4
                                    }}>
                                        <DataGridPro
                                            apiRef={table}
                                            sx={{
                                                "& .MuiDataGrid-columnHeaderTitle": {
                                                    whiteSpace: "normal",
                                                    lineHeight: "normal",
                                                    fontWeight: "550",
                                                    fontSize: "0.9rem",
                                                },
                                                "& .MuiDataGrid-columnHeader": {
                                                    // Forced to use important since overriding inline styles
                                                    height: "unset !important",
                                                },
                                                "& .MuiDataGrid-columnHeaders": {
                                                    // Forced to use important since overriding inline styles
                                                    maxHeight: "175px !important",
                                                    textAlign: "center",
                                                    fontWeight: "800 !important",
                                                },
                                            }}
                                            rows={replacementRows}
                                            columns={replacementCols}
                                            disableRowSelectionOnClick
                                            onStateChange={(newState) =>
                                                handleTableStateChange(newState, setMoleculeFilter)
                                            }
                                            pagination
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 5
                                                    },
                                                },
                                            }}
                                            getRowHeight={() => 'auto'}
                                            pageSizeOptions={[5, 10, 20]}
                                            slots={{
                                                toolbar: (props) => <CustomToolbar {...props} />,
                                            }}
                                        />
                                </Box>
                                </TabPanel>
                            </TabContext>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>

             <Box ref={scrollToRef} pb={2} sx={{ display: tabValue !== "1" ? "none" : "block"}}>
                <ScreenieGridView
                    isTopLevelEnable={false}
                    tabValue="1"
                    allRows={rows}
                    filters={[moleculeFilter]}
                    model="bioiso"
                />
            </Box>
            <MolInfoDialog
                open={dialogOpen}
                handleClose={() => setDialogOpen(false)}
                moleculeInfo={moleculeInfo}
            />
            <IndefiniteLoader state={bioisoProperties.loading} />
        </>
    );
};

export default BioisoVisualize;
import React from "react";
import { SOLAR_DEV } from "../../config";

function SequentialFiltering() {
  return (
    <div className="chemlet filter">
      <iframe
        title="Sequetial Filtering"
        width="100%"
        height="800"
        src={`${SOLAR_DEV}/sequential_ligand_filter/`}
      ></iframe>
    </div>
  );
}

export default SequentialFiltering;

import { StandaloneStructServiceProvider } from "ketcher-standalone";
import { Editor } from "ketcher-react";
import { Ketcher } from "ketcher-core";
import "ketcher-react/dist/index.css";
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import MolEditorResultV2 from "../../components/MolEditor/MolEditorResultV2";
import { FileUploader } from "../../components";
import http from "../../net/http-common";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { SOLAR_DEV } from "../../config";

const structServiceProvider = new StandaloneStructServiceProvider();
function MolEditor() {
  const [smileValue, setSmileValue] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const [fetchingFields, setfetchingFields] = useState(false);
  const [chains, setChains] = useState<string[]>([]);
  const [residues, setResidues] = useState<string[]>([]);
  const [residue, setResidue] = useState("");
  const [protChain, setProtChain] = useState("");
  const [PDBFile, setPDBFile] = useState<File>(null);
  const [advance, setAdvance] = useState<boolean>(false);
  const [alignment, setAlignment] = useState<string>("editor");

  const [molBenchfiles, setMolBenchFiles] = useState<File[] | []>([]);

  // const [pdbFileContent, setPdbFileContent] = useState<any>("");
 

  const [molEditorResult, setMolEditorResult] = useState<any[]>([]);

  const [loader, setLoader] = useState<boolean>(false);

  // function to get smile value on every change in mol editor
  const handleStructureChange = async (ketcher: Ketcher) => {
    const smiles = await ketcher.getSmiles();
    setSmileValue(smiles);
  };

  const handleFileUpload = async (file: File, name: string) => {
    switch (name) {
      case "pdb":
        setPDBFile(file);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!PDBFile) return;

    const form = new FormData();
    form.append("uploaded_protein_file", PDBFile);

    setfetchingFields(true);

    http
      .post(
        `https://app.moleculeai.com/api/pdb/chains`,
        form,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        }
      )
      .then((response: any) => {
        setfetchingFields(false);
        //  console.log(response);
        setChains(response.data[0]);
        setResidues(response.data[1]);
        setProtChain(response.data[0][0]);
        setResidue(response.data[1][0]);
      })
      .catch((error) => {
        setfetchingFields(false);
      });
  }, [PDBFile]);

  const handleDownloadSample = (fileType: string) => {
    const link = document.createElement("a");

    if (fileType === "protein") {
      link.download = "autodock_protein_sample.pdb";
      link.href = "/samples/autodock/2p16.pdb";
    } else if (fileType === "ligand") {
      link.download = "autodock_ligand_sample.csv";
      link.href = "/samples/autodock/docking.csv";
    }

    link.click();
  };

  // get result : currently getting result of screenie
  const editorResultHandler = async () => {
    if (!smileValue || !PDBFile || !residue || !protChain) {
      alert("Please add all the inputs");
      return;
    }

    setLoader(true);

    try {
      const request1 = http.post(
        `/properties/properties/v3`,
        { smiles: smileValue },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        }
      );

      const form = new FormData();

      form.append("pdb_file", PDBFile);
      form.append("ligand_smiles", smileValue);

      form.append("residue_name", residue);
      form.append("protein_chain", protChain);

      const request2 = http.post(
        `https://app.moleculeai.com/autodock_api/run_docking/`,
        form,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        }
      );

      const [response1, response2] = await Promise.all([request1, request2]);

      // Preparing Screenie data
      const setADMEAndToxValues = (DATA: any) => {
        DATA.id = molEditorResult.length+1;
        setMolEditorResult((prevResult) => [...prevResult, DATA]);
      };

      response1.data["physico_chemical_properties"].docking_score =
        response2.data.docking_score;
      response1.data["physico_chemical_properties"].SDF = response2.data.SDF;
      response1.data["physico_chemical_properties"].PDB = PDBFile;
      response1.data["physico_chemical_properties"].pdbFileContent =
        response2.data.Prepared_protein_content;

      setADMEAndToxValues(response1.data["physico_chemical_properties"]);
      setLoader(false);
    } catch (error) {
      // console.log("error is ", error);

      setErrorMsg("Error While fetching the result!");
      setShowError(true);
      setLoader(false);
    }
  };

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment) {
      if (newAlignment === "advance") {
        setAdvance(true);
      } else {
        setAdvance(false);
      }
      setAlignment(newAlignment);
      
    }
  };

  useEffect(() => {
    // Cleanup on component unmount
    return () => {
      setSmileValue("");
    };
  }, []);


  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Box py={2} mb={2}>
        <Box textAlign={"right"} px={3} mb={2}>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleToggle}
            aria-label="Platform"
            size="medium"
          >
            <ToggleButton
              value="editor"
              sx={{
                fontWeight: "bold",
                border: alignment === "editor" ? "1px solid #582FF1" : "",
                borderLeft:
                  alignment === "editor" ? "1px solid #582FF1 !important" : "",
              }}
            >
              Editor
            </ToggleButton>
            <ToggleButton
              value="advance"
              sx={{
                fontWeight: "bold",
                border: alignment === "advance" ? "1px solid #582FF1" : "",
                borderLeft:
                  alignment === "advance" ? "1px solid #582FF1 !important" : "",
              }}
            >
              Molecule Design
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Grid container px={3} spacing={1}>
          <Grid
            item
            sm={12}
            md={12}
            lg={advance ? 6 : 12}
            sx={{ width: "100%", transition: "all 0.5s ease-in-out" }}
          >
            <Box
              height={!advance ? "760px" : PDBFile ? "885px" : "825px"}
              sx={{ transition: "height 0.5s ease-in-out" }}
            >
              <Editor
                staticResourcesUrl={""}
                structServiceProvider={structServiceProvider}
                onInit={async (ketcher: Ketcher) => {
                  (window as any).ketcher = ketcher;

                  ketcher.setMolecule("");
                  ketcher.editor.subscribe("change", () =>
                    handleStructureChange(ketcher)
                  );
                }}
                errorHandler={function (message: string): void {
                  throw new Error("Function not implemented.");
                }}
              />
            </Box>
          </Grid>
          {advance && (
            <Grid
              item
              sm={12}
              md={12}
              lg={advance ? 6 : 12}
              sx={{ width: "100%" }}
            >
              <Box position={"relative"} height={"100%"}>
                <Box sx={{ background: "#fff", p: 2 }}>
                  <Stack direction="column" spacing={1.5}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography
                        sx={{
                          color: "var(--shade-2900, #29283B)",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        Protein file (.pdb)
                      </Typography>
                      <Button
                        variant="text"
                        onClick={() => handleDownloadSample("protein")}
                      >
                        {"Download Sample"}
                      </Button>
                    </Box>
                    <FileUploader
                      accept={".pdb"}
                      handleFileUpload={(files) =>
                        handleFileUpload(files[0], "pdb")
                      }
                      deleteHandlerDisable={() => {
                        setPDBFile(null);
                        setResidues([]);
                        setChains([]);
                        setMolBenchFiles([]);
                        return false;
                      }}
                      setMolBenchFiles={setMolBenchFiles}
                      molBenchfiles={molBenchfiles}
                    />
                  </Stack>
                  <Stack direction="column" spacing={1.5}>
                    <FormControl
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item md={12} lg={6}>
                          <Typography
                            sx={{
                              mt: 1,
                              color: "var(--shade-2900, #29283B)",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                          >
                            Residue Name
                          </Typography>
                          {fetchingFields ? <LinearProgress /> : ""}
                          <Select
                            disabled={residues?.length === 0}
                            value={residue}
                            label="Residue"
                            onChange={(e) => setResidue(e.target.value)}
                            size="small"
                            fullWidth
                          >
                            {residues?.map((v, i) => {
                              return (
                                <MenuItem key={i} value={v}>
                                  {v}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>

                        <Grid item md={12} lg={6}>
                          <Typography
                            sx={{
                              mt: 1,
                              color: "var(--shade-2900, #29283B)",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                          >
                            Protein Chain
                          </Typography>
                          {fetchingFields ? <LinearProgress /> : ""}
                          <Select
                            disabled={chains?.length === 0}
                            value={protChain}
                            label="Protein Chain"
                            onChange={(e) => setProtChain(e.target.value)}
                            size="small"
                            fullWidth
                          >
                            {chains?.map((v, i) => {
                              return (
                                <MenuItem key={i} value={v}>
                                  {v}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1.5} my={1}>
                    <FormControl>
                      <TextField
                        placeholder="Draw a Molecule"
                        fullWidth
                        id="smiles-string"
                        name="smiles-string"
                        value={smileValue}
                        onChange={(e) => setSmileValue(e.target.value)}
                        size="small"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1.5} my={1}>
                    <FormControl>
                      <Button
                        variant="contained"
                        onClick={editorResultHandler}
                        sx={{
                          "&.Mui-disabled": {
                            background: "#BDADF9",
                            color: "#fff",
                          },
                        }}
                        disabled={
                          !smileValue || !PDBFile || !residue || !protChain
                        }
                      >
                        Submit
                      </Button>
                    </FormControl>
                  </Stack>
                </Box>

                <MolEditorResultV2
                  molEditorResult={molEditorResult}
                  setMolEditorResult={setMolEditorResult}
                />
                <IndefiniteLoader state={loader} onlyForDiv={true} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default MolEditor;

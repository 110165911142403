import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import React from "react";

interface Properties {
  data: any;
}

function PropertyTable(props: Properties) {
  //console.log(props.data);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {Object.keys(props.data)
            .slice(1, -2)
            .map((v, i) => {
              return (
                <>
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle2" gutterBottom>
                        {v}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {props.data[v]}
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PropertyTable;

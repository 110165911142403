import React, { useEffect, useState } from "react";
import { deepOrange } from "@mui/material/colors";
import {
  Box,
  Avatar,
  Paper,
  Stack,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Chip,
  Grid,
  Button,
  useMediaQuery,
  Skeleton,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useUserAuth } from "../../context";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import http from "../../net/http-common";
import {
  convertIndianRupeeFormat,
  convertUnixTimestampToDate,
} from "./ProfilePageHelper";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { SERVER_URL } from "../../config";

function ProfilePage() {
  const { user, userSubscription, razorpaySubInfo } = useUserAuth();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [subscriptionType, setsubscriptionType] = useState("");
  const navigate = useNavigate();
  const [isSubscription, setIsSubscription] = useState<any | null>(null);
  const [isErrorMsg, setIsErrorMsg] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [globalLoader, setGlobalLoader] = useState<boolean>(false);
  const [openDialoag, setOpenDialog] = useState<boolean>(false);
  const [isDeleteUser, setIsDeleteuser] = useState<boolean>(false);

  const handleClose = () => {
    setOpenDialog(false);
    setIsDeleteuser(false);
  };

  const getInitials = (name: string) => {
    const nameParts = name.split(" ");
    const firstNameInitial = nameParts[0].charAt(0);
    let initials = `${firstNameInitial}`;
    if (nameParts.length > 1) {
      const lastNameInitial = nameParts[nameParts.length - 1].charAt(0);
      initials += `${lastNameInitial}`;
    }

    return initials;
  };

  const cancelSubscription = async (sub_id: string) => {
    setOpenDialog(false);
    setGlobalLoader(true);
    // setIsLoading(true);

    console.log("subscription id to delte", sub_id);

    try {
      const cancelledSubscription = await http.delete(
        `${SERVER_URL}/payment/cancel-razorpay-subscription/${sub_id}`,
        {
          params: {
            uid: user.uid,
          },
        }
      );

      if (cancelledSubscription.data.status === "Success") {
        window.location.href = "/user/profile";
      }
    } catch (error) {
      setShowError(true);
      setIsErrorMsg("Failed to delete your subscription");
    }

    setGlobalLoader(false);
  };

  const deleteAccountHandler = async () => {
    console.log("delete account");

    handleClose();

    setGlobalLoader(true);
    try {
      const deleteUserRes = await http.delete(
        `http://127.0.0.1:8000/user/delete-user`,
        {
          params: {
            uid: user.uid,
          },
        }
      );

      if (deleteUserRes.data.status === "Success") {
        window.location.href = "/";
      }
    } catch (error) {
      setGlobalLoader(false);
      setShowError(true);
      setIsErrorMsg("Failed to delete Your Account");
    }
  };

  useEffect(() => {
    // getting subscribed plan info
    const getPlanInfo = async () => {
      if (userSubscription && razorpaySubInfo) {
        try {
          const userPlanInfo = await http.get(
            `${SERVER_URL}/payment/get-razorpay-plan/${userSubscription.plan_id}`
          );

          // console.log('plan',userPlanInfo.data.response);

          if (
            userPlanInfo.data.response &&
            razorpaySubInfo.status === "active"
          ) {
            setIsSubscription(userPlanInfo.data.response);
          }

          setIsLoading(false);
        } catch (error) {
          setShowError(true);
          setIsErrorMsg("Failed to Load Your Subscription");
        }
      }
    };
    getPlanInfo();
  }, [userSubscription, razorpaySubInfo, globalLoader]);

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {isErrorMsg}
        </Alert>
      </Snackbar>
      <Grid container direction="column" spacing={2} sx={{ px: 6 }}>
        <Grid item container justifyContent="center">
          {user?.displayName ? (
            <Avatar
              sx={{
                bgcolor: deepOrange[700],
                width: 150,
                height: 150,
                fontSize: 50,
              }}
            >
              {getInitials(user.displayName)}
            </Avatar>
          ) : (
            <Avatar sx={{ width: 36, height: 36 }}></Avatar>
          )}
        </Grid>
        <Grid sx={{ px: isMobile ? 2 : 12, py: 2 }}>
          <Paper>
            <Table sx={{ mb: 4 }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{
                      backgroundColor: "rgb(196, 187, 249)",
                      padding: 0,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: "rgb(81, 59, 206)" }}
                      py={1}
                    >
                      User Info
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ borderBottom: "none" }}>
                  <TableCell align="center">
                    <TextField
                      id="outlined-read-only-input"
                      label="Name"
                      sx={{
                        labl: "#582FF1",
                      }}
                      defaultValue={user?.displayName}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      id="outlined-read-only-input"
                      label="Email"
                      defaultValue={user?.email}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                    // style={{ backgroundColor: "#d1ecf1", padding: 0 }}
                    style={{
                      backgroundColor: "rgb(196, 187, 249)",
                      padding: 0,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: "rgb(81, 59, 206)" }}
                      py={1}
                    >
                      Subscription Info
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  [1, 2, 3, 4].map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography>
                          <Skeleton animation="wave" />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <>
                    {isSubscription ? (
                      <>
                        <TableRow>
                          {userSubscription.initial_status === "cancelled" && (
                            <TableCell colSpan={12} align="center">
                              <Typography color={"error"} fontWeight={"bold"}>
                                You have Cancelled Your Subscription. You can
                                access your workflows till your next bill due
                                date.
                              </Typography>
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography>Subscription Plan</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {isSubscription.item.name.split("-")[0].trim()}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography>Amount</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {" "}
                              {isSubscription.item.currency === "USD"
                                ? "$"
                                : "₹"}
                              {convertIndianRupeeFormat(
                                isSubscription.item.amount / 100
                              )}
                              /month
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography>Status</Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              color:
                                userSubscription.initial_status !== "cancelled"
                                  ? "green"
                                  : "#d32f2f",
                              fontWeight: "bold",
                            }}
                          >
                            <Typography variant="h6">
                              {razorpaySubInfo.status === "active" &&
                              userSubscription.initial_status !== "cancelled"
                                ? "Active"
                                : "Cancelled"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography>Allowed Workflows</Typography>
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            <Typography variant="h6">
                              {userSubscription.workflows.map((item, index) => (
                                <Chip
                                  sx={{
                                    m: 0.5,
                                    background:
                                      "linear-gradient(93deg, #6540EE 7.27%, #8062EE 77.03%)",
                                    color: "#fff",
                                  }}
                                  label={item}
                                />
                              ))}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography>Billing period</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {convertUnixTimestampToDate(
                                razorpaySubInfo.current_start
                              )}{" "}
                              -{" "}
                              {convertUnixTimestampToDate(
                                razorpaySubInfo.current_end
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography>Next Bill Due</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {convertUnixTimestampToDate(
                                razorpaySubInfo.current_end
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "20vh",
                          textAlign: "center",
                        }}
                      >
                        <Box sx={{ margin: "1rem" }}>
                          <Typography variant="h5">
                            You don't have any subscription yet
                          </Typography>
                        </Box>
                        <Box>
                          <Button
                            variant="contained"
                            sx={{ width: "10rem" }}
                            onClick={() => navigate("/pricing")}
                          >
                            Subscribe Now
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
            {isSubscription && (
              <Grid
                container
                justifyContent={isMobile ? "center" : "flex-end"}
                flexDirection={isMobile ? "column" : "row"}
                sx={{ p: 2 }}
              >
                {/* Two Buttons Side by Side */}
                <Grid
                  container
                  justifyContent="flex-end"
                  columnGap={"10px"}
                  rowGap={"10px"}
                >
                  {userSubscription.initial_status !== "cancelled" && (
                    <Button
                      color="error"
                      variant="outlined"
                      //  sx={isMobile ? { mb: 3 } : { mr: 2 }}

                      onClick={() => setOpenDialog(true)}
                    >
                      Cancel Subscription
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => navigate("/pricing")}
                  >
                    Update Subscription
                  </Button>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* <Box textAlign={"center"} mt={4} mb={2}>
        <Button
          onClick={() => {
            setIsDeleteuser(true);
            setOpenDialog(true);
          }}
          size="large"
          color="error"
        >
          Delete Account
        </Button>
      </Box> */}
      <Dialog
        open={openDialoag}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              p: 5,
            }}
          >
            {isDeleteUser ? (
              <>
                <Typography variant="h5">
                  Are you sure you want to delete your account?
                </Typography>
                <Typography color={"error"} variant="h6" fontWeight={"bold"}>
                  This action cannot be undone.
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h4">Are You Sure ?</Typography>
                <Typography color={"error"} fontWeight={"bold"} variant="h6">
                  Your workflows will be available until the next billing date.
                </Typography>
              </>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          {isDeleteUser ? (
            <>
              <Button autoFocus onClick={handleClose}>
              <Typography variant="h6">Cancel</Typography>
              </Button>

              <Button
                 autoFocus
                color="error"
                onClick={deleteAccountHandler}
              >
                <Typography variant="h6">Delete</Typography>
              </Button>
            </>
          ) : (
            <>
              <Button autoFocus onClick={handleClose}>
              <Typography variant="h6">Later</Typography>
              </Button>

              <Button
               autoFocus
                color="error"
                onClick={() =>
                  cancelSubscription(userSubscription.subscription_id)
                }
              >
                <Typography variant="h6">Cancel Subscription</Typography>
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <IndefiniteLoader state={globalLoader} />
    </>
  );
}

export default ProfilePage;

export const getScreenieRequirements = (findAttribute: string) => {
  const screenieRequirements = JSON.parse(
    localStorage.getItem("screenieRequirements")
  );
  if (!screenieRequirements) {
    return null;
  }
  const { tabNum } = screenieRequirements;

  switch (tabNum) {
    case "2":
      return "2";
    case "3":
      return "3";
    case "4":
      return "4";
    case "5":
      return "5";
    default:
      return "1"; // Default to tab 1 if no match found
  }
};

export const setScreenieRequirements = (
  setProperties: any,
  propertyValue: any
) => {
  if (setProperties === "tab") {
    const screenieRequirements = JSON.parse(
      localStorage.getItem("screenieRequirements")
    );
    if (screenieRequirements) {
      screenieRequirements.tabNum = propertyValue;
      localStorage.setItem(
        "screenieRequirements",
        JSON.stringify(screenieRequirements)
      );
    } else {
      let newScreenieReq: { tabNum: string } = {
        tabNum: propertyValue,
      };
      localStorage.setItem(
        "screenieRequirements",
        JSON.stringify(newScreenieReq)
      );
    }
  }
};

export const physicoChemicalProp: string[] = [
  "MolLogP",
  "MolWt",
  "NumRotatableBonds",
  "HBondDonors",
  "HBondAcceptors",
  "TPSA",
  "HeavyAtoms",
  "NumAromaticRings",
  "SASA",
  "QED",
  "SA",
  "UndesiredRings",
];

export const medicinalChemistryFilters: string[] = [
  "Ghose",
  "Veber",
  "Egan",
  "Muegge",
  "Oprea",
  "REOS",
  "Mozziconacci",
  "Palm",
  "Pfizer3/75",
  "Lipinski",
];

export const maiFilters: string[] = [
  "PAINS",
  "MAI Lipinski+",
  "Unwanted Substructures",
  "MAI Rings",
];

export const toxicGroupsFIlters: string[] = [
  "BRENK",
  "NIH",
  "ZINC",
  "PAINS_A",
  "PAINS_B",
  "PAINS_C",
];



// we will use this function later.
// added table state in the localstorage : Density and No. of rows
/*
const handleTableStateChange = (newState: any) => {
  // extrating existing table state from localstorage
  const densityAndRowsOfTable = JSON.parse(
    localStorage.getItem("screenieTableState")
  );
  // newState.pagination.paginationModel.pageSize != 5 &&
  // newState.density.value != "standard" &&

  if (!densityAndRowsOfTable) {
    // if table state is not present in localstorage then will add it.
    const densityAndRowsOfTable: tableStateType = {
      density: "standard",
      pageSize: 5,
    };
    densityAndRowsOfTable.density = newState.density.value;
    densityAndRowsOfTable.pageSize =
      newState.pagination.paginationModel.pageSize;
    localStorage.setItem(
      "screenieTableState",
      JSON.stringify(densityAndRowsOfTable)
    );
  } else {
    // If previous table state is present in localstorage then we will update it with new state
    if (
      newState.pagination &&
      newState.pagination.paginationModel.pageSize !==
        densityAndRowsOfTable.pageSize
    ) {
      densityAndRowsOfTable.pageSize =
        newState.pagination.paginationModel.pageSize;
    }
    if (
      newState.pagination &&
      newState.density.value !== densityAndRows.density
    ) {
      densityAndRowsOfTable.density = newState.density.value;
    }
  }

  if (densityAndRowsOfTable) {
    localStorage.setItem(
      "screenieTableState",
      JSON.stringify(densityAndRowsOfTable)
    );
  }
};
*/
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context";
import FileUploader from "../common/FileUploader";
import BetaModal from "../common/BetaModal";
import { JOB_SERVER_URL } from "../../config";
import { RUNTIME } from "../../config";

type AutoMolInputProps = {
  postHandleSubmit: Function;
  handleErrorSubmit: Function;
  handleSubmitLoader: (status: boolean) => void;
};

function AutoMolInput({
  postHandleSubmit,
  handleErrorSubmit,
  handleSubmitLoader,
}: AutoMolInputProps) {
  const [logpWeight, setLogpWeight] = useState(0.0);
  const [qedWeigt, setQedWeigt] = useState(2.0);
  const [saWeight, setSaWeight] = useState(8.0);

  const [logpAction, setLogpAction] = useState("none");
  const [qedAction, setQedAction] = useState("maximize");
  const [saAction, setSaAction] = useState("minimize");

  const [ligandFile, setLigandFile] = useState("");
  const [betaOpen, setBetaOpen] = useState(false);

  const { user } = useUserAuth();
  const navigate = useNavigate();
  const runInProd = RUNTIME === "PROD";

  const handleSubmit = async (e: any) => {
    if (!ligandFile) {
      alert("You need to upload a ligand file");
      return;
    }
    // send the values to API
    // setInProgress(true)
    handleSubmitLoader(true);
    await axios
      .post(
        `${JOB_SERVER_URL}/automol/submit`,

        {
          logp: {
            property: "logP",
            action: logpAction,
            weight: logpWeight,
          },
          sa: {
            property: "SA",
            action: saAction,
            weight: saWeight,
          },
          qed: {
            property: "QED",
            action: qedAction,
            weight: qedWeigt,
          },
          ligands_file: ligandFile,
        },
        {
          params: {
            num_samples: "10",
            uid: user.uid,
          },
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res: any) => {
        postHandleSubmit(res);
      })
      .catch((error) => {
        handleErrorSubmit(error);
      });
  };

  const getAsBase64 = (file: File) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      setLigandFile(reader.result.toString());
    };
  };

  const handleSAAction = async (e: React.ChangeEvent, v: string) => {
    setSaAction(v);
  };

  const handleQEDAction = async (e: React.ChangeEvent, v: string) => {
    setQedAction(v);
  };

  const handleLogPAction = async (e: React.ChangeEvent, v: string) => {
    setLogpAction(v);
  };

  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.download = "input_automol.csv";

    // 👇️ set to relative path
    link.href = "/samples/automol/input_automol.csv";

    link.click();
  };

  const onBetaClick = () => {
    //    console.log("clicked beta access");
    setBetaOpen(true);
  };

  return (
    <>
      <Grid container direction="column">
        <Typography variant="h5" mt={1}>
          Submit Jobs to Genie
        </Typography>
        {runInProd && (
          <Grid container direction="column" className="beta-anchor">
            <Grid item xs={12} sx={{ mt: 5 }}>
              <Typography>
                This is a Premium feature. Try with{" "}
                <Button variant="contained" onClick={onBetaClick}>
                  BETA ACCESS
                </Button>
                <BetaModal
                  open={betaOpen}
                  openHandler={setBetaOpen}
                ></BetaModal>
              </Typography>
            </Grid>
          </Grid>
        )}
        <FormControl
          fullWidth
          className={RUNTIME === "PROD" ? "blurry-bg" : ""}
        >
          <Box display={"flex"} justifyContent={"space-between"} mb={1}>
            <Typography
              variant="h6"
              sx={{ mt: 1, color: "var(--shade-2900, #29283B)" }}
            >
              {"Ligands File (.csv)"}
            </Typography>
            <Button variant="text" onClick={handleDownloadSample}>
              {"Download Sample"}
            </Button>
          </Box>
          <FileUploader
            accept={"text/csv"}
            handleFileUpload={(files: FileList) => getAsBase64(files[0])}
            headerSelector={true}
          />

          <Typography
            variant={"h6"}
            sx={{ mt: 2.5, mb: 1.5, color: "var(--shade-2900, #29283B)" }}
          >
            Parameters
          </Typography>
          <Stack direction="column" spacing={2}>
            <Grid container item>
              <Grid item xs={2}>
                <Typography>LogP</Typography>
              </Grid>
              <Grid item xs={7}>
                <RadioGroup row defaultValue="none" onChange={handleLogPAction}>
                  <FormControlLabel
                    value="minimize"
                    control={<Radio />}
                    label="Minimize"
                  />
                  <FormControlLabel
                    value="maximize"
                    control={<Radio />}
                    label="Maximize"
                  />
                  <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label="None"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Weight"
                  placeholder="Weight"
                  fullWidth
                  type="number"
                  defaultValue={logpWeight}
                  onChange={(e: any) => setLogpWeight(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={2}>
                <Typography>SA</Typography>
              </Grid>
              <Grid item xs={7}>
                <RadioGroup
                  row
                  defaultValue="minimize"
                  onChange={handleSAAction}
                >
                  <FormControlLabel
                    value="minimize"
                    control={<Radio />}
                    label="Minimize"
                  />
                  <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label="None"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label={"Weight"}
                  placeholder={"Weight"}
                  type="number"
                  defaultValue={saWeight}
                  onChange={(e: any) => {
                    setSaWeight(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={2}>
                <Typography>QED</Typography>
              </Grid>
              <Grid item xs={7}>
                <RadioGroup
                  row
                  defaultValue="maximize"
                  onChange={handleQEDAction}
                >
                  <FormControlLabel
                    value="maximize"
                    control={<Radio />}
                    label="Maximize"
                  />
                  <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label="None"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label={"Weight"}
                  placeholder={"Weight"}
                  type="number"
                  defaultValue={qedWeigt}
                  inputMode="decimal"
                  onChange={(e: any) => setQedWeigt(e.target.value)}
                />
              </Grid>
            </Grid>
          </Stack>
          <Box display={"flex"} mt={2}>
            <Button
              variant="outlined"
              sx={{ textAlign: "right", mr: 2, ml: "auto" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </Button>
            <Button
              sx={{ textAlign: "right" }}
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </FormControl>
      </Grid>
    </>
  );
}

export default AutoMolInput;

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Card, LinearProgress, Stack, Tab, Typography } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridFilterModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import http from "../../net/http-common";
import { LIFT_SERVER_URL } from "../../config";


// custom toolbar with custom default filters
const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        printOptions={{
          disableToolbarButton: true,
        }}
      />
    </GridToolbarContainer>
  );
};

function LiftVisualResult() {
  const [tabValue, setTabValue] = useState("1");
  const [tabData, setTabData] = useState<any>(null);
  const [fileDownloadLoader,setFileDownloadLoader]=useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [tableStates, setTableStates] = useState<
    Record<string, GridFilterModel>
  >({});
  const data = location.state;

  //   console.log("data", data);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleStateChange = (tableName: string, state: GridFilterModel) => {
    setTableStates((prev) => ({
      ...prev,
      [tableName]: state,
    }));
  };

  // get dynamic columns
  const getColumns = (tableData: any[]): GridColDef[] => {
    if (tableData.length === 0) {
      return [];
    }

    // Start with the ID column
    const columns: GridColDef[] = [
      {
        field: "id",
        headerName: "ID",
        width: 90,
        editable: false,
      },
    ];

    // Add other columns dynamically
    const dataKeys = Object.keys(tableData[0]).filter((key) => key !== "id"); // Exclude 'id' to avoid duplication
    dataKeys.forEach((key) => {
      columns.push({
        field: key,
        headerName: key.charAt(0).toUpperCase() + key.slice(1),
        width: 150,
        editable: true,
        renderCell: (params) => {
          if (typeof params.value === "boolean") {
            if (params.value) {
              return <div>TRUE</div>;
            } else {
              return <div>FALSE</div>;
            }
          }

          return <div>{params.value}</div>;
        },
      });
    });

    return columns;
  };

  const downloadInteractions=async()=>{
 
    const interactionData=data.visualizerResult;
    setFileDownloadLoader(true);
    try {
        const response = await http.post(
          `${LIFT_SERVER_URL}/LIFT_api/download_interaction_info/`,
          interactionData,
          {
            headers: {
              accept: "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
            },
            // responseType:"blob"
          }
        );
        
        const href = window.URL.createObjectURL(new Blob([response.data]));

        const anchorElement = document.createElement("a");
  
        anchorElement.href = href;
        anchorElement.download = `lift_visualizer_result.csv`;
  
        document.body.appendChild(anchorElement);
        anchorElement.click();
  
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
  
 
      } catch (error) {
        console.log("error is", error);
      }

      setFileDownloadLoader(false);
    };
    
  useEffect(() => {
    if (!data) {
      navigate(-1);
    } else {
      setTabData(data.visualizerResult);
    }
    return () => {};
  }, []);

  return (
    <Box m={2}>
      <Box textAlign={"right"} mt={-4} mb={1} >
        <Button
          variant="contained"
          color="success"
          startIcon={<DownloadIcon sx={{ fontSize: 12 }} />}
          sx={{
            fontSize: 12,
            paddingTop: 1,
            paddingBottom: 1,
          }}
          onClick={downloadInteractions}
        >
          Download as CSV
        </Button>
      </Box>
      {fileDownloadLoader && <LinearProgress />}
      <Card sx={{ position: "relative" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              value={tabValue}
              onChange={handleTabChange}
              aria-label="filter tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabData &&
                Object.entries(tabData).map(([tabName, TabValue], index) => (
                  <Tab label={tabName} value={String(index + 1)} key={index} />
                ))}
            </TabList>
          </Box>
          {tabData &&
            Object.entries(tabData).map(
              ([tabName, TabValue]: [any, any], index) => (
                <TabPanel value={String(index + 1)} key={index}>
                  <Card>
                    <Box
                      display={"flex"}
                      height={550}
                      p={4}
                      overflow={"auto"}
                      justifyContent={"center"}
                    >
                      <img
                        src={`data:image/jpeg;base64,${TabValue.image}`}
                        alt={`Image for ${tabName}`}
                      />
                      <img src="/images/lift_fixed_image.png" />
                    </Box>
                  </Card>
                  {Object.entries(TabValue)
                    .filter(([key]) => !key.includes("image"))
                    .map(([tableName, tableData]: [any, any], index: any) => (
                      <Box key={tableName} mt={3}>
                        <Typography variant="h6" gutterBottom>
                          {tableName.toUpperCase()}
                        </Typography>
                        <DataGridPro
                          getRowId={(row) => row.id}
                          rows={tableData as any[]}
                          columns={getColumns(tableData)}
                          autoHeight
                          pagination
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              },
                            },
                          }}
                          pageSizeOptions={[10, 20, 30]}
                          slots={{
                            toolbar: (props) => <CustomToolbar {...props} />,
                          }}
                          disableRowSelectionOnClick
                          filterModel={tableStates[tableName]}
                          onFilterModelChange={(model) =>
                            handleStateChange(tableName, model)
                          }
                        />
                      </Box>
                    ))}
                </TabPanel>
              )
            )}
        </TabContext>
      </Card>
    </Box>
  );
}

export default LiftVisualResult;

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box
} from '@mui/material';

interface MoleculeInfo {
  svg: string;
  smiles: string;
}

interface molInfoProps {
  open: boolean;
  handleClose: () => void;
  moleculeInfo: MoleculeInfo;
}

const MolInfoDialog: React.FC<molInfoProps> = ({
  open,
  handleClose,
  moleculeInfo,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="info-dialog-title"
      aria-describedby="info-dialog-description"
    >
      <DialogTitle id="info-dialog-title" textAlign={"center"}>
        <Typography variant="h5">Molecule Information</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: "center",
          p: 8,
        }}>

        <Box sx={{ width: 1 }}>
          <Box
            component={"img"}
            src={`data:image/svg+xml;base64,${btoa(moleculeInfo.svg)}`}
            sx={{
              display: "block",
              m: "auto",
              width: 300,
              height: 300,
              transition: "transform 0.8s ease-in-out",
            }}
          />
          <Typography
            variant="h5"
            sx={{
              wordBreak: "break-word",
              overflowWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            {moleculeInfo.smiles}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MolInfoDialog;

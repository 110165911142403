import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import Viewer from "../../components/common/DockViewer";
import { Color } from "molstar/lib/mol-util/color";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

function PromiscuityViewer() {
  const navigate = useNavigate();
  const state = useLocation();
  const row = state.state.id;
  const promiscuityResults = useSelector(
    (state: any) => state.promiscuity.properties
  );

  useEffect(() => {
    var pdbqt1 =
      promiscuityResults.promiscuityData.results[row - 1].cavity_url ||
      "/samples/autodock/ace2.pdbqt";
    var pdbqt2 = promiscuityResults.promiscuityData.results[row - 1].output_url;

    Viewer.create("molviewer", [Color(0x33dd22), Color(0x1133ee)], true).then(
      (viewer) => {
        viewer.loadStructuresFromUrlsAndMerge([
          { url: pdbqt1, format: "pdbqt" },
          { url: pdbqt2, format: "pdbqt" },
        ]);
      }
    );
  }, [row, promiscuityResults]);

  return (
    <Grid
      item
      xs={12}
      md={12}
      sx={{
        m: 0,
        pl: 1,
        pr: 1,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        id="molviewer"
        style={{
          height: "500px",
          width: "80%",
          position: "relative",
          display: "flex", // Add display flex
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
        }}
      ></div>
    </Grid>
  );
}

export default PromiscuityViewer;

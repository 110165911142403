import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useForm } from "../../app/hooks";
import { setPdbInfo } from "../../views/PDBInfo/pdbInfoSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import http from "../../net/http-common";
import FileUploader from "../common/FileUploader";
import { ConstructionOutlined } from "@mui/icons-material";
import {
  localStorageSet,
  localStorageUpdate,
  localStorageGet,
  doesLocalStorageKeyExist,
} from "../../localStorage/localStorgaeUpdate";
import { useUserAuth } from "../../context";
import { ToastContainer, toast } from "react-toastify";

interface MolViewerInputI {
  id: string;
  doc: string;
  sample: string;
}

function MolViewerInput() {
  const { user } = useUserAuth();

  const navigate = useNavigate();
  const pdbInput = {
    id: "",
    doc: "",
    sample: "",
    phLevel: 7.4,
    removeWater: true,
    includeHeteroatoms: false,
    agreeTerms: false,
  };
  const [values, handleChange] = useForm<MolViewerInputI>(pdbInput);
  const [showInputModal, setShowInputModal] = useState(false);
  const [pdbFile, setPdbFile] = useState<File>(null);
  const [cifFile, setcifFile] = useState<File>(null);
  const [trajectoryFile, setTrajectoryFile] = useState<File>(null);
  const [FileType,setFileType] = useState<string>("");

  let fulfilled = false;

  // const handleRedirect = () => {
  //   navigate("/mol-viewer/viewer", {
  //     state: {
  //       pdbID: null,
  //       pdbFile : null,
  //     },
  //   })
  // }

  const handleDownloadPDBSample = () => {
    const link = document.createElement("a");
    link.download = "model.pdb";

    link.href = "/samples/DrViz/model.pdb";

    link.click();
  };
  const handleDownloadTrajectorySample = () => {
    const link = document.createElement("a");
    link.download = "coordinates.xtc";

    link.href = "/samples/DrViz/coordinates.xtc";

    link.click();
  };

  const submitForm = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (doesLocalStorageKeyExist("Dr.viz") && !user) {
      const counter = localStorageGet("Dr.viz");
      if (counter >= 2) {
        toast.error(
          "We need you to Login to  continue using this workflow. This module is always free to use, we need you to login to better understand your usage, Redirecting...",
          {
            position: "top-center",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            // transition: Bounce,
          }
        );
        setTimeout(() => {
          navigate("/user/login");
        }, 8000);
        // navigate("/user/login");
        return;
      }
    } else if (!user) {
      localStorageSet("Dr.viz");
    }

    if (!pdbFile && !values.id) return;
    localStorageUpdate("Dr.viz");
    navigate("/lab/mol-viewer/viewer", {
      state: {
        pdbID: values.id.toLowerCase(),
        pdbFile: pdbFile,
        trajectoryFile: trajectoryFile,
        FileType: FileType
      },
    });
  };

  // useEffect(() => {
  //   if(!values.id) return;

  //   if(values.id.length !== 4)
  //   return
  //   setFetchingAssembly(true);
  //   http
  //     .post(
  //       '/protein/detectAssemblies',
  //       {
  //         pdb_id: 'string',
  //       },
  //       {
  //         params: {
  //           pdb_id: values.id.toLowerCase(),
  //         },
  //         headers: {
  //           accept: 'application/json',
  //           'Content-Type': 'application/json',
  //         },
  //       },
  //     )
  //     .then((res: any) => {
  //       setAssemblies(res.data)
  //       setFetchingAssembly(false);
  //     })
  // }, [values.id])

  // const handleSelect = (event: SelectChangeEvent) => {
  //   setAssembly(event.target.value as string)
  // }

  return (
    <React.Fragment>
      <Grid item px={3} py={2.5} sx={{ backgroundColor: "grey.100" }}>
        <i className="ri-login-box-line"></i>
        <Typography
          display={"inline-block"}
          variant={"h6"}
          sx={{ color: "grey.900" }}
          ml={1}
          fontWeight={"600"}
        >
          {"Input"}
        </Typography>
      </Grid>
      <Grid container item p={2} direction={"column"} rowSpacing={3}>
        <Grid item>
          <Typography
            component="label"
            htmlFor="pdb-id"
            sx={{
              mt: 1,
              color: "var(--shade-2900, #29283B)",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            Enter a PDB ID
            <Button
              variant="text"
              id="sample"
              name="id"
              value="7kxo"
              onClick={handleChange}
            >
              Example: 7KXO
            </Button>
          </Typography>
          <TextField
            placeholder="7kxo"
            fullWidth
            id="pdb-id"
            name="id"
            value={values.id.toLowerCase()}
            onChange={handleChange}
          />
        </Grid>
        <Typography textAlign={"center"} my={1.5}>
          -- OR --
        </Typography>
        <Grid>
          <Typography
            sx={{
              mt: 1,
              color: "var(--shade-2900, #29283B)",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            Protein/Molecule File (.pdb,.mol2,.mmcif,.pdbqt,.mol,.sdf)
            
          </Typography>
          <FileUploader
            accept=".pdb, .sdf, .pdbqt, .mol2,.cifCore,.gro, .mmcif, .mol, .xyz"
            handleFileUpload={(files: FileList) => {
              const uploadedFile = files[0];
              const fileExtension = uploadedFile.name.split(".").pop(); // Get the file extension
              // Store the uploaded file and its extension based on the accepted formats
              if (
                fileExtension === "pdb"
                || fileExtension === "mol2" || fileExtension === "gro"
                || fileExtension === "pdbqt" || fileExtension === "mmcif" || fileExtension === "mol" 
                || fileExtension === "sdf" || fileExtension === "xyz" || fileExtension === "cifCore"
              ) {
                setPdbFile(uploadedFile);
                // Update state to indicate the file extension
                setFileType(fileExtension);
              }
            }}
          />
        </Grid>
        {pdbFile && (
          <>
            <Typography textAlign={"center"} my={1.5}>
              -- Optional --
            </Typography>
            <Grid>
              <Typography
                sx={{
                  mt: 0,
                  color: "var(--shade-2900, #29283B)",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                Trajectory File (.xtc)
                <Button variant="text" onClick={handleDownloadTrajectorySample}>
                  {"Download Sample"}
                </Button>
              </Typography>
              <FileUploader
                accept=".xtc"
                maxSize={1024}
                handleFileUpload={(files: FileList) => {
                  setTrajectoryFile(files[0]);
                }}
              />
            </Grid>
          </>
        )}

        <Grid container item mt={2} direction={"column"} rowSpacing={2}>
          <Grid item>
            <Button
              variant={"contained"}
              color={"primary"}
              fullWidth
              onClick={(e) => submitForm(e)}
            >
              {"Submit"}
            </Button>
          </Grid>
          {/* <Typography textAlign={"center"} my={1.5}>
            -- OR --
          </Typography>
          <Button
            variant={'contained'}
            color={'primary'}
            fullWidth
            onClick={handleRedirect}
          >
            {'Explore DrViz'}
          </Button> */}
        </Grid>
      </Grid>
      <ToastContainer />
    </React.Fragment>
  );
}

export default MolViewerInput;

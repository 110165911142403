import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { AppProvider } from './context';
import './index.css';
import { router } from './routerConfig';
import CssBaseline from '@mui/material/CssBaseline';
import { UserAuthContextProvider } from './context/authentication/UserAuthContext';
import { ThemeProvider } from '@mui/material';
import theme from './theme/theme';
import { Provider } from 'react-redux';
import { store } from './app/store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);


root.render(
  
    <Provider store={store}>
      <UserAuthContextProvider>
        <ThemeProvider theme={theme}>
          <AppProvider>
            <CssBaseline enableColorScheme />
            <RouterProvider router={router} />
            
          </AppProvider>
        </ThemeProvider>
      </UserAuthContextProvider>
    </Provider>
);

import { Card, Grid } from "@mui/material";

import { DashCard } from "../../components";

const internalDockingCards = [
  {
    label: "AutoDock",
    link: "autodock",
    description: "A widely adopted docking tool",
    dashIcon: "images/new-dashcards-images/docking1_dashcard.jpg",
    navlabel: "AutoDock",
  },
  {
    label: "UniDock",
    link: "unidock",
    description: "Rapid docking for large-scale screening",
    dashIcon: "images/new-dashcards-images/docking2_dashcard.jpg",
    navlabel: "UniDock",
  },
];
function InternalDockingDashboard() {
  return (
    <Grid container spacing={3} p={3}>
      {internalDockingCards.map((card, index) => (
        <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 2,
              flex: "1 0 0",
              borderRadius: "18px",
              border: "1px solid var(--shade-200, #E7EBF9)",
              height: "100%",
              "&:hover": {
                boxShadow: 7,
              },
            }}
          >
            <DashCard key={index} {...card} section="Docking" />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default InternalDockingDashboard;

import { Box, CircularProgress,Alert,Snackbar} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  selectPdbInfo,
  fetchRamachandran,
  // setRamachandran,
} from '../../views/PDBInfo/pdbInfoSlice'

interface RPlotProps {
  pdbID: string
  jobDir: string
}

function Ramachandran({ pdbID, jobDir }: RPlotProps) {
  const pdbInfo = useAppSelector(selectPdbInfo)
  const [errorMsg,setErrorMsg] = useState(null);
  const [showError,setShowError] = useState(false);
  const dispatch = useAppDispatch()
  // const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!pdbID || !jobDir) return
    if (pdbInfo?.ramachandran?.data) return

    dispatch(fetchRamachandran({ pdbID, jobDir }))
    .then((res)=>{
      if(res.payload.error)
      {
        setShowError(true);
        setErrorMsg("Error loading Ramachandran Plot")
      }
    }).catch(error=>{
        setShowError(true);
        setErrorMsg("Error loading Ramachandran Plot")
    })
    
  }, [jobDir, pdbID])

  return (
    <>
    <Snackbar open={showError} autoHideDuration={9000} sx={{width: '50%'}} onClose={()=>{setShowError(false)}}  anchorOrigin={{ vertical:'top', horizontal:'center' }}>
        <Alert onClose={()=>{setShowError(false)}} severity="error" variant="filled" sx={{ width: '100%' }}>
          {errorMsg}
        </Alert>
      </Snackbar>
      {!pdbInfo?.ramachandran?.loading ? (
        <Box
          component={'img'}
          sx={{ width: '100%', height: 'auto' }}
          src={`data:image/png;base64,${pdbInfo?.ramachandran?.data?.image}`}
          alt="ramachandra plot"
        ></Box>
      ) : (
        <CircularProgress />
      )}
    </>
  )
}

export default React.memo(Ramachandran)

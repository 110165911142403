import { Grid } from "@mui/material";
import { DashboardCard } from "../components";

function Dashboard() {

  return (
    <Grid container p={3}>
      <Grid item>
        <DashboardCard />
      </Grid>
    </Grid>
  );
}

export default Dashboard;
import { TabContext, TabList } from "@mui/lab";
import { Box, Card, CardContent, Grid, Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import { WorkflowLandingTab } from "../../models";
import { TabPanel } from "./TabPanel";

function WorkflowLandingTabs({ tabs }: { tabs: Array<WorkflowLandingTab> }) {
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    //console.log(newValue);
    setTabValue(newValue);
  };

  const [tabValue, setTabValue] = useState("0");

  return (
    <Card component={Card}>
      {/* Chips */}
      <CardContent sx={{ p: 0 }}>
        <Grid item>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
              <TabList
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                {tabs.map((tab, index) => (
                  <Tab key={index} label={tab.label} value={tab.id} />
                ))}
              </TabList>
            </Box>

            <Grid item sx={{ px: 3.75 }}>
              {tabs.map((tab, index) => (
                <TabPanel key={index} value={tab.id}>
                  <Typography>{tab.value}</Typography>
                </TabPanel>
              ))}
            </Grid>
          </TabContext>
        </Grid>
        {/* /content */}
      </CardContent>
    </Card>
  );
}

export default WorkflowLandingTabs;

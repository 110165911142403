import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { JOB_SERVER_URL, SERVER_URL } from "../../config";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BetaModal({
  open,
  openHandler,
}: {
  open: boolean;
  openHandler: (value: any) => void;
}) {
  const handleOpen = () => openHandler(true);
  const handleClose = () => openHandler(false);
  const [purpose, setPurpose] = useState("Commercial");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isNameValid, setIsNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [detailsDone, setdetailsDone] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const [statusMessage, setStatusMessage] = useState(
    " Thank you!. We have your details. We'll get back to you shortly"
  );

  function handleChange(event: SelectChangeEvent): void {
    setPurpose(event.target.value as string);
  }

  function isGenericEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) return false;
    const wellKnownDomains = [
      "gmail.com",
      "yahoo.com",
      "outlook.com",
      "hotmail.com",
      "aol.com",
      // Add more well-known domains as needed
    ];

    const [, domain] = email.toLowerCase().split("@");

    return wellKnownDomains.includes(domain);
  }

  function checkName(name: string) {
    if (!name || name.length < 3) {
      setIsNameValid(false);
      return false;
    } else {
      setIsNameValid(true);
      return true;
    }
  }

  function checkEmail(email: string) {
    if (!email || isGenericEmail(email)) {
      setIsEmailValid(false);
      return false;
    } else {
      setIsEmailValid(true);
      return true;
    }
  }

  const submitDetails = async () => {
    setInProgress(true);
    if (!checkName(name) || !checkEmail(email)) {
      setdetailsDone(false);
      setInProgress(false);
      return;
    }

    const response = await axios.post(`${SERVER_URL}/access/beta`, "", {
      params: {
        name: name,
        phone: phone,
        email: email,
        purpose: purpose,
      },
      headers: {
        accept: "application/json",
        "content-type": "application/x-www-form-urlencoded",
      },
    });

    setInProgress(false);

    if (response.status === 200) {
      setdetailsDone(true);
    } else {
      setdetailsDone(true);
      setStatusMessage(
        "Unable to record details. Please write to info@moleculeai.com"
      );
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {!detailsDone && (
            <Box sx={style}>
              <Typography mt={1}>
                Please submit below details. We'll get back to you shortly
              </Typography>
              <FormControl fullWidth>
                <Stack direction="column" spacing={1.5}>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                    }}
                  >
                    Name
                  </Typography>
                  <TextField
                    type={"text"}
                    required
                    onChange={(e) => {
                      if (checkName(e.target.value)) setName(e.target.value);
                    }}
                  />
                  {!isNameValid && (
                    <FormHelperText error>
                      Error: Please enter your full name
                    </FormHelperText>
                  )}
                </Stack>
                <Stack direction="column" spacing={1.5}>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                    }}
                  >
                    Organizational e-mail
                  </Typography>
                  <TextField
                    type={"email"}
                    required
                    onChange={(e) => {
                      if (checkEmail(e.target.value)) setEmail(e.target.value);
                    }}
                  />
                  {!isEmailValid && (
                    <FormHelperText error>
                      Error: Email should be valid and Organizational
                    </FormHelperText>
                  )}
                </Stack>
                <Stack direction="column" spacing={1.5}>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                    }}
                  >
                    Phone Number
                  </Typography>
                  <TextField
                    type={"tel"}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </Stack>
                <Stack direction="column" spacing={1.5}>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                    }}
                  >
                    Purpose
                  </Typography>
                  <Select
                    labelId="select-label"
                    id="purpose-select"
                    value={purpose}
                    label="Purpose"
                    onChange={handleChange}
                  >
                    <MenuItem value={"Commercial"}>Commercial</MenuItem>
                    <MenuItem value={"Academic Research"}>
                      Academic Research
                    </MenuItem>
                    <MenuItem value={"Individual"}>Individual</MenuItem>
                  </Select>
                </Stack>
                <Button
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={submitDetails}
                >
                  Submit
                </Button>
                {inProgress && (
                  <CircularProgress size={"1rem"} color={"inherit"} />
                )}
              </FormControl>
            </Box>
          )}
          {detailsDone && (
            <Box sx={style}>
              <Typography mt={1} classes={"green"}>
                {statusMessage}
              </Typography>
            </Box>
          )}
        </>
      </Modal>
    </div>
  );
}

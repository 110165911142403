export function localStorageSet(key: string): void {
  const stringValue = String(1);
  localStorage.setItem(key, stringValue);
}


export  function localStorageUpdate(key: string): void {
  const currentValue = localStorage.getItem(key);

    const updatedValue = parseInt(currentValue, 10) + 1;
    localStorage.setItem(key, updatedValue.toString());
  
}

export function localStorageGet(key: string): number | null {
  const value = localStorage.getItem(key);
  const parsedValue = parseInt(value, 10);
  return parsedValue;
}


export function doesLocalStorageKeyExist(key: string): boolean {
  return localStorage.getItem(key) !== null;
}
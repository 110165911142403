import { ReactNode } from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell,Typography} from '@mui/material'
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface InteractionInfo {
  site?: string
  type?: string
  data: any
  col?: Array<{ cell: any }>
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
      backgroundColor: "white",
  },
  '&:nth-of-type(even)': {
      backgroundColor: '#f2f7f9',
  },
  '& td': {
      padding: 15,
  },
}));

function InteractionType(props: InteractionInfo) {
  const visible: boolean = props.data.length >= 1
  const tBody = props.data.slice(1)

  // const TableHeaderRow = () => {
  //   return (
  //     <tr>
  //       {props.data[0].map((header: string) => {
  //         return <th key={header}>{header}</th>
  //       })}
  //     </tr>
  //   )
  // }

  // const TableRow = (props: { col: any[] }) => {
  //   return (
  //     <tr>
  //       {props.col.map((cell: string, i) => {
  //         return <td key={i}>{cell}</td>
  //       })}
  //     </tr>
  //   )
  // }

  return (
    <>
      {visible && (
        <><Accordion sx={{}}>
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant='h6' mt={0} sx={{backgroundColor:'#3d3b3b', color:'white',px: 3,width:'100%'}}>{props.type.toUpperCase()}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{p:0, mb:2}}>
          <Table size="small" style={{}}>
            <TableHead>
              <TableRow sx={{}}>
                {props.data[0].map((header: string) => {
                  return <TableCell style={{ backgroundColor: '#d1ecf1' }} key={header}><Typography  sx={{ color: 'black' }}>{header}</Typography></TableCell>
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {tBody.map((row: any[], i: number) => {
                return (
                  <StyledTableRow key={i}>
                    {row.map((cell: any, j: number) => {
                      return <TableCell key={j} >{cell}</TableCell>
                    })}
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
          </AccordionDetails>
          </Accordion>
        </>
      )}
      
    </>
  )
}

export default InteractionType

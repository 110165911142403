interface limitType {
  columnName?: string;
  info?: string;
  lower: number | null;
  upper: number | null;
}

export const admeToxLowerUpperLimit: Record<string, limitType> = {
  clintox: {
    columnName: "Clinical Toxicity",
    info: "This general category metric predicts the overall probability of a\
    molecule causing adverse effects in a clinical setting. It encompasses a broad spectrum\
    of potential toxic effects that a drug might have when administered to patients.",
    lower: null,
    upper: 0.454489658,
  },
  cardio: {
    columnName: "Cardiotoxicity",
    info: "This metric measures the probability of a compound being cardiotoxic,specifically focusing on hERG blockage (a potassium channel in the heart) with an IC50 cutoff of 10 micromolar. Cardiotoxicity can lead to heart-related adverse effects, making this an important parameter for drugs that may affect cardiovascular health.",
    lower: null,
    upper: 0.912508016,
  },
  hepato: {
    columnName: "Hepatotoxicity",
    info: "This metric predicts the likelihood of a compound being hepatotoxic, or toxic to the liver. The liver is a vital organ for drug metabolism, so hepatotoxicity is a major concern in pharmaceuticals, as it can lead to liver damage or failure.",
    lower: null,
    upper: 0.966014224,
  },
  respiratory: {
    columnName: "Respiratory Toxicity",
    info: "stimates the probability of a molecule causing respiratory toxicity.Compounds that are toxic to the respiratory system can cause breathing difficulties, lung damage, or other pulmonary issues.",
    lower: null,
    upper: 0.975901043,
  },
  cytotoxicity: {
    columnName: "Cytotoxicity",
    info: "This metric measures the probability of a molecule being cytotoxic, meaning it can be toxic to cells. Assessing cytotoxicity is important for understanding a drug's potential to cause cell damage or death, which can lead to adverse effects in the body.",
    lower: null,
    upper: 0.579933436,
  },
  mutagen: {
    columnName: "Mutagenicity",
    info: "This metric estimates the probability of a molecule being mutagenic,which means it can cause genetic mutations. Mutations can lead to various health problems, including cancer, making this a key parameter for assessing genetic risk.",
    lower: null,
    upper: 0.858149987,
  },
  carci: {
    columnName: "Carcinogenicity",
    info: "This metric predicts the probability of a molecule being carcinogenic,\
    i.e., having the potential to cause cancer. Carcinogenic compounds can lead to the\
    development of cancerous cells over time, making this a critical safety assessment in\
    drug development.",
    lower: null,
    upper: 0.76190515,
  },
  mouse_oral: {
    columnName: "Lethal Dose in Mouse via Oral Route (LD50) in mg/kg",
    info: "The model predicts the LD50, which is the\
    dose required to kill 50% of a test population (units: mg/kg). This measure is used to\
    assess the acute toxicity of a substance. In drug development, the LD50 value helps in\
    understanding the safety margin of the compound. The higher the LD50 value, the lower\
    the toxicity of the compound, indicating a larger safety margin.",
    lower: 127.14865,
    upper: null,
  },
  caco: {
    columnName: "Caco-2 permeability",
    info: "This measures how well a compound can permeate\
    through Caco-2 cells, a type of cell line derived from human colon carcinoma. The log P\
    value, with P in cm/s, indicates the ease with which a molecule can cross cell\
    membranes, an important factor in drug absorption. A higher log P value typically\
    suggests better permeability.",
    lower: -6.208353883,
    upper: null,
  },
  pampa: {
    columnName: "PAMPA Permeability",
    info: "The Parallel Artificial Membrane Permeability Assay (PAMPA) is a\
    method to predict the permeability of compounds. This endpoint estimates the\
    probability of a molecule to permeate through a membrane, simulating intestinal\
    absorption. It's a useful tool for early-stage drug screening to assess a compound's\
    ability to be absorbed.",
    lower: 0.18240345,
    upper: null,
  },
  pgp: {
    columnName: "Pgp Inhibition",
    info: "P-glycoprotein (Pgp) is a protein that pumps foreign substances out of\
    cells. This endpoint predicts the likelihood of a compound inhibiting this protein. Pgp\
    inhibition can impact drug absorption and distribution, particularly for drugs targeting\
    the brain or cancer cells. Drugs that inhibit Pgp might increase the effectiveness of other\
    drugs by enhancing their absorption or reducing their expulsion from cells.",
    lower: null,
    upper: null,
  },
  solubility: {
    columnName: "Aqueous Solubility (LogS)",
    info: "This predicts the solubility of a compound in water,\
    expressed as logS where S is in molarity (M). Solubility is crucial for a compound's\
    bioavailability and absorption. Poor solubility can lead to low effectiveness of a drug.\
    The higher the logS value, the more soluble the compound is in water.",
    lower: -5.775937724,
    upper: -0.092236866,
  },
  lipo: {
    columnName: "Lipophilicity (LogD value at pH 7.4)",
    info: "Lipophilicity represents the compound's ability to\
    dissolve in fats, oils, and non-polar solvents, versus in water. The logD value at pH 7.4\
    (physiological pH) provides an understanding of a compound's distribution between\
    water and octanol (nonpolar) at this pH. This property influences a molecule's\
    absorption, distribution, metabolism, and excretion in the body.",
    lower: -2.035821947,
    upper: 4.030132499,
  },
  bbb: {
    columnName: "BBB (Blood-Brain Barrier) Penetration",
    info: "This metric predicts the likelihood of a molecule\
    being able to cross the blood-brain barrier (BBB), a highly selective and protective\
    barrier that separates the brain from the circulatory system. Effective BBB penetration is\
    crucial for drugs targeting the central nervous system. The higher the penetration\
    probability, the more likely a compound can affect brain functions or treat neurological\
    disorders.",
    lower: null,
    upper: null,
  },
  CYP1A2_in: {
    columnName: "CYP1A2 Inhibitor",
    info: "Estimates the probability that a compound will inhibit CYP1A2, a key\
    enzyme in drug metabolism. Inhibiting CYP1A2 can alter the effectiveness and safety of\
    medications metabolized by this pathway. Inhibitors of CYP1A2 can affect the\
    metabolism of drugs processed by this enzyme, potentially leading to increased drug\
    levels and adverse effects.",
    lower: null,
    upper: 0.923688237,
  },
  CYP2C19_in: {
    columnName: "CYP2C19 Inhibitor",
    info: "Estimates the probability that a compound will inhibit CYP2C19, a\
    key enzyme in drug metabolism. Inhibiting CYP2C19 can alter the effectiveness and\
    safety of medications metabolized by this pathway. Inhibitors of CYP2C19 can affect the\
    metabolism of drugs processed by this enzyme, potentially leading to increased drug\
    levels and adverse effects.",
    lower: null,
    upper: 0.7685,
  },
  CYP2C9_in: {
    columnName: "CYP2C9 Inhibitor",
    info: "Estimates the probability that a compound will inhibit CYP2C9, a key\
    enzyme in drug metabolism. Inhibiting CYP2C9 can alter the effectiveness and safety of\
    medications metabolized by this pathway. Inhibitors of CYP2C9 can affect the\
    metabolism of drugs processed by this enzyme, potentially leading to increased drug\
    levels and adverse effects.",
    lower: null,
    upper: 0.704104199,
  },
  CYP2D6_in: {
    columnName: "CYP2D6 Inhibitor",
    info: "Estimates the probability that a compound will inhibit CYP2D6, a key\
    enzyme in drug metabolism. Inhibiting CYP2D6 can alter the effectiveness and safety of\
    medications metabolized by this pathway. Inhibitors of CYP2D6 can affect the\
    metabolism of drugs processed by this enzyme, potentially leading to increased drug\
    levels and adverse effects.",
    lower: null,
    upper: 0.902402399,
  },
  CYP3A4_in: {
    columnName: "CYP3A4 Inhibitor",
    info: "Estimates the probability that a compound will inhibit CYP3A4, a key\
    enzyme in drug metabolism. Inhibiting CYP3A4 can alter the effectiveness and safety of\
    medications metabolized by this pathway. Inhibitors of CYP3A4 can affect the\
    metabolism of drugs processed by this enzyme, potentially leading to increased drug\
    levels and adverse effects.",
    lower: null,
    upper: 0.912907072,
  },
  CYP2C9_sub: {
    columnName: "CYP2C9 Substrate",
    info: "Predicts the probability of a drug being a substrate for CYP2C9 i.e.,\
    the likelihood whether a drug compound is likely to be metabolized by the CYP2C9\
    enzyme. Understanding if a drug is a substrate helps in anticipating its metabolism\
    mechanism.",
    lower: 0.048575881,
    upper: 0.483085256,
  },
  CYP2D6_sub: {
    columnName: "CYP2D6 Substrate",
    info: "Predicts the probability of a drug being a substrate for CYP2D6 i.e.,\
    the likelihood whether a drug compound is likely to be metabolized by the CYP2D6\
    enzyme. Understanding if a drug is a substrate helps in anticipating its metabolism\
    mechanism.",
    lower: 0.092402511,
    upper: 0.668276046,
  },
  CYP3A4_sub: {
    columnName: "CYP3A4 Substrate",
    info: "Predicts the probability of a drug being a substrate for CYP3A4 i.e.,\
    the likelihood whether a drug compound is likely to be metabolized by the CYP3A4\
    enzyme. Understanding if a drug is a substrate helps in anticipating its metabolism\
    mechanism.",
    lower: 0.112138035,
    upper: 0.854837719,
  },

  hl: {
    columnName: "Half-Life",
    info: "Estimates the half-life of a molecule with units in hours. The half-life is the time\
    it takes for the concentration of the drug in the body to be reduced by half. This is a\
    crucial pharmacokinetic parameter that helps determine the dosing frequency and\
    duration of a drug's effect. A longer half-life means the drug stays in the body for an\
    extended period, which could allow for less frequent dosing.",
    lower: -2.729339315,
    upper: 37.07958072,
  },
  "NR-AR": {
    columnName: "NR-AR (Androgen Receptor)",
    info: "This metric is from tox21 dataset that predicts the\
    interaction with the androgen receptor, which is important for understanding the\
    impact on male reproductive health and the potential for endocrine disruption.",
    lower: null,
    upper: 0.854932379,
  },
  "NR-AR-LBD": {
    columnName: "NR-AR-LBD (Androgen Receptor Ligand-Binding Domain)",
    info: "This metric is from tox21\
    dataset and predicts the potential binding to the ligand-binding domain of the androgen\
    receptor, which is crucial for the receptor's activation and subsequent adverse biological\
    effects.",
    lower: null,
    upper: 0.803455991,
  },
  "NR-AhR": {
    columnName: "NR-AhR (Aryl Hydrocarbon Receptor)",
    info: "This metric is from tox21 dataset and\
    estimates the interaction with the aryl hydrocarbon receptor, which plays a role in the\
    body's response to certain environmental toxins, including dioxins.",
    lower: null,
    upper: 0.489323572,
  },
  "NR-Aromatase": {
    columnName: "NR-Aromatase",
    info: "This metric is from tox21 dataset and predicts the impact on\
    aromatase, an enzyme involved in estrogen synthesis. Inhibiting or modulating\
    aromatase can affect estrogen levels and is relevant in conditions like breast cancer.",
    lower: null,
    upper: 0.339553121,
  },
  "NR-ER": {
    columnName: "NR-ER (Estrogen Receptor)",
    info: "This metric is from tox21 dataset and predicts the\
    interaction with estrogen receptors, which is significant for understanding effects on\
    female reproductive health and the potential for endocrine disruption.",
    lower: null,
    upper: 0.657051247,
  },
  "NR-ER-LBD": {
    columnName: "NR-ER-LBD (Estrogen Receptor Ligand-Binding Domain)",
    info: "This metric is from tox21\
    dataset and estimates the potential binding to the estrogen receptor's ligand-binding\
    domain, impacting the receptor's activity.",
    lower: null,
    upper: 0.312520272,
  },
  "NR-PPAR-gamma": {
    columnName:
      "NR-PPAR-gamma (Peroxisome Proliferator-Activated Receptor Gamma)",
    info: "This metric\
    is from tox21 dataset and predicts interactions with PPAR-gamma, a receptor that plays\
    a key role in fat storage, glucose metabolism, and insulin sensitivity.",
    lower: null,
    upper: 0.140980191,
  },
  "SR-ARE": {
    columnName: "SR-ARE (Antioxidant Response Element)",
    info: "This metric is from tox21 dataset and\
    estimates the activation of the antioxidant response element pathway, which is involved\
    in the cellular response to oxidative stress and detoxification.",
    lower: null,
    upper: 0.67587681,
  },
  "SR-ATAD5": {
    columnName: "SR-ATAD5",
    info: "This metric is from tox21 dataset and predicts the impact on the ATAD5\
    gene, which is linked to the cellular response to DNA damage and the maintenance of\
    genomic stability.",
    lower: null,
    upper: 0.15498905,
  },
  "SR-HSE": {
    columnName: "SR-HSE (Heat Shock Element)",
    info: "This metric is from tox21 dataset and predicts the\
    activation of the heat shock element, which is part of the cellular response to stress,\
    particularly heat shock proteins.",
    lower: null,
    upper: 0.226434047,
  },
  "SR-MMP": {
    columnName: "SR-MMP (Mitochondrial Membrane Potential)",
    info: "his metric is from tox21 dataset\
    and estimates the effects on mitochondrial membrane potential, important for\
    understanding mitochondrial dysfunction and cytotoxicity.",
    lower: null,
    upper: 0.667930781,
  },
  "SR-p53": {
    columnName: "SR-p53",
    info: "This metric is from tox21 dataset and predicts the activation of the p53\
    pathway, a key regulator in the cellular response to DNA damage and an important\
    factor in cancer biology.",
    lower: null,
    upper: 0.399342234,
  },
  LOAEL: {
    columnName: "Lowest-Observed-Adverse-Effect Level (LOAEL) in mg/kg",
    info: "Lowest concentration or dose of a\
    substance at which there are observable adverse effects in the test subjects. We report\
    the -log10(LOAEL) value, where LOAEL (mg/kg_bw/day) is estimated for rats (subject)\
    assuming the molecule is chronically (>180 days) ingested via oral route.",
    lower: 0.022,
    upper: null,
  },
  ppbr: {
    columnName: "PPBR (Plasma Protein Binding Rate)",
    info: "This metric predicts the percentage of a drug that\
    remains unbound to plasma proteins in the blood. The unbound fraction of a drug is\
    usually pharmacologically active, so knowing the PPBR is important for assessing a\
    drug's efficacy and potential interactions. High plasma protein binding can affect the\
    drug's distribution, elimination, and overall therapeutic effect.",
    lower: 29.0219678,
    upper: 100.0,
  },
  // clear_hep: {
  //   lower: 0.566138151,
  //   upper: 1.89836961,
  // },
  hia_hou: {
    columnName: "HIA (Human Intestinal Absorption)",
    info: "This measures the likelihood of a compound being\
    absorbed through the human intestine. High HIA is often necessary for oral\
    effectiveness of drugs. This metric helps in understanding how much of a drug can enter\
    systemic circulation following oral administration.",
    lower: 0.283483784,
    upper: null,
  },
  // clear_hep_log: {
  //   lower: null,
  //   upper: null,
  // },
  hl_log: {
    columnName: "Half-Life in Hours",
    info: "Estimates the half-life of a molecule with units in hours. The half-life is the time\
    it takes for the concentration of the drug in the body to be reduced by half. This is a\
    crucial pharmacokinetic parameter that helps determine the dosing frequency and\
    duration of a drug's effect. A longer half-life means the drug stays in the body for an\
    extended period, which could allow for less frequent dosing.",
    lower: 1.14,
    upper: 21.63,
  },
  // vdss: {
  //   columnName: "VDss (Volume of Distribution at Steady State)",
  //   info: "This metric predicts VDss, measured in\
  //   liters per kilogram (l/kg). The volume of distribution at steady state is a pharmacokinetic\
  //   parameter that describes the extent to which a drug distributes into body tissues away\
  //   from the bloodstream. It helps in understanding how a drug is dispersed in the body and\
  //   is key to determining dosing regimens. Generally, a larger VDss indicates more extensive\
  //   distribution into tissues.",
  //   lower: 0.049779545,
  //   upper: 11.61553059,
  // },
  vdss_log: {
    columnName: "VDss (Volume of Distribution at Steady State) in l/Kg",
    // eslint-disable-next-line no-multi-str
    info: "This metric predicts VDss, measured in\
    liters per kilogram (l/kg). The volume of distribution at steady state is a pharmacokinetic\
    parameter that describes the extent to which a drug distributes into body tissues away\
    from the bloodstream. It helps in understanding how a drug is dispersed in the body and\
    is key to determining dosing regimens. Generally, a larger VDss indicates more extensive\
    distribution into tissues.",
    lower: 0.21,
    upper: 4.99,
  },
};

import {
  PresetStructureRepresentations,
  StructureRepresentationPresetProvider,
} from "molstar/lib/mol-plugin-state/builder/structure/representation-preset";

import {
  QualityAssessmentPLDDTPreset,
  QualityAssessmentQmeanPreset,
} from "molstar/lib/extensions/model-archive/quality-assessment/behavior";
import { QualityAssessment } from "molstar/lib/extensions/model-archive/quality-assessment/prop";

import { Structure } from "molstar/lib/mol-model/structure";
import { BuiltInTrajectoryFormat } from "molstar/lib/mol-plugin-state/formats/trajectory";
import {
  PluginStateObject as PSO,
  PluginStateTransform,
} from "molstar/lib/mol-plugin-state/objects";
import { createPluginUI } from "molstar/lib/mol-plugin-ui";
// import { renderReact18 } from "molstar/lib/mol-plugin-ui/react18";
import { PluginUIContext } from "molstar/lib/mol-plugin-ui/context";
import { PluginLayoutControlsDisplay } from "molstar/lib/mol-plugin/layout";
import {
  DefaultPluginUISpec,
  PluginUISpec,
} from "molstar/lib/mol-plugin-ui/spec";
import { PluginBehaviors } from "molstar/lib/mol-plugin/behavior";
import { PluginCommands } from "molstar/lib/mol-plugin/commands";
import { PluginConfig } from "molstar/lib/mol-plugin/config";
import { PluginSpec } from "molstar/lib/mol-plugin/spec";
import { StateObject } from "molstar/lib/mol-state";
import { Task } from "molstar/lib/mol-task";
import { Color } from "molstar/lib/mol-util/color";
import { ColorNames } from "molstar/lib/mol-util/color/names";
import { ParamDefinition as PD } from "molstar/lib/mol-util/param-definition";

import "molstar/lib/mol-util/polyfill";
import { ObjectKeys } from "molstar/lib/mol-util/type-helpers";

// import "./index.html";
import {
  ShowButtons,
  StructurePreset,
  ViewportComponent,
} from "./Viewport_Test";
import { StateObjectRef } from "molstar/lib/mol-state";
import { Backgrounds } from "molstar/lib/extensions/backgrounds";
import { G3DFormat, G3dProvider } from "molstar/lib/extensions/g3d/format";
import { DataFormatProvider } from "molstar/lib/mol-plugin-state/formats/provider";

require("molstar/lib/mol-plugin-ui/skin/light.scss");

export { PLUGIN_VERSION as version } from "molstar/lib/mol-plugin/version";
export { setDebugMode, setProductionMode } from "molstar/lib/mol-util/debug";
export { Viewer as DockingViewer };

const CustomFormats = [["g3d", G3dProvider] as const];

const Extensions = {
  backgrounds: PluginSpec.Behavior(Backgrounds),
  g3d: PluginSpec.Behavior(G3DFormat),
};

const DefaultViewerOptions = {
  extensions: ObjectKeys(Extensions),
  customFormats: CustomFormats as [string, DataFormatProvider][],

  layoutIsExpanded: false,
  layoutShowControls: true,
  layoutShowRemoteState: true,
  layoutControlsDisplay: "reactive" as PluginLayoutControlsDisplay,
  layoutShowSequence: true,
  layoutShowLog: false,
  layoutShowLeftPanel: true,

  // viewportShowExpand: PluginConfig.Viewport.ShowExpand.defaultValue,
  viewportShowControls: PluginConfig.Viewport.ShowControls.defaultValue,
  // viewportShowSettings: PluginConfig.Viewport.ShowSettings.defaultValue,
  // viewportShowSelectionMode: PluginConfig.Viewport.ShowSelectionMode.defaultValue,
  viewportShowAnimation: PluginConfig.Viewport.ShowAnimation.defaultValue,
  viewportShowTrajectoryControls: true,
  // PluginConfig.Viewport.ShowTrajectoryControls.defaultValue,

  viewportShowExpand: false,
  //viewportShowSelectionMode: true,
  // viewportShowAnimation: true,
  // viewportShowControls: true,
  viewportShowSettings: true,
  collapseLeftPanel: true,
  collapseRightPanel: true,
  viewportShowSelectionMode:
    PluginConfig.Viewport.ShowSelectionMode.defaultValue,
  pdbProvider: PluginConfig.Download.DefaultPdbProvider.defaultValue,
  emdbProvider: PluginConfig.Download.DefaultEmdbProvider.defaultValue,

  disableAntialiasing: PluginConfig.General.DisableAntialiasing.defaultValue,
  //viewportShowTrajectoryControls: PluginConfig.Viewport.ShowTrajectoryControls.defaultValue,
  pixelScale: PluginConfig.General.PixelScale.defaultValue,
  pickScale: PluginConfig.General.PickScale.defaultValue,

  pickPadding: PluginConfig.General.PickPadding.defaultValue,
  preferWebgl1: PluginConfig.General.PreferWebGl1.defaultValue,

  enableWboit: PluginConfig.General.EnableWboit.defaultValue,
  enableDpoit: PluginConfig.General.EnableDpoit.defaultValue,

  allowMajorPerformanceCaveat:
    PluginConfig.General.AllowMajorPerformanceCaveat.defaultValue,
  powerPreference: PluginConfig.General.PowerPreference.defaultValue,

  pluginStateServer: PluginConfig.State.DefaultServer.defaultValue,
  volumeStreamingServer:
    PluginConfig.VolumeStreaming.DefaultServer.defaultValue,

  // custom colors
  customColors: [0x00ff00, 0x0000ff],
  innerWidth: 200,
  outerWidth: 200,
};

class Viewer {
  constructor(public plugin: PluginUIContext) {}

  static async create(
    elementOrId: string | HTMLElement,
    colors = [Color(0x992211), Color(0xdddddd)],
    showButtons = true
  ) {
    const o = {
      ...DefaultViewerOptions,
    };
    const defaultSpec = DefaultPluginUISpec();

    const spec: PluginUISpec = {
      actions: defaultSpec.actions,
      behaviors: [
        ...defaultSpec.behaviors,
        ...o.extensions.map((e) => Extensions[e]),
      ],
      customFormats: o?.customFormats,
      animations: defaultSpec.animations,
      // customParamEditors: defaultSpec.customParamEditors,
      layout: {
        initial: {
          isExpanded: o.layoutIsExpanded,
          showControls: o.layoutShowControls,
          controlsDisplay: o.layoutControlsDisplay,
          regionState: {
            bottom: "full",
            left: o.collapseLeftPanel ? "collapsed" : "full",
            right: o.collapseRightPanel ? "hidden" : "full",
            top: "full",
          },
        },
      },
      components: {
        ...defaultSpec.components,
        controls: {
          ...defaultSpec.components?.controls,
          top: o.layoutShowSequence ? undefined : "none",
          bottom: o.layoutShowLog ? undefined : "none",
          left: o.layoutShowLeftPanel ? undefined : "none",
        },
        remoteState: o.layoutShowRemoteState ? "default" : "none",
      },
      config: [
        [PluginConfig.Viewport.ShowExpand, o.viewportShowExpand],
        [PluginConfig.Viewport.ShowControls, o.viewportShowControls],
        [PluginConfig.Viewport.ShowSettings, o.viewportShowSettings],
        [PluginConfig.Viewport.ShowSelectionMode, o.viewportShowSelectionMode],
        [PluginConfig.Viewport.ShowAnimation, o.viewportShowAnimation],
        [PluginConfig.State.DefaultServer, o.pluginStateServer],
        [PluginConfig.State.CurrentServer, o.pluginStateServer],
        [PluginConfig.VolumeStreaming.DefaultServer, o.volumeStreamingServer],
        [PluginConfig.Download.DefaultPdbProvider, o.pdbProvider],
        [PluginConfig.Download.DefaultEmdbProvider, o.emdbProvider],
        [ShowButtons, showButtons],
      ],
    };

    const element =
      typeof elementOrId === "string"
        ? document.getElementById(elementOrId)
        : elementOrId;
    if (!element)
      throw new Error(`Could not get element with id '${elementOrId}'`);
    const plugin = await createPluginUI(element, spec, {
      onBeforeUIRender: (plugin) => {
        // the preset needs to be added before the UI renders otherwise
        // "Download Structure" wont be able to pick it up
        plugin.builders.structure.representation.registerPreset(
          ViewerAutoPreset
        );
      },
    });

    (plugin.customState as any) = {
      colorPalette: {
        name: "colors",
        params: { list: { colors } },
      },
    };

    PluginCommands.Canvas3D.SetSettings(plugin, {
      settings: {
        renderer: {
          ...plugin.canvas3d!.props.renderer,
          backgroundColor: ColorNames.white,
        },
        camera: {
          ...plugin.canvas3d!.props.camera,
          helper: { axes: { name: "off", params: {} } },
        },
      },
    });

    return new Viewer(plugin);
  }

  async loadDataAndMerge(
    sources: {
      data: string;
      format: BuiltInTrajectoryFormat;
      isBinary?: boolean;
    }[]
  ) {
    const structures: { ref: string }[] = [];

    for (const { data, format, isBinary } of sources) {
      const _data = await this.plugin.builders.data.rawData({
        data,
        label: "none",
      });
      const trajectory = await this.plugin.builders.structure.parseTrajectory(
        _data,
        format
      );
      const model = await this.plugin.builders.structure.createModel(
        trajectory
      );
      const modelProperties =
        await this.plugin.builders.structure.insertModelProperties(model);
      const structure = await this.plugin.builders.structure.createStructure(
        modelProperties || model
      );
      const structureProperties =
        await this.plugin.builders.structure.insertStructureProperties(
          structure
        );

      structures.push({ ref: structureProperties?.ref || structure.ref });
    }

    // remove current structures from hierarchy as they will be merged
    // TODO only works with using loadStructuresFromUrlsAndMerge once
    //      need some more API metho to work with the hierarchy
    this.plugin.managers.structure.hierarchy.updateCurrent(
      this.plugin.managers.structure.hierarchy.current.structures,
      "remove"
    );

    const dependsOn = structures.map(({ ref }) => ref);
    const data = this.plugin.state.data
      .build()
      .toRoot()
      .apply(MergeStructures, { structures }, { dependsOn });
    const structure = await data.commit();
    const structureProperties =
      await this.plugin.builders.structure.insertStructureProperties(structure);
    this.plugin.behaviors.canvas3d.initialized.subscribe(async (v) => {
      await this.plugin.builders.structure.representation.applyPreset(
        structureProperties || structure,
        StructurePreset
      );
    });
    // console.log("Structures",structures);
  }

  async loadStructuresFromUrlsAndMerge(
    sources: {
      url: string;
      format: BuiltInTrajectoryFormat;
      isBinary?: boolean;
    }[]
  ) {
    const structures: { ref: string }[] = [];
    

    for (const { url, format, isBinary } of sources) {
      const data = await this.plugin.builders.data.download({ url, isBinary });
      const trajectory = await this.plugin.builders.structure.parseTrajectory(
        data,
        format
      );
      const model = await this.plugin.builders.structure.createModel(
        trajectory
      );
      const modelProperties =
        await this.plugin.builders.structure.insertModelProperties(model);
      const structure = await this.plugin.builders.structure.createStructure(
        modelProperties || model
      );
      const structureProperties =
        await this.plugin.builders.structure.insertStructureProperties(
          structure
        );

      structures.push({ ref: structureProperties?.ref || structure.ref });
    }

    // remove current structures from hierarchy as they will be merged
    // TODO only works with using loadStructuresFromUrlsAndMerge once
    //      need some more API metho to work with the hierarchy
    this.plugin.managers.structure.hierarchy.updateCurrent(
      this.plugin.managers.structure.hierarchy.current.structures,
      "remove"
    );

    const dependsOn = structures.map(({ ref }) => ref);
    const data = this.plugin.state.data
      .build()
      .toRoot()
      .apply(MergeStructures, { structures }, { dependsOn });
    const structure = await data.commit();
    const structureProperties =
      await this.plugin.builders.structure.insertStructureProperties(structure);
    this.plugin.behaviors.canvas3d.initialized.subscribe(async (v) => {
      await this.plugin.builders.structure.representation.applyPreset(
        structureProperties || structure,
        StructurePreset
      );
    });
    // console.log("structures",structures);
  }
}

type MergeStructures = typeof MergeStructures;
const MergeStructures = PluginStateTransform.BuiltIn({
  name: "merge-structures",
  display: { name: "Merge Structures", description: "Merge Structure" },
  from: PSO.Root,
  to: PSO.Molecule.Structure,
  params: {
    structures: PD.ObjectList(
      {
        ref: PD.Text(""),
      },
      ({ ref }) => ref,
      { isHidden: true }
    ),
  },
})({
  apply({ params, dependencies }) {
    return Task.create("Merge Structures", async (ctx) => {
      if (params.structures.length === 0) return StateObject.Null;

      const first = dependencies![params.structures[0].ref].data as Structure;
      const builder = Structure.Builder({ masterModel: first.models[0] });
      for (const { ref } of params.structures) {
        const s = dependencies![ref].data as Structure;
        for (const unit of s.units) {
          // TODO invariantId
          builder.addUnit(
            unit.kind,
            unit.model,
            unit.conformation.operator,
            unit.elements,
            unit.traits
          );
        }
      }

      const structure = builder.getStructure();
      return new PSO.Molecule.Structure(structure, {
        label: "Merged Structure",
      });
    });
  },
});

export const ViewerAutoPreset = StructureRepresentationPresetProvider({
  id: "preset-structure-representation-viewer-auto",
  display: {
    name: "Automatic (w/ Annotation)",
    group: "Annotation",
    description:
      "Show standard automatic representation but colored by quality assessment (if available in the model).",
  },
  isApplicable(a) {
    return (
      !!a.data.models.some((m) => QualityAssessment.isApplicable(m, "pLDDT")) ||
      !!a.data.models.some((m) => QualityAssessment.isApplicable(m, "qmean"))
    );
  },
  params: () => StructureRepresentationPresetProvider.CommonParams,
  async apply(ref, params, plugin) {
    const structureCell = StateObjectRef.resolveAndCheck(
      plugin.state.data,
      ref
    );
    const structure = structureCell?.obj?.data;
    if (!structureCell || !structure) return {};

    if (
      !!structure.models.some((m) => QualityAssessment.isApplicable(m, "pLDDT"))
    ) {
      return await QualityAssessmentPLDDTPreset.apply(ref, params, plugin);
    } else if (
      !!structure.models.some((m) => QualityAssessment.isApplicable(m, "qmean"))
    ) {
      return await QualityAssessmentQmeanPreset.apply(ref, params, plugin);
    } else {
      return await PresetStructureRepresentations.auto.apply(
        ref,
        params,
        plugin
      );
    }
  },
});

export default Viewer;

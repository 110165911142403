import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DataGridPro,
  GridColDef,
  useGridApiRef,
  GridFilterOperator,
  GridFilterItem,
} from "@mui/x-data-grid-pro";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  gridColumnPositionsSelector,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import {
  Box,
  Card,
  Grid,
  Tab,
  Autocomplete,
  TextField,
  MenuItem,
  Chip,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import TabContext from "@mui/lab/TabContext";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import TabList from "@mui/lab/TabList";
import { TabPanel } from "../../components/common/TabPanel";
import { useNavigate } from "react-router-dom";
import { setSingleSmileData } from "./rankerSlice";
import { getPlots } from "./rankerApi";

const CustomToolbar = (props: any) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        printOptions={{
          disableToolbarButton: true,
        }}
      />
    </GridToolbarContainer>
  );
};

const safeFormatNumber = (value: any) => {
  const num = parseFloat(value);
  if (isNaN(num)) return 0;
  return Number.isInteger(num) ? num : Math.round(num * 1000) / 1000;
};

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "SMILES",
    headerName: "SMILES",
    editable: false,
    headerAlign: "center",
    align: "center",
    width: 280,
  },
  {
    field: "svg",
    headerName: "2D Representation",
    description: "This column has images and is not sortable.",
    sortable: false,
    filterable: false,
    disableExport: true,
    width: 140,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <img
        src={`data:image/svg+xml;base64,${btoa(params.value)}`}
        alt="2D svg representation"
        style={{
          height: "90%",
          width: "95%",
          transition: "transform 0.3s ease-in-out",
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.transform = "scale(110%)";
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.transform = "scale(110%)";
        }}
      />
    ),
  },
  {
    field: "MolLogP",
    type: "number",
    headerName: "MolLogP",
    editable: false,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "MolWt",
    headerName: "MolWt",
    type: "number",
    editable: false,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "NumRotatableBonds",
    headerName: "NumRotatableBonds",
    type: "number",
    editable: false,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "HBondAcceptors",
    headerName: "HBondAcceptors",
    type: "number",
    editable: false,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "HBondDonors",
    headerName: "HBondDonors",
    type: "number",
    editable: false,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "TPSA",
    headerName: "TPSA",
    type: "number",
    editable: false,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "HeavyAtoms",
    headerName: "HeavyAtoms",
    type: "number",
    editable: false,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "NumAromaticRings",
    headerName: "NumAromaticRings",
    type: "number",
    editable: false,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "QED",
    headerName: "QED",
    type: "number",
    editable: false,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "SA",
    headerName: "SA Score",
    type: "number",
    editable: false,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "Docking_Score",
    headerName: "Docking Score",
    type: "number",
    editable: false,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
];

const statsCols: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "rank",
    headerName: "Rank",
    headerAlign: "center",
    align: "center",
    width: 100,
    editable: false,
  },
  {
    field: "num_molecules",
    headerName: "Number of Molecules",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 180,
    editable: false,
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "Docking_Score",
    headerName: "Average Docking Score",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 150,
    editable: false,
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "QED",
    headerName: "Average QED",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 100,
    editable: false,
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "SA",
    headerName: "Average SA Score",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 120,
    editable: false,
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "MolWt",
    headerName: "Average MolWt",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 100,
    editable: false,
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "MolLogP",
    headerName: "Average MolLogP",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 120,
    editable: false,
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "HBondDonors",
    headerName: "Average Number of H-Bond Donors",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 140,
    editable: false,
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "HBondAcceptors",
    headerName: "Average Number of H-Bond Acceptors",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 160,
    editable: false,
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "TPSA",
    headerName: "Average TPSA",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 100,
    editable: false,
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "NumRotatableBonds",
    headerName: "Average Number of Rotatable Bonds",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 180,
    editable: false,
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "NumAromaticRings",
    headerName: "Average Number of Aromatic Rings",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 160,
    editable: false,
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
  {
    field: "HeavyAtoms",
    headerName: "Average Number of Heavy Atoms",
    type: "number",
    headerAlign: "center",
    align: "center",
    width: 140,
    editable: false,
    valueFormatter: (params) => safeFormatNumber(params.value),
  },
];

function RankerVisualize() {
  let currTab = localStorage.getItem("rankerCurrTab");
  const [tabValue, setTabValue] = useState(currTab || "3");
  const [allCols, setAllCols] = useState<any[]>([]);
  const [rankARows, setRankARows] = useState<any[]>([]);
  const [rankAstRows, setRankAstRows] = useState<any[]>([]);
  const [rankBRows, setRankBRows] = useState<any[]>([]);
  const [rankCRows, setRankCRows] = useState<any[]>([]);
  const [rankDRows, setRankDRows] = useState<any[]>([]);
  const [statsRows, setStatsRows] = useState<any[]>([]);
  const [selectedInteractions, setSelectedInteractions] = useState<string[]>(
    []
  );
  const [rankBMolsSet, setRankBMolsSet] = useState<any[]>([]);
  const [filteredInteractions, setFilteredInteractions] = useState<string[]>(
    []
  );
  const [plots, setPlots] = useState([]);
  const [plotLoader, setPlotLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");


  const dispatch = useDispatch();

  const handleInteractionsData = (
    params: GridRenderCellParams<any, string[]>
  ) => {
    const sortedInteractions = params.value.sort((a, b) => {
      const isSelectedA = selectedInteractions.includes(a);
      const isSelectedB = selectedInteractions.includes(b);

      // Sort selected items first, then alphabetically
      if (isSelectedA === isSelectedB) {
        return a.localeCompare(b); // Sort alphabetically if both are selected or non-selected
      }
      return isSelectedA ? -1 : 1; // Selected items come first
    });
    return (
      <Box display={"flex"} flexWrap={"wrap"}>
        {sortedInteractions.map((interaction) => {
          const isSelected = selectedInteractions.includes(interaction);
          const isFiltered = filteredInteractions.includes(interaction);

          return (
            <Chip
              key={interaction}
              label={interaction}
              sx={{
                m: 0.5,
                color: isSelected ? "white" : isFiltered ? "white" : "none",
                backgroundColor: isSelected
                  ? "#4BB543"
                  : isFiltered
                  ? "#7A5BC5"
                  : "none",
              }}
            />
          );
        })}
      </Box>
    );
  };

  const plotHeadings : any = {
    Docking_Score: "Docking Score",
    QED: "Quantitative Estimate of Drug-likeness (QED)",
    SA: "Synthetic Accessibility (SA)",
    MolLogP: "Molecular LogP",
    MolWt: "Molecular Weight",
    NumRotatableBonds: "Number of Rotatable Bonds",
    HBONDAcceptors: "Number of H-Bond Acceptors",
    HBONDDonors: "Number of H-Bond Donors",
    TPSA: "Topological Polar Surface Area (TPSA)",
    HeavyAtoms: "Number of Heavy Atoms",
    NumAromaticRings: "Number of Aromatic Rings"
};

  const navigate = useNavigate();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    localStorage.setItem("rankerCurrTab", newValue);
  };

  let table = useGridApiRef();

  const rankerProperties = useSelector((state: any) => {
    const data = state.rankerProperty;
    return data;
  });

  useEffect(() => {
    if (rankerProperties.properties?.moleculeData) {
      const moleculeData = { ...rankerProperties.properties.moleculeData };
      delete moleculeData["A"];
      delete moleculeData["A*"];

      ["B", "C", "D"].forEach((key) => {
        if (moleculeData[key]) {
          moleculeData[key] = moleculeData[key].map(
            (item: any, index: number) => ({
              ...item,
              id: index + 1,
              ...(key === "B" && {
                concatenated_interactions: [
                  ...new Set(item.concatenated_interactions),
                ],
              }),
            })
          );
        }
      });
      const updateRankData = (data: any) => {
        setRankBMolsSet(data["B"]);
        setRankBRows(data["B"]);
        setRankCRows(data["C"]);
        setRankDRows(data["D"]);
      };

      updateRankData(moleculeData);

      setAllCols(columns);
      setStatsRows(rankerProperties.properties.statsSummary);
      const savedInteractions = localStorage.getItem("selectedInteractions");
      if (savedInteractions) {
        setSelectedInteractions(JSON.parse(savedInteractions) || []);
      }
    } else if (
      (rankerProperties.properties == null ||
        rankerProperties.properties.moleculeData == null) &&
      !rankerProperties.loading
    ) {
      navigate("/genie/ranker");
    }
  }, [rankerProperties]);

  const customArrayFilterOperator: GridFilterOperator = {
    label: "contains",
    value: "contains",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      // If no value or if the value is not an array, no filter is applied
      if (
        !filterItem.value ||
        !Array.isArray(filterItem.value) ||
        filterItem.value.length === 0
      ) {
        return null; // Don't apply filter if no value selected
      }

      return ({ value }) => {
        // 'value' refers to the array in the current cell data (concatInteractions array)
        if (!value || !Array.isArray(value)) {
          return false;
        }

        // Check if all the selected values from Autocomplete are in the cell's array
        return filterItem.value.every((selected: any) =>
          value.includes(selected)
        );
      };
    },
    InputComponent: ({ item, applyValue }) => {
      const [selectedValues, setSelectedValues] = useState(item.value || []);
      const handleFilterChange = (event: any, newValue: any) => {
        setSelectedValues(newValue); // Update the selected options
        applyValue({ ...item, value: newValue });
        // console.log({ item, applyValue, newValue });
      };

      return (
        <Box width={700}>
          <Autocomplete
            sx={{ mt: 2 }}
            multiple
            options={rankerProperties.properties.interactionsSet} // Custom string array for options
            value={selectedValues}
            freeSolo
            onChange={handleFilterChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Choose Interactions"
              />
            )}
          />
        </Box>
      );
    },
  };

  const getRankings = () => {
    const updateGroupStats = (group: any, mol: any) => {
      group.num_molecules++;
      group.Docking_Score += mol.Docking_Score || 0;
      group.QED += mol.QED || 0;
      group.SA += mol.SA || 0;
      group.MolWt += mol.MolWt || 0;
      group.MolLogP += mol.MolLogP || 0;
      group.HBondDonors += mol.HBondDonors || 0;
      group.HBondAcceptors += mol.HBondAcceptors || 0;
      group.TPSA += mol.TPSA || 0;
      group.NumRotatableBonds += mol.NumRotatableBonds || 0;
      group.NumAromaticRings += mol.NumAromaticRings || 0;
      group.HeavyAtoms += mol.HeavyAtoms || 0;
    };

    const calculateAverages = (group: any) => {
      if (group.num_molecules === 0) return;

      group.Docking_Score = (group.Docking_Score / group.num_molecules).toFixed(
        3
      );
      group.QED = (group.QED / group.num_molecules).toFixed(3);
      group.SA = (group.SA / group.num_molecules).toFixed(3);
      group.MolWt = (group.MolWt / group.num_molecules).toFixed(3);
      group.MolLogP = (group.MolLogP / group.num_molecules).toFixed(3);
      group.HBondDonors = (group.HBondDonors / group.num_molecules).toFixed(3);
      group.HBondAcceptors = (
        group.HBondAcceptors / group.num_molecules
      ).toFixed(3);
      group.TPSA = (group.TPSA / group.num_molecules).toFixed(3);
      group.NumRotatableBonds = (
        group.NumRotatableBonds / group.num_molecules
      ).toFixed(3);
      group.NumAromaticRings = (
        group.NumAromaticRings / group.num_molecules
      ).toFixed(3);
      group.HeavyAtoms = (group.HeavyAtoms / group.num_molecules).toFixed(3);
    };

    let MolSet = rankBMolsSet;

    if (MolSet.length == 0) return;

    const rankAstMols = [];
    const rankAMols = [];
    const rankBMols = [];

    const createDefaultStats = () => ({
      num_molecules: 0,
      Docking_Score: 0,
      QED: 0,
      SA: 0,
      MolWt: 0,
      MolLogP: 0,
      HBondDonors: 0,
      HBondAcceptors: 0,
      TPSA: 0,
      NumRotatableBonds: 0,
      NumAromaticRings: 0,
      HeavyAtoms: 0,
    });

    const stats: any = {
      "A*": createDefaultStats(),
      A: createDefaultStats(),
      B: createDefaultStats(),
    };

    const indexArr = [1, 1, 1];

    const selectedInteractionsSet = new Set(selectedInteractions);
    if (selectedInteractionsSet.size === 0) {
      setRankAstRows([]);
      setRankARows([]);
      setRankBRows(rankBMolsSet);
      setStatsRows((prevStatsRows) =>
        prevStatsRows.map((row) =>
          row.rank === "A*" || row.rank === "A"
            ? {
                ...row,
                num_molecules: 0,
                Docking_Score: 0,
                QED: 0,
                SA: 0,
                MolWt: 0,
                MolLogP: 0,
                HBondDonors: 0,
                HBondAcceptors: 0,
                TPSA: 0,
                NumRotatableBonds: 0,
                NumAromaticRings: 0,
                HeavyAtoms: 0,
              }
            : row
        )
      );
      return;
    }

    const getCommonInteractionCnt = (molInteractions: Array<string>) => {
      let count = 0;
      for (const molInteraction of molInteractions) {
        if (selectedInteractionsSet.has(molInteraction)) {
          count++;
        }
      }
      return count;
    };

    for (const mol of MolSet) {
      const noOfCommonInterations = getCommonInteractionCnt(
        mol.concatenated_interactions
      );
      const userInteractionCnt = selectedInteractions.length;

      if (noOfCommonInterations === userInteractionCnt) {
        rankAstMols.push({ ...mol, id: indexArr[0] });
        updateGroupStats(stats["A*"], mol);
        indexArr[0]++;
      } else if (noOfCommonInterations === 0) {
        rankBMols.push({ ...mol, id: indexArr[1] });
        updateGroupStats(stats["B"], mol);
        indexArr[1]++;
      } else {
        rankAMols.push({ ...mol, id: indexArr[2] });
        updateGroupStats(stats["A"], mol);
        indexArr[2]++;
      }
    }

    setRankAstRows(rankAstMols);
    setRankARows(rankAMols);
    setRankBRows(rankBMols);

    calculateAverages(stats["A*"]);
    calculateAverages(stats["A"]);
    calculateAverages(stats["B"]);

    const ranks = ["A*", "A", "B"];
    setStatsRows((prevStatsRows) =>
      prevStatsRows.map((row) =>
        ranks.includes(row.rank)
          ? {
              ...row,
              ...stats[row.rank],
            }
          : row
      )
    );
  };

  const IntCols: GridColDef[] = [
    ...columns,
    {
      field: "concatenated_interactions",
      headerName: "Concatenated Interactions",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: handleInteractionsData,
      filterOperators: [customArrayFilterOperator],
      width: 1150,
    },
  ];

  useEffect(() => {
    localStorage.setItem(
      "selectedInteractions",
      JSON.stringify(selectedInteractions)
    );
    getRankings();
  }, [selectedInteractions]);

  useEffect(() => {
    setPlotLoader(true);
    const fetchPlots = async () => {
      try {
        let rowData = null;
        switch (tabValue) {
          case "1": {
            rowData = rankAstRows;
            break;
          }
          case "2": {
            rowData = rankARows;
            break;
          }
          case "3": {
            rowData = rankBRows;
            break;
          }
          case "4": {
            rowData = rankCRows;
            break;
          }
          case "5": {
            rowData = rankDRows;
            break;
          }
          default: {
            return;
          }
        }

        if (rowData.length === 0) {
          setPlots([]);
          setPlotLoader(false);
          return;
        }
        rowData = rowData.map((row) => {
          return {
            SMILES: row.SMILES,
            Docking_Score: row.Docking_Score,
            QED: row.QED,
            SA: row.SA,
            MolLogP: row.MolLogP,
            MolWt: row.MolWt,
            NumRotatableBonds: row.NumRotatableBonds,
            HBONDAcceptors: row.HBondAcceptors,
            HBONDDonors: row.HBondDonors,
            TPSA: row.TPSA,
            HeavyAtoms: row.HeavyAtoms,
            NumAromaticRings: row.NumAromaticRings,
          }
        });
        const response = await getPlots({ requestBody: rowData });
        await setPlots(response.data);
      } catch (error) {
        setShowError(true);
        setErrorMsg("Error Constructing Plots.")
      }
      finally {
        setPlotLoader(false);
      }
    };

    fetchPlots();
  }, [tabValue, rankBRows]);


  const handleRowClick = (params: any) => {
    dispatch(setSingleSmileData(params.row));
    navigate("/genie/ranker/evaluations");
  };

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Grid container spacing={2} p={2} height={"95%"}>
        <Grid item container px={0} pt={3} height={"100%"}>
          <Grid item width={"100%"} sx={{ overflowY: "auto" }} height={"100%"}>
            <Autocomplete
              sx={{ width: "95%", m: 3, ml: 0 }}
              multiple
              options={rankerProperties?.properties?.interactionsSet || []}
              getOptionLabel={(option: string) => option}
              value={selectedInteractions}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                // TODO: ensure only on event when elm ios poped or added
                setSelectedInteractions(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Interations"
                  placeholder="Select Interations"
                />
              )}
              renderOption={(props, option, { selected }) => (
                <MenuItem
                  {...props}
                  key={option}
                  value={option}
                  sx={{ justifyContent: "space-between" }}
                >
                  {option}
                  {selected ? <CheckIcon color="info" /> : null}
                </MenuItem>
              )}
            />

            <Card sx={{ position: "relative" }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="filter tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Rank A*" value="1" />
                    <Tab label="Rank A" value="2" />
                    <Tab label="Rank B" value="3" />
                    <Tab label="Rank C" value="4" />
                    <Tab label="Rank D" value="5" />
                    <Tab label="Stats Summary" value="6" />
                    <Tab label="Grading System" value="7" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Box sx={{ width: "100%", height: "700px" }}>
                    <DataGridPro
                      apiRef={table}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          fontWeight: "550", // Force font weight to bold
                          fontSize: "0.9rem",
                        },
                        "& .MuiDataGrid-columnHeader": {
                          // Forced to use important since overriding inline styles
                          height: "unset !important",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          // Forced to use important since overriding inline styles
                          maxHeight: "175px !important",
                          textAlign: "center",
                          fontWeight: "800 !important",
                        },
                      }}
                      rows={rankAstRows}
                      columns={IntCols}
                      rowHeight={100}
                      pagination
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      onRowClick={handleRowClick}
                      slotProps={{
                        filterPanel: {
                          sx: {
                            width: 1100, // Adjust the filter panel width as needed
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 20]}
                      density={"standard"}
                      getRowHeight={() => 'auto'}
                      slots={{
                        toolbar: (props) => <CustomToolbar {...props} />,
                      }}
                    />
                  </Box>
                  {plots.length !== 0 && <Grid container>
                    <Box sx={{ width: "100%", height: "100%", p: 4 }}>
                      <Typography variant="h3" align="center" sx={{ mb: 4 }}>
                        Molecular Properties Analysis
                      </Typography>
                      <Grid container >
                        {plots.map((it: any, idx: number) => (
                          <Grid item xs={6} key={idx}>
                            <Typography variant="h5" ml={10} mt={5}>
                              {plotHeadings[it.property]}
                              <Typography variant="body1" color="textSecondary" align="left" sx={{ mb: 2 }}>
                                Average: {parseFloat(it.avg_value).toFixed(2)}
                              </Typography>
                            </Typography>
                            <Box
                              component="img"
                              src={`data:image/svg+xml;base64,${it.plot}`} 
                              sx={{ maxWidth: "100%", height: "auto", mx: "auto" }}
                              alt="Molecular Property Plot"
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <IndefiniteLoader state={plotLoader} onlyForDiv={true}/>
                  </Grid>}
                </TabPanel>
                <TabPanel value="2">
                  <Box sx={{ width: "100%", height: "700px" }}>
                    <DataGridPro
                      apiRef={table}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          fontWeight: "550", // Force font weight to bold
                          fontSize: "0.9rem",
                        },
                        "& .MuiDataGrid-columnHeader": {
                          // Forced to use important since overriding inline styles
                          height: "unset !important",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          // Forced to use important since overriding inline styles
                          maxHeight: "175px !important",
                          textAlign: "center",
                          fontWeight: "800 !important",
                        },
                      }}
                      rows={rankARows}
                      columns={IntCols}
                      onRowClick={handleRowClick}
                      rowHeight={100}
                      pagination
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 20]}
                      density={"standard"}
                      getRowHeight={() => 'auto'}
                      slotProps={{
                        filterPanel: {
                          sx: {
                            width: 1100, // Adjust the filter panel width as needed
                          },
                        },
                      }}
                      slots={{
                        toolbar: (props) => <CustomToolbar {...props} />,
                      }}
                    />
                  </Box>
                  {plots.length !== 0 && <Grid container>
                    <Box sx={{ width: "100%", height: "100%", p: 4 }}>
                      <Typography variant="h3" align="center" sx={{ mb: 4 }}>
                        Molecular Properties Analysis
                      </Typography>
                      <Grid container >
                        {plots.map((it: any, idx: number) => (
                          <Grid item xs={6} key={idx}>
                            <Typography variant="h5" ml={10} mt={5}>
                              {plotHeadings[it.property]}
                              <Typography variant="body1" color="textSecondary" align="left" sx={{ mb: 2 }}>
                                Average: {parseFloat(it.avg_value).toFixed(2)}
                              </Typography>
                            </Typography>
                            <Box
                              component="img"
                              src={`data:image/svg+xml;base64,${it.plot}`} 
                              sx={{ maxWidth: "100%", height: "auto", mx: "auto" }}
                              alt="Molecular Property Plot"
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <IndefiniteLoader state={plotLoader} onlyForDiv={true}/>
                  </Grid>}
                </TabPanel>
                <TabPanel value="3">
                  <Box sx={{ width: "100%", height: "700px" }}>
                    <DataGridPro
                      apiRef={table}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          fontWeight: "550", // Force font weight to bold
                          fontSize: "0.9rem",
                        },
                        "& .MuiDataGrid-columnHeader": {
                          // Forced to use important since overriding inline styles
                          height: "unset !important",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          // Forced to use important since overriding inline styles
                          maxHeight: "175px !important",
                          textAlign: "center",
                          fontWeight: "800 !important",
                        },
                      }}
                      onRowClick={handleRowClick}
                      rows={rankBRows}
                      columns={IntCols}
                      rowHeight={100}
                      pagination
                      slotProps={{
                        filterPanel: {
                          sx: {
                            width: 1100, // Adjust the filter panel width as needed
                          },
                        },
                      }}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 20]}
                      density={"standard"}
                      getRowHeight={() => 'auto'}
                      slots={{
                        toolbar: (props) => <CustomToolbar {...props} />,
                      }}
                    />
                  </Box>
                  {plots.length !== 0 && <Grid container>
                    <Box sx={{ width: "100%", height: "100%", p: 4 }}>
                      <Typography variant="h3" align="center" sx={{ mb: 4 }}>
                        Molecular Properties Analysis
                      </Typography>
                      <Grid container >
                        {plots.map((it: any, idx: number) => (
                          <Grid item xs={6} key={idx}>
                            <Typography variant="h5" ml={10} mt={5}>
                              {plotHeadings[it.property]}
                              <Typography variant="body1" color="textSecondary" align="left" sx={{ mb: 2 }}>
                                Average: {parseFloat(it.avg_value).toFixed(2)}
                              </Typography>
                            </Typography>
                            <Box
                              component="img"
                              src={`data:image/svg+xml;base64,${it.plot}`} 
                              sx={{ maxWidth: "100%", height: "auto", mx: "auto" }}
                              alt="Molecular Property Plot"
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <IndefiniteLoader state={plotLoader} onlyForDiv={true}/>
                  </Grid>}
                </TabPanel>
                <TabPanel value="4">
                  <Box sx={{ width: "100%", height: "700px" }}>
                    <DataGridPro
                      apiRef={table}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          fontWeight: "550", // Force font weight to bold
                          fontSize: "0.9rem",
                        },
                        "& .MuiDataGrid-columnHeader": {
                          // Forced to use important since overriding inline styles
                          height: "unset !important",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          // Forced to use important since overriding inline styles
                          maxHeight: "175px !important",
                          textAlign: "center",
                          fontWeight: "800 !important",
                        },
                      }}
                      rows={rankCRows}
                      columns={allCols}
                      onRowClick={handleRowClick}
                      rowHeight={100}
                      pagination
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 20]}
                      // autoHeight
                      slots={{
                        toolbar: (props) => <CustomToolbar {...props} />,
                      }}
                    />
                  </Box>
                  {plots.length !== 0 && <Grid container>
                    <Box sx={{ width: "100%", height: "100%", p: 4 }}>
                      <Typography variant="h3" align="center" sx={{ mb: 4 }}>
                        Molecular Properties Analysis
                      </Typography>
                      <Grid container >
                        {plots.map((it: any, idx: number) => (
                          <Grid item xs={6} key={idx}>
                            <Typography variant="h5" ml={10} mt={5}>
                              {plotHeadings[it.property]}
                              <Typography variant="body1" color="textSecondary" align="left" sx={{ mb: 2 }}>
                                Average: {parseFloat(it.avg_value).toFixed(2)}
                              </Typography>
                            </Typography>
                            <Box
                              component="img"
                              src={`data:image/svg+xml;base64,${it.plot}`} 
                              sx={{ maxWidth: "100%", height: "auto", mx: "auto" }}
                              alt="Molecular Property Plot"
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <IndefiniteLoader state={plotLoader} onlyForDiv={true}/>
                  </Grid>}
                </TabPanel>
                <TabPanel value="5">
                  <Box sx={{ width: "100%", height: "700px" }}>
                    <DataGridPro
                      apiRef={table}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          fontWeight: "550", // Force font weight to bold
                          fontSize: "0.9rem",
                        },
                        "& .MuiDataGrid-columnHeader": {
                          // Forced to use important since overriding inline styles
                          height: "unset !important",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          // Forced to use important since overriding inline styles
                          maxHeight: "175px !important",
                          textAlign: "center",
                          fontWeight: "800 !important",
                        },
                      }}
                      rows={rankDRows}
                      columns={allCols}
                      rowHeight={100}
                      onRowClick={handleRowClick}
                      pagination
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 20]}
                      slots={{
                        toolbar: (props) => <CustomToolbar {...props} />,
                      }}
                    />
                  </Box>
                  {plots.length !== 0 && <Grid container>
                    <Box sx={{ width: "100%", height: "100%", p: 4 }}>
                      <Typography variant="h3" align="center" sx={{ mb: 4 }}>
                        Molecular Properties Analysis
                      </Typography>
                      <Grid container >
                        {plots.map((it: any, idx: number) => (
                          <Grid item xs={6} key={idx}>
                            <Typography variant="h5" ml={10} mt={5}>
                              {plotHeadings[it.property]}
                              <Typography variant="body1" color="textSecondary" align="left" sx={{ mb: 2 }}>
                                Average: {parseFloat(it.avg_value).toFixed(2)}
                              </Typography>
                            </Typography>
                            <Box
                              component="img"
                              src={`data:image/svg+xml;base64,${it.plot}`} 
                              sx={{ maxWidth: "100%", height: "auto", mx: "auto" }}
                              alt="Molecular Property Plot"
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <IndefiniteLoader state={plotLoader} onlyForDiv={true}/>
                  </Grid>}
                </TabPanel>
                <TabPanel value="6">
                  <Box sx={{ width: "100%", height: "700px" }}>
                    <DataGridPro
                      apiRef={table}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          fontWeight: "550", // Force font weight to bold
                          fontSize: "0.9rem",
                        },
                        "& .MuiDataGrid-columnHeader": {
                          // Forced to use important since overriding inline styles
                          height: "unset !important",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          // Forced to use important since overriding inline styles
                          maxHeight: "175px !important",
                          textAlign: "center",
                          fontWeight: "800 !important",
                        },
                      }}
                      rows={statsRows}
                      columns={statsCols}
                      rowHeight={100}
                      pagination
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      disableRowSelectionOnClick
                      pageSizeOptions={[5, 10, 20]}
                      // autoHeight
                      slots={{
                        toolbar: (props) => <CustomToolbar {...props} />,
                      }}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value="7">
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/images/grading-system.png"
                      alt="grading system"
                      style={{ maxWidth: "75%", maxHeight: "75%" }}
                    />
                  </Box>
                </TabPanel>
              </TabContext>
            </Card>
          </Grid>
        </Grid>
        <IndefiniteLoader state={rankerProperties.loading} />
      </Grid>
    </>
  );
}

export default RankerVisualize;

import httpCommon from "../../net/http-common";
import { REINVENT_SERVER_URL } from "../../config";

export const getSingleSmileData = async ({
  smileName
}: {
  smileName: string;
}) => {
  return await httpCommon.post(
    `${REINVENT_SERVER_URL}/submit_generation/`,
    {
      smiles: smileName,
      property: "default"
    },
    {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      }
    }
  );
};
import httpCommon from "../../net/http-common";
import { ADMET_REST_URL } from "../../config";


export const getSingleSmileData = ({
    smileName,
  }: {
    smileName: string;
  }) => {
    //console.log("getpropertiesv2 props");
    return httpCommon.post(`${ADMET_REST_URL}/predict_smiles_array/`, [smileName], {
        headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
    });
  };
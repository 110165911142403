export const errorMessages = {
    serverError: "Something went wrong on the server. Please try again later.",
    clientError: "Something went wrong. Please check input and try again.",
    genericError: "Something went wrong. Please try again later.",
    connectionError: "Error connecting to the server. Please try again later.",
    requestError: "Error setting up request. Please try again later.",
    jobTableError : "Something went wrong. Error loading jobs table",
    submitJobError : "Error submitting job. Please try again later",
    submitInputError : "Error submitting file/data. Please try again later",
    pdbImportError : "Error Loading PDB data. Please try again later",
    exampleTableError: "Error Loading Examples Table. Please try again later"
  };
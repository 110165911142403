import React, { useEffect, useRef } from "react";

import { Viewer, TabPanel } from "../../components";
import http from "../../net/http-common";
import "../../css/pdbinfo.css";
import { RepresentationStyleHolder } from "../../utils/helpers";
import { useState } from "react";
import Grid from "@mui/material/Grid";

import { Alert, Snackbar, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Box } from "@mui/material";
import {
  AltResidue,
  Errat,
  ESP,
  HoleAnalysis,
  PDBClusterTable,
  PDBInfoTable,
  ProteinInfoCard,
  Ramachandran,
  SecStructure,
} from "../../components";
import { ClusterRow } from "../../components/PDB/PDBClusterTable";
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { errorMessages } from "../../common_variables/ErrorMsgs";

function PDBInfoDetails() {
  const state = useLocation();

  //console.log("state", state);
  const [pdbID, setPDBID] = React.useState(state.state.pdbID);
  const [assemblyID, setassemblyID] = useState(state.state.assembly);
  const [jobDir, setJobDir] = React.useState("");

  const [pdbInfo, setPDBInfo] = React.useState({});
  const [pdbInfoVisible, setPDBInfoVisible] = React.useState(false);
  const [annotatorVisible, setAnnotatorfoVisible] = React.useState(false);
  const [clusterData, setClusterData] = React.useState<ClusterRow[]>([]);
  const [clusterVisible, setClusterVisible] = React.useState(false);
  const [hetStyle, setHetStyle] = React.useState("spacefill");
  const [chainStyle, setChainStyle] = React.useState("chain-id");
  const [tabValue, setTabValue] = useState("1");
  const [erratVisible, setErratVisible] = React.useState(false);
  const [altResVisible, setAltResVisible] = React.useState(false);
  const [espVisible, setEspVisible] = React.useState(false);
  const [ssVisible, setSSVisible] = React.useState(false);
  const [holeVisible, setHoleVisible] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  let viewer = useRef<Viewer>();
  // const pdbID = '5dk3';

  const runJobs = async () => {
    setErrorMsg(null);
    setShowError(false);
    await http
      .post("/pdb/cluster", {
        pdb_id: pdbID,
      })
      .then((response: any) => {
        //console.log(response);
        if (response.data.Status === "Failed") {
          setShowError(true);
          // setErrorMsg(response.data.Response);
          setErrorMsg("Error loading cluster data. Please try again later");
          return;
        }
        setClusterVisible(true);
        setClusterData(response.data);
      })
      .catch((error) => {
        setShowError(true);
        if (error.response) {
          if (error.response.status >= 500) {
            setErrorMsg(errorMessages.serverError);
          } else if (error.response.status >= 400) {
            setErrorMsg(errorMessages.clientError);
          } else setErrorMsg(errorMessages.genericError);
        } else if (error.request) {
          setErrorMsg(errorMessages.connectionError);
        } else {
          setErrorMsg(errorMessages.requestError);
        }
      });
  };

  useEffect(() => {
    const style = {
      hetGroups: { kind: hetStyle },
    } as RepresentationStyleHolder;
    updateStyle(style);
  }, [hetStyle]);

  useEffect(() => {
    const style = {
      sequence: { coloring: chainStyle },
    } as RepresentationStyleHolder;
    updateStyle(style);
  }, [chainStyle]);

  const updateStyle = async (style: RepresentationStyleHolder) => {
    viewer.current?.updateStyle(style, true);
  };

  useLayoutEffect(() => {
    const initViewer = async () => {
      viewer.current = new Viewer();
      await viewer.current.init("viewer-1", {
        layoutShowControls: true,
        viewportShowExpand: false,
        collapseLeftPanel: true,
        layoutShowSequence: true,
      });

      const url = `https://www.ebi.ac.uk/pdbe/entry-files/download/${pdbID}.bcif`;
      // const url="";
      const format = "cif";
      const isBinary = true;
      const representationStyle = {
        sequence: { coloring: chainStyle }, // or just { }
        hetGroups: { kind: hetStyle },
        water: { hide: true },
        snfg3d: { hide: false },
      } as RepresentationStyleHolder;
      viewer.current.setBackground(0xffffff);
      await viewer.current.load({
        url: url,
        format: format,
        isBinary: isBinary,
        assemblyId: assemblyID,
        representationStyle: representationStyle,
      });
    };

    initViewer();
  }, []);

  useEffect(() => {
    const handleSubmit = async (): Promise<void> => {
      setErrorMsg(null);
      setShowError(false);
      await http
        .post("/pdb/info", {
          pdb_id: pdbID,
        })
        .then((response: any) => {
          setPDBInfo(response.data.info);
          setJobDir(response.data.job_dir);
          setPDBInfoVisible(true);
          setErratVisible(true);
          runJobs();
        })
        .catch((error) => {
          ////console.log(error);
          setShowError(true);
          //console.log(error)
          if (error.response) {
            if (error.response.status >= 500) {
              setErrorMsg(errorMessages.serverError);
            } else if (error.response.status >= 400) {
              setErrorMsg(errorMessages.clientError);
            } else setErrorMsg(errorMessages.genericError);
          } else if (error.request) {
            setErrorMsg(errorMessages.connectionError);
          } else {
            setErrorMsg(errorMessages.requestError);
          }
        });
    };
    handleSubmit();
  }, []);

  const tabs = [
    {
      label: "Structure Validation",
      value: "1",
      onChange() {
        setErratVisible(true);
      },
    },
    {
      label: "Structure Information",
      value: "2",
      onChange() {
        setSSVisible(true);
        setAltResVisible(true);
      },
    },
    {
      label: "Cluster Information",
      value: "3",
      onChange() {
        // setClusterVisible(true);
        setAnnotatorfoVisible(true);
      },
    },
    {
      label: "Tunnel Analysis",
      value: "4",
      onChange() {
        setHoleVisible(true);
      },
    },
    {
      label: "Miscellaneous",
      value: "5",
      onChange() {
        setEspVisible(true);
      },
    },
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    const foundTab = tabs.find((tab) => tab.value === newValue);
    foundTab.onChange();
  };

  return (
    <div>
      <Snackbar
        open={showError}
        autoHideDuration={10000}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      {/* Form */}
      <Grid container direction="column" sx={{ backgroundColor: "white" }}>
        <Grid container spacing={2} className="viewer-container">
          <Grid item xs={8} md={7}>
            <div className="viewer" id="viewer-1"></div>
          </Grid>
          <Grid item xs={4} md={5} className="info-table">
            {pdbInfoVisible && pdbInfo && (
              <PDBInfoTable data={pdbInfo}></PDBInfoTable>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Tabs */}
      <Box sx={{ width: "100%", p: 4, background: "white" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
            <TabList
              value={tabValue}
              onChange={handleTabChange}
              aria-label="PDB tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} value={tab.value} />
              ))}
            </TabList>
          </Box>
          {/* Structure Validation */}
          <TabPanel value="1" key={"ramachandran"}>
            <Grid container>
              <Grid container spacing={2} direction="row">
                <Grid item xs={6}>
                  <ProteinInfoCard title={"Ramachandran Plot"}>
                    <Ramachandran pdbID={pdbID} jobDir={jobDir}></Ramachandran>
                  </ProteinInfoCard>
                </Grid>
                <Grid item xs={6}>
                  <ProteinInfoCard title={"Errat"}>
                    <Errat pdbId={pdbID} jobDir={jobDir}></Errat>
                  </ProteinInfoCard>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          {/* Structure Information */}
          <TabPanel value="2" key={"structure information"}>
            <Grid container direction="row" columnSpacing={2}>
              <Grid item xs={6}>
                {ssVisible && (
                  <ProteinInfoCard title={"Secondary Structure"}>
                    <SecStructure pdbId={pdbID} jobDir={jobDir} />
                  </ProteinInfoCard>
                )}
              </Grid>
              <Grid item xs={6}>
                {altResVisible && (
                  <ProteinInfoCard title={"Alternate Residue"}>
                    <AltResidue pdbId={pdbID} jobDir={jobDir}></AltResidue>
                  </ProteinInfoCard>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          {/* Protein Annotation Cluster */}
          <TabPanel value="3" key={"cluster"}>
            <Grid container direction="row">
              <Grid item md={12}>
                {clusterVisible && (
                  <ProteinInfoCard title={"Related PDBs"}>
                    <PDBClusterTable data={clusterData} pdb_id={pdbID} />
                  </ProteinInfoCard>
                )}
              </Grid>
              <Grid container spacing={3}>
                {
                  // <Grid item md={12}>
                  //   {annotatorVisible && (
                  //     <ProteinInfoCard title={"Protein Annotation"}>
                  //       <MolAnnotator uniprotId={clusterData?.UniprotId} />
                  //     </ProteinInfoCard>
                  //   )}
                  // </Grid>
                }
              </Grid>
            </Grid>
          </TabPanel>
          {/* Tunnel Analysis */}
          <TabPanel value="4" key={"tunnel"}>
            <Grid container spacing={3}>
              <Grid item>
                {holeVisible && (
                  <ProteinInfoCard title={"Hole/Pore Analysis"}>
                    <HoleAnalysis pdbId={pdbID} jobDir={jobDir} />
                  </ProteinInfoCard>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          {/* Miscallaneous */}
          <TabPanel value="5" key={"esp"}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                {espVisible && (
                  <ProteinInfoCard title={"Electostatic Surface Potential"}>
                    <ESP pdbId={pdbID} jobDir={jobDir}></ESP>
                  </ProteinInfoCard>
                )}
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}

export default PDBInfoDetails;

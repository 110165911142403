import httpCommon from "../../net/http-common";
import { JOB_SERVER_URL, SOLAR_DEV } from "../../config";

export const getBioisostericProperties = ({
  task_id
}: {
  task_id: string
}) => {
  return httpCommon.get(`${JOB_SERVER_URL}/bioiso/results`,{
    params: {
      task_id: task_id,
    },
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};


export const getSingleSmileData = async ({
  smileName, properties, moleculeCount, leavingGroup, leavingIdx
}: {
  smileName: string;
  properties: string[];
  moleculeCount: Number;
  leavingGroup: string;
  leavingIdx: Number;
}) => {
  return await httpCommon.post(
    `${SOLAR_DEV}/bioiso_api/generate_single`,
    {
      smiles: smileName,
      properties: properties,
      sample_size: moleculeCount,
      leaving_group: leavingGroup,
      leaving_idx: leavingIdx,
    },
    {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      }
    }
  );
};
import { Stage } from "ngl";
import React, { useEffect, useState } from "react";

import http from "../../net/http-common";
import "../../css/altres.css";
import { SERVER_URL } from "../../config";
import { CircularProgress, Alert, Snackbar } from "@mui/material";

interface AltResProps {
  pdbId: string;
  jobDir: string;
}

function AltResidue({ pdbId, jobDir }: AltResProps) {
  const [loading, setLoading] = useState(true);
  const [inputFile, setInputFile] = useState(null);
  const [altFile, setAltFile] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (!pdbId || !jobDir) return;
    setLoading(true);
    //let stage = new Stage("alt-res-viewer");

    const form = new FormData();
    form.append("pdb_file", "");

    http
      .post(`${SERVER_URL}/pdb/altresidue`, form, {
        params: {
          pdb_id: pdbId,
          job_dir: jobDir,
        },
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        fetch(`${SERVER_URL}/static/pdb-info/${jobDir}/input.pdb`)
          .then((response) => {
            return response.text();
          })
          .then((pdbData) => {
            const pdbBlob = new Blob([pdbData], { type: "chemical/x-pdb" });
            const pdbFile = new File([pdbBlob], "protein_structure1.pdb", {
              type: "chemical/x-pdb",
            });
            setInputFile(pdbFile);
          })
          .catch((error) => {
            setShowError(true);
            setErrorMsg("Error Loading Alternate Residue");
          });

        fetch(
          `${SERVER_URL}/static/pdb-info/${jobDir}/${response.data.altfile}`
        )
          .then((response) => {
            return response.text();
          })
          .then((pdbData) => {
            const pdbBlob = new Blob([pdbData], { type: "chemical/x-pdb" });
            const pdbFile = new File([pdbBlob], "protein_structure2.pdb", {
              type: "chemical/x-pdb",
            });
            setAltFile(pdbFile);
          })
          .catch((error) => {
            setShowError(true);
            setErrorMsg("Error Loading Alternate Residue");
          });
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg("Error Loading Alternate Residue");
      });
  }, [pdbId, jobDir]);

  useEffect(() => {
    if (inputFile && altFile) {
      setLoading(false);
    }
  }, [inputFile, altFile]);

  useEffect(() => {
    if (loading) return;
    //console.log(inputFile, altFile);
    let stage = new Stage("alt-res-viewer");
    stage.loadFile(inputFile as File, {
      defaultRepresentation: true,
      ext: "pdb",
    });

    stage
      .loadFile(altFile as File, { defaultRepresentation: true, ext: "pdb" })
      .then(function (comp) {
        if (!comp) return;
        comp.removeAllRepresentations();
        comp.addRepresentation("licorice", {
          sele: "(sidechain or n. ca)",
        });
      });

    const div = document.getElementById("alt-res-viewer");
    div.style.width = "601px";
    div.style.width = "401px";
    stage.handleResize();
    stage.autoView();
    function handleResize() {
      stage.handleResize();
    }

    window.addEventListener("resize", handleResize);
  }, [loading]);

  return (
    <>
      {" "}
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      {loading ? <CircularProgress /> : <div id="alt-res-viewer"></div>}
    </>
  );
}

export default AltResidue;

import { Typography ,Card} from "@mui/material";

const InteractionHeader = (data: any) => {
  const info = data.data;
  return (
    <div>
      <Card sx={{p:3}}>
      <Typography variant="h5">PDB ID : {info.pdb_id}</Typography>
      {/* <div>
        <Typography variant="h5"> Interaction Sites : </Typography>
        {Object.keys(info.interactions).map((site: string) => (
          <ul>
            <li key={site}> {site}</li>
          </ul>
        ))}
      </div> */}
      </Card>
    </div>
  );
};

export default InteractionHeader;

import httpCommon from "../../net/http-common";
import { JOB_SERVER_URL } from "../../config";

interface RankerPropertiesParams {
  task_id?: string;
  maimol_id?: string;
  uid?: string;
}

export const getRankerProperties = ({ task_id, maimol_id, uid }: RankerPropertiesParams) => {
  const params = {
    ...(task_id && { task_id }),
    ...(maimol_id && { maimol_id }),
    ...(uid && { uid }),
  };

  return httpCommon.get(
    `${JOB_SERVER_URL}/ranker/results`, {
    params,
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getPlots = ({ requestBody }: { requestBody: any }) => {
  try {
    return httpCommon.post(
      `${JOB_SERVER_URL}/ranker/generate_histograms/`,
      requestBody,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      }
    );
  }
  catch (error) {
    console.log("error : ", error);
  }
};

import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormData from "form-data";
import Grid from "@mui/material/Grid";
import { GridColDef } from "@mui/x-data-grid";

import {
  Button,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
  Alert,
  TextField,
  Snackbar,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context";
import JobsTable from "../../components/common/JobsTable";
import {
  FileUploader,
  ModelCardHeading,
  WorkflowLandingTabs,
} from "../../components";

import Loaders from "../../components/common/Loaders";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import BetaModal from "../../components/common/BetaModal";
import { JOB_SERVER_URL, SOLAR_DEV } from "../../config";
import { useDispatch } from "react-redux";
import { RUNTIME } from "../../config";
import { DataGridPro } from "@mui/x-data-grid-pro";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { WorkflowLandingTab } from "../../models";

const columns: GridColDef[] = [
  {
    field: "cavityName",
    headerName: "Cavity Name",
    width: 250,
  },
  {
    field: "score",
    headerName: "Similairty Score",
    width: 150,
  },
];

const tabs: Array<WorkflowLandingTab> = [
  {
    id: "0",
    label: "Description",
    value: (
      <>
        <Typography paragraph>
          This module predicts the potential off-target effects of
          small-molecule drugs. By searching a comprehensive database of the
          full human proteome, it identifies protein cavities that resemble the
          binding cavity of your target protein. It then computes the docking
          scores for the molecule against these top-matching cavities, providing
          invaluable insights into potential off-target interactions.
        </Typography>
        <Box
          component="img"
          src="/images/hero/promiscuity.png"
          alt="Protein Info Visual"
          sx={{
            width: "100%", // Reduces the width to 80% of the parent container
            height: "auto",
            mt: 5, // Adds margin-top for space above the image
            mb: 2, // Adds margin-bottom for space below the image
            mx: "auto", // Centers the image horizontally
            display: "block", // Ensures the Box behaves like a block element
            p: 2, // Adds padding around the image inside the Box
          }}
        />
      </>
    ),
  },

  {
    id: "1",
    label: "References",
    value: (
      <>
        <Typography variant={"body1"} mb={1}>
          1. Predicting Polypharmacology by Binding Site Similarity: From Kinases to the Protein Universe - J. Chem. Inf. Model. 2010, 50, 1418–1431
        </Typography>
        
      </>
    ),
  },
];

function PromiscuitySubmitJobs() {
  const MODEL_NAME = "promiscuity";

  const [inProgress, setInProgress] = useState(false);
  // const [jobstatusVisible, setJobstatusVisible] = useState(false);
  const [doLoadJobs, setDoLoadJobs] = useState(true);
  const [jobID, setJobID] = useState("");
  const [PDBFile, setPDBFile] = useState<File>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const state = useLocation();
  const [showSubmitMsg, SetShowSubmitMsg] = useState(false);
  const [rows, setRows] = useState<[]>();
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const [betaOpen, setBetaOpen] = useState(false);

  const [fetchingFields, setfetchingFields] = useState(false);
  const [residues, setResidues] = useState<string[]>([]);
  const [residue, setResidue] = useState("");
  const [ligandSmile, setLigandSmile] = useState<string>("");
  const [pmsCutoff, setPmsCutoff] = useState<number | null>(0.9);

  const [matches, setMatches] = useState(0);
  const [histIMG, sethistIMG] = useState("");
  const [job_dir, setJob_dir] = useState("");
  const [preJobID, setPreJobID] = useState("");
  const [matchedDetails, setMatchedDetails] = useState<any>([]);
  const [selectedRowsFromModal, setSelectedRowsFromModal] =
    useState<string>("");

  const { user } = useUserAuth();

  const runInProd = RUNTIME === "PROD";

  // send inputs and get matches
  const handleSubmit = async (e: any) => {
    setInProgress(true);
    const form = new FormData();
    // do validation

    if (!PDBFile) {
      alert("No file uploaded");
      setInProgress(false);
    }

    form.append("uid", user.uid);
    form.append("protein_file", PDBFile);
    form.append("res_name", residue);
    form.append("pms_cutoff", pmsCutoff);
    const response = await axios
      .post(`${JOB_SERVER_URL}/pmsfilter/get_matches`, form, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        setInProgress(false);
        if (response.status !== 200) {
          //console.log(response.statusText);
          setInProgress(false);
          alert("error in getting matched PDBs");
        }

        const data = response.data;

        setMatches(data.matches);
        setJob_dir(data.job_dir);
        sethistIMG(data.image);
        setJobID(data.task_id);
        let matchingProteins = [];
        if (data.matched_details) {
          for (let key in data.matched_details) {
            let matchingProteinObj = {
              cavityName: "",
              score: "",
            };

            matchingProteinObj.cavityName = key;
            matchingProteinObj.score = data.matched_details[key];
            matchingProteins.push(matchingProteinObj);
          }
        }

        setMatchedDetails(matchingProteins);
        setDialogOpen(true);
      });
  };

  const deleteHandlerDisable = () => {
    setPDBFile(null);
  };

  const handleFileUpload = async (file: File, name: string) => {
    SetShowSubmitMsg(false);
    switch (name) {
      case "pdbfile":
        setPDBFile(file);
        break;
      default:
        //console.log("file type unknown");
        break;
    }
  };

  // To close dialog box
  const handleClose = () => {
    setDialogOpen(false);
    setSelectedRowsFromModal(null);
  };

  // on select rows of modal
  const handleSelectionChange = (selectionModel: any) => {
    const selectedRowsData = matchedDetails
      .filter((row: any) => selectionModel.includes(row.cavityName + row.score))
      .map((row: any) => {
        return row.cavityName;
      });

    setSelectedRowsFromModal(selectedRowsData.join(","));
  };

  const handleSubmitJob = async () => {
    if (selectedRowsFromModal === "") {
      setShowError(true);
      setErrorMsg("Please Select atleast a single Cavity");
      return;
    }

    setDialogOpen(false);
    setInProgress(true);
    const form = new FormData();
    // do validation

    if (!PDBFile) {
      alert("No file uploaded");
      setInProgress(false);
    }

    if (matches <= 0) {
      alert("No matches found. Reduce the Pocket Match Score cutoff");
      setInProgress(false);
      return;
    }

    form.append("uid", user.uid);
    form.append("task_id", jobID);
    form.append("protein_filename", PDBFile.name);
    form.append("res_name", residue);
    form.append("pms_cutoff", pmsCutoff);
    form.append("control_ligand_smile", ligandSmile);
    form.append("selected_cavities", selectedRowsFromModal);

    const response = await axios
      .post(`${JOB_SERVER_URL}/pmsfilter/run_docking`, form, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        setInProgress(false);
        setInProgress(false);
        setJobID(response.data.task_id);
        SetShowSubmitMsg(true);
        setDoLoadJobs(true);
      })
      .catch((error) => {
        setInProgress(false);
        setShowError(true);
        setErrorMsg(errorMessages.submitJobError);
      });
  };

  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.download = "promiscuity_sample_input.pdb";
    link.href = "/samples/promiscuity/promiscuity_input.pdb";
    link.click();
    //console.log("add files to download")
  };

  const onBetaClick = () => {
    // console.log("clicked beta access");
    setBetaOpen(true);
  };

  useEffect(() => {
    if (!doLoadJobs || !user) return;

    axios
      .get(`${JOB_SERVER_URL}/userjobs`, {
        params: {
          user_id: user.uid,
          model_name: MODEL_NAME,
          start: "0",
          end: "10",
        },
        headers: {
          accept: "application/json",
        },
      })
      .then((res) => {
        setDoLoadJobs(false);
        setRows(res.data);
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg(errorMessages.jobTableError);
        setDoLoadJobs(false);
        setRows([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doLoadJobs]);

  useEffect(() => {
    if (!PDBFile) return;

    const form = new FormData();
    form.append("uploaded_protein_file", PDBFile);

    setfetchingFields(true);

    const response = axios
      .post(`https://app.moleculeai.com/api/pdb/chains`, form, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      })
      .then((response: any) => {
        setfetchingFields(false);
        //        console.log(response);
        setResidues(response.data[1]);
        setResidue(response.data[1][0]);
      })
      .catch((error) => {
        setfetchingFields(false);
      });
  }, [PDBFile]);

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Box py={2}>
        {/* <Button onClick={handleDownloadSample}>Download Sample file</Button> */}
        <Grid container px={3} pt={2} spacing={2} alignItems="stretch">
          {/* <Grid item sm={12} md={8} sx={{ width: "100%" }} display="flex">
            <Card>
              <CardContent sx={{ pt: 2 }}>
                <Typography variant={"h5"} fontWeight={"bold"} mb={2}>
                  Description
                </Typography>
                <Typography sx={{ pb: 6 }}>
                  This module predicts the potential off-target effects of
                  small-molecule drugs. By searching a comprehensive database of
                  the full human proteome, it identifies protein cavities that
                  resemble the binding cavity of your target protein. It then
                  computes the docking scores for the molecule against these
                  top-matching cavities, providing invaluable insights into
                  potential off-target interactions.
                </Typography>
                <Box
                  component="img"
                  src="/images/hero/promiscuity.png"
                  alt="Protein Info Visual"
                  sx={{
                    width: "100%", // Reduces the width to 80% of the parent container
                    height: "auto",
                    mt: 5, // Adds margin-top for space above the image
                    mb: 2, // Adds margin-bottom for space below the image
                    mx: "auto", // Centers the image horizontally
                    display: "block", // Ensures the Box behaves like a block element
                    p: 2, // Adds padding around the image inside the Box
                  }}
                />
              </CardContent>
            </Card>
          </Grid> */}
          <Grid item sm={12} md={8} sx={{ width: "100%" }} display={"flex"}>
            <WorkflowLandingTabs tabs={tabs} />
          </Grid>
          <Grid item sm={12} md={4} sx={{ width: "100%" }} display="flex">
            <Card sx={{ height: 1, width: "100%" }}>
              <CardContent>
                <Typography variant="h5" my={1}>
                  Submit Jobs to Promiscuity
                </Typography>
                {runInProd && (
                  <Grid container direction="column" className="beta-anchor">
                    <Grid item xs={12} sx={{ mt: 5 }}>
                      <Typography>
                        This is a Premium feature. Try with{" "}
                        <Button variant="contained" onClick={onBetaClick}>
                          BETA ACCESS
                        </Button>
                        <BetaModal
                          open={betaOpen}
                          openHandler={setBetaOpen}
                        ></BetaModal>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <FormControl
                  fullWidth
                  className={RUNTIME === "PROD" ? "blurry-bg" : ""}
                >
                  <Stack direction="column" spacing={1.5}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      mb={1}
                    >
                      <Typography
                        sx={{
                          mt: 1,
                          color: "var(--shade-2900, #29283B)",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        Protein File
                      </Typography>

                      <Button variant="text" onClick={handleDownloadSample}>
                        {"Download Sample"}
                      </Button>
                    </Box>

                    <FileUploader
                      accept={
                        ".pdb, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      }
                      handleFileUpload={(files) =>
                        handleFileUpload(files[0], "pdbfile")
                      }
                      headerSelector={false}
                      fileUploadMsg={"Limit 200MB per file"}
                      deleteHandlerDisable={deleteHandlerDisable}
                    />
                  </Stack>
                  <Typography component="label" htmlFor="residue-string">
                    Residue Name
                  </Typography>
                  {fetchingFields ? <LinearProgress /> : ""}
                  <Select
                    disabled={residues?.length === 0}
                    value={residue}
                    label="Residue"
                    onChange={(e) => setResidue(e.target.value)}
                  >
                    {residues?.map((v, i) => {
                      return (
                        <MenuItem key={i} value={v}>
                          {v}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Typography component="label" htmlFor="ligand-string">
                    Ligand SMILES
                  </Typography>
                  <TextField
                    placeholder="Ligand Smile"
                    fullWidth
                    id="ligand-smile-string"
                    name="ligand-smile-string"
                    value={ligandSmile}
                    onChange={(e) => setLigandSmile(e.target.value)}
                    sx={{ marginBottom: 2 }}
                  />
                  <Typography component="label" htmlFor="pms-string">
                    Pocket Match Score Cutoff
                  </Typography>
                  <TextField
                    placeholder="0.5"
                    fullWidth
                    id="pms-num"
                    name="pms-num"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 0.1, // Step value for decimal input
                      min: 0.5, // Minimum value
                      max: 1.0, // Maximum value
                    }}
                    value={pmsCutoff}
                    onChange={(e) => setPmsCutoff(Number(e.target.value))}
                    sx={{ marginBottom: 2 }}
                    helperText="Min: 0.5 , Max: 1.0"
                  />
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      mt: 2,
                      "&.Mui-disabled": {
                        background: "#BDADF9",
                        color: "#fff",
                      },
                    }}
                    disabled={
                      !residue || !ligandSmile || !pmsCutoff || !PDBFile
                    }
                  >
                    Submit
                  </Button>
                  {/* <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={inProgress}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop> */}
                </FormControl>
                <IndefiniteLoader state={inProgress} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid sx={{ width: "50%", mx: "auto" }}>
          {showSubmitMsg && (
            <Alert
              onClose={() => {
                SetShowSubmitMsg(false);
              }}
              sx={{ mt: 2 }}
              variant="filled"
              severity="success"
            >{`Job submitted with id ${jobID}`}</Alert>
          )}
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className="jobs-container"
          mt={2}
          px={3}
        >
          <Card sx={{ width: 1 }}>
            <CardContent>
              {doLoadJobs && <Loaders type={"table"} />}
              {!doLoadJobs && (
                <JobsTable
                  jobs={rows}
                  setDoLoadJobs={setDoLoadJobs}
                  nextUrl="visualize"
                ></JobsTable>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Matched Cavities Histogram
        </DialogTitle>

        <Box
          component="img"
          src={`data:image/png;base64,${histIMG}`}
          alt="PDB Result"
          sx={{
            width: "100%", // Reduces the width to 80% of the parent container
            height: "auto",
            mt: 5, // Adds margin-top for space above the image
            mb: 2, // Adds margin-bottom for space below the image
            mx: "auto", // Centers the image horizontally
            display: "block", // Ensures the Box behaves like a block element
            p: 1, // Adds padding around the image inside the Box
          }}
        />

        {matchedDetails.length > 0 && (
          <Box sx={{ m: 2, height: "300px", width: "80%", mx: "auto" }}>
            <DataGridPro
              getRowId={(row: any) => row.cavityName + row.score}
              rows={matchedDetails}
              columns={columns}
              pagination
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 4,
                  },
                },
              }}
              checkboxSelection
              disableRowSelectionOnClick
              pageSizeOptions={[4, 8, 12]}
              onRowSelectionModelChange={handleSelectionChange}
            />
          </Box>
        )}

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Got ${matches} matches with Pocket Match Score cutoff ${pmsCutoff} Continue to get docking scores?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmitJob} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PromiscuitySubmitJobs;

import React from "react";
import { Box, Typography, Grid, LinearProgress, Backdrop } from "@mui/material";
import { useState, useEffect } from "react";
import logo from '../../MoleculeGEN_White_Grey_Logo.png'

const IndefiniteLoader = (props : any) => {

    const [loadingText, setLoadingText] = useState("Processing...");
    const [color,setColor] = useState("#FDBC4A");

    useEffect(() => {
        const changeTextInterval = setInterval(() => {
            setLoadingText((prevText) => {
                const textOptions = ["Processing...", "Please wait..."];
                const nextIndex = (textOptions.indexOf(prevText) + 1) % textOptions.length;
                return textOptions[nextIndex];
            });
        }, 3000);
        return () => clearInterval(changeTextInterval);
    }, []);

    useEffect(() => {
        const changeColorInterval = setInterval(() => {
            setColor((color) => {
                const colorOptions = ["#FDBC4A","#FD994A","#FD6D4A","#FD9E4A","#FDCF4A"];
                const nextIndex = (colorOptions.indexOf(color) + 1) % colorOptions.length;
                return colorOptions[nextIndex];
            });
        },200);
        return () => clearInterval(changeColorInterval);
    }, []);

    return (

        <> <Backdrop
            sx={{
               // color: "#FDBC4A",
               color:{color},
                backgroundColor: 'rgba(46,46,46,0.7)',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                width: { sm: `calc(100% - ${0}px)` }, // adjust this value according to drawer width in Layout.jsx
                ml: 'auto',
                position:props.onlyForDiv?"absolute":""
            }}
            open={props.state}
        >


            <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={5}>
                <img src={logo} alt="MoleculeGEN" style={{
                    width: '50%',
                    maxWidth: '350px',
                    display: 'block',
                    margin: '20px auto 0',
                }} />
                <Grid item>
                    <Typography variant="h4" color={'#EEEEEE'}>{loadingText}</Typography>
                </Grid>


                <Grid item width={'100%'}>
                    <LinearProgress color={"inherit"} sx={{ width: '33%', height: 14, borderRadius: 2, mx: 'auto' }} />
                </Grid>
            </Grid>
        </Backdrop>
        </>
    )

}

export default IndefiniteLoader
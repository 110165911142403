import { useEffect, useRef, useState, ChangeEvent } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Drawer,
  Alert,
  FormControl,
  FormHelperText,
  Checkbox,
  TextField,
  FormControlLabel,
  Snackbar,
} from "@mui/material";
import { saveAs } from "file-saver";
import { LigandInput } from "../../components";
import Button from "@mui/material/Button";
import { Viewer } from "../../components";
import "../../css/viewer.css";
import { selectLigandPrep } from "./ligandPrepSlice";
import { useAppSelector } from "../../app/hooks";
import { useLocation } from "react-router-dom";
import { getLigandInfo } from "./ligandPrepApi";
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridToolbar,
} from "@mui/x-data-grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import http from "../../net/http-common";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";

const GLOBAL_VARS = {
  MIN_PH: 0,
  MAX_PH: 14,
  DEFAULT_MIN_PH: 6.8,
  DEFAULT_MAX_PH: 7.2,

  MIN_CONFORMER_NO: 1,
  MAX_CONFORMER_NO: 1000,
  DEFAULT_CONFORMER_NO: 32,

  MIN_MINIMIZATION_STEPS: 50,
  MAX_MINIMIZATION_STEPS: 1000,
  DEFAULT_MINIMIZATION_STEPS: 100,
};

function LigandPrep() {
  const state = useLocation();
  ////console.log(state?.state?.data?.sdf,"inpur info");
  const [SDF, setSDF] = useState("");
  const [showConformers, setShowConformers] = useState(false);

  const [rows, setRows] = useState([]);
  const [inputRows, setInputRows] = useState(state.state.data.sdf);
  const [inProgress, setInProgress] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const [minPHValue, setMinPhValue] = useState(GLOBAL_VARS.DEFAULT_MIN_PH);
  const [maxPHValue, setMaxPhValue] = useState(GLOBAL_VARS.DEFAULT_MAX_PH);
  const [tutomer, setTutomer] = useState(false);
  const [conformer, setConformer] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);

  const [minSteps, setMinSteps] = useState(
    GLOBAL_VARS.DEFAULT_MINIMIZATION_STEPS
  );
  const [conformerNo, setConformerNo] = useState(
    GLOBAL_VARS.DEFAULT_CONFORMER_NO
  );
  const [isOpen, setOpen] = useState(false);
  const isValidMinPhValue =
    minPHValue >= GLOBAL_VARS.MIN_PH && minPHValue <= GLOBAL_VARS.MAX_PH;
  const isValidMaxPhValue =
    maxPHValue >= GLOBAL_VARS.MIN_PH && maxPHValue <= GLOBAL_VARS.MAX_PH;
  const isValidsteps =
    minSteps >= GLOBAL_VARS.MIN_MINIMIZATION_STEPS &&
    minSteps <= GLOBAL_VARS.MAX_MINIMIZATION_STEPS;
  const isValidConformerNo =
    conformerNo >= GLOBAL_VARS.MIN_CONFORMER_NO &&
    conformerNo <= GLOBAL_VARS.MAX_CONFORMER_NO;
  const isValidPh = minPHValue <= maxPHValue;
  const [exportMsg, setExportMsg] = useState(null);
  const [jobDir, setJobDir] = useState(null);
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  let viewer = useRef<Viewer>();
  const ligandPrep = useAppSelector(selectLigandPrep);

  const handleSubmit = async (e: any) => {
    if (
      !isValidMinPhValue ||
      !isValidMaxPhValue ||
      !isValidsteps ||
      !isValidConformerNo ||
      !isValidPh
    )
      return;

    setOpen(false);
    setInProgress(true);
    const data = new FormData();
    if (state.state.smilesFile && !state.state.smiles)
      data.append("smiles_file", state.state.smilesFile);
    else data.append("smiles_file", "");

    var params = {
      smiles: state.state.smiles,
      min_ph: minPHValue ? minPHValue : GLOBAL_VARS.DEFAULT_MIN_PH,
      max_ph: maxPHValue ? maxPHValue : GLOBAL_VARS.DEFAULT_MAX_PH,
      generate_conformers: conformer,
      generate_tautomers: tutomer,
      minimization_steps: minSteps,
      num_conformers: conformerNo,
    };

    getLigandInfo({ smileData: data, params: params })
      .then((res) => {
        setInProgress(false);
        const rows = Object.values(res.data.ligands).map((v: any) => ({
          id: v.id,
          name: v.name,
          image: v.image,
          mol: v.mol,
        }));
        setRows(rows);
        setJobDir(res.data.job_dir);
        setShowConformers(true);
        setTabValue(1);
      })
      .catch((error) => {
        setInProgress(false);
        setShowError(true);
        setErrorMsg("Error Preparing Ligand(s). Please try again later");
      });
  };

  const updateViewer = async (
    data: string,
    format:
      | "mmcif"
      | "cifCore"
      | "pdb"
      | "pdbqt"
      | "gro"
      | "xyz"
      | "mol"
      | "sdf"
      | "mol2"
  ) => {
    await viewer.current?.loadStructureFromData(data, format);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "name", headerName: "Name", width: 350 },
    {
      field: "image",
      headerName: "2D Image",
      width: 300,
      editable: false,
      renderCell: (params: any) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{ height: "200px", width: "100%" }}
        />
      ),
    },
  ];

  const inputColumns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "smiles", headerName: "SMILE", width: 300 },
    {
      field: "img",
      headerName: "2D Image",
      width: 250,
      editable: false,
      renderCell: (params: any) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{ height: "200px", width: "100%" }}
        />
      ),
    },
  ];

  const initViewer = async (id: string) => {
    viewer.current = new Viewer();
    await viewer.current.init(id, {
      layoutShowControls: false,
      viewportShowExpand: false,
      collapseLeftPanel: true,
      layoutShowSequence: false,
    });
  };

  useEffect(() => {
    if (!showConformers) {
      initViewer("viewer").then(() => {
        updateViewer(ligandPrep.SDF.data, "sdf");
      });
    }
  });

  useEffect(() => {
    {
      if (tabValue === 0) {
        ////console.log("data",ligandPrep.SDF.data);
        initViewer("viewer").then(() => {
          updateViewer(ligandPrep.SDF.data, "sdf");
        });
      }
    }
  }, [tabValue]);

  useEffect(() => {
    if (showConformers && tabValue !== 0) {
      initViewer("ligand").then(() => {
        updateViewer(rows[0].mol, "sdf");
      });
    }
  });

  const handleRowClick = async (
    params: GridRowParams, // GridRowParams
    event: any
  ) => {
    if (!params) return;
    initViewer("ligand").then(() => {
      updateViewer(params.row.mol, "sdf");
    });
  };

  const handleInputRowClick = async (
    params: GridRowParams, // GridRowParams
    event: any
  ) => {
    if (!params) return;
    initViewer("viewer").then(() => {
      updateViewer(params.row.mol, "sdf");
    });
  };

  const handleExport = () => {
    setExportMsg("File export will begin shortly");
    http
      .get("/ligand/export", {
        params: {
          job_dir: jobDir,
        },
        responseType: "arraybuffer",
      })
      .then(async (response: any) => {
        //console.log(response);
        const blob = new Blob([response.data], { type: "application/zip" });
        saveAs(blob, `prepared_ligand.zip`);
        setExportMsg("Prepared Ligand exported successfully");
      })
      .catch((error: any) => {
        setExportMsg("Error exporting file");
      });

    setTimeout(() => {
      setExportMsg(null);
    }, 9000);
  };

  const toggleDrawer = (isOpen: boolean) => () => {
    setOpen(isOpen);
  };

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={10000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      {showConformers && exportMsg && (
        <Alert
          onClose={() => {
            setExportMsg(null);
          }}
          sx={{ mt: 2, px: 5 }}
          severity={exportMsg === "Error exporting file" ? "error" : "success"}
        >
          {exportMsg}
        </Alert>
      )}
      <Grid container p={0} m={0} height={"95%"}>
        <Grid>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            style={{ paddingLeft: 5, paddingTop: 5, height: 50 }}
          >
            <Tab label="Input Ligand(s)" />
            {showConformers && <Tab label="Prepared Ligand(s)" />}
          </Tabs>
        </Grid>

        {tabValue === 0 && (
          <div style={{ height: "100%", width: "100%" }}>
            <Grid container spacing={3} px={3} py={3} height={"100%"}>
              <Grid item xs={8} sx={{ overflowY: "auto", maxHeight: "100%" }}>
                {/* Input Ligands */}

                <Card>
                  <CardContent sx={{ p: 0, pb: 0, "&:last-child": { pb: 0 } }}>
                    <DataGrid
                      rows={inputRows}
                      columns={inputColumns}
                      rowHeight={300}
                      onRowClick={handleInputRowClick}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10, 20, 50]}
                      slots={{ toolbar: GridToolbar }}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} style={{ position: "sticky", top: 0 }}>
                <Card sx={{}}>
                  <CardContent
                    sx={{
                      py: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography my="auto" textAlign="justify" variant="h6">
                      Input Ligand
                    </Typography>
                    <Button
                      sx={{ textAlign: "right", justifyContent: "center" }}
                      variant="contained"
                      onClick={toggleDrawer(true)}
                    >
                      Prepare Ligand(s)
                    </Button>
                  </CardContent>
                  <CardContent sx={{ pt: 0 }}>
                    <div style={{ height: 400 }} id="viewer"></div>
                  </CardContent>
                </Card>
                <Drawer
                  anchor="right"
                  open={isOpen}
                  onClose={toggleDrawer(false)}
                >
                  <Grid
                    container
                    direction="column"
                    spacing={2}
                    px={2}
                    sx={{ marginTop: 3 }}
                  >
                    <Grid item>
                      <Typography variant="h5">Ligand Prepration</Typography>
                      <FormControl
                        fullWidth
                        error={!isValidMinPhValue || !isValidPh}
                        sx={{ mt: 3 }}
                      >
                        <TextField
                          label="Enter Minimum pH Value"
                          placeholder="Enter Minimum pH Value"
                          fullWidth
                          type="number"
                          value={minPHValue}
                          inputProps={{
                            step: "any",
                          }}
                          onChange={(e: any) => {
                            setMinPhValue(e.target.value);
                          }}
                        />
                        {!isValidMinPhValue && (
                          <FormHelperText>
                            Error: pH value should be between 0 and 14.
                          </FormHelperText>
                        )}
                        {!isValidPh && (
                          <FormHelperText>
                            Error: min pH should be less than max pH
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl
                        fullWidth
                        error={!isValidMaxPhValue || !isValidPh}
                      >
                        <TextField
                          label="Enter Maximum pH Value"
                          placeholder="Enter Maximum pH Value"
                          fullWidth
                          type="number"
                          value={maxPHValue}
                          inputProps={{
                            step: "any",
                          }}
                          onChange={(e: any) => {
                            setMaxPhValue(e.target.value);
                          }}
                        />
                        {!isValidMaxPhValue && (
                          <FormHelperText>
                            Error: pH value should be between 0 and 14.
                          </FormHelperText>
                        )}
                        {!isValidPh && (
                          <FormHelperText>
                            Error: min pH should be less than max pH
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl fullWidth error={!isValidConformerNo}>
                        <TextField
                          label="No. of Conformers"
                          placeholder="No. of Conformers"
                          fullWidth
                          type="number"
                          value={conformerNo}
                          inputProps={{}}
                          onChange={(e: any) => {
                            setConformerNo(e.target.value);
                          }}
                        />
                        {!isValidConformerNo && (
                          <FormHelperText>{`Error: Number should be between ${GLOBAL_VARS.MIN_CONFORMER_NO} and ${GLOBAL_VARS.MAX_CONFORMER_NO}.`}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl fullWidth error={!isValidsteps}>
                        <TextField
                          label="Minimisation Steps"
                          placeholder="Minimisation Steps"
                          fullWidth
                          type="number"
                          value={minSteps}
                          inputProps={{}}
                          onChange={(e: any) => {
                            setMinSteps(e.target.value);
                          }}
                        />
                        {!isValidsteps && (
                          <FormHelperText>{`Steps should be between ${GLOBAL_VARS.MIN_MINIMIZATION_STEPS} and ${GLOBAL_VARS.MAX_MINIMIZATION_STEPS}.`}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <FormControlLabel
                            label={
                              <Typography variant="body1">
                                Tautomer and protonation:
                              </Typography>
                            }
                            labelPlacement="start"
                            control={
                              <Checkbox
                                checked={tutomer}
                                onChange={(e: any) =>
                                  setTutomer(e.target.checked)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            label={
                              <Typography variant="body1">
                                Conformer generation:
                              </Typography>
                            }
                            labelPlacement="start"
                            control={
                              <Checkbox
                                checked={conformer}
                                onChange={(e: any) =>
                                  setConformer(e.target.checked)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Button
                    sx={{
                      textAlign: "right",
                      justifyContent: "center",
                      marginTop: 2,
                      mx: 6,
                    }}
                    variant="contained"
                    disabled={ligandPrep.SDF?.data?.length === 0}
                    onClick={handleSubmit}
                  >
                    Prepare Ligand(s)
                  </Button>
                </Drawer>
              </Grid>
            </Grid>
            <IndefiniteLoader state={inProgress} />
          </div>
        )}
        {tabValue === 1 && (
          <div style={{ height: "100%", width: "100%" }}>
            <Grid container spacing={3} px={6} py={3} height={"100%"}>
              <Grid item xs={8} sx={{ overflowY: "auto", maxHeight: "100%" }}>
                {/* Prepared Ligands */}

                <Card>
                  <CardContent sx={{ p: 0, pb: 0, "&:last-child": { pb: 0 } }}>
                    {/* <LigandTable
                  conformers={conformers}
                  showConformers={showConformers}
                ></LigandTable> */}
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      rowHeight={300}
                      onRowClick={handleRowClick}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10, 20, 50]}
                      slots={{ toolbar: GridToolbar }}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} style={{ position: "sticky", top: 0 }}>
                <Card>
                  <CardContent
                    sx={{
                      py: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography align="center" variant="h6">
                      Prepared Ligand
                    </Typography>
                    <Button
                      sx={{ border: "1px solid", py: 1 }}
                      onClick={handleExport}
                    >
                      Export
                    </Button>
                  </CardContent>
                  <CardContent sx={{ pt: 0 }}>
                    <div id="ligand"></div>
                  </CardContent>
                </Card>
                {/* <LigandInput/> */}
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
    </>
  );
}

export default LigandPrep;

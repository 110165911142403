import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import {
  getAllRowsFilterData,
  getMaiFilterData,
  getMedchemFilterData,
  getPropertiesV2,
  getToxicFilterData,
} from "./propertiesApi";
import { PropertiesState } from "../../models";

const initialState: PropertiesState = {
  status: "idle",
  properties: {
    data: null,
    loading: false,
    singleSmileData: null,
    otherFilters: {
      medchemFiltersPreData: null,
      medchemLoader: false,
      maiFiltersPreData: null,
      maiLoader: false,
      toxicFiltersPreData: null,
      toxicLoader: false,
      allRowsFiltersPreData: null,
      allRowsLoader: false,
    },
  },
};

// fetch all the physicochemical properties
export const fetchPropertiesV2 = createAsyncThunk(
  "properties/fetchPropertiesV2",
  async ({
    smiles,
    smilesFile,
    headerName,
    from_model,
    model_name,
    job_id,
  }: {
    smiles: string;
    smilesFile: string;
    headerName: string;
    from_model: boolean;
    model_name: string;
    job_id: string;
  }) => {
    // //console.log("requesting v2");

    try {
      const response = await getPropertiesV2({
        smiles,
        smilesFile,
        headerName,
        from_model,
        model_name,
        job_id,
      });
      // ////console.log("v2 resp", response.data);
      return response.data;
    } catch (error) {
      return { error };
    }
  }
);

// get medchem filter table data
export const fetchMedchemFilters = createAsyncThunk(
  "properties/fetchMedchemFilters",
  async ({ submittedSmiles }: { submittedSmiles: string[] }) => {
    try {
      const response = await getMedchemFilterData({
        submittedSmiles,
      });
      return response.data;
    } catch (error) {
      return { error };
    }
  }
);

// get Mai filter table data
export const fetchMaiFilters = createAsyncThunk(
  "properties/fetchMaiFilters",
  async ({ submittedSmiles }: { submittedSmiles: string[] }) => {
    try {
      const response = await getMaiFilterData({
        submittedSmiles,
      });
      return response.data;
    } catch (error) {
      return { error };
    }
  }
);

// get toxic filter table data
export const fetchToxicFilters = createAsyncThunk(
  "properties/fetchToxicFilters",
  async ({ submittedSmiles }: { submittedSmiles: string[] }) => {
    try {
      const response = await getToxicFilterData({
        submittedSmiles,
      });
      return response.data;
    } catch (error) {
      return { error };
    }
  }
);

// get all rows filter table data
export const fetchAllRowsFilters = createAsyncThunk(
  "properties/fetchAllRowsFilters",
  async () => {
    try {
      console.log("Fired")
      const response = await getAllRowsFilterData();
      return response.data;
    } catch (error) {
      return { error };
    }
  }
);


export const propertiesSliceV2 = createSlice({
  name: "properties",

  initialState,
  reducers: {
    setProperties(state, action) {
      ////console.log(action);
    },
    setSinglesmileData(state, action) {
      state.properties.singleSmileData = action.payload;
    },
    setSinglesmileinData(state, action) {
      state.properties.singleSmileData = {};
      state.properties.singleSmileData.smiles = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Fetch Properties
    builder
      .addCase(
        fetchPropertiesV2.fulfilled,
        (state: PropertiesState, action) => {
          state.properties.data = action.payload.properties;
          state.properties.loading = false;
        }
      )
      .addCase(fetchPropertiesV2.rejected, (state, action) => {
        state.properties.loading = false;
      })
      .addCase(fetchPropertiesV2.pending, (state) => {
        state.properties.loading = true;
      })

      .addCase(
        fetchMedchemFilters.fulfilled,
        (state: PropertiesState, action) => {
          state.properties.otherFilters.medchemFiltersPreData = action.payload;
          // console.log('data from slice',state.properties.otherFilters.medchemFiltersPreData);

          state.properties.otherFilters.medchemLoader = false;
        }
      )
      .addCase(fetchMedchemFilters.rejected, (state, action) => {
        state.properties.otherFilters.medchemLoader = false;
      })
      .addCase(fetchMedchemFilters.pending, (state) => {
        state.properties.otherFilters.medchemLoader = true;
      })

      .addCase(fetchMaiFilters.fulfilled, (state: PropertiesState, action) => {
        state.properties.otherFilters.maiFiltersPreData = action.payload;
        // console.log('mai data from slice',state.properties.otherFilters.maiFiltersPreData);

        state.properties.otherFilters.maiLoader = false;
      })
      .addCase(fetchMaiFilters.rejected, (state, action) => {
        state.properties.otherFilters.maiLoader = false;
      })
      .addCase(fetchMaiFilters.pending, (state) => {
        state.properties.otherFilters.maiLoader = true;
      })

      .addCase(
        fetchToxicFilters.fulfilled,
        (state: PropertiesState, action) => {
          state.properties.otherFilters.toxicFiltersPreData = action.payload;
          // console.log('toxic data from slice',state.properties.otherFilters.toxicFiltersPreData);

          state.properties.otherFilters.toxicLoader = false;
        }
      )
      .addCase(fetchToxicFilters.rejected, (state, action) => {
        state.properties.otherFilters.toxicLoader = false;
      })
      .addCase(fetchToxicFilters.pending, (state) => {
        state.properties.otherFilters.toxicLoader = true;
      })

      .addCase(
        fetchAllRowsFilters.fulfilled,
        (state: PropertiesState, action) => {
          state.properties.otherFilters.allRowsFiltersPreData = action.payload;
          // console.log('all rows data from slice',state.properties.otherFilters.allRowsFiltersPreData);

          state.properties.otherFilters.allRowsLoader = false;
        }
      )
      .addCase(fetchAllRowsFilters.rejected, (state, action) => {
        state.properties.otherFilters.allRowsLoader = false;
      })
      .addCase(fetchAllRowsFilters.pending, (state) => {
        state.properties.otherFilters.allRowsLoader = true;
      });
  },
});

export const { setProperties, setSinglesmileData, setSinglesmileinData } =
  propertiesSliceV2.actions;
export const selectPropertiesV2 = (state: RootState) => state.propertiesV2;
export default propertiesSliceV2.reducer;

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import {Typography} from '@mui/material';
import { Box } from '@mui/material';

// Create a custom styled component for the container
const StyledContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 2,
});

// Create a custom styled component for the heading
const StyledHeading = styled(Typography)({
});

// Create a custom styled component for the button
const StyledButton = styled(Button)({
  marginLeft: "16px"
});

type ModelCardHeadingProps = {
  button: {
    label: string | JSX.Element;
    onClick: () => {} | void
  },
  heading: string
}

function ModelCardHeading({button, heading}: ModelCardHeadingProps) {
  return (
    <StyledContainer px={3.5} pt={2}>
      <StyledHeading variant={"h5"}>{heading}</StyledHeading>
      <StyledButton onClick={button.onClick}>{button.label}</StyledButton>
    </StyledContainer>
  );
}

export default ModelCardHeading;

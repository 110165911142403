import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
  Alert,
  Snackbar,
  Grid,
} from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import theme from "../../theme/theme";
import {
  fetchLigand,
  fetchSDF,
  selectLigandPrep,
} from "../../views/LigandPrep/ligandPrepSlice";
import { useNavigate } from "react-router-dom";
import FileUploader from "../common/FileUploader";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import { error } from "console";
import BetaModal from "../common/BetaModal";

import { RUNTIME } from "../../config";
import http from "../../net/http-common";
import IndefiniteLoader from "../common/IndefiniteLoader";

function LigandInput() {
  const [SMILESFile, setSMILESFile] = useState<File>();
  const [smiles, setSmiles] = useState("");
  const [header, setHeader] = useState("smiles");
  const [progress, setProgress] = useState(false);
  const ligandPrep = useAppSelector(selectLigandPrep);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const [betaOpen, setBetaOpen] = useState(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const runInProd = RUNTIME === "PROD";
  // const handleLigandSubmit = async (e: any) => {
  //   // get SDF for the SMILES
  //   // initViewer()
  //   const data = new FormData()
  //   data.append('smiles_file', SMILESFile)
  //   dispatch(fetchSDF({ smileData: data }))
  // }

  const handleFileUpload = async (file: File) => {
    setSMILESFile(file);
  };

  const onBetaClick = () => {
    //    console.log("clicked beta access");
    setBetaOpen(true);
  };

  const handleSubmit = async (e: any) => {
    if (!smiles && !SMILESFile) return;

    setProgress(true);
    //console.log("submit clicked");
    const data = new FormData();
    if (SMILESFile) data.append("smiles_file", SMILESFile);
    else data.append("smiles_file", "");

    Promise.all([
      dispatch(
        fetchSDF({ smileData: data, column_name: header, smiles: smiles })
      ),
    ])
      .then((res) => {
        setProgress(false);
        const result = res[0].payload as any;
        if (result.error) {
          setShowError(true);
          setErrorMsg(errorMessages.submitInputError);
        } else {
          navigate("prep", {
            state: {
              smiles: smiles,
              smilesFile: SMILESFile,
              data: result.response.data,
            },
          });
        }
      })
      .catch((error) => {
        setProgress(false);
        setShowError(true);
        setErrorMsg(errorMessages.submitInputError);
      });
  };
  const handleSelectedHeader = (header: string) => {
    setHeader(header);
  };
  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.download = "ligand_prep_input.csv";
    link.href = "/samples/ligand_prep/ligand_prep.csv";

    link.click();
  };

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Card>
        <Box display={"flex"} justifyContent={"space-between"} m={1}>
          <Typography
            variant="h6"
            fontSize={18}
            fontWeight={600}
            sx={{ mt: 1, color: "var(--shade-2900, #29283B)" }}
          >
            {"Ligand Preparation"}
          </Typography>
          <Button variant="text" onClick={handleDownloadSample}>
            {"Download Sample"}
          </Button>
        </Box>
        <CardContent>
          {runInProd && (
            <Grid container direction="column" className="beta-anchor">
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography>
                  This is a Premium feature. Try with{" "}
                  <Button variant="contained" onClick={onBetaClick}>
                    BETA ACCESS
                  </Button>
                  <BetaModal
                    open={betaOpen}
                    openHandler={setBetaOpen}
                  ></BetaModal>
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            direction="column"
            className={RUNTIME === "PROD" ? "blurry-bg" : ""}
          >
            <Box>
              <Typography fontSize={15} mt={1}>
                Upload a valid SMILES file with one or more ligands
              </Typography>
              <FileUploader
                accept=".sdf, .pdb, .csv, .mol2, chemical/x-mdl-sdfile, chemical/x-mdl-sdfile-v300,"
                handleFileUpload={(files) => handleFileUpload(files[0])}
                headerSelector
                handleSelectedHeader={handleSelectedHeader}
              />

              <Typography textAlign={"center"} my={1.5}>
                -- OR --
              </Typography>

              <Typography component="label" htmlFor="smiles-string">
                Ligand SMILES
              </Typography>
              <TextField
                placeholder="SMILES string"
                fullWidth
                id="smiles-string"
                name="smiles-string"
                value={smiles}
                onChange={(e) => setSmiles(e.target.value)}
              />

              <Button
                variant="contained"
                sx={{
                  marginTop: "1rem",
                }}
                disabled={ligandPrep.SDF?.data?.length === 0 || progress}
                onClick={handleSubmit}
              >
                Add Ligands
              </Button>
            </Box>
          </Grid>

          <IndefiniteLoader state={progress} />
        </CardContent>

        <CardActions
          sx={{ px: 4, pb: 4, pt: 1, textAlign: "right" }}
        ></CardActions>
      </Card>
    </>
  );
}

export default LigandInput;

import React, { useRef, useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import {
  DataGridPro,
  getGridNumericOperators,
  GridColDef,
  GridFilterItem,
  GridFilterModel,
  GridFilterOperator,
  GridRowParams,
  GridToolbar,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import { GridCellParams } from "@mui/x-data-grid";
import { Paper, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { Typography } from "@mui/material";
import { setSinglesmileData } from "./propertiesSliceV2";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { TabPanel } from "../../components/common/TabPanel";
import { Box, Button, Card, Grid, Tab, IconButton, Modal } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";

import Viewer from "../../components/common/MolViewer";

import InputNumberInterval from "../../components/Properties/InputNumberInterval";

import { PropertiesInput } from "../../components";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectPropertiesV2 } from "./propertiesSliceV2";

import axios from "axios";

import { useNavigate } from "react-router-dom";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";

import {
  getScreenieRequirements,
  setScreenieRequirements,
} from "./propertiesHelper";
function PropertiesV2() {
  const [ro8TableVisible, setRo8TableVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [rowSelected, setRowSelected] = useState(false);
  const [rows, setRows] = useState<[]>([]);
  const [tabValue, setTabValue] = useState("1");
  const [allRows, setallRows] = useState<[]>([]);
  const [medicalRows, setMedicalRows] = useState<[]>([]);
  const [Medicalcolumns, setMedicalColumns] = useState<string[]>([]);
  const [allCols, setAllCols] = useState<GridColDef[]>([]);
  const [maiRows, setMaiRows] = useState<[]>([]);
  const [toxicRows, settoxicRows] = useState<[]>([]);

  const [brenkAction, setbrenkAction] = useState("IGNORE_ALL");
  const [nihAction, setnihAction] = useState("IGNORE_ALL");
  const [zincAction, setzincAction] = useState("IGNORE_ALL");
  const [painsAAction, setpainsAAction] = useState("IGNORE_ALL");
  const [painsBAction, setpainsBAction] = useState("IGNORE_ALL");
  const [painsCAction, setpainsCAction] = useState("IGNORE_ALL");

  const [selectedBrenkValues, setSelectedBrenkValues] = useState<string[]>([]);
  const [selectedNIHValues, setSelectedNIHValues] = useState<string[]>([]);
  const [selectedZincValues, setSelectedZincValues] = useState<string[]>([]);
  const [toxicFiltersCols, settoxicFiltersCols] = useState<string[]>([]);
  const [toxicFiltersallRows, settoxicFiltersallRows] = useState<[]>([]);
  const [showToxicFilters, setshowToxicFilters] = useState(false);

  const [loading, setLoading] = useState(false);
  const [brenkColumnGroupArray, setBrenkColumnGroupArray] = useState<any[]>([]);
  const [dataForEvaluation, setDataForEvaluation] = useState<{}>({});
  const [nihColumnGroupArray, setnihColumnGroupArray] = useState<
    Array<{} | { field: string }>
  >([{}]);
  const [zincColumnGroupArray, setzincColumnGroupArray] = useState<
    Array<{} | { field: string }>
  >([{}]);
  const [painsAColumnGroupArray, setpainsAColumnGroupArray] = useState<
    Array<{} | { field: string }>
  >([{}]);
  const [painsBColumnGroupArray, setpainsBColumnGroupArray] = useState<
    Array<{} | { field: string }>
  >([{}]);
  const [painsCColumnGroupArray, setpainsCColumnGroupArray] = useState<
    Array<{} | { field: string }>
  >([{}]);

  const [selectedPainsAValues, setSelectedPainsAValues] = useState<string[]>(
    []
  );
  const [selectedPainsBValues, setSelectedPainsBValues] = useState<string[]>(
    []
  );
  const [selectedPainsCValues, setSelectedPainsCValues] = useState<string[]>(
    []
  );

  // Create a new state to hold all selected values

  const [toxicColumnGroupHeadings, setToxicColumnGroupHeadings] = useState<
    string[]
  >([]);
  interface MyObjectType {
    [key: string]: { [key: string]: string };
  }
  interface Myobj {
    smiles: string; // Assuming 'smiles' is a string, adjust the type if necessary
    SMILES: string;
    // Other properties...
  }

  const [subCatalogs, setsubCatalogs] = useState<MyObjectType>({});
  const [medicinalFiltersData, setMedicinalFiltersData] = useState<{
    [key: string]: any;
  }>({});
  const navigate = useNavigate();
  const { properties } = useAppSelector(selectPropertiesV2);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useAppDispatch();

  interface ResponseItem {
    id: number;
    smiles: string;
    BRENK: number;
    NIH: number;
    ZINC: number;
    PAINS_A: number;
    PAINS_B: number;
    PAINS_C: number;
  }
  interface ModifiedResponseItem extends ResponseItem {
    svg: string | null;
  }

  const Ro8FilterModel: GridFilterModel = {
    items: [
      //n_Ar >=1 & n_Ar <=3, rotBond <=10, TPSA <=140, QED >=0.5]
      {
        id: 1,
        field: "MolWt",
        operator: "<=",
        value: "400",
      },
      {
        id: 2,
        field: "HBondAcceptors",
        operator: "<=",
        value: "10",
      },
      {
        id: 3,
        field: "HBondDonors",
        operator: "<=",
        value: "5",
      },
      {
        id: 4,
        field: "LogP",
        operator: "<=",
        value: "3.5",
      },
      {
        id: 5,
        field: "RotatableBonds",
        operator: "<=",
        value: "10",
      },
      {
        id: 6,
        field: "TPSA",
        operator: "<=",
        value: "140",
      },
      {
        id: 7,
        field: "QED",
        operator: ">=",
        value: "0.5",
      },
    ],
  };

  const BetweenOperator: GridFilterOperator[] = [
    {
      label: "Between",
      value: "between",
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }
        if (filterItem.value[0] == null || filterItem.value[1] == null) {
          return null;
        }

        return ({ value }) => {
          return (
            value !== null &&
            filterItem.value[0] <= value &&
            value <= filterItem.value[1]
          );
        };
      },
      InputComponent: InputNumberInterval,
    },
    ...getGridNumericOperators(),
  ];

  let table = useGridApiRef();

  // Find the index of "svg" in Medicalcolumns
  const svgIndex = Medicalcolumns.indexOf("svg");

  // Remove "svg" from Medicalcolumns if found
  if (svgIndex !== -1) {
    Medicalcolumns.splice(svgIndex, 1);
  }

  // Add "svg" as the second element in Medicalcolumns
  Medicalcolumns.splice(1, 0, "svg");

  // Map Medicalcolumns to medCols
  const medCols: GridColDef[] = Medicalcolumns.map((columnName) => {
    if (columnName === "smiles") {
      return {
        field: columnName,
        headerName: "SMILES",
        editable: false,
        type: "string",
      };
    } else if (columnName === "svg") {
      return {
        field: columnName,
        headerName: "2D Representation",
        description: "This column has images and is not sortable.",
        sortable: false,
        filterable: false,
        disableExport: true,
        renderCell: (params) => (
          <img
            src={`data:image/svg+xml;base64,${btoa(params.value)}`}
            alt="2D svg representation"
            style={{ height: "200px", width: "100%" }}
          />
        ),
      };
    } else {
      return {
        field: columnName,
        headerName: columnName,
        editable: false,
        type: "boolean", // Adjust the type based on your requirements
        renderCell: (params) => {
          const value = params.row[columnName];

          // Accessing the object corresponding to the current SMILES
          const smilesObject = medicinalFiltersData[params.id];

          if (
            smilesObject?.columnName === undefined ||
            smilesObject?.columnName === null
          ) {
            return (
              <>
                <Tooltip title={"Fail"}>
                  <span>FAIL</span>
                </Tooltip>
              </>
            );
          } else {
            // Accessing the value and hasValue properties
            const { value: propertyValue } = smilesObject?.columnName;

            return (
              <>
                {value ? (
                  <Tooltip title={"Yes"}>
                    <span>PASS</span>
                  </Tooltip>
                ) : (
                  <Tooltip title={propertyValue}>
                    <span>FAIL</span>
                  </Tooltip>
                )}
              </>
            );
          }
        },
      };
    }
  });

  const toxicFiltersAllcols: GridColDef[] = [
    {
      field: "svg",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{ height: "200px", width: "100%" }}
        />
      ),
    },
    ...toxicFiltersCols.map((columnName: any) => ({
      field: columnName,
      headerName: columnName,
      editable: false,
      type: "boolean",
      renderCell: (params: GridCellParams) => (
        <span>{!params.value ? "PASS" : "FAIL"}</span>
      ),
    })),

    { field: "id", headerName: "ID" },
    { field: "smiles", headerName: "SMILES", editable: false },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "Smiles",
      headerName: "SMILES",
      editable: false,
      type: "string",
    },

    {
      field: "svg",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{ height: "200px", width: "100%" }}
        />
      ),
    },

    {
      field: "MolWt",
      headerName: "MolWt",
      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "MolLogP",
      headerName: "LogP",
      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "NumRotatableBonds",
      headerName: "RotatableBonds",
      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "HBondDonors",
      headerName: "HBondDonors",
      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "HBondAcceptors",
      headerName: "HBondAcceptors",
      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "TPSA",
      headerName: "TPSA",

      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "HeavyAtoms",
      headerName: "HeavyAtoms",
      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "NumAromaticRings",
      headerName: "AromaticRings",
      type: "number",
      filterOperators: BetweenOperator,
      width: 150,
    },
    {
      field: "SA",
      headerName: "SA",
      type: "number",
      filterOperators: BetweenOperator,
    },
    // {
    //   field: "SASA",
    //   headerName: "SASA",

    //   type: "number",
    //   filterOperators: BetweenOperator,
    // },
    {
      field: "QED",
      headerName: "QED",
      type: "number",
      filterOperators: BetweenOperator,
    },
    {
      field: "UndesiredRings",
      headerName: "UndesiredRings",
      type: "number",
      filterOperators: BetweenOperator,
      width: 150,
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ToxicColumns: GridColDef[] = [
    { field: "id", headerName: "ID", headerAlign: "center", align: "center" },
    {
      field: "smiles",
      headerName: "SMILES",
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "svg",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      width: 110,
      disableExport: true,
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{ height: "200px", width: "100%" }}
        />
      ),
    },
    {
      field: "BRENK",
      headerName: "Brenk",
      type: "boolean",
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <>{params.value ? <span>PASS</span> : <span>FAIL</span>}</>;
      },
    },
    {
      field: "NIH",
      headerName: "NIH",
      type: "boolean",
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <>{params.value ? <span>PASS</span> : <span>FAIL</span>}</>;
      },
    },
    {
      field: "ZINC",
      headerName: "ZINC",
      type: "boolean",
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <>{params.value ? <span>PASS</span> : <span>FAIL</span>}</>;
      },
    },
    {
      field: "PAINS_A",
      headerName: "PAINS_A",
      type: "boolean",

      renderCell: (params) => {
        return <>{params.value ? <span>PASS</span> : <span>FAIL</span>}</>;
      },
    },
    {
      field: "PAINS_B",
      headerName: "PAINS_B",
      type: "boolean",

      renderCell: (params) => {
        return <>{params.value ? <span>PASS</span> : <span>FAIL</span>}</>;
      },
    },
    {
      field: "PAINS_C",
      headerName: "PAINS_C",
      type: "boolean",

      renderCell: (params) => {
        return <>{params.value ? <span>PASS</span> : <span>FAIL</span>}</>;
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const MAIcols: GridColDef[] = [
    {
      field: "smiles",
      headerName: "SMILES",
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "svg",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      disableExport: true,
      width: 120,

      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{ height: "200px", width: "80%" }}
        />
      ),
    },
    {
      field: "MAI Lipinski+",
      headerName: "MAI Lipinski+",
      type: "string",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Unwanted Substructures",
      headerName: "Unwanted Substructures",
      type: "string",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "MAI Rings",
      headerName: "MAI Rings",
      type: "string",
      headerAlign: "center",
      align: "center",
    },
  ];

  useEffect(() => {
    setRo8TableVisible(true);
  }, []);

  const applyFilters = async (e: any) => {
    table.current.setFilterModel(Ro8FilterModel);
  };

  useEffect(() => {
    if (!properties || !properties.data) {
      navigate("/properties-v2");
      return;
    }
    if (!properties.loading) {
      const modifiedData = properties.data.map((item: any, index: any) => {
        return {
          ...item,
          id: index + 1,
        };
      });
      setRows(modifiedData);
    }
    if (!properties.data) navigate("/properties-v2");
    //console.log("data is ", properties.data);

    setCurrentRow(properties.data[0]);
  }, [properties]);

  const getSvgForSmiles = (smiles: string) => {
    const property = properties.data.find(
      (property: any) => property.smiles === smiles
    );

    if (property && property.svg) {
      return property.svg;
    }

    return null;
  };

  useEffect(() => {
    if (!properties) {
      navigate("/properties-v2");
      return;
    }

    if (
      properties.data !== null &&
      properties.otherFilters.medchemFiltersPreData !== null &&
      properties.otherFilters.maiFiltersPreData !== null &&
      properties.otherFilters.toxicFiltersPreData !== null &&
      properties.otherFilters.allRowsFiltersPreData !== null
    ) {
      console.log("properties.otherFilters.allRowsFilterPreData",properties.otherFilters.toxicFiltersPreData);
      let propertiesWithSmilesAsKeys: { [key: string]: Myobj } = {};

      properties.data.forEach((obj: Myobj) => {
        propertiesWithSmilesAsKeys[obj.smiles] = obj;
      });

      const fetchData = async () => {
        setLoading(true);
        try {
          const smilesArray = properties.data.map(
            (property: any) => property.smiles
          );

          const response: { data: any } = {
            data: null,
          };
          response.data = properties.otherFilters.medchemFiltersPreData;

          // console.log("med data from filter", response.data);

          // setDataForEvaluation(updateDataforEvaluation);
          // console.log("dataForEvaluation",dataForEvaluation);
          const filteredData = response.data.reduce((acc: any, item: any) => {
            const result: { [key: string]: any } = {};

            if (item.hasOwnProperty("result")) {
              // this SMILES isi invalid and returned with `result` field
              return acc;
            }
            // Iterate over each key in the object
            for (const key in item) {
              if (key !== "smiles") {
                const valueArray = item[key];
                const hasValue = valueArray[0]; // First element indicates whether value exists
                const value = !hasValue ? valueArray[1][0] : null; // Second element is the value if it exists

                result[key] = { value, hasValue };
              }
            }

            acc[item.smiles] = result;
            return acc;
          }, {});

          setMedicinalFiltersData(filteredData);
          console.log("filetereddata",filteredData);

          const responseData = response.data;

          if (responseData && responseData.length > 0) {
            // Transform the data
            const transformedData = responseData.map((property: any) => {
              const newObj: any = {};
              Object.keys(property).forEach((key) => {
                if (key !== "smiles") {
                  newObj[key] = property[key][0];
                } else {
                  newObj[key] = property[key];
                }
              });

              // Add "svg" column to each row
              newObj["svg"] = getSvgForSmiles(newObj["smiles"]);

              return newObj;
            });

            const keys = Object.keys(transformedData[0]);
            const filteredColumns = keys.filter(
              (key) =>
                key !== "img" &&
                key !== "Ghose" &&
                key !== "RO8" &&
                key !== "Brenk" &&
                key !== "NIH" &&
                key !== "PAINS_A" &&
                key !== "PAINS_B" &&
                key !== "PAINS_C" &&
                key !== "ZINC" &&
                key !== "NoLargeRings"
            );
            setMedicalColumns(filteredColumns);
            setMedicalRows(transformedData);

            let medChemSmilesAsKeys: { [key: string]: Myobj } = {};
            response.data.forEach((obj: Myobj) => {
              medChemSmilesAsKeys[obj.smiles] = obj;
            });

            const Mairesponse: { data: any } = {
              data: null,
            };

            Mairesponse.data = properties.otherFilters.maiFiltersPreData;

            // console.log("Mairesponse", Mairesponse.data);

            const modifiedResponse = Mairesponse.data.map(
              (item: any, index: any) => {
                const smiles = smilesArray[index];
                const svg = getSvgForSmiles(smiles); // Assuming getSvgForSmiles returns the SVG image

                return { ...item, smiles, svg };
              }
            );
            // console.log("modifiedResponse", modifiedResponse.data);
            setMaiRows(modifiedResponse);

            let maiSmilesAsKeys: { [key: string]: Myobj } = {};
            Mairesponse.data.forEach((obj: Myobj) => {
              maiSmilesAsKeys[obj.SMILES] = obj;
            });

            const ToxicFiltersResponse: { data: any } = {
              data: null,
            };

            ToxicFiltersResponse.data =
              properties.otherFilters.toxicFiltersPreData;

            // console.log("input toxic data", ToxicFiltersResponse.data);

            let toxicSmilesAskeys: { [key: string]: Myobj } = {};
            ToxicFiltersResponse.data.forEach((obj: Myobj) => {
              toxicSmilesAskeys[obj.smiles] = obj;
            });

            const DataEvaluationObject = {
              properties: propertiesWithSmilesAsKeys,
              medchem: medChemSmilesAsKeys,
              toxicfilters: toxicSmilesAskeys,
              mai: maiSmilesAsKeys,
            };

            setDataForEvaluation(DataEvaluationObject);

            const modifiedToxicFilterResponse = ToxicFiltersResponse.data.map(
              (obj: any, index: number) => {
                const { top_level, smiles, ...rest } = obj;
                const svg = getSvgForSmiles(smiles); // Assuming getSvgForSmiles returns SVG string

                return {
                  ...rest,
                  smiles, // Include the 'smiles' property in the row
                  svg,
                  id: index, // Use the index as the unique identifier
                };
              }
            );

            settoxicRows(modifiedToxicFilterResponse);

            const topRulesresponse: { data: any } = {
              data: null,
            };

            topRulesresponse.data =
              properties.otherFilters.allRowsFiltersPreData;

            //console.log("Top level", response.data);
            if (topRulesresponse && topRulesresponse.data) {
              const data = topRulesresponse.data;
              setsubCatalogs(data);

              // Extract keys from the response object
              const keys = Object.keys(data);
              setToxicColumnGroupHeadings(keys);
              const combinedValues = keys.reduce((acc, key) => {
                return acc.concat(data[key]);
              }, []);

              const uniqueValues = Array.from(new Set(combinedValues));

              settoxicFiltersCols(uniqueValues);
              //console.log("toxicFiltersCols", uniqueValues);
            }
          } else {
            console.warn("No data retrieved.");
          }
        } catch (error) {
          // Handle errors
          console.error("Error fetching data:", error);
        } finally {
          // console.log("dataForEvaluation", dataForEvaluation);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [properties]);

  useEffect(() => {
    const filteredMedCols = medCols.filter(
      (col) => col.field !== "svg" && col.field !== "smiles"
    );

    const filteredMaiCols = MAIcols.filter(
      (col) => col.field !== "svg" && col.field !== "smiles"
    );

    const filteredToxicCols = ToxicColumns.filter(
      (col) => col.field !== "svg" && col.field !== "smiles"
    );
    const mergedCols = [
      ...columns,
      ...filteredMedCols,
      ...filteredMaiCols,
      ...filteredToxicCols,
    ];
    setAllCols(mergedCols);
  }, [medCols, columns, MAIcols, ToxicColumns]);

  useEffect(() => {
    // //console.log("rows", rows);
    let combinedRows: any[] = [];
    if (
      rows.length > 0 &&
      medicalRows.length > 0 &&
      maiRows.length > 0 &&
      toxicRows.length > 0
    ) {
      // Iterate over rows and merge corresponding objects from medicalRows
      for (let i = 0; i < rows.length; i++) {
        const row: any = rows[i];
        const medicalRow: any = medicalRows[i];
        const maiRow: any = maiRows[i];
        const toxicRow: any = toxicRows[i];

        // Create a new object to hold the merged data
        const combinedRow: any = {};

        // Copy key-value pairs from row to combinedRow
        for (const key in medicalRow) {
          combinedRow[key] = medicalRow[key];
        }
        for (const key in row) {
          combinedRow[key] = row[key];
        }
        for (const key in maiRow) {
          combinedRow[key] = maiRow[key];
        }
        for (const key in toxicRow) {
          combinedRow[key] = toxicRow[key];
        }
        combinedRows.push(combinedRow);
        // //console.log(combinedRows)
      }
    }

    setallRows(combinedRows as []);
  }, [medicalRows, maiRows, toxicRows]);

  let viewer = useRef<Viewer>();

  const initViewer = async () => {
    viewer.current = new Viewer();
    await viewer.current.init("molviewer", {
      layoutShowControls: false,
      viewportShowExpand: false,
      collapseLeftPanel: true,
      layoutShowSequence: false,
    });
  };

  useEffect(() => {
    if (!properties.data) {
      navigate("/properties-v2");
    }
  }, []);

  const updateViewer = async (
    data: string,
    format:
      | "mmcif"
      | "cifCore"
      | "pdb"
      | "pdbqt"
      | "gro"
      | "xyz"
      | "mol"
      | "sdf"
      | "mol2"
  ) => {
    initViewer().then(() => {
      const _ref = viewer?.current?.loadStructureFromData(data, format, {
        dataLabel: "",
      });
    });
  };

  const handleRowClick = (
    tabNum: any,
    params: GridRowParams, // GridRowParams
    event: any
  ) => {
    console.log("Llicked singleSmileData", params.row);
    dispatch(setSinglesmileData(params.row));
    // console.log("paramsrow", params.row);
    // console.log("dataForEvaluation", dataForEvaluation);

    if (dataForEvaluation) {
      navigate("/properties-v2/evaluations", {
        state: {
          data: dataForEvaluation,
        },
      });
    }
    // dispatch(setSinglesmileData(null));
    setCurrentRow(params.row);
    setRowSelected(true);
  };

  useEffect(() => {
    if (currentRow) {
      updateViewer(currentRow.mol2, "mol2");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRow, tabValue]);

  // change and set tab value to remember
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    setScreenieRequirements("tab", newValue);
  };

  useEffect(() => {

    const tabNum = getScreenieRequirements("tab");
    // console.log("tabname", tabNum);

    if (tabNum) setTabValue(tabNum);
    else {
      setTabValue("1");
    }
  }, []);


 

  const handleToxicFilterSubmit = () => {
    setshowToxicFilters(true);
    const smilesArray = properties.data.map((property: any) => property.smiles);
    console.log("smilesArray",smilesArray);

    async function FetchSubrules() {
      setLoading(true);
      try {
        const response = await axios.post(
          "",
          {
            input_json: {
              BRENK: {
                action: `${brenkAction}`,
                group: selectedBrenkValues,
              },
              NIH: {
                action: `${nihAction}`,
                group: selectedNIHValues,
              },
              ZINC: {
                action: `${zincAction}`,
                group: selectedZincValues,
              },
              PAINS_A: {
                action: `${painsAAction}`,
                group: selectedPainsAValues,
              },
              PAINS_B: {
                action: `${painsBAction}`,
                group: selectedPainsBValues,
              },
              PAINS_C: {
                action: `${painsCAction}`,
                group: selectedPainsCValues,
              },
            },
            list_of_smiles: smilesArray,
            top_level: false,
          },
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        console.log("response",response);

        const brenkArray = selectedBrenkValues.map((value) => ({
          field: value,
        }));
        setBrenkColumnGroupArray(brenkArray);

        const nihArray = selectedNIHValues.map((value) => ({ field: value }));
        setnihColumnGroupArray(nihArray);

        const zincArray = selectedZincValues.map((value) => ({ field: value }));
        setzincColumnGroupArray(zincArray);

        const painsAArray = selectedPainsAValues.map((value) => ({
          field: value,
        }));
        setzincColumnGroupArray(painsAArray);

        const painsBArray = selectedPainsBValues.map((value) => ({
          field: value,
        }));
        setpainsBColumnGroupArray(painsBArray);

        const painsCArray = selectedPainsCValues.map((value) => ({
          field: value,
        }));
        setpainsCColumnGroupArray(painsCArray);

        const brenkKeys = Object.keys(response.data[0]["BRENK"]);
        const nihKeys = Object.keys(response.data[0]["NIH"]);
        const zincKeys = Object.keys(response.data[0]["ZINC"]);
        const painsAKeys = Object.keys(response.data[0]["PAINS_A"]);
        const painsBKeys = Object.keys(response.data[0]["PAINS_B"]);
        const painsCKeys = Object.keys(response.data[0]["PAINS_C"]);

        const concatenatedValues = [].concat(
          brenkKeys,
          nihKeys,
          zincKeys,
          painsAKeys,
          painsBKeys,
          painsCKeys
        );

        settoxicFiltersCols(concatenatedValues);
        const modifiedToxicFilterResponse = response.data.map(
          (obj: any, index: number) => {
            const { top_level, smiles, ...rest } = obj;
            const svg = getSvgForSmiles(smiles); // Assuming getSvgForSmiles returns SVG string

            return {
              ...rest,
              smiles, // Include the 'smiles' property in the row
              svg,
              id: index, // Use the index as the unique identifier
            };
          }
        );
        settoxicFiltersallRows(modifiedToxicFilterResponse);
      } catch (error) {
        // Handle error
        console.error("Error fetching subrules:", error);
      } finally {
        setLoading(false);
        handleClose();
      }
    }

    FetchSubrules();
  };

  


  return (
    <>
      <Grid container pl={6} pr={0} pt={3} spacing={3} pb={0} height={"100%"}>
        <Grid item container xs={4} direction={"column"}>
          <Button
            variant="contained"
            startIcon={<CloudUploadIcon />}
            onClick={handleOpenModal}
          >
            Upload New File or SMILE String
          </Button>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="upload-modal-title"
            aria-describedby="upload-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Grid item mb={3} width={"35%"} sx={{ position: "relative" }}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseModal}
                sx={{ position: "absolute", top: 6, right: 15 }}
              >
                <CloseIcon />
              </IconButton>
              <PropertiesInput
                inFilterPage={true}
                handleCloseModal={handleCloseModal}
              />
            </Grid>
          </Modal>
        </Grid>

        <Grid xs={12} md={12} container spacing={2} pt={2} height={"95%"}>
          <Grid item container px={0} xs={12} pt={3} height={"100%"}>
            <Grid
              item
              width={"100%"}
              sx={{ overflowY: "auto" }}
              height={"100%"}
            >
              <Card sx={{ position: "relative" }}>
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      value={tabValue}
                      onChange={handleTabChange}
                      aria-label="filter tabs"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab label="Physicochemical Properties" value="1" />
                      <Tab label="MedChem Filters" value="2" />
                      {/* <Tab label="MAI filters" value="3"/> */}
                      <Tab label="MAI Filters" value="3" />
                      <Tab label="Toxic Filters" value="4" />
                      <Tab label="All properties and Filters" value="5" />
                    </TabList>
                  </Box>
                  {/* Properties */}
                  <TabPanel value="1">
                    {ro8TableVisible && (
                      <Box sx={{ width: "100%", height: "100%" }}>
                        <DataGridPro
                          sx={{
                            "& .MuiDataGrid-columnHeaderTitle": {
                              whiteSpace: "normal",
                              lineHeight: "normal",
                            },
                            "& .MuiDataGrid-columnHeader": {
                              // Forced to use important since overriding inline styles
                              height: "unset !important",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                              // Forced to use important since overriding inline styles
                              maxHeight: "175px !important",
                              textAlign: "center",
                            },
                          }}
                          rows={rows}
                          getRowId={(row) => row.id}
                          columns={columns}
                          rowHeight={150}
                          onRowClick={(params, event) =>
                            handleRowClick(tabValue, params, event)
                          }
                          density="compact"
                          disableMultipleColumnsFiltering={false}
                          pagination
                          initialState={{
                            pagination: {
                              paginationModel: { pageSize: 10, page: 0 },
                            },
                            pinnedColumns: { left: ["id", "Smiles"] },
                          }}
                          pageSizeOptions={[10, 25, 50]}
                          autoHeight
                          slots={{ toolbar: GridToolbar }}
                        />
                      </Box>
                    )}
                  </TabPanel>
                  <TabPanel value="2">
                    {ro8TableVisible && (
                      <Box sx={{ width: "100%", height: "100%" }}>
                        <DataGridPro
                          rows={medicalRows}
                          getRowId={(row) => row.smiles}
                          columns={medCols}
                          rowHeight={150}
                          onRowClick={(params, event) =>
                            handleRowClick(tabValue, params, event)
                          }
                          disableMultipleColumnsFiltering={false}
                          density="compact"
                          pagination
                          initialState={{
                            pagination: {
                              paginationModel: { pageSize: 10, page: 0 },
                            },
                            pinnedColumns: { left: ["smiles"] },
                          }}
                          pageSizeOptions={[10, 25, 50]}
                          autoHeight
                          slots={{ toolbar: GridToolbar }}
                        />
                      </Box>
                    )}
                  </TabPanel>

                  <TabPanel value="3">
                    {ro8TableVisible && (
                      <Box sx={{ width: "100%", height: "100%" }}>
                        <DataGridPro
                          rows={maiRows}
                          getRowId={(row) => row.SMILES}
                          columns={MAIcols}
                          rowHeight={150}
                          onRowClick={(params, event) =>
                            handleRowClick(tabValue, params, event)
                          }
                          disableMultipleColumnsFiltering={false}
                          density="compact"
                          pagination
                          initialState={{
                            pagination: {
                              paginationModel: { pageSize: 10, page: 0 },
                            },
                            pinnedColumns: { left: ["smiles"] },
                          }}
                          pageSizeOptions={[10, 25, 50]}
                          autoHeight
                          slots={{ toolbar: GridToolbar }}
                        />
                      </Box>
                    )}
                  </TabPanel>
                  <TabPanel value="5">
                    {ro8TableVisible && (
                      <Box sx={{ width: "100%", height: "100%" }}>
                        <DataGridPro
                          rows={allRows}
                          getRowId={(row) => row.smiles}
                          columns={allCols}
                          rowHeight={150}
                          onRowClick={(params, event) =>
                            handleRowClick(tabValue, params, event)
                          }
                          disableMultipleColumnsFiltering={false}
                          density="compact"
                          pagination
                          initialState={{
                            pagination: {
                              paginationModel: { pageSize: 10, page: 0 },
                            },
                            pinnedColumns: { left: ["id", "smiles"] },
                          }}
                          pageSizeOptions={[10, 25, 50]}
                          autoHeight
                          slots={{ toolbar: GridToolbar }}
                        />
                      </Box>
                    )}
                  </TabPanel>

                  <TabPanel value="4">
                    {ro8TableVisible && (
                      <Box sx={{ width: "100%", height: "100%" }}>
                        <Button size="small" onClick={handleOpen}>
                          Customize Toxic Groups Subrules
                        </Button>
                        <Button
                          size="small"
                          onClick={() => setshowToxicFilters(false)}
                          disabled={!showToxicFilters}
                        >
                          Top Level Filters
                        </Button>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box
                            sx={{
                              position: "absolute" as "absolute",
                              top: "50%",
                              left: "50%",
                              right: "40%",
                              transform: "translate(-50%, -50%)",
                              width: 1300,
                              bgcolor: "background.paper",
                              border: "2px solid #000",
                              boxShadow: 24,
                              p: 4,
                              height: 970,
                            }}
                          >
                            <Paper
                              sx={{
                                padding: 4,
                                borderRadius: 6,
                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                              }}
                            >
                              <Typography
                                variant="h3"
                                textAlign="center"
                                color="primary"
                                fontWeight={700}
                                marginBottom={6}
                              >
                                Customize Toxic Groups by Subrules
                              </Typography>
                              <Grid container spacing={2}>
                                {/* Heading column */}
                                <Grid item xs={2}>
                                  <Typography
                                    variant="h6"
                                    sx={{ marginTop: 6 }}
                                  >
                                    Brenk
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    sx={{ marginTop: 6 }}
                                  >
                                    NIH
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    sx={{ marginTop: 6 }}
                                  >
                                    Zinc
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    sx={{ marginTop: 6 }}
                                  >
                                    Pains_A
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    sx={{ marginTop: 6 }}
                                  >
                                    Pains_B
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    sx={{ marginTop: 6 }}
                                  >
                                    Pains_C
                                  </Typography>
                                </Grid>
                                {/* Form control column */}
                                <Grid item xs={4}>
                                  <FormControl
                                    sx={{ marginTop: 3, width: "15rem" }}
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      Brenk Actions
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={brenkAction}
                                      label="Actions"
                                      onChange={(e: SelectChangeEvent) =>
                                        setbrenkAction(e.target.value as string)
                                      }
                                    >
                                      <MenuItem value={"SELECT_ALL"}>
                                        Select all groups
                                      </MenuItem>
                                      <MenuItem value={"SELECT_FEW"}>
                                        Select few groups and ignore others
                                      </MenuItem>
                                      <MenuItem value={"IGNORE_FEW"}>
                                        Ignore few groups and select others
                                      </MenuItem>
                                      <MenuItem value={"IGNORE_ALL"}>
                                        Ignore all groups
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                  <FormControl
                                    sx={{ marginTop: 3, width: "15rem" }}
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      NIH Actions
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={nihAction}
                                      label="Actions"
                                      onChange={(e: SelectChangeEvent) =>
                                        setnihAction(e.target.value as string)
                                      }
                                    >
                                      <MenuItem value={"SELECT_ALL"}>
                                        Select all groups
                                      </MenuItem>
                                      <MenuItem value={"SELECT_FEW"}>
                                        Select few groups and ignore others
                                      </MenuItem>
                                      <MenuItem value={"IGNORE_FEW"}>
                                        Ignore few groups and select others
                                      </MenuItem>
                                      <MenuItem value={"IGNORE_ALL"}>
                                        Ignore all groups
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                  <FormControl
                                    sx={{ marginTop: 3, width: "15rem" }}
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      Zinc Actions
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={zincAction}
                                      label="Actions"
                                      onChange={(e: SelectChangeEvent) =>
                                        setzincAction(e.target.value as string)
                                      }
                                    >
                                      <MenuItem value={"SELECT_ALL"}>
                                        Select all groups
                                      </MenuItem>
                                      <MenuItem value={"SELECT_FEW"}>
                                        Select few groups and ignore others
                                      </MenuItem>
                                      <MenuItem value={"IGNORE_FEW"}>
                                        Ignore few groups and select others
                                      </MenuItem>
                                      <MenuItem value={"IGNORE_ALL"}>
                                        Ignore all groups
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                  <FormControl
                                    sx={{ marginTop: 3, width: "15rem" }}
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      Pains_A Actions
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={painsAAction}
                                      label="Actions"
                                      onChange={(e: SelectChangeEvent) =>
                                        setpainsAAction(
                                          e.target.value as string
                                        )
                                      }
                                    >
                                      <MenuItem value={"SELECT_ALL"}>
                                        Select all groups
                                      </MenuItem>
                                      <MenuItem value={"SELECT_FEW"}>
                                        Select few groups and ignore others
                                      </MenuItem>
                                      <MenuItem value={"IGNORE_FEW"}>
                                        Ignore few groups and select others
                                      </MenuItem>
                                      <MenuItem value={"IGNORE_ALL"}>
                                        Ignore all groups
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                  <FormControl
                                    sx={{ marginTop: 3, width: "15rem" }}
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      Pains_B Actions
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={painsBAction}
                                      label="Actions"
                                      onChange={(e: SelectChangeEvent) =>
                                        setpainsBAction(
                                          e.target.value as string
                                        )
                                      }
                                    >
                                      <MenuItem value={"SELECT_ALL"}>
                                        Select all groups
                                      </MenuItem>
                                      <MenuItem value={"SELECT_FEW"}>
                                        Select few groups and ignore others
                                      </MenuItem>
                                      <MenuItem value={"IGNORE_FEW"}>
                                        Ignore few groups and select others
                                      </MenuItem>
                                      <MenuItem value={"IGNORE_ALL"}>
                                        Ignore all groups
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                  <FormControl
                                    sx={{ marginTop: 3, width: "15rem" }}
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      Pains_C Actions
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={painsCAction}
                                      label="Actions"
                                      onChange={(e: SelectChangeEvent) =>
                                        setpainsCAction(
                                          e.target.value as string
                                        )
                                      }
                                    >
                                      <MenuItem value={"SELECT_ALL"}>
                                        Select all groups
                                      </MenuItem>
                                      <MenuItem value={"SELECT_FEW"}>
                                        Select few groups and ignore others
                                      </MenuItem>
                                      <MenuItem value={"IGNORE_FEW"}>
                                        Ignore few groups and select others
                                      </MenuItem>
                                      <MenuItem value={"IGNORE_ALL"}>
                                        Ignore all groups
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                  {/* Add more FormControl components for each heading */}
                                </Grid>
                                {/* Autocomplete column */}
                                <Grid item xs={6}>
                                  <Autocomplete
                                    sx={{ marginTop: 1 }}
                                    multiple
                                    disabled={
                                      brenkAction === "IGNORE_ALL" ||
                                      brenkAction === "SELECT_ALL"
                                    }
                                    id="tags-standard"
                                    options={Object.values(
                                      subCatalogs["BRENK"] ?? {}
                                    )}
                                    value={selectedBrenkValues}
                                    onChange={(event, newValue) => {
                                      setSelectedBrenkValues(newValue || []);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        label="Brenk Catalogs"
                                      />
                                    )}
                                  />

                                  <Autocomplete
                                    sx={{ marginTop: 5 }}
                                    multiple
                                    disabled={
                                      nihAction === "IGNORE_ALL" ||
                                      nihAction === "SELECT_ALL"
                                    }
                                    id="tags-standard"
                                    options={Object.values(
                                      subCatalogs["NIH"] ?? {}
                                    )}
                                    value={selectedNIHValues}
                                    onChange={(event, newValue) => {
                                      setSelectedNIHValues(newValue || []);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        label="NIH Catalogs"
                                      />
                                    )}
                                  />
                                  <Autocomplete
                                    sx={{ marginTop: 5 }}
                                    multiple
                                    disabled={
                                      zincAction === "IGNORE_ALL" ||
                                      zincAction === "SELECT_ALL"
                                    }
                                    id="tags-standard"
                                    options={Object.values(
                                      subCatalogs["ZINC"] ?? {}
                                    )}
                                    value={selectedZincValues}
                                    onChange={(event, newValue) => {
                                      setSelectedZincValues(newValue || []);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        label="Zinc Catalogs"
                                      />
                                    )}
                                  />
                                  <Autocomplete
                                    sx={{ marginTop: 5 }}
                                    multiple
                                    disabled={
                                      painsAAction === "IGNORE_ALL" ||
                                      painsAAction === "SELECT_ALL"
                                    }
                                    id="tags-standard"
                                    options={Object.values(
                                      subCatalogs["PAINS_A"] ?? {}
                                    )}
                                    value={selectedPainsAValues}
                                    onChange={(event, newValue) => {
                                      setSelectedPainsAValues(newValue || []);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        label="Pains_A Catalogs"
                                      />
                                    )}
                                  />
                                  <Autocomplete
                                    sx={{ marginTop: 5 }}
                                    multiple
                                    disabled={
                                      painsBAction === "IGNORE_ALL" ||
                                      painsBAction === "SELECT_ALL"
                                    }
                                    id="tags-standard"
                                    options={Object.values(
                                      subCatalogs["PAINS_B"] ?? {}
                                    )}
                                    value={selectedPainsBValues}
                                    onChange={(event, newValue) => {
                                      setSelectedPainsBValues(newValue || []);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        label="Pains_B Catalogs"
                                      />
                                    )}
                                  />
                                  <Autocomplete
                                    sx={{ marginTop: 5 }}
                                    multiple
                                    disabled={
                                      painsCAction === "IGNORE_ALL" ||
                                      painsCAction === "SELECT_ALL"
                                    }
                                    id="tags-standard"
                                    options={Object.values(
                                      subCatalogs["PAINS_C"] ?? {}
                                    )}
                                    value={selectedPainsCValues}
                                    onChange={(event, newValue) => {
                                      setSelectedPainsCValues(newValue || []);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        label="Pains_C Catalogs"
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Paper>

                            <Box
                              display="flex"
                              justifyContent="space-between"
                              sx={{
                                mt: 2,
                              }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleToxicFilterSubmit}
                              >
                                Submit
                              </Button>
                            </Box>
                          </Box>
                        </Modal>
                        {showToxicFilters ? (
                          <DataGridPro
                            rows={toxicFiltersallRows}
                            getRowId={(row) => row.smiles}
                            columns={toxicFiltersAllcols}
                            rowHeight={150}
                            onRowClick={(params, event) =>
                              handleRowClick(tabValue, params, event)
                            }
                            disableMultipleColumnsFiltering={false}
                            density="compact"
                            pagination
                            initialState={{
                              pagination: {
                                paginationModel: { pageSize: 10, page: 0 },
                              },
                              pinnedColumns: { left: ["id", "smiles"] },
                            }}
                            pageSizeOptions={[10, 25, 50]}
                            autoHeight
                            slots={{ toolbar: GridToolbar }}
                            // columnGroupingModel={columnGroupingModel}
                          />
                        ) : (
                          <DataGridPro
                            rows={toxicRows}
                            getRowId={(row) => row.smiles}
                            columns={ToxicColumns}
                            rowHeight={150}
                            onRowClick={(params, event) =>
                              handleRowClick(tabValue, params, event)
                            }
                            disableMultipleColumnsFiltering={false}
                            density="compact"
                            pagination
                            initialState={{
                              pagination: {
                                paginationModel: { pageSize: 10, page: 0 },
                              },
                              pinnedColumns: { left: ["id", "smiles"] },
                            }}
                            pageSizeOptions={[10, 25, 50]}
                            autoHeight
                            slots={{ toolbar: GridToolbar }}
                          />
                        )}
                      </Box>
                    )}
                    {/* <Box>
                      <Button onClick={handleOpen}>Customise</Button>
                      
                    {/* </Box>  */}
                  </TabPanel>
                </TabContext>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <IndefiniteLoader state={loading} />
    </>
  );
}

export default PropertiesV2;

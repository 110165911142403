import React from "react";
import { chemletsCards } from "./chemletCards";
import { Card, Grid } from "@mui/material";
import { DashCard } from "../../components";

function ChemletDashboard() {
  return (
    <Grid container spacing={3} p={3}>
      {chemletsCards.map((card, index) => (
        <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 2,
              flex: "1 0 0",
              borderRadius: "18px",
              border: "1px solid var(--shade-200, #E7EBF9)",
              height: "100%",
              "&:hover": {
                boxShadow: 7,
              },
            }}
          >
            <DashCard key={index} {...card} section="Genie" />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default ChemletDashboard;

import httpCommon from "../../net/http-common";
import { MEDFILTERS_URL } from "../../config";

export const getProperties = ({
  smiles,
  smilesFile,
  from_model,
  model_name,
  job_id,
}: {
  smiles: string;
  smilesFile: string;
  from_model: boolean;
  model_name: string;
  job_id: string;
}) => {
  return httpCommon.post(
    "/properties/properties",
    {
      smiles: {
        smiles: smiles,
      },
      file: {
        smiles_file: smilesFile,
      },
      params: {
        from_model: from_model,
        model_name: model_name,
        job_id: job_id,
      },
    },
    {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
      },
    }
  );
};

export const getPropertiesV2 = ({
  smiles,
  smilesFile,
  headerName,
  from_model,
  model_name,
  job_id,
}: {
  smiles: string;
  smilesFile: string;
  headerName: string;
  from_model: boolean;
  model_name: string;
  job_id: string;
}) => {
  //console.log("getpropertiesv2 props");
  return httpCommon.post(
    "/properties/properties/v2",
    {
      smiles: {
        smiles: smiles,
      },
      file: {
        smiles_file: smilesFile,
      },
      params: {
        from_model: from_model,
        model_name: model_name,
        job_id: job_id,
      },
    },
    {
      params: {
        smiles_column: headerName,
      },
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
      },
    }
  );
};

export const getMedchemFilterData = ({
  submittedSmiles,
}: {
  submittedSmiles: string[];
}) => {
  //console.log("getpropertiesv2 props");
  return httpCommon.post(`${MEDFILTERS_URL}/predict_smiles/`, submittedSmiles, {
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
    },
  });
};

export const getMaiFilterData = ({
  submittedSmiles,
}: {
  submittedSmiles: string[];
}) => {
  //console.log("getpropertiesv2 props");
  return httpCommon.post(
    `https://gamma.knowdisdata.com/molfil/`,
    { smiles: submittedSmiles },
    {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
      },
    }
  );
};

export const getToxicFilterData = ({
  submittedSmiles,
}: {
  submittedSmiles: string[];
}) => {
  //console.log("getpropertiesv2 props");
  return httpCommon.post(
    `https://gamma.knowdisdata.com/toxgroups/filter/`,
    {
      input_json: {
        BRENK: {
          action: "SELECT_ALL",
          group: [],
        },
        NIH: {
          action: "SELECT_ALL",
          group: [],
        },
        ZINC: {
          action: "SELECT_ALL",
          group: [],
        },
        PAINS_A: {
          action: "SELECT_ALL",
          group: [],
        },
        PAINS_B: {
          action: "SELECT_ALL",
          group: [],
        },
        PAINS_C: {
          action: "SELECT_ALL",
          group: [],
        },
      },
      list_of_smiles: submittedSmiles,
    },
    {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
      },
    }
  );
};

export const getAllRowsFilterData = () => {
  //console.log("getpropertiesv2 props");
  return httpCommon.get(`https://gamma.knowdisdata.com/toxgroups/get_rules`, {
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
    },
  });
};

export const getPropertiesV3 = ({
  smiles,
}: {
  smiles: string;
}) => {
  return httpCommon.post(
    `/properties/properties/v3`,
    { smiles: smiles },
    {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
      },
    }
  );
};

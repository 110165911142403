
import {GridFilterModel} from "@mui/x-data-grid-pro";

// based on dropdown value the function returns operator and value 
export const getFilterType=(filterType:boolean):GridFilterModel=>{
    let filterConf:string[]=[];
    filterConf.push(filterType?"between":"outOfRange");
    filterConf.push(filterType?"Pass":"Fail");

      const admetDefaultFilters: GridFilterModel = {
        items:[
              {
                id: 1,
                field: "clintox",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 2,
                field: "cardio",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 3,
                field: "hepato",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 4,
                field: "respiratory",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 5,
                field: "cytotoxicity",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 6,
                field: "mutagen",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 7,
                field: "carci",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 8,
                field: "mouse_oral",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 9,
                field: "caco",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 10,
                field: "pampa",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 11,
                field: "pgp",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 12,
                field: "solubility",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 13,
                field: "lipo",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 14,
                field: "bbb",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 15,
                field: "CYP1A2_in",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 16,
                field: "CYP2C19_in",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 17,
                field: "CYP2C9_in",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 18,
                field: "CYP2D6_in",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 19,
                field: "CYP3A4_in",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 20,
                field: "CYP2C9_sub",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 21,
                field: "CYP2D6_sub",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 22,
                field: "CYP3A4_sub",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 23,
                field: "NR-AR",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 24,
                field: "NR-AR-LBD",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 25,
                field: "NR-AhR",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 26,
                field: "NR-Aromatase",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 27,
                field: "NR-ER",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 28,
                field: "NR-ER-LBD",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 29,
                field: "NR-PPAR-gamma",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 30,
                field: "SR-ARE",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 31,
                field: "SR-ATAD5",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 32,
                field: "SR-HSE",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 33,
                field: "SR-MMP",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 34,
                field: "SR-p53",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 35,
                field: "LOAEL",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 36,
                field: "ppbr",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 37,
                field: "hia_hou",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id: 38,
                field: "hl_log",
                operator: filterConf[0],
                value: filterConf[1],
              },
              {
                id:39,
                field: "vdss_log",
                operator: filterConf[0],
                value: filterConf[1],
              },
        ]
      }

    
    return admetDefaultFilters;
}
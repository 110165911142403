import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { DiffMolState } from "../../models";
import { getTargetData } from "./diffMolApi";



const initialState: DiffMolState = {
  status: "idle",
  targetData: {
    data: null,
    loading: false,
  },
};

export const fetchTargetData = createAsyncThunk(
  "diffMol/fetchTargetData",
  async ({ target }: { target: string }) => {
    try{
    const response = await getTargetData({ target });
    return response.data;}
    catch(error){
      return {error};
    }
  }
);



export const diffMolSlice = createSlice({
  name: "diffMol",

  initialState,
  reducers: {
    setDiffMol(state, action) {
      //console.log(action);
      state.targetData = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Fetch Ligand Preparation
    builder
      .addCase(fetchTargetData.fulfilled, (state, action) => {
        state.targetData.data = Object.values(action.payload);
        state.targetData.loading = false;
      })
      .addCase(fetchTargetData.rejected, (state, action) => {
        state.targetData.loading = false;
      })
      .addCase(fetchTargetData.pending, (state) => {
        state.targetData.loading = true;
      })

  },
});

export const { setDiffMol } = diffMolSlice.actions;
export const selectDiffMol = (state: RootState) => state.diffMol;
export default diffMolSlice.reducer;

import { useState } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function useForm<T>(initialValues: T): [T, (e: any) => void] {
  const [values, setValues] = useState(initialValues);
  return [values, (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }];
}


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { JOB_SERVER_URL } from "../../config";

interface stateType {
  status: string;
  properties: {
    promiscuityData: any;
    loading: boolean;
  };
}

const initialState: stateType = {
  status: "idle",
  properties: {
    promiscuityData: [],
    loading: false,
  },
};

export const fetchPromiscuityResults = createAsyncThunk(
  "promiscuity/fetchPromiscuityResults",
  async (task_id: string) => {
    return axios
      .get(
        `${JOB_SERVER_URL}/pmsfilter/results?task_id=${task_id}&as_file=false`
      )
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  }
);

const promiscuitySlice = createSlice({
  name: "promiscuity",

  initialState,
  reducers: {
    setProperties(state, action) {
      //console.log(action);
    },
  },
  extraReducers: (builder) => {
    // Fetch Properties
    builder
      .addCase(
        fetchPromiscuityResults.fulfilled,
        (state: stateType, action: any) => {
          state.properties.promiscuityData = action.payload;
          state.properties.loading = false;
        }
      )
      .addCase(fetchPromiscuityResults.rejected, (state, action: any) => {
        state.properties.loading = false;
      })
      .addCase(fetchPromiscuityResults.pending, (state) => {
        state.properties.loading = true;
      });
  },
});

export const { setProperties } = promiscuitySlice.actions;
export default promiscuitySlice.reducer;

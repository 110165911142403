import React, { useEffect } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormData from "form-data";
import { SSProps, TabPanelProps } from "../../models";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchSSData, selectPdbInfo } from "../../views/PDBInfo/pdbInfoSlice";
import { CircularProgress, Alert, Snackbar } from "@mui/material";
import { useState } from "react";

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function SecStructure({ pdbId, jobDir }: SSProps) {
  const [tabValue, setTabValue] = React.useState(0);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const dispatch = useAppDispatch();
  const pdbInfo = useAppSelector(selectPdbInfo);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    //console.log('errat pdbID', pdbId)
    const form = new FormData();
    form.append("pdb_file", "");

    if (!pdbId || !jobDir) return;
    if (pdbInfo?.ss?.data) return;
    dispatch(fetchSSData({ form, pdbID: pdbId, jobDir }))
      .then((res) => {
        if (res.payload.error) {
          setShowError(true);
          setErrorMsg("Error loading Structure Information");
        }
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg("Error loading Structure Information");
      });
  }, [pdbId, jobDir]);

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Box sx={{ width: "100%" }}>
        {pdbInfo?.ss?.loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={tabValue} onChange={handleChange}>
                {pdbInfo?.ss?.data?.images?.map((e, i) => {
                  const chain = `Chain ${e[0]}`;
                  return <Tab label={chain} key={i} />;
                })}
              </Tabs>
            </Box>
            <>
              {pdbInfo?.ss?.data?.images?.map((v, i) => {
                return (
                  <TabPanel key={i} value={tabValue} index={i} url={""}>
                    <Box
                      component={"img"}
                      sx={{
                        width: "100%",
                      }}
                      className="ss-image"
                      src={`data:image/png;base64,${v[1]}`}
                      alt="value"
                    />
                  </TabPanel>
                );
              })}
            </>
          </>
        )}
      </Box>
    </>
  );
}

import { Backdrop } from "@mui/material";
import React from "react";
import { CSSProperties } from "@mui/styled-engine";
import logo from '../../MoleculeGEN_White_Grey_Logo.png'


interface Props {
    handleLoaderEnd: () => void;
  }
  
const HomeLoader:React.FC<Props>=({handleLoaderEnd})=>{

    return(
<>

<div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
}}>
      <div style={{
        position: 'relative',
      }}>
        <video autoPlay muted onEnded={()=>{handleLoaderEnd()}} style={{
              maxHeight: '300px',
             // maxWidth: '300px',
              display: 'block',
              margin: '0 auto',
        }}>
          <source src="videos/home_loader.mp4" type="video/mp4" />
        </video>
        <img src={logo} alt="MoleculeGEN" style={{
              width: '60%', 
              maxWidth: '400px',
              display: 'block',
              margin: '20px auto 0',
        }} />
      </div>

    </div>
        </>
    )
}
export default HomeLoader
import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Navbar from "./Navbar";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "remixicon/fonts/remixicon.css";
import { useState, useEffect } from "react";
import { Tooltip, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import path from "path";
import { LicenseInfo } from "@mui/x-license";
import { excludedPaths, navItems } from "./layoutHelper";
import '../../css/typography.css'

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children?: any;
}

export default function Layout(props: Props) {
  const [drawerWidth, setDrawerWidth] = useState<string>("300");
  const [backDisplay, setbackdisplay] = useState(false);
  // const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerToggle = (value: string) => {
    setDrawerWidth(value);
  };

  useEffect(() => {
    // Check if the current pathname matches any of the excluded paths
    const shouldDisplayBackButton = !excludedPaths.includes(
      window.location.pathname
    );
    setbackdisplay(shouldDisplayBackButton);
    const MUI_KEY: string = process.env.REACT_APP_MUI_KEY as string;

    LicenseInfo.setLicenseKey(MUI_KEY);
  }, [window.location.pathname]);

  return (
    <React.Fragment>
      <Navbar handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: "auto",
          mt: "94px",
          backgroundColor: "#EAEAEC",
          height: "calc(100vh - 94px)",
          overflow: "auto",
        }}
      >
        {backDisplay && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{
              ml: 3,
              mt: 1.5,
            }}
          >
            <Tooltip title="Back">
              <ArrowBackIosIcon
                color="primary"
                sx={{
                  fontSize: 40,
                  width: 28,
                  cursor: "pointer",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate(-1)}
              />
            </Tooltip>
            <Typography
              variant="body1"
              color="primary"
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => navigate(-1)}
            >
              Back
            </Typography>
          </Box>
        )}
        <Outlet />
      </Box>
    </React.Fragment>
  );
}

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Alert,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Button, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

interface TableData {
  title: string;
  value: string;
  color?: string;
  info: string;
  lowerValue: number | null;
  upperValue: number | null;
  toolTipText: string;
  toolTipColor: string;
}

interface SectionData {
  heading: string;
  data: TableData[];
}

interface Props {
  adme_data: SectionData[];
}

const ADMETable: React.FC<Props> = ({ adme_data }) => {
  // const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: "white",
  //   },
  //   "&:nth-of-type(even)": {
  //     backgroundColor: "#f2f7f9",
  //   },
  //   "& td": {
  //     padding: 4,
  //   },
  // }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#EDEBF9",
    },
    "& td": {
      padding: 4,
    },
  }));
  return (
    <Paper variant="outlined" square sx={{ borderRadius: 5 }}>
      <TableContainer component={Paper} style={{ overflow: "hidden" }}>
        {adme_data.map((row: SectionData, index: number) => (
          <Table key={index}>
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={6}
                  align="center"
                  // style={{ backgroundColor: "#d1ecf1", padding: 0 }}
                  style={{ backgroundColor: "rgb(196, 187, 249)", padding: 0 }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "rgb(81, 59, 206)" }}
                    py={1}
                  >
                    {row.heading}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.data.length === 0 ? (
                <StyledTableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{ backgroundColor: "white", padding: 0 }}
                  >
                    <Typography py={0.2}>No data</Typography>
                  </TableCell>
                </StyledTableRow>
              ) : (
                row.data.map((rowData, index) => (
                  <StyledTableRow key={index}>
                    <TableCell width="60%" className="text-left">
                      {rowData.title}
                    </TableCell>
                    <TableCell width="20%" sx={{ textAlign: "center" }}>
                      {parseFloat(rowData.value).toFixed(3)}
                    </TableCell>
                    {/* <TableCell width="20%">
                      <FiberManualRecordIcon sx={{ fontSize: 16,marginTop:0.6}} style={{ color: rowData.color }} />
                    </TableCell> */}
                    <TableCell width="10%">
                      <Tooltip
                        title={
                          <div>
                            <p>
                              {rowData.toolTipText}
                              <br />
                              Lower Limit :{" "}
                              {rowData.lowerValue != null
                                ? rowData.lowerValue
                                : "NULL"}
                              <br />
                              Upper Limit :{" "}
                              {rowData.upperValue != null
                                ? rowData.upperValue
                                : "NULL"}
                            </p>
                          </div>
                        }
                        placement="top"
                        arrow
                      >
                        <CircleIcon
                          sx={{
                            fontSize: 14,
                            marginTop: 0.6,
                            color: rowData.toolTipColor,
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell width="10%">
                      <Tooltip
                        title={
                          <Typography fontSize={13}>{rowData.info}</Typography>
                        }
                        placement="right"
                        arrow
                      >
                        <InfoIcon sx={{ fontSize: 16, marginTop: 0.6 }} />
                      </Tooltip>
                    </TableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        ))}
      </TableContainer>
    </Paper>
  );
};

export default ADMETable;

import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useRef } from "react";
import Papa from "papaparse";

type FileUploaderProps = {
  handleFileUpload: (files: FileList) => {} | void;
  handleSelectedHeader?: (header: string) => {} | void;
  accept?: string;
  multiple?: boolean;
  headerSelector?: boolean; // for excel files to choose smile column
  maxSize?: number; //to display max size
  fileUploadMsg?: string; // to display message
  deleteHandlerDisable?: () => void;
  setMolBenchFiles?: React.Dispatch<React.SetStateAction<[] | File[]>>;
  molBenchfiles?: [] | File[];
};

function FileUploader({
  handleFileUpload,
  handleSelectedHeader,
  accept,
  multiple = false,
  headerSelector = false,
  maxSize = 20,
  fileUploadMsg,
  deleteHandlerDisable,
  setMolBenchFiles,
  molBenchfiles,
}: FileUploaderProps) {
  const id = Date.now();
  const [files, setFiles] = useState<File[] | []>([]);
  const [dragging, setDragging] = useState(false);
  const labelRef = useRef<HTMLLabelElement>();
  const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
  const [selectedHeader, setSelectedHeader] = useState<string>("");
  const [isFileValid, setIsFileValid] = useState(true);

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragEnter = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setDragging(false);

    setFiles(Array.from(e.dataTransfer.files));
    if (setMolBenchFiles) setMolBenchFiles(Array.from(e.dataTransfer.files));
  };

  const fileFormatChecker = (
    e: React.ChangeEvent<HTMLInputElement>
  ): Boolean => {
    if (!accept) {
      setIsFileValid(true);
      return true;
    }
    const file = e.target.files[0];
    const validFormats = accept.split(",").map((item) => item.trim());
    const fileExtensions = validFormats.filter((item) => item.startsWith("."));
    const fileTypes = validFormats.filter((item) => !item.startsWith("."));

    for (let i = 0; i < fileTypes.length; i++) {
      if (file.type == fileTypes[i]) {
        setIsFileValid(true);
        return true;
      }
    }
    for (let i = 0; i < fileExtensions.length; i++) {
      var curerntExt = fileExtensions[i];
      var fileName: string = file.name;
      const dotIndex = fileName.lastIndexOf(".");

      if (dotIndex !== -1) {
        const fileExtension = fileName.slice(dotIndex);
        if (fileExtension.toLowerCase() === curerntExt.toLowerCase()) {
          setIsFileValid(true);
          return true;
        }
      }
    }
    setIsFileValid(false);
    return false;
  };

  const getAllHeaders = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (result) => {
          const headers = result.meta.fields;

          if (headers.length > 0) {
            setSelectedHeader(headers[0]);
            if (handleSelectedHeader) handleSelectedHeader(headers[0]);
          }

          setCsvHeaders(headers);
        },
        error: (error) => {
          console.error(error.message);
        },
      });
    }
  };

  const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files.length) return;

    if (!fileFormatChecker(e)) return;

    // //console.log("here");
    setFiles(Array.from(e.target.files));
    if (setMolBenchFiles) setMolBenchFiles(Array.from(e.target.files));

    if (headerSelector) getAllHeaders(e);

    handleFileUpload(e.target.files);
  };

  const deleteFile = (index: number) => {
    // console.log("deletc called");
    const newFile = files.filter((file, i) => i !== index);
    setFiles(newFile);
    setIsFileValid(true);
    if (headerSelector) {
      setCsvHeaders([]);
      setSelectedHeader("");
    }

    if (newFile.length == 0) {
      deleteHandlerDisable();
    }
  };

  useEffect(() => {
    if (molBenchfiles && molBenchfiles.length > 0) {
      setFiles(molBenchfiles);
    }
  }, []);

  return (
    <Box
      sx={{
        borderRadius: "10px",
        border: "1px dashed #DEE2E6",
        background: "#FFF",
        p: 2,
        cursor: "pointer",
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems={"center"}
        component={"label"}
        htmlFor={"upload-file-" + id}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        ref={labelRef}
      >
        <Grid item>
          <Box
            sx={{
              borderRadius: "6px",
              border: "1px solid #E5DBF8",
              background: "var(--shade-2100, #F7F3FF)",
              padding: "14px 15px 8px",
            }}
          >
            <FileUploadIcon />
          </Box>
        </Grid>
        <Grid item>
          <Typography>{"Upload or drag/drop a file"}</Typography>
          <Typography color={"var(--shade-2600, #7F7D95)"} fontSize={"12px"}>
            {fileUploadMsg
              ? fileUploadMsg
              : `Max size ${
                  maxSize >= 1024 ? (maxSize / 1024).toFixed(2) : maxSize
                } ${maxSize >= 1024 ? "GB" : "MB"}`}
          </Typography>
        </Grid>
        <Grid item sx={{ margin: "0 auto" }}>
          <Box
            component="button"
            sx={{
              py: 1,
              px: 2,
              borderRadius: "6px",
              border: "1px solid #D6E9FF",
              background: "var(--shade-2100, #F7F3FF)",
              color: "var(--primary-2-purpel, #582FF1)",
            }}
            onClick={() => labelRef.current.click()}
          >
            {"Browse"}
          </Box>
        </Grid>
      </Grid>
      <input
        hidden={true}
        type="file"
        id={"upload-file-" + id}
        accept={accept}
        onChange={onFileUpload}
        multiple={multiple}
      />
      <List dense={true}>
        {files.map((file: File, index: number) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton edge={"end"} onClick={() => deleteFile(index)}>
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemIcon>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textWrap: "nowrap",
                },
              }}
              primary={
                <Tooltip title={file.name}>
                  <span>
                    {file.name.length > 20
                      ? `${file.name.substring(0, 20)}...`
                      : file.name}
                  </span>
                </Tooltip>
              }
              secondary={file.size}
            />
          </ListItem>
        ))}
      </List>
      <Grid>
        {headerSelector && csvHeaders.length > 0 && (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              SMILES Column Header
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedHeader}
              label="File Header"
              onChange={(e) => {
                setSelectedHeader(e.target.value as string);
                if (handleSelectedHeader) handleSelectedHeader(e.target.value);
              }}
            >
              {csvHeaders.map((header, index) => (
                <MenuItem key={index} value={header}>
                  {header}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
      <Grid>
        {!isFileValid && (
          <Typography color="error" variant="body2">
            {`File type is not valid. Require ${accept} file`}
          </Typography>
        )}
      </Grid>
    </Box>
  );
}

export default FileUploader;

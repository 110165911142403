import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface JobStatusDialogProps {
    open: boolean;
    onClose: () => void;
    data?: {
        message: string;
        ranker_id: string;
        state: string;
    };
}

const JobStatusDialog: React.FC<JobStatusDialogProps> = ({ open, onClose, data }) => {
    const isRunning = data?.state === "RUNNING";
    const icon = isRunning ? <CircularProgress color="info" size={30} /> : <ErrorOutlineIcon color="error" fontSize="large" />;
    const titleColor = isRunning ? "success.main" : "error.main";

    return (
        <Dialog open={open} maxWidth="xs" fullWidth>
            <DialogTitle>
                <Box display="flex" alignItems="center" gap={1}>
                    {icon}
                    <Typography variant="h6" color={titleColor}>
                        Job State : {data?.state}
                    </Typography>
                </Box>
            </DialogTitle>

            <DialogContent dividers>
                {data ? (
                    <Box display="flex" flexDirection="column" gap={2}>
                        <Typography variant="body1" color="textSecondary">
                            {data.message}
                        </Typography>
                        <Box display="flex" flexDirection="column" gap={1} p={1} bgcolor="#f5f5f5" borderRadius={1}>
                        <Typography variant="body2">
                            <Typography component="span" fontWeight="bold">
                                Ranker ID: 
                            </Typography>
                            <Typography component="span"  ml={0.5}>
                                {data.ranker_id || "N/A"}
                            </Typography>
                            </Typography>
                            <Typography variant="body2">
                                <Typography component="span" fontWeight="bold">
                                    State:
                                </Typography>
                                <Typography component="span" color={titleColor} ml={0.5} fontWeight="bold">
                                    {data.state}
                                </Typography>
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <Typography variant="body2">No data available</Typography>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="primary" fullWidth>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default JobStatusDialog;

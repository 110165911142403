import React, { useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { StateObjectRef } from "molstar/lib/mol-state";

import http from "../../net/http-common";
import Grid from "@mui/material/Grid";
import Viewer from "../common/MolViewer";
import { LinearProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",

  p: 4,
};

function DyMean() {
  const [target, setTarget] = useState("");
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [inProgress, setInProgress] = useState(true);
  const [targetData, setTargetData] = useState<any>();
  const [selectedRow, setSelectedRow] = useState(-1);
  const [currentRef, setCurrentRef] = useState<StateObjectRef>(null);
  const [showModal, setShowModal] = useState(false);

  let pdb_viewer = useRef<Viewer>();
  let orig_viewer = useRef<Viewer>();

  const handleChange = (event: SelectChangeEvent) => {
    setInProgress(true);
    setIsTableVisible(false);
    setTarget(event.target.value);
  };

  const handleSelectRow = (i: number, v: any) => {
    setSelectedRow(i);
    setTarget(v);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  useEffect(() => {
    http
      .post(`/models/dymean/summary`, "", {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        //console.log(response);
        setTargetData(response.data);
        setInProgress(false);
        setIsTableVisible(true);
      });
  }, []);

  const initViewer = async (id: string) => {
    if (id === "dymol-origviewer") {
      pdb_viewer.current = new Viewer();
      await pdb_viewer.current.init(id, {
        layoutShowControls: true,
        viewportShowExpand: false,
        collapseLeftPanel: true,
        layoutShowSequence: true,
      });
    } else {
      orig_viewer.current = new Viewer();
      await orig_viewer.current.init(id, {
        layoutShowControls: true,
        viewportShowExpand: false,
        collapseLeftPanel: true,
        layoutShowSequence: true,
      });
    }
  };

  // const updateViewer = async (
  //   data: string,
  //   format:
  //     | "mmcif"
  //     | "cifCore"
  //     | "pdb"
  //     | "pdbqt"
  //     | "gro"
  //     | "xyz"
  //     | "mol"
  //     | "sdf"
  //     | "mol2"
  // ) => {
  //   initViewer().then(() => {
  //     const _ref = viewer?.current?.loadStructureFromData(
  //       data,
  //       format,
  //       { dataLabel: "" },
  //       currentRef
  //     );
  //   });
  // };

  useEffect(() => {
    if (selectedRow === -1) return;
    http
      .post(`/models/dymean/getpdbs`, "", {
        params: {
          target: target,
        },
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        // //console.log(response);
        initViewer("dymol-origviewer").then(() => {
          const _ref = pdb_viewer?.current?.loadStructureFromData(
            response.data.pdb,
            "pdb",
            { dataLabel: "" },
            currentRef
          );
        });
        initViewer("dymol-pdbviewer").then(() => {
          const _ref = orig_viewer?.current?.loadStructureFromData(
            response.data.model_pdb,
            "pdb",
            { dataLabel: "" },
            currentRef
          );
        });
      });
  }, [selectedRow, target]);

  return (
    <Box sx={{ minWidth: 120 }}>
      <Grid container spacing={2} className="viewer-container">
        <Grid item xs={8} md={8}>
          {inProgress && <LinearProgress></LinearProgress>}
        </Grid>
      </Grid>
      <>
        {isTableVisible && (
          <>
            <Grid container spacing={2} className="viewer-container">
              <Grid item xs={8} md={8}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="left">PDB</TableCell>
                        <TableCell align="left">HEAVY CHAIN</TableCell>
                        <TableCell align="left">LIGHT CHAIN</TableCell>
                        <TableCell align="left">CDR TYPE</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(targetData).map((v, i) => {
                        return (
                          <>
                            <TableRow
                              key={i}
                              onClick={() => handleSelectRow(i, v)}
                              selected={i === selectedRow}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">{i}</TableCell>
                              <TableCell align="left">
                                {targetData[v].pdb}
                              </TableCell>
                              <TableCell align="left">
                                {targetData[v].H}
                              </TableCell>
                              <TableCell align="left">
                                {targetData[v].L}
                              </TableCell>
                              <TableCell align="left">
                                {targetData[v].cdr_type}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Modal open={showModal} onClose={handleClose} keepMounted>
                <Box sx={style}>
                  {showModal && (
                    <Grid>
                      <Typography id="modal-title" variant="h6" component="h6">
                        PDB ID : {target}
                      </Typography>
                      <p>
                        Heavy Chain : {targetData ? targetData[target].H : ""}
                      </p>
                      <p>
                        Light Chain : {targetData ? targetData[target].L : ""}
                      </p>
                      <p>
                        CDR Type :
                        {targetData ? targetData[target].cdr_type : ""}
                      </p>
                      <p>TM Score : NA</p>
                    </Grid>
                  )}
                  <Grid container spacing={2} className="viewer-container">
                    <Grid item xs={6} md={6}>
                      <Typography variant="h6" component="h6">
                        Original PDB
                      </Typography>
                      <div
                        id="dymol-origviewer"
                        style={{
                          height: "600px",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        Select any molecule to visualize
                      </div>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Typography variant="h6" component="h6">
                        Model PDB
                      </Typography>
                      <div
                        id="dymol-pdbviewer"
                        style={{
                          height: "600px",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        Select any molecule to visualize
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
            </Grid>
          </>
        )}
      </>
    </Box>
  );
}

export default DyMean;

import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  DataGridPro,
  GridColDef,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridFilterItem,
  getGridNumericOperators,
  GridFilterOperator,
  gridClasses,
  GridColumnGroupingModel,
} from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Card,
  Grid,
  Tab,
  ToggleButtonGroup,
  CircularProgress,
  Typography,
  Snackbar,
  Alert,
  Autocomplete,
  TextField,
  FormControl,
  dialogActionsClasses,
} from "@mui/material";
import http from "../../net/http-common";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { TabPanel } from "../../components/common/TabPanel";
import { tableStateType } from "../AdmeTox/ADMEPropertyFilter";
import WindowIcon from "@mui/icons-material/Window";
import ScreenieGridView from "../../components/Properties/ScreenieGridView";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { JOB_SERVER_URL } from "../../config";

const columnsNumMapping: any = {
  "Gen SA Score": 0,
  "Input SA Score": 1,
  "Gen QED": 2,
  "Input QED": 3,
  "Gen HIA_HOU": 4,
  "Input HIA_HOU": 5,
  "Gen VDSS": 6,
  "Input VDSS": 7,
  "Gen Half-Life": 8,
  "Input Half-Life": 9,
  "Gen Hepatotoxicity": 10,
  "Input Hepatotoxicity": 11,
  "Gen Cardiotoxicity": 12,
  "Input Cardiotoxicity": 13,
  "Input Carcinogenecity": 14,
  "Gen Carcinogenecity": 15,
  "Gen Respiratory Toxicity": 16,
  "Input Respiratory Toxicity": 17,
  "Gen Mutagenicity": 18,
  "Input Mutagenicity": 19,
  "Gen Cytotoxicity": 20,
  "Input Cytotoxicity": 21,
  "Gen Lowest Observed Adverse Effect Level": 22,
  "Input Lowest Observed Adverse Effect Level": 23,
};

const numColumnMapping: any = {
  0: "Gen-SA-Score",
  1: "Inp-SA-Score",
  2: "Gen-QED",
  3: "Inp-QED",
  4: "Gen-HIA(Human-Intestinal-Absorption)",
  5: "Inp-HIA(Human-Intestinal-Absorption)",
  6: "Gen-VDSS",
  7: "Inp-VDSS",
  8: "Gen-Half-Life",
  9: "Inp-Half-Life",
  10: "Gen-Hepatotoxicity",
  11: "Inp-Hepatotoxicity",
  12: "Gen-Cardiotoxicity",
  13: "Inp-Cardiotoxicity",
  14: "Inp-Carcinogenecity",
  15: "Gen-Carcinogenecity",
  16: "Gen-Respiratory-toxicity",
  17: "Inp-Respiratory-toxicity",
  18: "Gen-Mutagenicity",
  19: "Inp-Mutagenicity",
  20: "Gen-Cytotoxicity",
  21: "Inp-Cytotoxicity",
  22: "Gen-Lowest-Observed-Adverse-Effect-Level",
  23: "Inp-Lowest-Observed-Adverse-Effect-Level",
};
const columnList = [
  "Gen SA Score",
  "Input SA Score",
  "Gen QED",
  "Input QED",
  "Gen Human Int. Absorption",
  "Input Human Int. Absorption",
  "Gen VDSS",
  "Input VDSS",
  "Gen Half-Life",
  "Input Half-Life",
  "Gen Hepatotoxicity",
  "Input Hepatotoxicity",
  "Gen Cardiotoxicity",
  "Input Cardiotoxicity",
  "Input Carcinogenecity",
  "Gen Carcinogenecity",
  "Gen Respiratory Toxicity",
  "Input Respiratory Toxicity",
  "Gen Mutagenicity",
  "Input Mutagenicity",
  "Gen Cytotoxicity",
  "Input Cytotoxicity",
  "Gen Lowest Observed Adverse Effect Level",
  "Input Lowest Observed Adverse Effect Level",
];

const columnComparisonOperator: GridFilterOperator = {
  label: "Greater than",
  value: "columnGreaterThan",
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    if (!filterItem.field || !numColumnMapping[filterItem.value]) {
      return null;
    }
    return ({ row }: { row: any }) => {
      const colValue = row[filterItem.field]; // Main column's value
      const comparisonColValue = row[numColumnMapping[filterItem.value]]; // Comparison column's value
      if (colValue == null || comparisonColValue == null) {
        return false;
      }
      return colValue > comparisonColValue;
    };
  },
  InputComponent: ({ item, applyValue }) => {
    const [selectedValues, setSelectedValues] = useState(
      numColumnMapping[item.value] || ""
    );
    const handleFilterChange = (event: any, newValue: any) => {
      setSelectedValues(newValue);
      item.value = columnsNumMapping[newValue];
      applyValue({ ...item });
    };
    return (
      <Box>
        <Autocomplete
          freeSolo
          options={columnList}
          value={selectedValues}
          onChange={handleFilterChange}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Select column" />
          )}
        />
      </Box>
    );
  },
};

const columnComparisonOperatorgte: GridFilterOperator = {
  label: "Greater than equal to",
  value: "columnGreaterThanEqualTo",
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    if (!filterItem.field || !numColumnMapping[filterItem.value]) {
      return null; // No filter applied if either is missing
    }

    return ({ row }: { row: any }) => {
      const colValue = row[filterItem.field];
      const comparisonColValue = row[numColumnMapping[filterItem.value]]; // Comparison column's value

      if (colValue == null || comparisonColValue == null) {
        return false;
      }
      return colValue >= comparisonColValue;
    };
  },
  InputComponent: ({ item, applyValue }) => {
    const [selectedValues, setSelectedValues] = useState(
      numColumnMapping[item.value] || ""
    );
    const handleFilterChange = (event: any, newValue: any) => {
      setSelectedValues(newValue);
      item.value = columnsNumMapping[newValue];
      applyValue({ ...item }); // Update the filter item value
    };
    return (
      <Box>
        <Autocomplete
          freeSolo
          options={columnList}
          value={selectedValues}
          onChange={handleFilterChange}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Select column" />
          )}
        />
      </Box>
    );
  },
};

export const CustomToolbar = ({
  props,
  isExport = true,
}: {
  props: any;
  isExport?: boolean;
}) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {isExport && (
        <GridToolbarExport
          printOptions={{
            disableToolbarButton: true,
          }}
        />
      )}
    </GridToolbarContainer>
  );
};

const LeaderReinventVisualize = () => {
  const [tabValue, setTabValue] = useState("1");
  const [alignment, setAlignment] = useState("grid");
  const scrollToRef = useRef(null);
  const [rows, setRows] = useState<any[]>([]);
  const [moleculeFilter, setMoleculeFilter] = useState<any>(null);
  const [densityAndRows, setDensityAndRows] = useState<tableStateType>({
    density: "standard",
    pageSize: 5,
  });
  const [histograms, setHistograms] = useState<any[]>([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleScroll = () => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const leaderReinvent = useSelector(
    (state: any) => state.leaderReinventProperty.properties
  );

  let table = useGridApiRef();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  const handleTableStateChange = (
    newState: any,
    setFilterState: React.Dispatch<any>
  ) => {
    setFilterState(newState.filter.filterModel);
  };

  const customOperatorList = [
    ...getGridNumericOperators().filter((op) =>
      ["=", "!=", ">", ">=", "<", "<="].includes(op.value)
    ),
    columnComparisonOperator,
    columnComparisonOperatorgte,
  ];

  const cols: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "SMILES",
      headerName: "SMILES",
      editable: false,
      headerAlign: "center",
      width: 200,
    },
    {
      field: "svg",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      disableExport: true,
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{
            height: "200px",
            width: "80%",
            transition: "transform 0.3s ease-in-out",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(150%)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "scale(100%)";
          }}
        />
      ),
    },
    {
      field: "Input_SMILES",
      headerName: "Input SMILES",
      editable: false,
      headerAlign: "center",
      width: 200,
    },
    {
      field: "input_svg",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      disableExport: true,
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{
            height: "200px",
            width: "80%",
            transition: "transform 0.3s ease-in-out",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(150%)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "scale(100%)";
          }}
        />
      ),
    },
    {
      field: "Tanimoto",
      headerName: "Tanimoto",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Inp-SA-Score",
      headerName: "Input SA Score",
      width: 140,
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: customOperatorList,
    },
    {
      field: "Gen-SA-Score",
      type: "number",
      width: 140,
      headerName: "Gen SA Score",
      headerAlign: "center",
      align: "center",
      filterOperators: customOperatorList,
    },
    {
      field: "Inp-QED",
      headerName: "Input QED",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: customOperatorList,
    },
    {
      field: "Gen-QED",
      headerName: "Gen QED",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: customOperatorList,
    },
    {
      field: "Inp-HIA(Human-Intestinal-Absorption)",
      headerName: "Input HIA_HOU",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: customOperatorList,
    },
    {
      field: "Gen-HIA(Human-Intestinal-Absorption)",
      headerName: "Gen HIA_HOU",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: customOperatorList,
    },
    {
      field: "Inp-VDSS",
      headerName: "Input VDSS",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: customOperatorList,
    },
    {
      field: "Gen-VDSS",
      headerName: "Gen VDSS",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: customOperatorList,
    },
    {
      field: "Inp-Half-Life",
      headerName: "Input Half-Life",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: customOperatorList,
    },
    {
      field: "Gen-Half-Life",
      headerName: "Gen Half-Life",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: customOperatorList,
    },
    {
      field: "Inp-Hepatotoxicity",
      headerName: "Input Hepatotoxicity",
      width: 140,
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: customOperatorList,
    },
    {
      field: "Gen-Hepatotoxicity",
      headerName: "Gen Hepatotoxicity",
      width: 140,
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: customOperatorList,
    },
    {
      field: "Inp-Cardiotoxicity",
      headerName: "Input Cardiotoxicity",
      width: 140,
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: customOperatorList,
    },
    {
      field: "Gen-Cardiotoxicity",
      headerName: "Gen Cardiotoxicity",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: customOperatorList,
    },
  ];

  if (!leaderReinvent.isSingleInput) {
    cols.push(
      {
        field: "Inp-Carcinogenecity",
        headerName: "Input Carcinogenecity",
        width: 140,
        type: "number",
        headerAlign: "center",
        align: "center",
        filterOperators: customOperatorList,
      },
      {
        field: "Gen-Carcinogenecity",
        headerName: "Gen Carcinogenecity",
        width: 140,
        type: "number",
        headerAlign: "center",
        align: "center",
        filterOperators: customOperatorList,
      },
      {
        field: "Inp-Respiratory-toxicity",
        headerName: "Input Respiratory Toxicity",
        width: 140,
        type: "number",
        headerAlign: "center",
        align: "center",
        filterOperators: customOperatorList,
      },
      {
        field: "Gen-Respiratory-toxicity",
        headerName: "Gen Respiratory Toxicity",
        width: 140,
        type: "number",
        headerAlign: "center",
        align: "center",
        filterOperators: customOperatorList,
      },
      {
        field: "Inp-Mutagenicity",
        headerName: "Input Mutagenicity",
        type: "number",
        width: 140,
        headerAlign: "center",
        align: "center",
        filterOperators: customOperatorList,
      },
      {
        field: "Gen-Mutagenicity",
        headerName: "Gen Mutagenicity",
        type: "number",
        width: 140,
        headerAlign: "center",
        align: "center",
        filterOperators: customOperatorList,
      },
      {
        field: "Inp-Cytotoxicity",
        headerName: "Input Cytotoxicity",
        type: "number",
        headerAlign: "center",
        align: "center",
        filterOperators: customOperatorList,
      },
      {
        field: "Gen-Cytotoxicity",
        headerName: "Gen Cytotoxicity",
        type: "number",
        headerAlign: "center",
        align: "center",
        filterOperators: customOperatorList,
      },
      {
        field: "Inp-Lowest-Observed-Adverse-Effect-Level",
        headerName: "Input Lowest Observed Adverse Effect Level",
        width: 200,
        type: "number",
        headerAlign: "center",
        align: "center",
        filterOperators: customOperatorList,
      },
      {
        field: "Gen-Lowest-Observed-Adverse-Effect-Level",
        headerName: "Gen Lowest Observed Adverse Effect Level",
        width: 200,
        type: "number",
        headerAlign: "center",
        align: "center",
        filterOperators: customOperatorList,
      }
    );
  }

  const columnGrouping: GridColumnGroupingModel = [
    {
      groupId: "Molecule",
      headerName: "Molecule",
      children: [
        { field: "id" },
        { field: "SMILES" },
        { field: "svg" },
        { field: "Input_SMILES" },
        { field: "input_svg" },
        { field: "Tanimoto" },
      ],
    },
    {
      groupId: "SA Score",
      headerName: "SA Score",
      children: [{ field: "Inp-SA-Score" }, { field: "Gen-SA-Score" }],
    },
    {
      groupId: "QED",
      headerName: "QED",
      children: [{ field: "Inp-QED" }, { field: "Gen-QED" }],
    },
    {
      groupId: "HIA/HOU",
      headerName: "HIA/HOU",
      children: [
        { field: "Inp-HIA(Human-Intestinal-Absorption)" },
        { field: "Gen-HIA(Human-Intestinal-Absorption)" },
      ],
    },
    {
      groupId: "VDSS",
      headerName: "VDSS",
      children: [{ field: "Inp-VDSS" }, { field: "Gen-VDSS" }],
    },
    {
      groupId: "Half-Life",
      headerName: "Half-Life",
      children: [{ field: "Inp-Half-Life" }, { field: "Gen-Half-Life" }],
    },
    {
      groupId: "Hepatotoxicity",
      headerName: "Hepatotoxicity",
      children: [
        { field: "Inp-Hepatotoxicity" },
        { field: "Gen-Hepatotoxicity" },
      ],
    },
    {
      groupId: "Cardiotoxicity",
      headerName: "Cardiotoxicity",
      children: [
        { field: "Inp-Cardiotoxicity" },
        { field: "Gen-Cardiotoxicity" },
      ],
    },
    {
      groupId: "Carcinogenicity",
      headerName: "Carcinogenicity",
      children: [
        { field: "Inp-Carcinogenecity" },
        { field: "Gen-Carcinogenecity" },
      ],
    },
    {
      groupId: "Respiratory Toxicity",
      headerName: "Respiratory Toxicity",
      children: [
        { field: "Inp-Respiratory-toxicity" },
        { field: "Gen-Respiratory-toxicity" },
      ],
    },
    {
      groupId: "Mutagenicity",
      headerName: "Mutagenicity",
      children: [{ field: "Inp-Mutagenicity" }, { field: "Gen-Mutagenicity" }],
    },
    {
      groupId: "Cytotoxicity",
      headerName: "Cytotoxicity",
      children: [{ field: "Inp-Cytotoxicity" }, { field: "Gen-Cytotoxicity" }],
    },
    {
      groupId: "Lowest Observed Adverse Effect Level",
      headerName: "Lowest Observed Adverse Effect Level",
      children: [
        { field: "Inp-Lowest-Observed-Adverse-Effect-Level" },
        { field: "Gen-Lowest-Observed-Adverse-Effect-Level" },
      ],
    },
  ];

  const fieldsToRound = [
    "Gen-Cardiotoxicity",
    "Gen-HIA(Human-Intestinal-Absorption)",
    "Gen-Half-Life",
    "Gen-Hepatotoxicity",
    "Gen-QED",
    "Gen-SA-Score",
    "Gen-VDSS",
    "Inp-Cardiotoxicity",
    "Tanimoto",
    "Inp-HIA(Human-Intestinal-Absorption)",
    "Inp-Half-Life",
    "Inp-Hepatotoxicity",
    "Inp-QED",
    "Inp-SA-Score",
    "Inp-VDSS",
    "Inp-Human-Int.-Absorption",
    "Gen-Human-Int.-Absorption",
    "Gen-HIA(Human-Intestinal-Absorption)",
    "Inp-HIA(Human-Intestinal-Absorption)",
    "Inp-Carcinogenecity",
    "Gen-Respiratory-toxicity",
    "Inp-Respiratory-toxicity",
    "Gen-Mutagenicity",
    "Inp-Mutagenicity",
    "Gen-Cytotoxicity",
    "Inp-Cytotoxicity",
    "Gen-Lowest-Observed-Adverse-Effect-Level",
    "Inp-Lowest-Observed-Adverse-Effect-Level",
    "Gen-Carcinogenecity",
    "Gen-Clinical-Toxicity",
    "Inp-Clinical-Toxicity",
  ];

  const plotNameMap: any = {
    Tanimoto: "Tanimoto",
    "Gen-SA-Score": "Generated Synthetic Accessibility Score",
    "Inp-SA-Score": "Input Synthetic Accessibility Score",
    "Gen-QED": "Generated QED",
    "Inp-QED": "Input QED",
    "Gen-HIA(Human-Intestinal-Absorption)":
      "Generated HIA (Human Intestinal Absorption)",
    "Inp-HIA(Human-Intestinal-Absorption)":
      "Input HIA (Human Intestinal Absorption)",
    "Gen-VDSS": "Generated Volume of Distribution (VDSS)",
    "Inp-VDSS": "Input Volume of Distribution (VDSS)",
    "Gen-Half-Life": "Generated Half-Life",
    "Inp-Half-Life": "Input Half-Life",
    "Gen-Hepatotoxicity": "Generated Hepatotoxicity",
    "Inp-Hepatotoxicity": "Input Hepatotoxicity",
    "Gen-Cardiotoxicity": "Generated Cardiotoxicity",
    "Inp-Cardiotoxicity": "Input Cardiotoxicity",
    "Gen-Clinical-Toxicity": "Generated Clinical Toxicity",
    "Inp-Clinical-Toxicity": "Input Clinical Toxicity",
    "Gen-Carcinogenecity": "Generated Carcinogenicity",
    "Inp-Carcinogenecity": "Input Carcinogenicity",
    "Gen-Respiratory-toxicity": "Generated Respiratory Toxicity",
    "Inp-Respiratory-toxicity": "Input Respiratory Toxicity",
    "Gen-Mutagenicity": "Generated Mutagenicity",
    "Inp-Mutagenicity": "Input Mutagenicity",
    "Gen-Cytotoxicity": "Generated Cytotoxicity",
    "Inp-Cytotoxicity": "Input Cytotoxicity",
    "Gen-Lowest-Observed-Adverse-Effect-Level":
      "Generated LOAEL (Lowest Observed Adverse Effect Level)",
    "Inp-Lowest-Observed-Adverse-Effect-Level":
      "Input LOAEL (Lowest Observed Adverse Effect Level)",
  };

  useEffect(() => {
    if (Array.isArray(leaderReinvent?.data)) {
      const modifiedRows = leaderReinvent.data.map((row: any, id: number) => {
        let newRow = { ...row, id };

        fieldsToRound.forEach((field) => {
          if (newRow[field]) {
            newRow[field] = parseFloat(newRow[field].toFixed(3));
          }
        });
        return newRow;
      });
      setRows(modifiedRows);
    } else if (leaderReinvent.data === null && !leaderReinvent.loading) {
      navigate("/leader/leader");
    }
  }, [leaderReinvent]);

  useEffect(() => {
    const fetchHistograms = async () => {
      try {
        setLoader(true);
        if (rows.length === 0) return;
        const response = await http.post(
          `${JOB_SERVER_URL}/reinvent/generate_histograms`,
          rows
        );
        setHistograms(response.data);
      } catch (error: any) {
        setShowError(true);
        setErrorMsg("Unable to construct Plots");
      } finally {
        setLoader(false);
      }
    };

    fetchHistograms();
  }, [rows]);

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        aria-label="Platform"
        sx={{
          margin: "-1rem 1rem 0rem 3rem",
          display: tabValue === "1" ? "flex" : "none",
          justifyContent: "right",
        }}
      >
        <Button
          sx={{
            fontSize: 12,
            paddingTop: 1,
            paddingBottom: 1,
            mx: 1,
            backgroundColor: "#582FF1;",
            fontWeight: "bold",
          }}
          onClick={handleScroll}
          variant="contained"
          startIcon={<WindowIcon sx={{ fontSize: 12 }} />}
          size="large"
        >
          Grid View
        </Button>
      </ToggleButtonGroup>
      <Grid container spacing={2} p={2}>
        <Grid item container px={1} pt={3} height={"100%"}>
          <Grid item width={"100%"} sx={{ overflowY: "auto" }} height={"100%"}>
            <Card sx={{ position: "relative" }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="filter tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Molecules" value="1" />
                    <Tab label="Stats" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Box sx={{ width: "100%", height: "700px" }}>
                    <DataGridPro
                      apiRef={table}
                      experimentalFeatures={{ columnGrouping: true }}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          fontWeight: "550",
                          fontSize: "0.9rem",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          // Forced to use important since overriding inline styles
                          maxHeight: "155px !important",
                          textAlign: "center",
                          fontWeight: "800 !important",
                        },
                        "& .MuiDataGrid-columnHeaderTitleContainer": {
                          display: "flex",
                          justifyContent: "center",
                        },
                      }}
                      rows={rows}
                      columns={cols}
                      columnGroupingModel={columnGrouping}
                      disableRowSelectionOnClick
                      rowHeight={100}
                      pagination
                      density={
                        densityAndRows.density
                          ? densityAndRows.density
                          : "standard"
                      }
                      onStateChange={(newState) =>
                        handleTableStateChange(newState, setMoleculeFilter)
                      }
                      slotProps={{
                        filterPanel: {
                          sx: {
                            width: 1000,
                            [`& .${gridClasses["filterFormColumnInput"]}`]: {
                              width: "fit-content !important",
                            },
                            [`& .${gridClasses["filterFormOperatorInput"]}`]: {
                              width: "fit-content !important",
                            },
                            [`& .${gridClasses["filterFormValueInput"]}`]: {
                              width: "400px!important",
                            },
                          },
                        },
                      }}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: densityAndRows.pageSize
                              ? densityAndRows.pageSize
                              : 5,
                          },
                        },
                      }}
                      pinnedColumns={{
                        left: [
                          "id",
                          "SMILES",
                          "svg",
                          "Input_SMILES",
                          "input_svg",
                          "Tanimoto",
                        ], // Columns in "Identification" group
                      }}
                      pageSizeOptions={[5, 10, 20]}
                      slots={{
                        toolbar: (props) => <CustomToolbar {...props} />,
                      }}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value="2">
                  <Box sx={{ width: "100%", height: "100%", p: 4 }}>
                    <Typography variant="h3" align="center" sx={{ mb: 4 }}>
                      Molecular Properties Analysis
                    </Typography>

                    <Grid container>
                      {histograms.map((it: any, idx: number) => (
                        <Grid item xs={6} key={idx}>
                          <Typography variant="h6" marginLeft={9} marginTop={3}>
                            {plotNameMap[it.property]}
                            <Typography color="textSecondary" align="left">
                              Avg Value: {it.avg_value}
                            </Typography>
                          </Typography>
                          <Box
                            component="img"
                            src={`data:image/svg+xml;base64,${it.plot}`}
                            sx={{
                              maxWidth: "100%",
                              height: "auto",
                              mx: "auto",
                            }}
                            alt="Molecular Property Plot"
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </TabPanel>
              </TabContext>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Box
        ref={scrollToRef}
        pb={2}
        sx={{ display: tabValue !== "1" ? "none" : "block" }}
      >
        <ScreenieGridView
          isTopLevelEnable={false}
          tabValue="1"
          allRows={rows}
          filters={[moleculeFilter]}
          model="leader_reinvent"
        />
      </Box>
      <IndefiniteLoader state={leaderReinvent.loading || loader} />
    </>
  );
};

export default LeaderReinventVisualize;
